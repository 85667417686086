import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const drugAlcohol: AnswerPathTreeNode = {
  path: 'drugAlcohol',
  children: [
    { path: 'treatment', nodeId: NodeIds.drugAlcoholTreatment },
    { path: 'choices', nodeId: NodeIds.drugAlcoholTreatmentChoices },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const familyHistory: AnswerPathTreeNode = {
  path: 'familyHistory',
  children: [
    { path: 'hasFamilyHistory2', nodeId: NodeIds.healthInsuredFamilyHasFamilyHistory2 },
    { path: 'hasFamilyMedicalConditions2', nodeId: NodeIds.healthInsuredFamilyHasMedicalHistory2 },
    {
      path: 'familyHistory2',
      children: [
        { path: 'hasFamilyHistory2', nodeId: NodeIds.healthInsuredHasFamilyHistory2 },
        { path: 'hasFamilyMedicalHistory2', nodeId: NodeIds.healthInsuredHasFamilyMedicalHistory2 },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const lumbago: AnswerPathTreeNode = {
  path: 'lumbago',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredLumbagoIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredLumbagoPreviousEpisodesCount },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredLumbagoPainLocation },
        { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredLumbagoDateSymptoms },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredLumbagoPainCause },
        { path: 'details', nodeId: NodeIds.healthInsuredLumbagoPainCauseDetails },
      ],
    },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredLumbagoHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredLumbagoHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredLumbagoMedicationWasPrescribed },

    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredLumbagoHadOperation },
        { path: 'performedOrPlanned', nodeId: NodeIds.healthInsuredLumbagoOperationPerformedOrPlanned },
        { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthInsuredLumbagoTypeOfOperationPerformed },
        { path: 'performedDate', nodeId: NodeIds.healthInsuredLumbagoOperationPerformedDate },
        { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthInsuredLumbagoTypeOfOperationPlanned },
        { path: 'plannedDate', nodeId: NodeIds.healthInsuredLumbagoOperationPlannedDate },
        { path: 'unknownDate', nodeId: NodeIds.healthInsuredLumbagoOperationUnknownDate },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredLumbagoHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredLumbagoAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredLumbagoAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredLumbagoAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredLumbagoAbsenceRecoveryDate },
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredLumbagoAbsenceHasRecovered },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredLumbagoAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredLumbagoHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredLumbagoHasConsultedDoctorsProfession },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredLumbagoHasConsultedDoctorsProfessionDetails,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredLumbagoHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredLumbagoHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredLumbagoHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredLumbagoHasConsultedDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredLumbagoHasConsultedDoctorsResult },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredLumbagoHasConsultedDoctorsFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredLumbagoHasConsultedDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredLumbagoHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredLumbagoHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredLumbagoHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredLumbagoHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredLumbagoHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredLumbagoHasTakenTestsOrExamsResult },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredLumbagoHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredLumbagoMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredLumbagoMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredLumbagoMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredLumbagoMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredLumbagoMedicationEndDate },
      ],
    },
  ],
};

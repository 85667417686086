import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const lifestyle: AnswerPathTreeNode = {
  path: 'lifestyle',
  children: [
    { path: 'receivedDrugOrAlcoholCounselling', nodeId: NodeIds.lifestyleReceivedDrugOrAlcoholCounselling },
    { path: 'chargedOrConvicted', nodeId: NodeIds.lifestyleChargedOrConvicted },
    { path: 'engagedInHazardousActivities', nodeId: NodeIds.lifestyleHasEngagedInHazardousActivities },
    { path: 'heartCirculatory', nodeId: NodeIds.healthHeartCirculatory },
    { path: 'heartCirculatorySystemChoices', nodeId: NodeIds.healthHeartCirculatorySystemChoices },
    { path: 'insuredHasPendingFelonies', nodeId: NodeIds.insuredHasPendingFelonies },
    { path: 'hasInsuredBeenOnProbation', nodeId: NodeIds.hasInsuredBeenOnProbation },
    { path: 'hasInsuredUsedTobaccoWithinAYear', nodeId: NodeIds.hasInsuredUsedTobaccoWithinAYear },
    { path: 'lifeStyleQuantityBigCigars', nodeId: NodeIds.lifeStyleQuantityBigCigars },
    { path: 'lifestyleAlcoholHabit', nodeId: NodeIds.lifestyleAlcoholHabit },
    { path: 'lifestyleAlcoholHabitChoice', nodeId: NodeIds.lifestyleAlcoholHabitChoice },
    { path: 'occupationInCategory', nodeId: NodeIds.lifestyleOccupationInCategory },
    { path: 'flownAsPrivatePilot', nodeId: NodeIds.lifestyleFlownAsPrivatePilot },
    { path: 'extremeSport', nodeId: NodeIds.lifestyleExtremeSport },
    { path: 'extremeSportChoices', nodeId: NodeIds.lifestyleExtremeSportChoices },
    { path: 'activelyWorking', nodeId: NodeIds.lifestyleActivelyWorking },
    {
      path: 'statusNeitherStudentStayingHomeOrRetired',
      nodeId: NodeIds.lifestyleStatusNeitherStudentStayingHomeOrRetired,
    },
    {
      path: 'indictableOffenceOtherThanImpairedDriving',
      nodeId: NodeIds.lifestyleIndictableOffenceOtherThanImpairedDriving,
    },
    { path: 'underInvestigationsCircumstances', nodeId: NodeIds.lifestyleUnderInvestigationsCircumstances },
    { path: 'underInvestigationsDate', nodeId: NodeIds.lifestyleUnderInvestigationsDate },
    { path: 'underInvestigationsCharges', nodeId: NodeIds.lifestyleUnderInvestigationsCharges },
    { path: 'trialCircumstances', nodeId: NodeIds.lifestyleTrialCircumstances },
    { path: 'trialDate', nodeId: NodeIds.lifestyleTrialDate },
    { path: 'trialCharges', nodeId: NodeIds.lifestyleTrialCharges },
    { path: 'probationStartDate', nodeId: NodeIds.lifestyleProbationStartDate },
    { path: 'probationEndDate', nodeId: NodeIds.lifestyleProbationEndDate },
    { path: 'probationCircumstances', nodeId: NodeIds.lifestyleProbationCircumstances },
    { path: 'probationDate', nodeId: NodeIds.lifestyleProbationDate },
    { path: 'probationCharges', nodeId: NodeIds.lifestyleProbationCharges },
    { path: 'probationSentenceDetails', nodeId: NodeIds.lifestyleProbationSentenceDetails },
    { path: 'anyOtherOffences', nodeId: NodeIds.lifestyleAnyOtherOffences },
    { path: 'otherOffencesCircumstances', nodeId: NodeIds.lifestyleOtherOffencesCircumstances },
    { path: 'otherOffencesDate', nodeId: NodeIds.lifestyleOtherOffencesDate },
    { path: 'otherOffencesCharges', nodeId: NodeIds.lifestyleOtherOffencesCharges },
    {
      path: 'chargedOrConvicted',
      children: [
        { path: 'circumstances', nodeId: NodeIds.lifeStyleInsuredChargedOrConvictedCircumstances },
        { path: 'date', nodeId: NodeIds.lifeStyleInsuredChargedOrConvictedDate },
        { path: 'charges', nodeId: NodeIds.lifeStyleInsuredChargedOrConvictedCharges },
        { path: 'sentenceDetails', nodeId: NodeIds.lifeStyleInsuredChargedOrConvictedSentenceDetails },
      ],
    },
    {
      path: 'additionOffence',
      children: [
        { path: 'hasAdditionalOffence', nodeId: NodeIds.lifeStyleInsuredHasAdditionalOffence },
        {
          path: 'circumstances',
          nodeId: NodeIds.lifeStyleInsuredChargedOrConvictedAdditionalOffenceCircumstances,
        },
        {
          path: 'date',
          nodeId: NodeIds.lifeStyleInsuredChargedOrConvictedAdditionalOffencesDate,
        },
        {
          path: 'charges',
          nodeId: NodeIds.lifeStyleInsuredChargedOrConvictedAdditionalOffencesCharges,
        },
      ],
    },
    {
      path: 'travel',
      children: [
        { path: 'moreThan6Months', nodeId: 'lifestyle-travel-more-than-6-months' },
        { path: 'otherThanApprovedCountries', nodeId: NodeIds.lifestyleTravelOtherThanApprovedCountries },
        {
          path: 'pastTravel',
          nodeId: NodeIds.lifestyleTravelPastDetails,
          isCollection: true,
          children: [
            { path: 'countriesVisited', nodeId: NodeIds.lifestyleTravelPastDetailsCountriesVisited },
            { path: 'dateOfTravel', nodeId: NodeIds.lifestyleTravelPastDetailsDateOfTravel },
            { path: 'lengthOfStayInDays', nodeId: NodeIds.lifestyleTravelPastDetailsLengthOfStayInDays },
            { path: 'purpose', nodeId: NodeIds.lifestyleTravelPastDetailsPurpose },
            { path: 'purposeOther', nodeId: NodeIds.lifestyleTravelPastDetailsPurposeOther },
            { path: 'travelWithin02Years', nodeId: 'lifestyle-travel-past-details-within-02years' },
            { path: 'last12MonthsDestination', nodeId: NodeIds.lifestyleInsuredTravelLast12MonthsDestination },
            {
              path: 'last12MonthsDestinationOther',
              nodeId: NodeIds.lifestyleInsuredTravelLast12MonthsDestinationOther,
            },
            { path: 'last12MonthsReason', nodeId: NodeIds.lifestyleInsuredTravelLast12MonthsReason },
            { path: 'last12MonthsReasonOther', nodeId: NodeIds.lifestyleInsuredTravelLast12MonthsReasonOther },
            {
              path: 'last12MonthsDepartureDate',
              nodeId: NodeIds.lifestyleInsuredTravelLast12MonthsDepartureDate,
            },
            { path: 'last12MonthsReturnDate', nodeId: NodeIds.lifestyleInsuredTravelLast12MonthsReturnDate },
          ],
        },
        {
          path: 'futureTravel',
          nodeId: NodeIds.lifestyleTravelFutureDetails,
          isCollection: true,
          children: [
            { path: 'countriesVisited', nodeId: NodeIds.lifestyleTravelFutureDetailsCountriesVisited },
            { path: 'dateOfTravel', nodeId: NodeIds.lifestyleTravelFutureDetailsDateOfTravel },
            { path: 'lengthOfStayInDays', nodeId: NodeIds.lifestyleTravelFutureDetailsLengthOfStayInDays },
            { path: 'purpose', nodeId: NodeIds.lifestyleTravelFutureDetailsPurpose },
            { path: 'purposeOther', nodeId: NodeIds.lifestyleTravelFutureDetailsPurposeOther },
            { path: 'travelWithin02Years', nodeId: 'lifestyle-travel-future-details-within-02years' },
            { path: 'next12MonthsDestination', nodeId: NodeIds.lifestyleInsuredTravelNext12MonthsDestination },
            {
              path: 'next12MonthsDestinationOther',
              nodeId: NodeIds.lifestyleInsuredTravelNext12MonthsDestinationOther,
            },
            { path: 'next12MonthsReason', nodeId: NodeIds.lifestyleInsuredTravelNext12MonthsReason },
            { path: 'next12MonthsReasonOther', nodeId: NodeIds.lifestyleInsuredTravelNext12MonthsReasonOther },
            {
              path: 'next12MonthsDepartureDate',
              nodeId: NodeIds.lifestyleInsuredTravelNext12MonthsDepartureDate,
            },
            { path: 'next12MonthsReturnDate', nodeId: NodeIds.lifestyleInsuredTravelNext12MonthsReturnDate },
            { path: 'next12MonthsUnknownDate', nodeId: NodeIds.lifestyleInsuredTravelNext12MonthsUnknownDate },
          ],
        },
      ],
    },
    {
      path: 'insuredTravel',
      children: [
        { path: 'hasTraveled', nodeId: NodeIds.lifestyleInsuredHasTraveled },
        { path: 'travel12MonthsPastOrFuture', nodeId: NodeIds.lifestyleInsuredTravel12MonthsPastOrFuture },
      ],
    },
    {
      path: 'tobacco',
      children: [
        { path: 'mostRecentUsage', nodeId: NodeIds.lifestyleTobaccoMostRecentUsage },
        { path: 'choices', nodeId: NodeIds.lifestyleTobaccoChoices },
        { path: 'choicesOtherIs', nodeId: NodeIds.lifestyleInsuredTobaccoChoicesOtherIs },
        { path: 'cigarettesDailyQuantity', nodeId: NodeIds.lifestyleInsuredTobaccoCigarettesDailyQuantity },
        { path: 'lastDateTobaccoUse', nodeId: NodeIds.lifestyleTobaccoLastDateTobaccoUse },
        { path: 'typicalUsage', nodeId: NodeIds.lifestyleTobaccoTypicalUsage },
        { path: 'lastDateBigCigars', nodeId: NodeIds.lifestyleTobaccoLastDateBigCigars },
        { path: 'tobaccoAnswerInconsistent', nodeId: NodeIds.lifestyleTobaccoAnswerInconsistent },
      ],
    },
    {
      path: 'marijuana',
      children: [
        { path: 'mostRecentUsage', nodeId: NodeIds.lifestyleMarijuanaMostRecentUsage },
        { path: 'lifestyleHasUsedDrugs', nodeId: NodeIds.lifestyleHasUsedDrugs },
        { path: 'drugType', nodeId: NodeIds.lifestyleDrugType },
        { path: 'marijuanaUsageDetails', nodeId: NodeIds.lifestyleMarijuanaUsageDetails },
        { path: 'otherDrugDetails', nodeId: NodeIds.lifestyleOtherDrugDetails },
        { path: 'marijuanaUsageReason', nodeId: NodeIds.lifestyleMarijuanaUsageReason },
        { path: 'marijuanaMedProviderName', nodeId: NodeIds.lifestyleMarijuanaMedProviderName },
        { path: 'marijuanaMedProviderAddress', nodeId: NodeIds.lifestyleMarijuanaMedProviderAddress },
        { path: 'marijuanaMedProviderPhone', nodeId: NodeIds.lifestyleMarijuanaMedProviderPhone },
        { path: 'typicalUsage', nodeId: NodeIds.lifestyleMarijuanaTypicalUsage },
        { path: 'prescription', nodeId: NodeIds.lifestyleMarijuanaPrescription },
        { path: 'useFormDetails', nodeId: NodeIds.lifestyleInsuredMarijuanaUseFormDetails },
      ],
    },
    {
      path: 'drugs',
      children: [
        { path: 'mostRecentUsage', nodeId: NodeIds.lifestyleDrugMostRecentUsage },
        { path: 'illegalOrWithoutPrescriptionUsage', nodeId: NodeIds.lifestyleIllegalOrWithoutPrescriptionDrugUsage },
      ],
    },
    {
      path: 'alcohol',
      children: [
        { path: 'typicalUsageBeer', nodeId: NodeIds.lifestyleAlcoholBeerTypicalUsage },
        { path: 'typicalUsageWine', nodeId: NodeIds.lifestyleAlcoholWineTypicalUsage },
        { path: 'typicalUsageSpirits', nodeId: NodeIds.lifestyleAlcoholSpiritsTypicalUsage },
        { path: 'typicalUsageCocktails', nodeId: NodeIds.lifestyleAlcoholCocktailsTypicalUsage },
      ],
    },
    {
      path: 'driving',
      children: [
        { path: 'licenceSuspended', nodeId: NodeIds.lifestyleDriversLicenceSuspended },
        { path: 'impairedDriving', nodeId: NodeIds.lifestyleImpairedDriving },
        { path: 'drivingOffenses', nodeId: NodeIds.lifestyleDrivingOffenses },
        { path: 'convictionOtherThanForDrunkDriving', nodeId: NodeIds.lifestyleConvictionOtherThanForDrunkDriving },
        { path: 'convictionDrunkDrivingMoreThanOnce', nodeId: NodeIds.lifestyleConvictionDrunkDrivingMoreThanOnce },
        { path: 'otherMovingViolations', nodeId: NodeIds.lifestyleOtherMovingViolations },
        { path: 'movingViolationsConvictions', nodeId: NodeIds.lifestyleMovingViolationsConvictions },
        { path: 'otherMovingViolationsConvictions', nodeId: NodeIds.lifestyleOtherMovingViolationsConvictions },
        { path: 'movingAccidents', nodeId: NodeIds.lifestyleMovingAccidents },
        { path: 'otherMovingAccidents', nodeId: NodeIds.lifestyleOtherMovingAccidents },
        { path: 'movingViolationDetails', nodeId: NodeIds.lifestyleMovingViolationDetails },

        { path: 'insured1stViolationDate', nodeId: NodeIds.lifestyleInsured1stViolationDate },
        { path: 'insured2ndViolationDate', nodeId: NodeIds.lifestyleInsured2ndViolationDate },

        { path: 'movingViolationDetailsLast05Years', nodeId: 'lifestyle-moving-violation-details-last-05years' },
        { path: 'movingViolationNumber', nodeId: NodeIds.lifestyleMovingViolationNumber },
        { path: 'movingViolationDate', nodeId: NodeIds.lifestyleMovingViolationDate },
        { path: 'movingViolationType', nodeId: NodeIds.lifestyleMovingViolationType },
        { path: 'awaitingSentenceDrunkDriving', nodeId: NodeIds.lifestyleAwaitingSentenceDrunkDriving },
        { path: 'driverLicenseStillSuspended', nodeId: NodeIds.lifestyleDriverLicenseStillSuspended },
        { path: 'driversLicenceSuspensionDetails', nodeId: NodeIds.lifestyleDriversLicenceSuspensionDetails },
        { path: 'driversLicenceSuspensionReason', nodeId: NodeIds.lifestyleDriversLicenceSuspensionReason },
        { path: 'driversLicenceSuspensionReinstated', nodeId: NodeIds.lifestyleDriversLicenceSuspensionReinstated },
        { path: 'driverProfileOtherViolation', nodeId: NodeIds.lifestyleDriverProfileOtherViolation },
        { path: '1stSpeedingLimitDetails', nodeId: 'lifestyle-1st-speeding-limit-details' },
        { path: '1stDrivingViolationsOthersDetails', nodeId: 'lifestyle-1st-driving-violations-others-details' },
        { path: '2ndDrivingViolationsChoices', nodeId: 'lifestyle-2nd-driving-violations-choices' },
        { path: '2ndSpeedingLimitDetails', nodeId: 'lifestyle-2nd-speeding-limit-details' },
        { path: '2ndDrivingViolationsOthersDetails', nodeId: 'lifestyle-2nd-driving-violations-others-details' },
        { path: 'additionalDrivingViolations', nodeId: NodeIds.lifestyleAdditionalDrivingViolations },
        { path: 'hasViolationInPast3Years', nodeId: NodeIds.lifestyleInsuredDrivingHasViolationsInPast3Years },
        {
          path: 'impairedDrivingOffense',
          nodeId: NodeIds.lifestyleImpairedDrivingOffense,
          isCollection: true,
          children: [
            { path: 'offenseDate', nodeId: NodeIds.lifestyleImpairedDrivingOffenseDate },
            {
              path: 'licenseSuspensionDate',
              nodeId: NodeIds.lifestyleImpairedDrivingLicenseSuspensionDate,
            },
            {
              path: 'licenseRecoveryDate',
              nodeId: NodeIds.lifestyleImpairedDrivingLicenseRecoveryDate,
            },
            {
              path: 'ignitionInterlockDevice',
              nodeId: NodeIds.lifestyleImpairedDrivingIgnitionInterlockDevice,
            },
            {
              path: 'ignitionInterlockDeviceStartDate',
              nodeId: NodeIds.lifestyleImpairedDrivingIgnitionInterlockDeviceStartDate,
            },
            {
              path: 'ignitionInterlockDeviceEndDate',
              nodeId: NodeIds.lifestyleImpairedDrivingIgnitionInterlockDeviceEndDate,
            },
            {
              path: 'withSuspendedLicense',
              nodeId: NodeIds.lifestyleImpairedDrivingWithSuspendedLicense,
            },
            {
              path: 'anyOtherImpairedDrivingDetails',
              nodeId: NodeIds.lifestyleImpairedDrivingAnyOtherImpairedDrivingDetails,
            },
            {
              path: 'anyOtherImpairedDrivingDetailsDate',
              nodeId: NodeIds.lifestyleImpairedDrivingAnyOtherImpairedDrivingDetailsDate,
            },
          ],
        },
        {
          path: 'licenseSuspendOtherReason',
          nodeId: NodeIds.lifestyleLicenseSuspendOtherReason,
          isCollection: true,
          children: [
            { path: 'type', nodeId: NodeIds.lifestyleLicenseSuspendOtherReasonType },
            {
              path: 'medicalReasonDetails',
              nodeId: NodeIds.lifestyleLicenseSuspendOtherReasonMedicalReasonDetails,
            },
            {
              path: 'otherDetails',
              nodeId: NodeIds.lifestyleLicenseSuspendOtherReasonOtherDetails,
            },
            {
              path: 'suspensionDate',
              nodeId: NodeIds.lifestyleLicenseSuspendOtherReasonSuspensionDate,
            },
            {
              path: 'recoveryDate',
              nodeId: NodeIds.lifestyleLicenseSuspendOtherReasonRecoveryDate,
            },
            {
              path: 'withSuspendedLicense',
              nodeId: NodeIds.lifestyleLicenseSuspendOtherReasonWithSuspendedLicense,
            },
          ],
        },
        {
          path: 'additionalDrivingDetails',
          nodeId: NodeIds.additionalDrivingDetails,
          isCollection: true,
          children: [
            { path: 'additionalDrivingViolationsChoices', nodeId: NodeIds.additionalDrivingViolationsChoices },
            { path: 'additionalSpeedingLimitDetails', nodeId: NodeIds.additionalSpeedingLimitDetails },
            {
              path: 'additionalDrivingViolationsOtherDetails',
              nodeId: NodeIds.additionalDrivingViolationsOtherDetails,
            },
            { path: 'additionalDrivingViolationsDate', nodeId: NodeIds.additionalDrivingViolationsDate },
          ],
        },
      ],
    },
    {
      path: 'insuredDriving',
      children: [
        { path: 'hasViolations', nodeId: NodeIds.lifestyleInsuredDrivingHasViolations },
        { path: 'violationsReasons', nodeId: NodeIds.lifestyleInsuredDrivingViolationsReasons },
        { path: 'violations', nodeId: NodeIds.lifestyleInsuredDrivingViolations },
        { path: '1stViolationDetails', nodeId: NodeIds.lifestyleInsured1stViolationDetails },
      ],
    },
    {
      path: 'dui',
      children: [
        { path: 'choices', nodeId: NodeIds.lifestyleDUIChoices },
        { path: 'number', nodeId: NodeIds.lifestyleDUINumber },
        { path: 'date', nodeId: NodeIds.lifestyleDUIDate },
        { path: 'type', nodeId: NodeIds.lifestyleDUIType },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const previousApplication: AnswerPathTreeNode = {
  path: 'previousApplication',
  children: [
    { path: 'exists', nodeId: NodeIds.previousApplicationExists },
    {
      path: 'existingApplication',
      nodeId: NodeIds.existingApplication,
      isCollection: true,
      children: [
        { path: 'choices', nodeId: NodeIds.previousApplicationChoices },
        { path: 'carrierName', nodeId: NodeIds.previousApplicationCarrierName },
        { path: 'date', nodeId: NodeIds.previousApplicationDate },
        { path: 'reasonForOutcome', nodeId: NodeIds.previousApplicationReasonForOutcome },
        { path: 'pastApplicationDecision', nodeId: NodeIds.pastApplicationDecision },
      ],
    },
  ],
};

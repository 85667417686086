import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const bankruptcy: AnswerPathTreeNode = {
  path: 'bankruptcy',
  children: [
    { path: 'hasDeclaredBankruptcy', nodeId: NodeIds.insuredHasDeclaredBankruptcy },
    { path: 'type', nodeId: NodeIds.insuredBankruptcyType },
    { path: 'personalAmount', nodeId: NodeIds.insuredBankruptcyPersonalAmount },
    { path: 'professionalCommercialAmount', nodeId: NodeIds.insuredBankruptcyProfessionalCommercialAmount },
    { path: 'consumerProposalAmount', nodeId: NodeIds.insuredBankruptcyConsumerProposalAmount },
    { path: 'filedOrProposedDate', nodeId: NodeIds.insuredBankruptcyFiledOrProposedDate },
  ],
};

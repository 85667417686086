import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const osteoporosisOsteopenia: AnswerPathTreeNode = {
  path: 'osteoporosisOsteopenia',
  children: [
    {
      path: 'isSingleEpisode',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaIsSingleEpisode,
    },
    {
      path: 'previousEpisodesCount',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaPreviousEpisodesCount,
    },
    {
      path: 'dateSymptoms',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaDateSymptoms,
    },
    {
      path: 'hasConsultedDoctors',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctors,
    },
    {
      path: 'hasTakenTestsOrExams',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExams,
    },
    {
      path: 'medicationWasPrescribed',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaMedicationWasPrescribed,
    },
    {
      path: 'painLocation',
      children: [
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaPainLocation,
        },
        {
          path: 'leftRight',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaPainLocationLeftRight,
        },
        {
          path: 'other',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaPainLocationOther,
        },
      ],
    },
    {
      path: 'painCause',
      children: [
        {
          path: 'cause',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaPainCause,
        },
        {
          path: 'details',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaPainCauseDetails,
        },
      ],
    },
    {
      path: 'operation',
      children: [
        {
          path: 'hadOperation',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHadOperation,
        },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaTypeOfOperationPerformed,
        },
        {
          path: 'performedDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaOperationPerformedDate,
        },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaTypeOfOperationPlanned,
        },
        {
          path: 'plannedDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaOperationPlannedDate,
        },
        {
          path: 'unknownDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaOperationUnknownDate,
        },
      ],
    },
    {
      path: 'absence',
      children: [
        {
          path: 'hadAbsence',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHadAbsence,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisosteopeniaAbsenceStartDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaAbsenceFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaAbsenceFrequency,
        },
        {
          path: 'recovery',
          children: [
            {
              path: 'hasRecovered',
              nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaAbsenceHasRecovered,
            },
            {
              path: 'recoveryDate',
              nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaAbsenceRecoveryDate,
            },
            {
              path: 'notRecoveredDetails',
              nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaAbsenceNotRecoveredDetails,
            },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsProfession,
        },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsProfessionDetails,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsName },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsLastDate,
        },
        { path: 'result', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsResult },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDate },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsResult,
        },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoporosisOsteopeniaMedicationEndDate },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const healthSecondOwner: AnswerPathTreeNode = {
  path: 'healthSecondOwner',
  children: [
    {
      path: 'cardiovascularSystem',
      children: [
        { path: 'hasCondition', nodeId: NodeIds.healthSecondOwnerCirculatorySystemHasCondition },
        { path: 'circulatorySystemChoices', nodeId: NodeIds.healthSecondOwnerCirculatorySystemChoices },
        {
          path: 'highBloodPressure',
          children: [
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerHighBloodPressureMedicationWasPrescribed,
            },
            { path: 'diagnosisDate', nodeId: NodeIds.healthSecondOwnerHighBloodPressureDiagnosisDate },
            { path: 'isControlled', nodeId: NodeIds.healthSecondOwnerHighBloodPressureIsControlled },
            { path: 'abnormalBloodPressureDetails', nodeId: NodeIds.healthSecondOwnerAbnormalBloodPressureDetails },
            { path: 'medicationDetails', nodeId: NodeIds.healthSecondOwnerHighBloodPressureMedicationDetails },
            { path: 'noMedicationReason', nodeId: NodeIds.healthSecondOwnerHighBloodPressureNoMedicationReason },
          ],
        },
        {
          path: 'highCholesterol',
          children: [
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerHighCholesterolMedicationWasPrescribed,
            },
            { path: 'diagnosisDate', nodeId: NodeIds.healthSecondOwnerHighCholesterolDiagnosisDate },
            { path: 'isControlled', nodeId: NodeIds.healthSecondOwnerHighCholesterolIsControlled },

            { path: 'medicationDetails', nodeId: NodeIds.healthSecondOwnerHighCholesterolMedicationDetails },
            { path: 'noMedicationReason', nodeId: NodeIds.healthSecondOwnerHighCholesterolNoMedicationReason },
            {
              path: 'cholesterolDetails',
              nodeId: NodeIds.healthSecondOwnerCirculatorySystemCholesterolDetails,
            },
          ],
        },
        { path: 'aneurysmDetails', nodeId: NodeIds.healthSecondOwnerAneurysmDetails },
        { path: 'heartAttackDetails', nodeId: NodeIds.healthSecondOwnerHeartAttackDetails },
        { path: 'anginaDetails', nodeId: NodeIds.healthSecondOwnerAnginaDetails },
        { path: 'tiaDetails', nodeId: NodeIds.healthSecondOwnerTiaDetails },
        { path: 'cvaDetails', nodeId: NodeIds.healthSecondOwnerCvaDetails },
        { path: 'heartMurmurDetails', nodeId: NodeIds.healthSecondOwnerHeartMurmurDetails },
        { path: 'chestPainDetails', nodeId: NodeIds.healthSecondOwnerChestPainDetails },
        { path: 'palpitationsDetails', nodeId: NodeIds.healthSecondOwnerPalpitationsDetails },
        { path: 'anyOtherHeartDisorderDetails', nodeId: NodeIds.healthSecondOwnerAnyOtherHeartDisorderDetails },
      ],
    },
    {
      path: 'respiratorySystem',
      children: [
        { path: 'hasCondition', nodeId: NodeIds.healthSecondOwnerRespiratoryHasCondition },
        { path: 'respiratoryChoices', nodeId: NodeIds.healthSecondOwnerRespiratoryChoices },
        {
          path: 'obstructiveSleepApnea',
          children: [
            { path: 'diagnosisDate', nodeId: NodeIds.healthSecondOwnerObstructiveSleepApneaDiagnosisDate },
            { path: 'cpapTreatment', nodeId: NodeIds.healthSecondOwnerCpapTreatment },
            { path: 'cpapTreatmentUsageHours', nodeId: NodeIds.healthSecondOwnerCpapTreatmentUsageHours },
            { path: 'cpapTreatmentUsageWeek', nodeId: NodeIds.healthSecondOwnerCpapTreatmentUsageWeek },
            { path: 'cpapTreatmentIsControlled', nodeId: NodeIds.healthSecondOwnerCpapTreatmentIsControlled },
            { path: 'noCpapTreatmentReason', nodeId: NodeIds.healthSecondOwnerNoCpapTreatmentReason },
            {
              path: 'noCpapTreatmentReasonOtherTreatment',
              nodeId: NodeIds.healthSecondOwnerNoCpapTreatmentReasonOtherTreatment,
            },
            { path: 'noCpapTreatmentReasonOther', nodeId: NodeIds.healthSecondOwnerNoCpapTreatmentReasonOther },
            { path: 'symptomsSeverity', nodeId: NodeIds.healthSecondOwnerSleepApneaSymptomsSeverity },
          ],
        },
        { path: 'asthmaDetails', nodeId: NodeIds.healthSecondOwnerAsthmaDetails },
        { path: 'chronicBronchitisDetails', nodeId: NodeIds.healthSecondOwnerChronicBronchitisDetails },
        { path: 'emphysemaDetails', nodeId: NodeIds.healthSecondOwnerEmphysemaDetails },
        { path: 'copdDetails', nodeId: NodeIds.healthSecondOwnerCopdDetails },
        { path: 'cysticFibrosisDetails', nodeId: NodeIds.healthSecondOwnerCysticFibrosisDetails },
        { path: 'sarcoidosisDetails', nodeId: NodeIds.healthSecondOwnerSarcoidosisDetails },
        { path: 'coughingUpBloodDetails', nodeId: NodeIds.healthSecondOwnerCoughingUpBloodDetails },
        { path: 'shortnessOfBreathDetails', nodeId: NodeIds.healthSecondOwnerShortnessOfBreathDetails },
        {
          path: 'anyOtherRespiratoryDisorderDetails',
          nodeId: NodeIds.healthSecondOwnerAnyOtherRespiratoryDisorderDetails,
        },
      ],
    },
    {
      path: 'digestiveSystem',
      children: [
        { path: 'hasCondition', nodeId: NodeIds.healthSecondOwnerDigestiveSystemHasCondition },
        { path: 'digestiveSystemChoices', nodeId: NodeIds.healthSecondOwnerDigestiveSystemChoices },
        {
          path: 'gerd',
          children: [
            { path: 'isControlled', nodeId: NodeIds.healthSecondOwnerGerdIsControlled },
            { path: 'consulted', nodeId: NodeIds.healthSecondOwnerGerdConsulted },
            { path: 'details', nodeId: NodeIds.healthSecondOwnerGerdDetails },
            { path: 'complications', nodeId: NodeIds.healthSecondOwnerGerdComplications },
            { path: 'complicationsDetails', nodeId: NodeIds.healthSecondOwnerGerdComplicationsDetails },
          ],
        },
        {
          path: 'gallstones',
          children: [
            { path: 'diagnosisDate', nodeId: NodeIds.healthSecondOwnerGallstonesDiagnosisDate },
            { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerGallstonesHadOperation },
            { path: 'operationDate', nodeId: NodeIds.healthSecondOwnerGallstonesOperationDate },
            { path: 'pastSurgeryRecovery', nodeId: NodeIds.healthSecondOwnerGallstonesPastSurgeryRecovery },
            { path: 'pendingSurgery', nodeId: NodeIds.healthSecondOwnerGallstonesPendingSurgery },
            { path: 'pendingSurgeryDate', nodeId: NodeIds.healthSecondOwnerGallstonesPendingSurgeryDate },
            { path: 'pendingSurgeryUnknownDate', nodeId: NodeIds.healthSecondOwnerGallstonesPendingSurgeryUnknownDate },
          ],
        },
        { path: 'crohnsDiseaseDetails', nodeId: NodeIds.healthSecondOwnerCrohnsDiseaseDetails },
        { path: 'ulcerativeColitisDetails', nodeId: NodeIds.healthSecondOwnerUlcerativeColitisDetails },
        { path: 'celiacDiseaseDetails', nodeId: NodeIds.healthSecondOwnerCeliacDiseaseDetails },
        { path: 'polypsDetails', nodeId: NodeIds.healthSecondOwnerPolypsDetails },
        { path: 'cancerPolypsDetails', nodeId: NodeIds.healthSecondOwnerCancerPolypsDetails },
        { path: 'hepatitisDetails', nodeId: NodeIds.healthSecondOwnerHepatitisDetails },
        { path: 'cirrhosisDetails', nodeId: NodeIds.healthSecondOwnerCirrhosisDetails },
        { path: 'pancreatitisDetails', nodeId: NodeIds.healthSecondOwnerPancreatitisDetails },
        { path: 'ulcersDetails', nodeId: NodeIds.healthSecondOwnerUlcersDetails },
        { path: 'gallbladderDisorderDetails', nodeId: NodeIds.healthSecondOwnerGallbladderDisorderDetails },
        { path: 'bleedingDetails', nodeId: NodeIds.healthSecondOwnerBleedingDetails },
        {
          path: 'anyOtherDisorderOfTheDigestiveSystemDetails',
          nodeId: NodeIds.healthSecondOwnerAnyOtherDisorderOfTheDigestiveSystemDetails,
        },
      ],
    },
    {
      path: 'genitourinarySystem',
      children: [
        { path: 'hasCondition', nodeId: NodeIds.healthSecondOwnerGenitourinaryHasCondition },
        { path: 'genitourinaryChoices', nodeId: NodeIds.healthSecondOwnerGenitourinaryChoices },
        { path: 'otherDetails', nodeId: NodeIds.healthSecondOwnerGenitourinaryOtherDetails },
        {
          path: 'kidneyStone',
          children: [
            { path: 'lastEpisode', nodeId: NodeIds.healthSecondOwnerKidneyStoneLastEpisode },
            { path: 'episodesCount', nodeId: NodeIds.healthSecondOwnerKidneyStoneEpisodesCount },
            { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerKidneyStoneHasRecovered },
          ],
        },
        { path: 'urineAbnormalitiesDetails', nodeId: NodeIds.healthSecondOwnerUrineAbnormalitiesDetails },
        { path: 'disordersOfTheKidneyDetails', nodeId: NodeIds.healthSecondOwnerDisordersOfTheKidneyDetails },
        { path: 'urinaryTractDetails', nodeId: NodeIds.healthSecondOwnerUrinaryTractDetails },
        { path: 'bladderDetails', nodeId: NodeIds.healthSecondOwnerBladderDetails },
        { path: 'prostateDetails', nodeId: NodeIds.healthSecondOwnerProstateDetails },
        { path: 'genitalOrgansTestsDetails', nodeId: NodeIds.healthSecondOwnerGenitalOrgansTestsDetails },
      ],
    },
    {
      path: 'endocrineSystem',
      children: [
        { path: 'hasCondition', nodeId: NodeIds.healthSecondOwnerEndocrineSystemHasCondition },
        { path: 'endocrineChoices', nodeId: NodeIds.healthSecondOwnerEndocrineChoices },
        {
          path: 'diabetes',
          children: [
            { path: 'type', nodeId: NodeIds.healthSecondOwnerDiabetesType },
            { path: 'details', nodeId: NodeIds.healthSecondOwnerDiabetesDetails },
          ],
        },
        {
          path: 'thyroidDisorder',
          children: [
            { path: 'isHypothyroidism', nodeId: NodeIds.healthSecondOwnerThyroidDisorderIsHypothyroidism },
            { path: 'diagnosisDate', nodeId: NodeIds.healthSecondOwnerThyroidDisorderDiagnosisDate },
            { path: 'hadSurgery', nodeId: NodeIds.healthSecondOwnerThyroidDisorderHadSurgery },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerThyroidDisorderMedicationWasPrescribed,
            },
            { path: 'synthroid', nodeId: NodeIds.healthSecondOwnerThyroidDisorderSynthroid },
            { path: 'isControlled', nodeId: NodeIds.healthSecondOwnerThyroidDisorderIsControlled },
            { path: 'recoveredDetails', nodeId: NodeIds.healthSecondOwnerThyroidDisorderRecoveredDetails },
          ],
        },
        { path: 'abnormalBloodSugarDetails', nodeId: NodeIds.healthSecondOwnerAbnormalBloodSugarDetails },
        { path: 'pituitaryGlandDisorderDetails', nodeId: NodeIds.healthSecondOwnerPituitaryGlandDisorderDetails },
        { path: 'adrenalGlandDisorderDetails', nodeId: NodeIds.healthSecondOwnerAdrenalGlandDisorderDetails },
        {
          path: 'anyOtherGlandularOrHormonalDisorderDetails',
          nodeId: NodeIds.healthSecondOwnerAnyOtherGlandularOrHormonalDisorderDetails,
        },
      ],
    },
    {
      path: 'lumbago',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerLumbagoIsSingleEpisode },
        { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerLumbagoPreviousEpisodesCount },
        {
          path: 'painLocation',
          children: [
            { path: 'location', nodeId: NodeIds.healthSecondOwnerLumbagoPainLocation },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerLumbagoDateSymptoms },
          ],
        },
        {
          path: 'painCause',
          children: [
            { path: 'cause', nodeId: NodeIds.healthSecondOwnerLumbagoPainCause },
            { path: 'details', nodeId: NodeIds.healthSecondOwnerLumbagoPainCauseDetails },
          ],
        },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerLumbagoHasConsultedDoctors },
        { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerLumbagoHasTakenTestsOrExams },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerLumbagoMedicationWasPrescribed },
        {
          path: 'operation',
          children: [
            { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerLumbagoHadOperation },
            {
              path: 'performedOrPlanned',
              nodeId: NodeIds.healthSecondOwnerLumbagoOperationPerformedOrPlanned,
            },
            {
              path: 'typeOfOperationPerformed',
              nodeId: NodeIds.healthSecondOwnerLumbagoTypeOfOperationPerformed,
            },
            {
              path: 'performedDate',
              nodeId: NodeIds.healthSecondOwnerLumbagoOperationPerformedDate,
            },
            {
              path: 'typeOfOperationPlanned',
              nodeId: NodeIds.healthSecondOwnerLumbagoTypeOfOperationPlanned,
            },
            { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerLumbagoOperationPlannedDate },
            { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerLumbagoOperationUnknownDate },
          ],
        },
        {
          path: 'absence',
          children: [
            { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerLumbagoHadAbsence },
            { path: 'startDate', nodeId: NodeIds.healthSecondOwnerLumbagoAbsenceStartDate },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerLumbagoAbsenceFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthSecondOwnerLumbagoAbsenceFrequency },
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerLumbagoAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerLumbagoAbsenceRecoveryDate },
                { path: 'notRecoveredDetails', nodeId: NodeIds.healthSecondOwnerLumbagoAbsenceNotRecoveredDetails },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthSecondOwnerLumbagoHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            { path: 'profession', nodeId: NodeIds.healthSecondOwnerLumbagoHasConsultedDoctorsProfession },
            {
              path: 'professionDetails',
              nodeId: NodeIds.healthSecondOwnerLumbagoHasConsultedDoctorsProfessionDetails,
            },
            { path: 'name', nodeId: NodeIds.healthSecondOwnerLumbagoHasConsultedDoctorsName },
            { path: 'address', nodeId: NodeIds.healthSecondOwnerLumbagoHasConsultedDoctorsAddress },
            { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerLumbagoHasConsultedDoctorsFirstDate },
            { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerLumbagoHasConsultedDoctorsLastDate },
            { path: 'result', nodeId: NodeIds.healthSecondOwnerLumbagoHasConsultedDoctorsResult },
            { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerLumbagoHasConsultedDoctorsFrequencyNumber },
            { path: 'frequency', nodeId: NodeIds.healthSecondOwnerLumbagoHasConsultedDoctorsFrequency },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthSecondOwnerLumbagoHasConsultedDoctorFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthSecondOwnerLumbagoHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthSecondOwnerLumbagoHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            { path: 'type', nodeId: NodeIds.healthSecondOwnerLumbagoHasTakenTestsOrExamsType },
            { path: 'date', nodeId: NodeIds.healthSecondOwnerLumbagoHasTakenTestsOrExamsDate },
            { path: 'result', nodeId: NodeIds.healthSecondOwnerLumbagoHasTakenTestsOrExamsResult },
            {
              path: 'abnormalResult',
              nodeId: NodeIds.healthSecondOwnerLumbagoHasTakenTestsOrExamsDetailsAbnormalResult,
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthSecondOwnerLumbagoMedicationDetails,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.healthSecondOwnerLumbagoMedicationName },
            { path: 'dosage', nodeId: NodeIds.healthSecondOwnerLumbagoMedicationDosage },
            { path: 'startDate', nodeId: NodeIds.healthSecondOwnerLumbagoMedicationStartDate },
            { path: 'endDate', nodeId: NodeIds.healthSecondOwnerLumbagoMedicationEndDate },
          ],
        },
      ],
    },
    {
      path: 'sciaticNerve',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerSciaticNerveIsSingleEpisode },
        { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerSciaticNervePreviousEpisodesCount },
        {
          path: 'painLocation',
          children: [
            { path: 'location', nodeId: NodeIds.healthSecondOwnerSciaticNervePainLocation },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerSciaticNerveDateSymptoms },
          ],
        },
        {
          path: 'painCause',
          children: [
            { path: 'cause', nodeId: NodeIds.healthSecondOwnerSciaticNervePainCause },
            { path: 'details', nodeId: NodeIds.healthSecondOwnerSciaticNervePainCauseDetails },
          ],
        },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerSciaticNerveHasConsultedDoctors },
        { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerSciaticNerveHasTakenTestsOrExams },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerSciaticNerveMedicationWasPrescribed },
        {
          path: 'operation',
          children: [
            { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerSciaticNerveHadOperation },
            {
              path: 'performedOrPlanned',
              nodeId: NodeIds.healthSecondOwnerSciaticNerveOperationPerformedOrPlanned,
            },
            {
              path: 'typeOfOperationPerformed',
              nodeId: NodeIds.healthSecondOwnerSciaticNerveTypeOfOperationPerformed,
            },
            {
              path: 'performedDate',
              nodeId: NodeIds.healthSecondOwnerSciaticNerveOperationPerformedDate,
            },
            {
              path: 'typeOfOperationPlanned',
              nodeId: NodeIds.healthSecondOwnerSciaticNerveTypeOfOperationPlanned,
            },
            { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerSciaticNerveOperationPlannedDate },
            { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerSciaticNerveOperationUnknownDate },
          ],
        },
        {
          path: 'absence',
          children: [
            { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerSciaticNerveHadAbsence },
            { path: 'startDate', nodeId: NodeIds.healthSecondOwnerSciaticNerveAbsenceStartDate },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerSciaticNerveAbsenceFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthSecondOwnerSciaticNerveAbsenceFrequency },
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerSciaticNerveAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerSciaticNerveAbsenceRecoveryDate },
                {
                  path: 'notRecoveredDetails',
                  nodeId: NodeIds.healthSecondOwnerSciaticNerveAbsenceNotRecoveredDetails,
                },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthSecondOwnerSciaticNerveHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            { path: 'profession', nodeId: NodeIds.healthSecondOwnerSciaticNerveHasConsultedDoctorsProfession },
            {
              path: 'professionDetails',
              nodeId: NodeIds.healthSecondOwnerSciaticNerveHasConsultedDoctorsProfessionDetails,
            },
            { path: 'name', nodeId: NodeIds.healthSecondOwnerSciaticNerveHasConsultedDoctorsName },
            { path: 'address', nodeId: NodeIds.healthSecondOwnerSciaticNerveHasConsultedDoctorsAddress },
            { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerSciaticNerveHasConsultedDoctorsFirstDate },
            { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerSciaticNerveHasConsultedDoctorsLastDate },
            { path: 'result', nodeId: NodeIds.healthSecondOwnerSciaticNerveHasConsultedDoctorsResult },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerSciaticNerveHasConsultedDoctorsFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthSecondOwnerSciaticNerveHasConsultedDoctorsFrequency },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthSecondOwnerSciaticNerveHasConsultedDoctorFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthSecondOwnerSciaticNerveHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthSecondOwnerSciaticNerveHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            { path: 'type', nodeId: NodeIds.healthSecondOwnerSciaticNerveHasTakenTestsOrExamsType },
            { path: 'date', nodeId: NodeIds.healthSecondOwnerSciaticNerveHasTakenTestsOrExamsDate },
            { path: 'result', nodeId: NodeIds.healthSecondOwnerSciaticNerveHasTakenTestsOrExamsResult },
            {
              path: 'abnormalResult',
              nodeId: NodeIds.healthSecondOwnerSciaticNerveHasTakenTestsOrExamsDetailsAbnormalResult,
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthSecondOwnerSciaticNerveMedicationDetails,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.healthSecondOwnerSciaticNerveMedicationName },
            { path: 'dosage', nodeId: NodeIds.healthSecondOwnerSciaticNerveMedicationDosage },
            { path: 'startDate', nodeId: NodeIds.healthSecondOwnerSciaticNerveMedicationStartDate },
            { path: 'endDate', nodeId: NodeIds.healthSecondOwnerSciaticNerveMedicationEndDate },
          ],
        },
      ],
    },
    {
      path: 'discDegeneration',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerDiscDegenerationIsSingleEpisode },
        { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerDiscDegenerationPreviousEpisodesCount },
        {
          path: 'painLocation',
          children: [
            { path: 'location', nodeId: NodeIds.healthSecondOwnerDiscDegenerationPainLocation },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerDiscDegenerationDateSymptoms },
          ],
        },
        {
          path: 'painCause',
          children: [
            { path: 'cause', nodeId: NodeIds.healthSecondOwnerDiscDegenerationPainCause },
            { path: 'details', nodeId: NodeIds.healthSecondOwnerDiscDegenerationPainCauseDetails },
          ],
        },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasConsultedDoctors },
        { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasTakenTestsOrExams },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerDiscDegenerationMedicationWasPrescribed },
        {
          path: 'operation',
          children: [
            { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerDiscDegenerationHadOperation },
            {
              path: 'performedOrPlanned',
              nodeId: NodeIds.healthSecondOwnerDiscDegenerationOperationPerformedOrPlanned,
            },
            {
              path: 'typeOfOperationPerformed',
              nodeId: NodeIds.healthSecondOwnerDiscDegenerationTypeOfOperationPerformed,
            },
            {
              path: 'performedDate',
              nodeId: NodeIds.healthSecondOwnerDiscDegenerationOperationPerformedDate,
            },
            {
              path: 'typeOfOperationPlanned',
              nodeId: NodeIds.healthSecondOwnerDiscDegenerationTypeOfOperationPlanned,
            },
            { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerDiscDegenerationOperationPlannedDate },
            { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerDiscDegenerationOperationUnknownDate },
          ],
        },
        {
          path: 'absence',
          children: [
            { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerDiscDegenerationHadAbsence },
            { path: 'startDate', nodeId: NodeIds.healthSecondOwnerDiscDegenerationAbsenceStartDate },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerDiscDegenerationAbsenceFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthSecondOwnerDiscDegenerationAbsenceFrequency },
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerDiscDegenerationAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerDiscDegenerationAbsenceRecoveryDate },
                {
                  path: 'notRecoveredDetails',
                  nodeId: NodeIds.healthSecondOwnerDiscDegenerationAbsenceNotRecoveredDetails,
                },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            { path: 'profession', nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasConsultedDoctorsProfession },
            {
              path: 'professionDetails',
              nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasConsultedDoctorsProfessionDetails,
            },
            { path: 'name', nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasConsultedDoctorsName },
            { path: 'address', nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasConsultedDoctorsAddress },
            { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasConsultedDoctorsFirstDate },
            { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasConsultedDoctorsLasttDate },
            { path: 'result', nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasConsultedDoctorsResult },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasConsultedDoctorsFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasConsultedDoctorsFrequency },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasConsultedDoctorFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            { path: 'type', nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasTakenTestsOrExamsType },
            { path: 'date', nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasTakenTestsOrExamsDate },
            { path: 'result', nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasTakenTestsOrExamsResult },
            {
              path: 'abnormalResult',
              nodeId: NodeIds.healthSecondOwnerDiscDegenerationHasTakenTestsOrExamsDetailsAbnormalResult,
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthSecondOwnerDiscDegenerationMedicationDetails,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.healthSecondOwnerDiscDegenerationMedicationName },
            { path: 'dosage', nodeId: NodeIds.healthSecondOwnerDiscDegenerationMedicationDosage },
            { path: 'startDate', nodeId: NodeIds.healthSecondOwnerDiscDegenerationMedicationStartDate },
            { path: 'endDate', nodeId: NodeIds.healthSecondOwnerDiscDegenerationMedicationEndDate },
          ],
        },
      ],
    },
    {
      path: 'fracture',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerFractureIsSingleEpisode },
        { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerFracturePreviousEpisodesCount },
        {
          path: 'painLocation',
          children: [
            { path: 'location', nodeId: NodeIds.healthSecondOwnerFracturePainLocation },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerFractureDateSymptoms },
          ],
        },
        {
          path: 'painCause',
          children: [
            { path: 'cause', nodeId: NodeIds.healthSecondOwnerFracturePainCause },
            { path: 'details', nodeId: NodeIds.healthSecondOwnerFracturePainCauseDetails },
          ],
        },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerFractureHasConsultedDoctors },
        { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerFractureHasTakenTestsOrExams },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerFractureMedicationWasPrescribed },
        {
          path: 'operation',
          children: [
            { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerFractureHadOperation },
            {
              path: 'performedOrPlanned',
              nodeId: NodeIds.healthSecondOwnerFractureOperationPerformedOrPlanned,
            },
            {
              path: 'typeOfOperationPerformed',
              nodeId: NodeIds.healthSecondOwnerFractureTypeOfOperationPerformed,
            },
            {
              path: 'performedDate',
              nodeId: NodeIds.healthSecondOwnerFractureOperationPerformedDate,
            },
            {
              path: 'typeOfOperationPlanned',
              nodeId: NodeIds.healthSecondOwnerFractureTypeOfOperationPlanned,
            },
            { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerFractureOperationPlannedDate },
            { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerFractureOperationUnknownDate },
          ],
        },
        {
          path: 'absence',
          children: [
            { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerFractureHadAbsence },
            { path: 'startDate', nodeId: NodeIds.healthSecondOwnerFractureAbsenceStartDate },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerFractureAbsenceFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthSecondOwnerFractureAbsenceFrequency },
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerFractureAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerFractureAbsenceRecoveryDate },
                { path: 'notRecoveredDetails', nodeId: NodeIds.healthSecondOwnerFractureAbsenceNotRecoveredDetails },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthSecondOwnerFractureHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            { path: 'profession', nodeId: NodeIds.healthSecondOwnerFractureHasConsultedDoctorsProfession },
            {
              path: 'professionDetails',
              nodeId: NodeIds.healthSecondOwnerFractureHasConsultedDoctorsProfessionDetails,
            },
            { path: 'name', nodeId: NodeIds.healthSecondOwnerFractureHasConsultedDoctorsName },
            { path: 'address', nodeId: NodeIds.healthSecondOwnerFractureHasConsultedDoctorsAddress },
            { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerFractureHasConsultedDoctorsFirstDate },
            { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerFractureHasConsultedDoctorsLastDate },
            { path: 'result', nodeId: NodeIds.healthSecondOwnerFractureHasConsultedDoctorsResult },
            { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerFractureHasConsultedDoctorsFrequencyNumber },
            { path: 'frequency', nodeId: NodeIds.healthSecondOwnerFractureHasConsultedDoctorsFrequency },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthSecondOwnerFractureHasConsultedDoctorFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthSecondOwnerFractureHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthSecondOwnerFractureHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            { path: 'type', nodeId: NodeIds.healthSecondOwnerFractureHasTakenTestsOrExamsType },
            { path: 'date', nodeId: NodeIds.healthSecondOwnerFractureHasTakenTestsOrExamsDate },
            { path: 'result', nodeId: NodeIds.healthSecondOwnerFractureHasTakenTestsOrExamsResult },
            {
              path: 'abnormalResult',
              nodeId: NodeIds.healthSecondOwnerFractureHasTakenTestsOrExamsDetailsAbnormalResult,
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthSecondOwnerFractureMedicationDetails,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.healthSecondOwnerFractureMedicationName },
            { path: 'dosage', nodeId: NodeIds.healthSecondOwnerFractureMedicationDosage },
            { path: 'startDate', nodeId: NodeIds.healthSecondOwnerFractureMedicationStartDate },
            { path: 'endDate', nodeId: NodeIds.healthSecondOwnerFractureMedicationEndDate },
          ],
        },
      ],
    },
    {
      path: 'herniatedDisc',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerHerniatedDiscIsSingleEpisode },
        { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerHerniatedDiscPreviousEpisodesCount },
        {
          path: 'painLocation',
          children: [
            { path: 'location', nodeId: NodeIds.healthSecondOwnerHerniatedDiscPainLocation },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerHerniatedDiscDateSymptoms },
          ],
        },
        {
          path: 'painCause',
          children: [
            { path: 'cause', nodeId: NodeIds.healthSecondOwnerHerniatedDiscPainCause },
            { path: 'details', nodeId: NodeIds.healthSecondOwnerHerniatedDiscPainCauseDetails },
          ],
        },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasConsultedDoctors },
        { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasTakenTestsOrExams },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerHerniatedDiscMedicationWasPrescribed },
        {
          path: 'operation',
          children: [
            { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerHerniatedDiscHadOperation },
            {
              path: 'performedOrPlanned',
              nodeId: NodeIds.healthSecondOwnerHerniatedDiscOperationPerformedOrPlanned,
            },
            {
              path: 'typeOfOperationPerformed',
              nodeId: NodeIds.healthSecondOwnerHerniatedDiscTypeOfOperationPerformed,
            },
            {
              path: 'performedDate',
              nodeId: NodeIds.healthSecondOwnerHerniatedDiscOperationPerformedDate,
            },
            {
              path: 'typeOfOperationPlanned',
              nodeId: NodeIds.healthSecondOwnerHerniatedDiscTypeOfOperationPlanned,
            },
            { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerHerniatedDiscOperationPlannedDate },
            { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerHerniatedDiscOperationUnknownDate },
          ],
        },
        {
          path: 'absence',
          children: [
            { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerHerniatedDiscHadAbsence },
            { path: 'startDate', nodeId: NodeIds.healthSecondOwnerHerniatedDiscAbsenceStartDate },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerHerniatedDiscAbsenceFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthSecondOwnerHerniatedDiscAbsenceFrequency },
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerHerniatedDiscAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerHerniatedDiscAbsenceRecoveryDate },
                {
                  path: 'notRecoveredDetails',
                  nodeId: NodeIds.healthSecondOwnerHerniatedDiscAbsenceNotRecoveredDetails,
                },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            { path: 'profession', nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasConsultedDoctorsProfession },
            {
              path: 'professionDetails',
              nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasConsultedDoctorsProfessionDetails,
            },
            { path: 'name', nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasConsultedDoctorsName },
            { path: 'address', nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasConsultedDoctorsAddress },
            { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasConsultedDoctorsFirstDate },
            { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasConsultedDoctorsLastDate },
            { path: 'result', nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasConsultedDoctorsResult },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasConsultedDoctorsFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasConsultedDoctorsFrequency },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasConsultedDoctorFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            { path: 'type', nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasTakenTestsOrExamsType },
            { path: 'date', nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasTakenTestsOrExamsDate },
            { path: 'result', nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasTakenTestsOrExamsResult },
            {
              path: 'abnormalResult',
              nodeId: NodeIds.healthSecondOwnerHerniatedDiscHasTakenTestsOrExamsDetailsAbnormalResult,
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthSecondOwnerHerniatedDiscMedicationDetails,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.healthSecondOwnerHerniatedDiscMedicationName },
            { path: 'dosage', nodeId: NodeIds.healthSecondOwnerHerniatedDiscMedicationDosage },
            { path: 'startDate', nodeId: NodeIds.healthSecondOwnerHerniatedDiscMedicationStartDate },
            { path: 'endDate', nodeId: NodeIds.healthSecondOwnerHerniatedDiscMedicationEndDate },
          ],
        },
      ],
    },
    {
      path: 'scoliosisLordosis',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisIsSingleEpisode },
        { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisPreviousEpisodesCount },
        {
          path: 'painLocation',
          children: [
            { path: 'location', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisPainLocation },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisDateSymptoms },
          ],
        },
        {
          path: 'painCause',
          children: [
            { path: 'cause', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisPainCause },
            { path: 'details', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisPainCauseDetails },
          ],
        },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasConsultedDoctors },
        { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasTakenTestsOrExams },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisMedicationWasPrescribed },
        {
          path: 'operation',
          children: [
            { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHadOperation },
            {
              path: 'performedOrPlanned',
              nodeId: NodeIds.healthSecondOwnerScoliosisLordosisOperationPerformedOrPlanned,
            },
            {
              path: 'typeOfOperationPerformed',
              nodeId: NodeIds.healthSecondOwnerScoliosisLordosisTypeOfOperationPerformed,
            },
            {
              path: 'performedDate',
              nodeId: NodeIds.healthSecondOwnerScoliosisLordosisOperationPerformedDate,
            },
            {
              path: 'typeOfOperationPlanned',
              nodeId: NodeIds.healthSecondOwnerScoliosisLordosisTypeOfOperationPlanned,
            },
            { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisOperationPlannedDate },
            { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisOperationUnknownDate },
          ],
        },
        {
          path: 'absence',
          children: [
            { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHadAbsence },
            { path: 'startDate', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisAbsenceStartDate },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerScoliosisLordosisAbsenceFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisAbsenceFrequency },
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisAbsenceRecoveryDate },
                {
                  path: 'notRecoveredDetails',
                  nodeId: NodeIds.healthSecondOwnerScoliosisLordosisAbsenceNotRecoveredDetails,
                },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            { path: 'profession', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasConsultedDoctorsProfession },
            {
              path: 'professionDetails',
              nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasConsultedDoctorsProfessionDetails,
            },
            { path: 'name', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasConsultedDoctorsName },
            { path: 'address', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasConsultedDoctorsAddress },
            { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasConsultedDoctorsFirstDate },
            { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasConsultedDoctorsLastDate },
            { path: 'result', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasConsultedDoctorsResult },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasConsultedDoctorsFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasConsultedDoctorsFrequency },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasConsultedDoctorFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            { path: 'type', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasTakenTestsOrExamsType },
            { path: 'date', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasTakenTestsOrExamsDate },
            { path: 'result', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasTakenTestsOrExamsResult },
            {
              path: 'abnormalResult',
              nodeId: NodeIds.healthSecondOwnerScoliosisLordosisHasTakenTestsOrExamsDetailsAbnormalResult,
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthSecondOwnerScoliosisLordosisMedicationDetails,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisMedicationName },
            { path: 'dosage', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisMedicationDosage },
            { path: 'startDate', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisMedicationStartDate },
            { path: 'endDate', nodeId: NodeIds.healthSecondOwnerScoliosisLordosisMedicationEndDate },
          ],
        },
      ],
    },
    {
      path: 'otherBackNeckDisorder',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerBackNeckOtherIsSingleEpisode },
        { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerBackNeckOtherPreviousEpisodesCount },
        {
          path: 'painLocation',
          children: [
            { path: 'location', nodeId: NodeIds.healthSecondOwnerBackNeckOtherPainLocation },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerBackNeckOtherDateSymptoms },
          ],
        },
        {
          path: 'painCause',
          children: [
            { path: 'cause', nodeId: NodeIds.healthSecondOwnerBackNeckOtherPainCause },
            { path: 'details', nodeId: NodeIds.healthSecondOwnerBackNeckOtherPainCauseDetails },
          ],
        },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasConsultedDoctors },
        { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasTakenTestsOrExams },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerBackNeckOtherMedicationWasPrescribed },
        {
          path: 'operation',
          children: [
            { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerBackNeckOtherHadOperation },
            {
              path: 'performedOrPlanned',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherOperationPerformedOrPlanned,
            },
            {
              path: 'typeOfOperationPerformed',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherTypeOfOperationPerformed,
            },
            {
              path: 'performedDate',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherOperationPerformedDate,
            },
            {
              path: 'typeOfOperationPlanned',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherTypeOfOperationPlanned,
            },
            { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerBackNeckOtherOperationPlannedDate },
            { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerBackNeckOtherOperationUnknownDate },
          ],
        },
        {
          path: 'absence',
          children: [
            { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerBackNeckOtherHadAbsence },
            { path: 'startDate', nodeId: NodeIds.healthSecondOwnerBackNeckOtherAbsenceStartDate },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherAbsenceFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthSecondOwnerBackNeckOtherAbsenceFrequency },
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerBackNeckOtherAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerBackNeckOtherAbsenceRecoveryDate },
                {
                  path: 'notRecoveredDetails',
                  nodeId: NodeIds.healthSecondOwnerBackNeckOtherAbsenceNotRecoveredDetails,
                },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            {
              path: 'profession',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasConsultedDoctorsProfession,
            },
            {
              path: 'professionDetails',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasConsultedDoctorsProfessionDetails,
            },
            {
              path: 'name',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasConsultedDoctorsName,
            },
            {
              path: 'address',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasConsultedDoctorsAddress,
            },
            {
              path: 'firstDate',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasConsultedDoctorsFirstDate,
            },
            {
              path: 'lastDate',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasConsultedDoctorsLastDate,
            },
            {
              path: 'result',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasConsultedDoctorsResult,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasConsultedDoctorsFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasConsultedDoctorsFrequency,
            },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasConsultedDoctorFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            {
              path: 'type',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasTakenTestsOrExamsType,
            },
            {
              path: 'date',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasTakenTestsOrExamsDate,
            },
            {
              path: 'result',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasTakenTestsOrExamsResult,
            },
            {
              path: 'abnormalResult',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherHasTakenTestsOrExamsDetailsAbnormalResult,
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthSecondOwnerBackNeckOtherMedicationDetails,
          isCollection: true,
          children: [
            {
              path: 'name',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherMedicationName,
            },
            {
              path: 'dosage',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherMedicationDosage,
            },
            {
              path: 'startDate',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherMedicationStartDate,
            },
            {
              path: 'endDate',
              nodeId: NodeIds.healthSecondOwnerBackNeckOtherMedicationEndDate,
            },
          ],
        },
      ],
    },
    {
      path: 'musculoskeletal',
      children: [
        { path: 'hasCondition', nodeId: NodeIds.healthSecondOwnerMusculoskeletalHasCondition },
        { path: 'conditions', nodeId: NodeIds.healthSecondOwnerMusculoskeletalConditions },
        { path: 'conditionsOther', nodeId: NodeIds.healthSecondOwnerMusculoskeletalConditionsOther },
        {
          path: 'backNeck',
          children: [
            { path: 'hasCondition', nodeId: NodeIds.healthSecondOwnerBackNeckHasCondition },
            { path: 'conditions', nodeId: NodeIds.healthSecondOwnerBackNeckConditions },
            { path: 'conditionsOther', nodeId: NodeIds.healthSecondOwnerBackNeckConditionsOther },
          ],
        },
        {
          path: 'osteoarthritis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerOsteoarthritisIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerOsteoarthritisPreviousEpisodesCount },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerOsteoarthritisPainLocation },
                { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerOsteoarthritisDateSymptoms },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerOsteoarthritisPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerOsteoarthritisPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerOsteoarthritisMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerOsteoarthritisHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthSecondOwnerOsteoarthritisOperationPerformedDate },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerOsteoarthritisOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerOsteoarthritisOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerOsteoarthritisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerOsteoarthritisAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerOsteoarthritisAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerOsteoarthritisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerOsteoarthritisAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerOsteoarthritisAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerOsteoarthritisAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerOsteoarthritisMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthSecondOwnerOsteoarthritisMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'spondylitisAnkylosing',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingIsSingleEpisode },
            {
              path: 'previousEpisodesCount',
              nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingPreviousEpisodesCount,
            },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingPainLocation },
                { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingDateSymptoms },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctors },
            {
              path: 'hasTakenTestsOrExams',
              nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasTakenTestsOrExams,
            },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingMedicationWasPrescribed,
            },
            {
              path: 'operation',
              children: [
                {
                  path: 'hadOperation',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHadOperation,
                },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingTypeOfOperationPerformed,
                },
                {
                  path: 'performedDate',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingOperationPerformedDate,
                },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingAbsenceStartDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingAbsenceFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthSecondOwnerSpondylitisAnkylosingMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'osteoporosis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerOsteoporosisIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerOsteoporosisPreviousEpisodesCount },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerOsteoporosisPainLocation },
                { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerOsteoporosisDateSymptoms },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerOsteoporosisPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerOsteoporosisPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerOsteoporosisHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerOsteoporosisHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerOsteoporosisMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerOsteoporosisHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisTypeOfOperationPerformed,
                },
                { path: 'operationPerformedDate', nodeId: NodeIds.healthSecondOwnerOsteoporosisOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthSecondOwnerOsteoporosisTypeOfOperationPlanned },
                { path: 'operationPlannedDate', nodeId: NodeIds.healthSecondOwnerOsteoporosisOperationPlannedDate },
                { path: 'operationUnknownDate', nodeId: NodeIds.healthSecondOwnerOsteoporosisOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerOsteoporosisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerOsteoporosisAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerOsteoporosisAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerOsteoporosisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerOsteoporosisAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerOsteoporosisAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerOsteoporosisAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerOsteoporosisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerOsteoporosisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerOsteoporosisMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthSecondOwnerOsteoporosisMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'whiplash',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerWhiplashIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerWhiplashPreviousEpisodesCount },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerWhiplashPainLocation },
                { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerWhiplashDateSymptoms },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerWhiplashPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerWhiplashPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerWhiplashHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerWhiplashHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerWhiplashMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerWhiplashHadOperation },
                { path: 'performedOrPlanned', nodeId: NodeIds.healthSecondOwnerWhiplashOperationPerformedOrPlanned },
                { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthSecondOwnerWhiplashTypeOfOperationPerformed },
                { path: 'operationPerformedDate', nodeId: NodeIds.healthSecondOwnerWhiplashOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthSecondOwnerWhiplashTypeOfOperationPlanned },
                { path: 'operationPlannedDate', nodeId: NodeIds.healthSecondOwnerWhiplashOperationPlannedDate },
                { path: 'operationUnknownDate', nodeId: NodeIds.healthSecondOwnerWhiplashOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerWhiplashHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerWhiplashAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerWhiplashAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerWhiplashAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerWhiplashAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerWhiplashAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerWhiplashAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerWhiplashHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerWhiplashHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerWhiplashHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerWhiplashHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthSecondOwnerWhiplashHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerWhiplashHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthSecondOwnerWhiplashHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerWhiplashHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerWhiplashHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerWhiplashHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerWhiplashHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerWhiplashHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerWhiplashHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthSecondOwnerWhiplashHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerWhiplashHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerWhiplashHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerWhiplashHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerWhiplashMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerWhiplashMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthSecondOwnerWhiplashMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerWhiplashMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthSecondOwnerWhiplashMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'torticollis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerTorticollisIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerTorticollisPreviousEpisodesCount },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerTorticollisPainLocation },
                { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerTorticollisDateSymptoms },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerTorticollisPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerTorticollisPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerTorticollisHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerTorticollisHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerTorticollisMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerTorticollisHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerTorticollisOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerTorticollisTypeOfOperationPerformed,
                },
                { path: 'operationPerformedDate', nodeId: NodeIds.healthSecondOwnerTorticollisOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthSecondOwnerTorticollisTypeOfOperationPlanned },
                { path: 'operationPlannedDate', nodeId: NodeIds.healthSecondOwnerTorticollisOperationPlannedDate },
                { path: 'operationUnknownDate', nodeId: NodeIds.healthSecondOwnerTorticollisOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerTorticollisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerTorticollisAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerTorticollisAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerTorticollisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerTorticollisAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerTorticollisAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerTorticollisAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },

            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerTorticollisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthSecondOwnerTorticollisHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerTorticollisHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerTorticollisHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerTorticollisHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerTorticollisHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerTorticollisHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerTorticollisHasConsultedDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerTorticollisHasConsultedDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerTorticollisHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerTorticollisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerTorticollisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerTorticollisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthSecondOwnerTorticollisHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthSecondOwnerTorticollisHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerTorticollisHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerTorticollisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerTorticollisMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerTorticollisMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerTorticollisMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerTorticollisMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerTorticollisMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'neuralgia',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaPreviousEpisodesCount },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaPainLocation },
                { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaDateSymptoms },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasTakenTestsOrExams },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaMedicationWasPrescribed,
            },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaOperationPerformedOrPlanned,
                },
                {
                  path: 'performed',
                  nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaTypeOfOperationPerformed,
                },
                {
                  path: 'performedDate',
                  nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaOperationPerformedDate,
                },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                {
                  path: 'hadAbsence',
                  nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHadAbsence,
                },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaAbsenceStartDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaAbsenceFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerArnoldsNeuralgiaMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'sprain',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerSprainIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerSprainPreviousEpisodesCount },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerSprainPainLocation },
                { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerSprainDateSymptoms },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerSprainPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerSprainPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerSprainHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerSprainHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerSprainMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerSprainHadOperation },
                { path: 'performedOrPlanned', nodeId: NodeIds.healthSecondOwnerSprainOperationPerformedOrPlanned },
                { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthSecondOwnerSprainTypeOfOperationPerformed },
                { path: 'operationPerformedDate', nodeId: NodeIds.healthSecondOwnerSprainOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthSecondOwnerSprainTypeOfOperationPlanned },
                { path: 'operationPlannedDate', nodeId: NodeIds.healthSecondOwnerSprainOperationPlannedDate },
                { path: 'operationUnknownDate', nodeId: NodeIds.healthSecondOwnerSprainOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                {
                  path: 'hadAbsence',
                  nodeId: NodeIds.healthSecondOwnerSprainHadAbsence,
                },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerSprainAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerSprainAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerSprainAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerSprainAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerSprainAbsenceRecoveryDate },
                    { path: 'notRecoveredDetails', nodeId: NodeIds.healthSecondOwnerSprainAbsenceNotRecoveredDetails },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerSprainHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthSecondOwnerSprainHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerSprainHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerSprainHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerSprainHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerSprainHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerSprainHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerSprainHasConsultedDoctorsResult },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerSprainHasConsultedDoctorsFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerSprainHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerSprainHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerSprainHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerSprainHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthSecondOwnerSprainHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthSecondOwnerSprainHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerSprainHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerSprainHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerSprainMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerSprainMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerSprainMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerSprainMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerSprainMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'arthritis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerArthritisIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerArthritisPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerArthritisDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerArthritisPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthSecondOwnerArthritisPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthSecondOwnerArthritisPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerArthritisPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerArthritisPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerArthritisHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerArthritisHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerArthritisMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerArthritisHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerArthritisOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerArthritisTypeOfOperationPerformed,
                },
                {
                  path: 'performedDate',
                  nodeId: NodeIds.healthSecondOwnerArthritisOperationPerformedDate,
                },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthSecondOwnerArthritisTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerArthritisOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerArthritisOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerArthritisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerArthritisAbsenceStartDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerArthritisAbsenceFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerArthritisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerArthritisAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerArthritisAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerArthritisAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerArthritisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthSecondOwnerArthritisHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerArthritisHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerArthritisHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerArthritisHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerArthritisHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerArthritisHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerArthritisHasConsultedDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerArthritisHasConsultedDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerArthritisHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerArthritisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerArthritisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerArthritisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthSecondOwnerArthritisHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthSecondOwnerArthritisHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerArthritisHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerArthritisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerArthritisMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerArthritisMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerArthritisMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerArthritisMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerArthritisMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'osteoarthritis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisIsSingleEpisode },
            {
              path: 'previousEpisodesCount',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisPreviousEpisodesCount,
            },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisPainLocation },
                {
                  path: 'leftRight',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisPainLocationLeftRight,
                },
                { path: 'other', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisPainCauseDetails },
              ],
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctors,
            },
            {
              path: 'hasTakenTestsOrExams',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExams,
            },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisMedicationWasPrescribed,
            },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisTypeOfOperationPerformed,
                },
                {
                  path: 'performedDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisOperationPerformedDate,
                },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisTypeOfOperationPlanned,
                },
                {
                  path: 'plannedDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisOperationPlannedDate,
                },
                {
                  path: 'unknownDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisOperationUnknownDate,
                },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisAbsenceStartDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisAbsenceFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    {
                      path: 'hasRecovered',
                      nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisAbsenceHasRecovered,
                    },
                    {
                      path: 'recoveryDate',
                      nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisAbsenceRecoveryDate,
                    },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsName },
                {
                  path: 'address',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId:
                    NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisMedicationDosage },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisMedicationStartDate,
                },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoarthritisMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'osteoporosisOsteopenia',
          children: [
            {
              path: 'isSingleEpisode',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaIsSingleEpisode,
            },
            {
              path: 'previousEpisodesCount',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaPreviousEpisodesCount,
            },
            {
              path: 'dateSymptoms',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaDateSymptoms,
            },
            {
              path: 'painLocation',
              children: [
                {
                  path: 'location',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaPainLocation,
                },
                {
                  path: 'leftRight',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaPainLocationLeftRight,
                },
                {
                  path: 'other',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaPainLocationOther,
                },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaPainCause },
                {
                  path: 'details',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaPainCauseDetails,
                },
              ],
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctors,
            },
            {
              path: 'hasTakenTestsOrExams',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExams,
            },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationWasPrescribed,
            },
            {
              path: 'operation',
              children: [
                {
                  path: 'hadOperation',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHadOperation,
                },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaTypeOfOperationPerformed,
                },
                {
                  path: 'performedDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaOperationPerformedDate,
                },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaTypeOfOperationPlanned,
                },
                {
                  path: 'plannedDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaOperationPlannedDate,
                },
                {
                  path: 'unknownDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaOperationUnknownDate,
                },
              ],
            },
            {
              path: 'absence',
              children: [
                {
                  path: 'hadAbsence',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHadAbsence,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceStartDate,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceFrequency,
                },
                {
                  path: 'recovery',
                  children: [
                    {
                      path: 'hasRecovered',
                      nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceHasRecovered,
                    },
                    {
                      path: 'recoveryDate',
                      nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceRecoveryDate,
                    },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId:
                    NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId:
                    NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId:
                    NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId:
                    NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId:
                    NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationName },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'fibromyalgia',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerFibromyalgiaIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerFibromyalgiaPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerFibromyalgiaDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerFibromyalgiaPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthSecondOwnerFibromyalgiaPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthSecondOwnerFibromyalgiaPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerFibromyalgiaPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerFibromyalgiaPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerFibromyalgiaMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerFibromyalgiaHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationperformed',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthSecondOwnerFibromyalgiaOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthSecondOwnerFibromyalgiaTypeOfOperationPlanned },
                { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerFibromyalgiaOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerFibromyalgiaOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerFibromyalgiaHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerFibromyalgiaAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerFibromyalgiaAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerFibromyalgiaAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerFibromyalgiaAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerFibromyalgiaAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerFibromyalgiaAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerFibromyalgiaMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthSecondOwnerFibromyalgiaMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'replacementJoint',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerReplacementJointIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerReplacementJointPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerReplacementJointDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerReplacementJointPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthSecondOwnerReplacementJointPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthSecondOwnerReplacementJointPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerReplacementJointPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerReplacementJointPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerReplacementJointHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerReplacementJointHasTakenTestsOrExams },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerReplacementJointMedicationWasPrescribed,
            },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerReplacementJointHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthSecondOwnerReplacementJointOperationPerformedDate },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerReplacementJointOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerReplacementJointOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerReplacementJointHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerReplacementJointAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerReplacementJointAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerReplacementJointAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerReplacementJointAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerReplacementJointAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerReplacementJointAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerReplacementJointHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerReplacementJointHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerReplacementJointMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthSecondOwnerReplacementJointMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'sprain',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainIsSingleEpisode },
            {
              path: 'previousEpisodesCount',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainPreviousEpisodesCount,
            },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasConsultedDoctors },
            {
              path: 'hasTakenTestsOrExams',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasTakenTestsOrExams,
            },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainMedicationWasPrescribed,
            },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainOperationPerformedDate },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainAbsenceStartDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainAbsenceFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalSprainMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'fracture',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureIsSingleEpisode },
            {
              path: 'previousEpisodesCount',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalFracturePreviousEpisodesCount,
            },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerMusculoskeletalFracturePainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthSecondOwnerMusculoskeletalFracturePainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthSecondOwnerMusculoskeletalFracturePainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerMusculoskeletalFracturePainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerMusculoskeletalFracturePainCauseDetails },
              ],
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasConsultedDoctors,
            },
            {
              path: 'hasTakenTestsOrExams',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasTakenTestsOrExams,
            },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureMedicationWasPrescribed,
            },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureTypeOfOperationPerformed,
                },
                {
                  path: 'performedDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureOperationPerformedDate,
                },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureAbsenceStartDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureAbsenceFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    {
                      path: 'hasRecovered',
                      nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureAbsenceHasRecovered,
                    },
                    {
                      path: 'recoveryDate',
                      nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureAbsenceRecoveryDate,
                    },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalFractureMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'bursitis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerBursitisIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerBursitisPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerBursitisDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerBursitisPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthSecondOwnerBursitisPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthSecondOwnerBursitisPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerBursitisPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerBursitisPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerBursitisHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerBursitisHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerBursitisMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerBursitisHadOperation },
                { path: 'performedOrPlanned', nodeId: NodeIds.healthSecondOwnerBursitisOperationPerformedOrPlanned },
                { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthSecondOwnerBursitisTypeOfOperationPerformed },
                { path: 'performedDate', nodeId: NodeIds.healthSecondOwnerBursitisOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthSecondOwnerBursitisTypeOfOperationPlanned },
                { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerBursitisOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerBursitisOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerBursitisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerBursitisAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerBursitisAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerBursitisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerBursitisAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerBursitisAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerBursitisAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerBursitisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerBursitisHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerBursitisHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerBursitisHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthSecondOwnerBursitisHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerBursitisHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthSecondOwnerBursitisHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerBursitisHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerBursitisHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerBursitisHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerBursitisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerBursitisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerBursitisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthSecondOwnerBursitisHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerBursitisHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerBursitisHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerBursitisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerBursitisMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerBursitisMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthSecondOwnerBursitisMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerBursitisMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthSecondOwnerBursitisMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'tendonitis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerTendonitisIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerTendonitisPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerTendonitisDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerTendonitisPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthSecondOwnerTendonitisPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthSecondOwnerTendonitisPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerTendonitisPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerTendonitisPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerTendonitisHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerTendonitisHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerTendonitisMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerTendonitisHadOperation },
                { path: 'performedOrPlanned', nodeId: NodeIds.healthSecondOwnerTendonitisOperationPerformedOrPlanned },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerTendonitisTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthSecondOwnerTendonitisOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthSecondOwnerTendonitisTypeOfOperationPlanned },
                { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerTendonitisOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerTendonitisOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerTendonitisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerTendonitisAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerTendonitisAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerTendonitisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerTendonitisAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerTendonitisAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerTendonitisAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerTendonitisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthSecondOwnerTendonitisHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerTendonitisHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerTendonitisHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerTendonitisHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerTendonitisHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerTendonitisHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerTendonitisHasConsultedDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerTendonitisHasConsultedDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerTendonitisHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerTendonitisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerTendonitisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerTendonitisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthSecondOwnerTendonitisHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthSecondOwnerTendonitisHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerTendonitisHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerTendonitisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerTendonitisMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerTendonitisMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerTendonitisMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerTendonitisMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerTendonitisMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'epicondylitis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerEpicondylitisIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerEpicondylitisPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerEpicondylitisDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerEpicondylitisPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthSecondOwnerEpicondylitisPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthSecondOwnerEpicondylitisPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerEpicondylitisPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerEpicondylitisPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerEpicondylitisHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerEpicondylitisHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerEpicondylitisMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerEpicondylitisHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerEpicondylitisOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerEpicondylitisTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthSecondOwnerEpicondylitisOperationPerformedDate },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthSecondOwnerEpicondylitisTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerEpicondylitisOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerEpicondylitisOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerEpicondylitisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerEpicondylitisAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerEpicondylitisAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerEpicondylitisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerEpicondylitisAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerEpicondylitisAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerEpicondylitisAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerEpicondylitisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthSecondOwnerEpicondylitisHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerEpicondylitisHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerEpicondylitisHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerEpicondylitisHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerEpicondylitisHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerEpicondylitisHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerEpicondylitisHasConsultedDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerEpicondylitisHasConsultedDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerEpicondylitisHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerEpicondylitisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerEpicondylitisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerEpicondylitisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthSecondOwnerEpicondylitisHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthSecondOwnerEpicondylitisHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerEpicondylitisHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerEpicondylitisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerEpicondylitisMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerEpicondylitisMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerEpicondylitisMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerEpicondylitisMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerEpicondylitisMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'ligamentTearMeniscus',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusIsSingleEpisode },
            {
              path: 'previousEpisodesCount',
              nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusPreviousEpisodesCount,
            },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasTakenTestsOrExams },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusMedicationWasPrescribed,
            },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusOperationPerformedDate },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusAbsenceStartDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusAbsenceFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsAddress },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsFirstDate,
                },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerLigamentTearMeniscusMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'carpalTunnel',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerCarpalTunnelIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerCarpalTunnelPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerCarpalTunnelDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerCarpalTunnelPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthSecondOwnerCarpalTunnelPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthSecondOwnerCarpalTunnelPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerCarpalTunnelPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerCarpalTunnelPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerCarpalTunnelMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerCarpalTunnelHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerCarpalTunnelOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerCarpalTunnelTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthSecondOwnerCarpalTunnelOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthSecondOwnerCarpalTunnelTypeOfOperationPlanned },
                { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerCarpalTunnelOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerCarpalTunnelOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerCarpalTunnelHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerCarpalTunnelAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerCarpalTunnelAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerCarpalTunnelAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerCarpalTunnelAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerCarpalTunnelAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerCarpalTunnelAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasConsultedDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasConsultedDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerCarpalTunnelHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerCarpalTunnelMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerCarpalTunnelMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerCarpalTunnelMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerCarpalTunnelMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerCarpalTunnelMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'muscularDystrophy',
          children: [
            { path: 'type', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyType },
            { path: 'diagnosisDate', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyDiagnosisDate },
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasTakenTestsOrExams },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerMuscularDystrophyMedicationWasPrescribed,
            },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerMuscularDystrophyOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerMuscularDystrophyTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyOperationPerformedDate },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthSecondOwnerMuscularDystrophyTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerMuscularDystrophyAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasConsultedDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasConsultedDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthSecondOwnerMuscularDystrophyHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerMuscularDystrophyMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerMuscularDystrophyMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'other',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherIsSingleEpisode },
            {
              path: 'previousEpisodesCount',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherPreviousEpisodesCount,
            },
            { path: 'dateSymptoms', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherPainCause },
                { path: 'details', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasTakenTestsOrExams },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherMedicationWasPrescribed,
            },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherOperationPerformedDate },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherAbsenceStartDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherAbsenceFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            { path: 'profession', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsProfession },
            {
              path: 'professionDetails',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsProfessionDetails,
            },
            { path: 'name', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsName },
            { path: 'address', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsAddress },
            { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsFirstDate },
            { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsLastDate },
            { path: 'result', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsResult },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsFrequency },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            { path: 'type', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasTakenTestsOrExamsType },
            { path: 'date', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasTakenTestsOrExamsDate },
            { path: 'result', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasTakenTestsOrExamsResult },
            {
              path: 'abnormalResult',
              nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherHasTakenTestsOrExamsDetailsAbnormalResult,
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherMedicationDetails,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherMedicationName },
            { path: 'dosage', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherMedicationDosage },
            { path: 'startDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherMedicationStartDate },
            { path: 'endDate', nodeId: NodeIds.healthSecondOwnerMusculoskeletalOtherMedicationEndDate },
          ],
        },
      ],
    },
    {
      path: 'neurologicalDisorders',
      children: [
        { path: 'epilepsyHasCondition', nodeId: NodeIds.healthSecondOwnerEpilepsyHasCondition },
        { path: 'epilepsyDetails', nodeId: NodeIds.healthSecondOwnerEpilepsyDetails },
        {
          path: 'neurologicalDisordersHasCondition',
          nodeId: NodeIds.healthSecondOwnerNeurologicalDisordersHasCondition,
        },
        { path: 'neurologicalDisordersConditions', nodeId: NodeIds.healthSecondOwnerNeurologicalConditions },
        { path: 'cerebralPalsyDetails', nodeId: NodeIds.healthSecondOwnerCerebralPalsyDetails },
        { path: 'paralysisDetails', nodeId: NodeIds.healthSecondOwnerParalysisDetails },
        { path: 'concussionDetails', nodeId: NodeIds.healthSecondOwnerConcussionDetails },
        { path: 'migrainesDetails', nodeId: NodeIds.healthSecondOwnerMigrainesDetails },
        {
          path: 'blurredVisionOrOpticNeuritisDetails',
          nodeId: NodeIds.healthSecondOwnerBlurredVisionOrOpticNeuritisDetails,
        },
        { path: 'multipleSclerosisDetails', nodeId: NodeIds.healthSecondOwnerMultipleSclerosisDetails },
        { path: 'alsDetails', nodeId: NodeIds.healthSecondOwnerAlsDetails },
        { path: 'parkinsonsDetails', nodeId: NodeIds.healthSecondOwnerParkinsonsDetails },
        { path: 'alzheimerDetails', nodeId: NodeIds.healthSecondOwnerAlzheimerDetails },
        { path: 'lossOfConsciousnessDetails', nodeId: NodeIds.healthSecondOwnerLossOfConsciousnessDetails },
        { path: 'numbnessDetails', nodeId: NodeIds.healthSecondOwnerNumbnessDetails },
        { path: 'memoryLossDetails', nodeId: NodeIds.healthSecondOwnerMemoryLossDetails },
        { path: 'degenerativeDiseaseDetails', nodeId: NodeIds.healthSecondOwnerDegenerativeDiseaseDetails },
        {
          path: 'anyOtherCognitiveImpairmentDetails',
          nodeId: NodeIds.healthSecondOwnerAnyOtherCognitiveImpairmentDetails,
        },
      ],
    },
    {
      path: 'mentalHealth',
      children: [
        { path: 'hasCondition', nodeId: NodeIds.healthSecondOwnerMentalHealthHasCondition },
        { path: 'applicableConditions', nodeId: NodeIds.healthSecondOwnerMentalHealthApplicableConditions },
        {
          path: 'insomnia',
          children: [
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerInsomniaMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthSecondOwnerInsomniaHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthSecondOwnerInsomniaHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'hadAbsence',
              nodeId: NodeIds.healthSecondOwnerInsomniaHadAbsence,
            },
            {
              path: 'absence',
              children: [
                {
                  path: 'recovery',
                  children: [
                    {
                      path: 'hasRecovered',
                      nodeId: NodeIds.healthSecondOwnerInsomniaAbsenceHasRecovered,
                    },
                    {
                      path: 'recoveryDate',
                      nodeId: NodeIds.healthSecondOwnerInsomniaAbsenceRecoveryDate,
                    },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerInsomniaAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerInsomniaMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerInsomniaMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthSecondOwnerInsomniaMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerInsomniaMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthSecondOwnerInsomniaMedicationEndDate,
                },
                {
                  path: 'stillUses',
                  nodeId: NodeIds.healthSecondOwnerInsomniaMedicationStillUses,
                },
                {
                  path: 'hasChanged',
                  nodeId: NodeIds.healthSecondOwnerInsomniaMedicationHasChanged,
                },
                {
                  path: 'hasChangedDetails',
                  nodeId: NodeIds.healthSecondOwnerInsomniaMedicationHasChangedDetails,
                },
              ],
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerInsomniaHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerInsomniaHasOrWillConsultDoctorsProfession,
                },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthSecondOwnerInsomniaHasOrWillConsultDoctorsProfessionOther,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerInsomniaHasOrWillConsultDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthSecondOwnerInsomniaHasOrWillConsultDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerInsomniaHasOrWillConsultDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthSecondOwnerInsomniaHasOrWillConsultDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerInsomniaHasOrWillConsultDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerInsomniaHasOrWillConsultDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerInsomniaHasOrWillConsultDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerInsomniaHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerInsomniaHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hadAbsenceDetails',
              nodeId: NodeIds.healthSecondOwnerInsomniaHadAbsenceDetails,
              isCollection: true,
              children: [
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerInsomniaAbsenceStartDate,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerInsomniaAbsenceFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerInsomniaAbsenceFrequency,
                },
              ],
            },
          ],
        },
        {
          path: 'bipolarDisorder',
          children: [
            {
              path: 'isSingleEpisode',
              nodeId: NodeIds.healthSecondOwnerBipolarDisorderIsSingleEpisode,
            },
            {
              path: 'diagnosticDate',
              nodeId: NodeIds.healthSecondOwnerBipolarDisorderDiagnosticDate,
            },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerBipolarDisorderMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthSecondOwnerBipolarDisorderHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthSecondOwnerBipolarDisorderHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'went-to-emergency-department',
              nodeId: NodeIds.healthSecondOwnerBipolarDisorderWentToEmergencyDepartment,
            },
            {
              path: 'was-hospitalized',
              nodeId: NodeIds.healthSecondOwnerBipolarDisorderWasHospitalized,
            },
            {
              path: 'suicidalThoughtsAndAttempt',
              children: [
                {
                  path: 'hadSuicidalThoughtsAndAttempt',
                  nodeId: NodeIds.healthSecondOwnerBipolarDisorderHadSuicidalThoughtsAndAttempt,
                },
                {
                  path: 'choices',
                  nodeId: NodeIds.healthSecondOwnerBipolarDisorderSuicidalThoughtsAndAttemptChoices,
                },
                {
                  path: 'suicidalIdeation',
                  children: [
                    {
                      path: 'frequencyNumber',
                      nodeId: NodeIds.healthSecondOwnerBipolarDisorderSuicidalThoughtsFrequencyNumber,
                    },
                    {
                      path: 'frequency',
                      nodeId: NodeIds.healthSecondOwnerBipolarDisorderSuicidalThoughtsFrequency,
                    },
                  ],
                },
                {
                  path: 'attemptedSuicide',
                  children: [
                    {
                      path: 'attemptsNumber',
                      nodeId: NodeIds.healthSecondOwnerBipolarDisorderSuicideAttemptsNumber,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hadAbsence',
              nodeId: NodeIds.healthSecondOwnerBipolarDisorderHadAbsence,
            },
            {
              path: 'absence',
              children: [
                {
                  path: 'recovery',
                  children: [
                    {
                      path: 'hasRecovered',
                      nodeId: NodeIds.healthSecondOwnerBipolarDisorderAbsenceHasRecovered,
                    },
                    {
                      path: 'recoveryDate',
                      nodeId: NodeIds.healthSecondOwnerBipolarDisorderAbsenceRecoveryDate,
                    },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerBipolarDisorderAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'wasHospitalizedDetails',
              nodeId: NodeIds.healthSecondOwnerBipolarDisorderWasHospitalizedDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthSecondOwnerBipolarDisorderWasHospitalizedDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerBipolarDisorderWasHospitalizedFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerBipolarDisorderWasHospitalizedFrequency },
                { path: 'location', nodeId: NodeIds.healthSecondOwnerBipolarDisorderWasHospitalizedLocation },
              ],
            },
            {
              path: 'suicidalThoughtsDetails',
              nodeId: NodeIds.healthSecondOwnerBipolarDisorderSuicidalThoughtsDetails,
              isCollection: true,
              children: [{ path: 'date', nodeId: NodeIds.healthSecondOwnerBipolarDisorderSuicidalThoughtsDate }],
            },
            {
              path: 'suicideAttemptsDetails',
              nodeId: NodeIds.healthSecondOwnerBipolarDisorderSuicideAttemptsDetails,
              isCollection: true,
              children: [{ path: 'date', nodeId: NodeIds.healthSecondOwnerBipolarDisorderSuicideAttemptsDate }],
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsProfession,
                },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerBipolarDisorderHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'wentToEmergencyDepartmentDetails',
              nodeId: NodeIds.healthSecondOwnerBipolarDisorderWentToEmergencyDepartmentDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthSecondOwnerBipolarDisorderWentToEmergencyDepartmentDate },
                { path: 'location', nodeId: NodeIds.healthSecondOwnerBipolarDisorderWentToEmergencyDepartmentLocation },
              ],
            },
            {
              path: 'hadAbsenceDetails',
              nodeId: NodeIds.healthSecondOwnerBipolarDisorderHadAbsenceDetails,
              isCollection: true,
              children: [
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerBipolarDisorderAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerBipolarDisorderAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerBipolarDisorderAbsenceFrequency },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerBipolarDisorderMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerBipolarDisorderMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerBipolarDisorderMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerBipolarDisorderMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerBipolarDisorderMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthSecondOwnerBipolarDisorderMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthSecondOwnerBipolarDisorderMedicationHasChanged },
                {
                  path: 'hasChangedDetails',
                  nodeId: NodeIds.healthSecondOwnerBipolarDisorderMedicationHasChangedDetails,
                },
              ],
            },
          ],
        },
        {
          path: 'psychosis',
          children: [
            {
              path: 'isSingleEpisode',
              nodeId: NodeIds.healthSecondOwnerPsychosisIsSingleEpisode,
            },
            {
              path: 'diagnosticDate',
              nodeId: NodeIds.healthSecondOwnerPsychosisDiagnosticDate,
            },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerPsychosisMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthSecondOwnerPsychosisHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthSecondOwnerPsychosisHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'went-to-emergency-department',
              nodeId: NodeIds.healthSecondOwnerPsychosisWentToEmergencyDepartment,
            },
            {
              path: 'was-hospitalized',
              nodeId: NodeIds.healthSecondOwnerPsychosisWasHospitalized,
            },
            {
              path: 'suicidalThoughtsAndAttempt',
              children: [
                {
                  path: 'hadSuicidalThoughtsAndAttempt',
                  nodeId: NodeIds.healthSecondOwnerPsychosisHadSuicidalThoughtsAndAttempt,
                },
                {
                  path: 'choices',
                  nodeId: NodeIds.healthSecondOwnerPsychosisSuicidalThoughtsAndAttemptChoices,
                },
                {
                  path: 'suicidalIdeation',
                  children: [
                    {
                      path: 'frequencyNumber',
                      nodeId: NodeIds.healthSecondOwnerPsychosisSuicidalThoughtsFrequencyNumber,
                    },
                    {
                      path: 'frequency',
                      nodeId: NodeIds.healthSecondOwnerPsychosisSuicidalThoughtsFrequency,
                    },
                  ],
                },
                {
                  path: 'attemptedSuicide',
                  children: [
                    {
                      path: 'attemptsNumber',
                      nodeId: NodeIds.healthSecondOwnerPsychosisSuicideAttemptsNumber,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hadAbsence',
              nodeId: NodeIds.healthSecondOwnerPsychosisHadAbsence,
            },
            {
              path: 'absence',
              children: [
                {
                  path: 'recovery',
                  children: [
                    {
                      path: 'hasRecovered',
                      nodeId: NodeIds.healthSecondOwnerPsychosisAbsenceHasRecovered,
                    },
                    {
                      path: 'recoveryDate',
                      nodeId: NodeIds.healthSecondOwnerPsychosisAbsenceRecoveryDate,
                    },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerPsychosisAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'suicideAttemptsDetails',
              nodeId: NodeIds.healthSecondOwnerPsychosisSuicideAttemptsDetails,
              isCollection: true,
              children: [{ path: 'date', nodeId: NodeIds.healthSecondOwnerPsychosisSuicideAttemptsDate }],
            },
            {
              path: 'suicidalThoughtsDetails',
              nodeId: NodeIds.healthSecondOwnerPsychosisSuicidalThoughtsDetails,
              isCollection: true,
              children: [{ path: 'date', nodeId: NodeIds.healthSecondOwnerPsychosisSuicidalThoughtsDate }],
            },
            {
              path: 'hadAbsenceDetails',
              nodeId: NodeIds.healthSecondOwnerPsychosisHadAbsenceDetails,
              isCollection: true,
              children: [
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerPsychosisAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerPsychosisAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerPsychosisAbsenceFrequency },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerPsychosisMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerPsychosisMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerPsychosisMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerPsychosisMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerPsychosisMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthSecondOwnerPsychosisMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthSecondOwnerPsychosisMedicationHasChanged },
                { path: 'hasChangedDetails', nodeId: NodeIds.healthSecondOwnerPsychosisMedicationHasChangedDetails },
              ],
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerPsychosisHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthSecondOwnerPsychosisHasOrWillConsultDoctorsProfession },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthSecondOwnerPsychosisHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerPsychosisHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerPsychosisHasOrWillConsultDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerPsychosisHasOrWillConsultDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerPsychosisHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerPsychosisHasOrWillConsultDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerPsychosisHasOrWillConsultDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerPsychosisHasOrWillConsultDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerPsychosisHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerPsychosisHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'wentToEmergencyDepartmentDetails',
              nodeId: NodeIds.healthSecondOwnerPsychosisWentToEmergencyDepartmentDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthSecondOwnerPsychosisWentToEmergencyDepartmentDate },
                { path: 'location', nodeId: NodeIds.healthSecondOwnerPsychosisWentToEmergencyDepartmentLocation },
              ],
            },
            {
              path: 'wasHospitalizedDetails',
              nodeId: NodeIds.healthSecondOwnerPsychosisWasHospitalizedDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthSecondOwnerPsychosisWasHospitalizedDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerPsychosisWasHospitalizedFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerPsychosisWasHospitalizedFrequency },
                { path: 'location', nodeId: NodeIds.healthSecondOwnerPsychosisWasHospitalizedLocation },
              ],
            },
          ],
        },
        {
          path: 'suicideAttempt',
          children: [
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerSuicideAttemptMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthSecondOwnerSuicideAttemptHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthSecondOwnerSuicideAttemptHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'was-hospitalized',
              nodeId: NodeIds.healthSecondOwnerSuicideAttemptWasHospitalized,
            },
            { path: 'date', nodeId: NodeIds.healthSecondOwnerSuicideAttemptDate },
            { path: 'reason', nodeId: NodeIds.healthSecondOwnerSuicideAttemptReason },
            {
              path: 'moreThanOne',
              nodeId: NodeIds.healthSecondOwnerSuicideAttemptHasMoreThanOneAttempt,
            },
            {
              path: 'howManyAttempts',
              nodeId: NodeIds.healthSecondOwnerSuicideAttemptHowManyAttempts,
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerSuicideAttemptMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerSuicideAttemptMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerSuicideAttemptMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerSuicideAttemptMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerSuicideAttemptMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthSecondOwnerSuicideAttemptMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthSecondOwnerSuicideAttemptMedicationHasChanged },
                {
                  path: 'hasChangedDetails',
                  nodeId: NodeIds.healthSecondOwnerSuicideAttemptMedicationHasChangedDetails,
                },
              ],
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsProfession,
                },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerSuicideAttemptHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'wasHospitalizedDetails',
              nodeId: NodeIds.healthSecondOwnerSuicideAttemptWasHospitalizedDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthSecondOwnerSuicideAttemptWasHospitalizedDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerSuicideAttemptWasHospitalizedFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerSuicideAttemptWasHospitalizedFrequency },
                { path: 'location', nodeId: NodeIds.healthSecondOwnerSuicideAttemptWasHospitalizedLocation },
              ],
            },
            {
              path: 'multipleAttemptsDetails',
              nodeId: NodeIds.healthSecondOwnerSuicideAttemptMultipleAttemptsDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthSecondOwnerSuicideAttemptMultipleAttemptsDate },
                { path: 'reason', nodeId: NodeIds.healthSecondOwnerSuicideAttemptMultipleAttemptsReason },
              ],
            },
          ],
        },
        {
          path: 'eatingDisorder',
          children: [
            {
              path: 'diagnosticDate',
              nodeId: NodeIds.healthSecondOwnerEatingDisorderDiagnosticDate,
            },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerEatingDisorderMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthSecondOwnerEatingDisorderHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthSecondOwnerEatingDisorderHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'was-hospitalized',
              nodeId: NodeIds.healthSecondOwnerEatingDisorderWasHospitalized,
            },
            {
              path: 'isControlled',
              nodeId: NodeIds.healthSecondOwnerEatingDisorderIsControlled,
            },
            { path: 'notControlledDetails', nodeId: NodeIds.healthSecondOwnerEatingDisorderNotControlledDetails },
            {
              path: 'hasStableWeightDate',
              nodeId: NodeIds.healthSecondOwnerEatingDisorderHasStableWeightDate,
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsProfession,
                },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerEatingDisorderHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'wasHospitalizedDetails',
              nodeId: NodeIds.healthSecondOwnerEatingDisorderWasHospitalizedDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthSecondOwnerEatingDisorderWasHospitalizedDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerEatingDisorderWasHospitalizedFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerEatingDisorderWasHospitalizedFrequency },
                { path: 'location', nodeId: NodeIds.healthSecondOwnerEatingDisorderWasHospitalizedLocation },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerEatingDisorderMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerEatingDisorderMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerEatingDisorderMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerEatingDisorderMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerEatingDisorderMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthSecondOwnerEatingDisorderMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthSecondOwnerEatingDisorderMedicationHasChanged },
                {
                  path: 'hasChangedDetails',
                  nodeId: NodeIds.healthSecondOwnerEatingDisorderMedicationHasChangedDetails,
                },
              ],
            },
          ],
        },
        {
          path: 'attentionDeficitHyperactivityDisorder',
          children: [
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerAddAdhdMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthSecondOwnerAddAdhdHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthSecondOwnerAddAdhdHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'isControlled',
              nodeId: NodeIds.healthSecondOwnerAddAdhdIsControlled,
            },
            {
              path: 'hasStableWeightDate',
              nodeId: NodeIds.healthSecondOwnerAddAdhdHasStableWeightDate,
            },
            { path: 'notControlledDetails', nodeId: NodeIds.healthSecondOwnerAddAdhdNotControlledDetails },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerAddAdhdMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerAddAdhdMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerAddAdhdMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerAddAdhdMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerAddAdhdMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthSecondOwnerAddAdhdMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthSecondOwnerAddAdhdMedicationHasChanged },
                { path: 'hasChangedDetails', nodeId: NodeIds.healthSecondOwnerAddAdhdMedicationHasChangedDetails },
              ],
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerAddAdhdHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthSecondOwnerAddAdhdHasOrWillConsultDoctorsProfession },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthSecondOwnerAddAdhdHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerAddAdhdHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerAddAdhdHasOrWillConsultDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerAddAdhdHasOrWillConsultDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerAddAdhdHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerAddAdhdHasOrWillConsultDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerAddAdhdHasOrWillConsultDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerAddAdhdHasOrWillConsultDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerAddAdhdHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerAddAdhdHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
          ],
        },
        {
          path: 'autismSpectrumDisorder',
          children: [
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerAutismMedicationWasPrescribed },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthSecondOwnerAutismHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthSecondOwnerAutismHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'performsDailyActivitiesWithoutHelp',
              nodeId: NodeIds.healthSecondOwnerAutismPerformsDailyActivitiesWithoutHelp,
            },
            {
              path: 'performsDailyActivitiesWithoutHelpDetails',
              nodeId: NodeIds.healthSecondOwnerAutismPerformsDailyActivitiesWithoutHelpDetails,
            },
            {
              path: 'needsAccommodation',
              nodeId: NodeIds.healthSecondOwnerAutismNeedsAccommodation,
            },
            {
              path: 'accommodationDetails',
              nodeId: NodeIds.healthSecondOwnerAutismAccommodationDetails,
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerAutismHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthSecondOwnerAutismHasOrWillConsultDoctorsProfession },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthSecondOwnerAutismHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerAutismHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerAutismHasOrWillConsultDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerAutismHasOrWillConsultDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerAutismHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerAutismHasOrWillConsultDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerAutismHasOrWillConsultDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerAutismHasOrWillConsultDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerAutismHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerAutismHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerAutismMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerAutismMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerAutismMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerAutismMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerAutismMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthSecondOwnerAutismMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthSecondOwnerAutismMedicationHasChanged },
                { path: 'hasChangedDetails', nodeId: NodeIds.healthSecondOwnerAutismMedicationHasChangedDetails },
              ],
            },
          ],
        },
        {
          path: 'intellectualImpairment',
          children: [
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'performsDailyActivitiesWithoutHelp',
              nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentPerformsDailyActivitiesWithoutHelp,
            },
            {
              path: 'performsDailyActivitiesWithoutHelpDetails',
              nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentPerformsDailyActivitiesWithoutHelpDetails,
            },
            {
              path: 'needsAccommodation',
              nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentNeedsAccommodation,
            },
            {
              path: 'accommodationDetails',
              nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentAccommodationDetails,
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsProfession,
                },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsName },
                {
                  path: 'address',
                  nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentMedicationHasChanged },
                {
                  path: 'hasChangedDetails',
                  nodeId: NodeIds.healthSecondOwnerIntellectualImpairmentMedicationHasChangedDetails,
                },
              ],
            },
          ],
        },
        {
          path: 'downSyndrome',
          children: [
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerDownSyndromeMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthSecondOwnerDownSyndromeHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthSecondOwnerDownSyndromeHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'performsDailyActivitiesWithoutHelp',
              nodeId: NodeIds.healthSecondOwnerDownSyndromePerformsDailyActivitiesWithoutHelp,
            },
            {
              path: 'performsDailyActivitiesWithoutHelpDetails',
              nodeId: NodeIds.healthSecondOwnerDownSyndromePerformsDailyActivitiesWithoutHelpDetails,
            },
            {
              path: 'needsAccommodation',
              nodeId: NodeIds.healthSecondOwnerDownSyndromeNeedsAccommodation,
            },
            {
              path: 'accommodationDetails',
              nodeId: NodeIds.healthSecondOwnerDownSyndromeAccommodationDetails,
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsProfession },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerDownSyndromeHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerDownSyndromeMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerDownSyndromeMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerDownSyndromeMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerDownSyndromeMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerDownSyndromeMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthSecondOwnerDownSyndromeMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthSecondOwnerDownSyndromeMedicationHasChanged },
                { path: 'hasChangedDetails', nodeId: NodeIds.healthSecondOwnerDownSyndromeMedicationHasChangedDetails },
              ],
            },
          ],
        },
        {
          path: 'otherMentalHealth',
          children: [
            {
              path: 'disorderType',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherDisorderType,
            },
            { path: 'date', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherDate },
            {
              path: 'hasDiagnostic',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasDiagnostic,
            },
            {
              path: 'diagnosticResult',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherDiagnosticResult,
            },
            {
              path: 'diagnosticDate',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherDiagnosticDate,
            },
            {
              path: 'anyOtherTests',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasTakenTestsOrExams,
            },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'went-to-emergency-department',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherWentToEmergencyDepartment,
            },
            {
              path: 'was-hospitalized',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherWasHospitalized,
            },
            {
              path: 'hadAbsence',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHadAbsence,
            },
            {
              path: 'absence',
              children: [
                {
                  path: 'recovery',
                  children: [
                    {
                      path: 'hasRecovered',
                      nodeId: NodeIds.healthSecondOwnerMentalHealthOtherAbsenceHasRecovered,
                    },
                    {
                      path: 'recoveryDate',
                      nodeId: NodeIds.healthSecondOwnerMentalHealthOtherAbsenceRecoveryDate,
                    },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerMentalHealthOtherAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherMedicationHasChanged },
                {
                  path: 'hasChangedDetails',
                  nodeId: NodeIds.healthSecondOwnerMentalHealthOtherMedicationHasChangedDetails,
                },
              ],
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsProfession,
                },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsAddress },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsFirstDate,
                },
                { path: 'lastDate', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },

            {
              path: 'wentToEmergencyDepartmentDetails',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherWentToEmergencyDepartmentDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherWentToEmergencyDepartmentDate },
                {
                  path: 'location',
                  nodeId: NodeIds.healthSecondOwnerMentalHealthOtherWentToEmergencyDepartmentLocation,
                },
              ],
            },
            {
              path: 'wasHospitalizedDetails',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherWasHospitalizedDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherWasHospitalizedDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerMentalHealthOtherWasHospitalizedFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherWasHospitalizedFrequency },
                { path: 'location', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherWasHospitalizedLocation },
              ],
            },
            {
              path: 'hadAbsenceDetails',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherhadAbsenceDetails,
              isCollection: true,
              children: [
                { path: 'startDate', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherAbsenceFrequency },
              ],
            },
            {
              path: 'HasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthSecondOwnerMentalHealthOtherHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'details', nodeId: NodeIds.healthSecondOwnerMentalHealthOtherTestsOrExamsDetails },
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerMentalHealthOtherOtherTestsOrExamsDetailsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerMentalHealthOtherOtherTestsOrExamsDetailsResult,
                },
              ],
            },
          ],
        },
        {
          path: 'depression',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerDepressionIsSingleEpisode },
            { path: 'diagnosticDate', nodeId: NodeIds.healthSecondOwnerDepressionDiagnosticDate },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerDepressionMedicationWasPrescribed },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerDepressionHasConsultedDoctors },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthSecondOwnerDepressionHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'went-to-emergency-department',
              nodeId: NodeIds.healthSecondOwnerDepressionWentToEmergencyDepartment,
            },
            { path: 'was-hospitalized', nodeId: NodeIds.healthSecondOwnerDepressionWasHospitalized },
            {
              path: 'suicidalThoughtsAndAttempt',
              children: [
                {
                  path: 'hadSuicidalThoughtsAndAttempt',
                  nodeId: NodeIds.healthSecondOwnerDepressionHadSuicidalThoughtsAndAttempt,
                },
                {
                  path: 'choices',
                  nodeId: NodeIds.healthSecondOwnerDepressionSuicidalThoughtsAndAttemptChoices,
                },
                {
                  path: 'suicidalIdeation',
                  children: [
                    {
                      path: 'frequencyNumber',
                      nodeId: NodeIds.healthSecondOwnerDepressionSuicidalThoughtsFrequencyNumber,
                    },
                    {
                      path: 'frequency',
                      nodeId: NodeIds.healthSecondOwnerDepressionSuicidalThoughtsFrequency,
                    },
                  ],
                },
                {
                  path: 'attemptedSuicide',
                  children: [
                    {
                      path: 'attemptsNumber',
                      nodeId: NodeIds.healthSecondOwnerDepressionSuicideAttemptsNumber,
                    },
                  ],
                },
              ],
            },
            { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerDepressionHadAbsence },
            {
              path: 'absence',
              children: [
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerDepressionAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerDepressionAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthSecondOwnerDepressionAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthSecondOwnerDepressionMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerDepressionMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthSecondOwnerDepressionMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerDepressionMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthSecondOwnerDepressionMedicationEndDate,
                },
                {
                  path: 'stillUses',
                  nodeId: NodeIds.healthSecondOwnerDepressionMedicationStillUses,
                },
                {
                  path: 'hasChanged',
                  nodeId: NodeIds.healthSecondOwnerDepressionMedicationHasChanged,
                },
                {
                  path: 'hasChangedDetails',
                  nodeId: NodeIds.healthSecondOwnerDepressionMedicationHasChangedDetails,
                },
              ],
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthSecondOwnerDepressionHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthSecondOwnerDepressionHasOrWillConsultDoctorsProfession,
                },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthSecondOwnerDepressionHasOrWillConsultDoctorsProfessionOther,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthSecondOwnerDepressionHasOrWillConsultDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthSecondOwnerDepressionHasOrWillConsultDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthSecondOwnerDepressionHasOrWillConsultDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthSecondOwnerDepressionHasOrWillConsultDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthSecondOwnerDepressionHasOrWillConsultDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerDepressionHasOrWillConsultDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerDepressionHasOrWillConsultDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthSecondOwnerDepressionHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthSecondOwnerDepressionHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'wentToEmergencyDepartmentDetails',
              nodeId: NodeIds.healthSecondOwnerDepressionWentToEmergencyDepartmentDetails,
              isCollection: true,
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerDepressionWentToEmergencyDepartmentDate,
                },
                {
                  path: 'location',
                  nodeId: NodeIds.healthSecondOwnerDepressionWentToEmergencyDepartmentLocation,
                },
              ],
            },
            {
              path: 'wasHospitalizedDetails',
              nodeId: NodeIds.healthSecondOwnerDepressionWasHospitalizedDetails,
              isCollection: true,
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerDepressionWasHospitalizedDate,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerDepressionWasHospitalizedFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerDepressionWasHospitalizedFrequency,
                },
                {
                  path: 'location',
                  nodeId: NodeIds.healthSecondOwnerDepressionWasHospitalizedLocation,
                },
              ],
            },
            {
              path: 'suicidalThoughtsDetails',
              nodeId: NodeIds.healthSecondOwnerDepressionSuicidalThoughtsDetails,
              isCollection: true,
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerDepressionSuicidalThoughtsDate,
                },
              ],
            },
            {
              path: 'suicideAttemptsDetails',
              nodeId: NodeIds.healthSecondOwnerDepressionSuicideAttemptsDetails,
              isCollection: true,
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.healthSecondOwnerDepressionSuicideAttemptsDate,
                },
              ],
            },
            {
              path: 'hadAbsenceDetails',
              nodeId: NodeIds.healthSecondOwnerDepressionHadAbsenceDetails,
              isCollection: true,
              children: [
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthSecondOwnerDepressionAbsenceStartDate,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerDepressionAbsenceFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerDepressionAbsenceFrequency,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'anxiety',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerAnxietyIsSingleEpisode },
        { path: 'diagnosticDate', nodeId: NodeIds.healthSecondOwnerAnxietyDiagnosticDate },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerAnxietyMedicationWasPrescribed },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerAnxietyHasConsultedDoctors },
        {
          path: 'hasConsultedOtherDoctorOrTherapist',
          nodeId: NodeIds.healthSecondOwnerAnxietyHasConsultedOtherDoctorsOrTherapists,
        },
        { path: 'went-to-emergency-department', nodeId: NodeIds.healthSecondOwnerAnxietyWentToEmergencyDepartment },
        { path: 'was-hospitalized', nodeId: NodeIds.healthSecondOwnerAnxietyWasHospitalized },
        {
          path: 'suicidalThoughtsAndAttempt',
          children: [
            {
              path: 'hadSuicidalThoughtsAndAttempt',
              nodeId: NodeIds.healthSecondOwnerAnxietyHadSuicidalThoughtsAndAttempt,
            },
            {
              path: 'choices',
              nodeId: NodeIds.healthSecondOwnerAnxietySuicidalThoughtsAndAttemptChoices,
            },
            {
              path: 'suicidalIdeation',
              children: [
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerAnxietySuicidalThoughtsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerAnxietySuicidalThoughtsFrequency,
                },
              ],
            },
            {
              path: 'attemptedSuicide',
              children: [
                {
                  path: 'attemptsNumber',
                  nodeId: NodeIds.healthSecondOwnerAnxietySuicideAttemptsNumber,
                },
              ],
            },
          ],
        },
        { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerAnxietyHadAbsence },
        {
          path: 'absence',
          children: [
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerAnxietyAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerAnxietyAbsenceRecoveryDate },
                { path: 'notRecoveredDetails', nodeId: NodeIds.healthSecondOwnerAnxietyAbsenceNotRecoveredDetails },
              ],
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthSecondOwnerAnxietyMedicationDetails,
          isCollection: true,
          children: [
            {
              path: 'name',
              nodeId: NodeIds.healthSecondOwnerAnxietyMedicationName,
            },
            {
              path: 'dosage',
              nodeId: NodeIds.healthSecondOwnerAnxietyMedicationDosage,
            },
            {
              path: 'startDate',
              nodeId: NodeIds.healthSecondOwnerAnxietyMedicationStartDate,
            },
            {
              path: 'endDate',
              nodeId: NodeIds.healthSecondOwnerAnxietyMedicationEndDate,
            },
            {
              path: 'stillUses',
              nodeId: NodeIds.healthSecondOwnerAnxietyMedicationStillUses,
            },
            {
              path: 'hasChanged',
              nodeId: NodeIds.healthSecondOwnerAnxietyMedicationHasChanged,
            },
            {
              path: 'hasChangedDetails',
              nodeId: NodeIds.healthSecondOwnerAnxietyMedicationHasChangedDetails,
            },
          ],
        },
        {
          path: 'hasOrWillConsultDoctorsDetails',
          nodeId: NodeIds.healthSecondOwnerAnxietyHasOrWillConsultDoctorsDetails,
          isCollection: true,
          children: [
            {
              path: 'profession',
              nodeId: NodeIds.healthSecondOwnerAnxietyHasOrWillConsultDoctorsProfession,
            },
            {
              path: 'professionOther',
              nodeId: NodeIds.healthSecondOwnerAnxietyHasOrWillConsultDoctorsProfessionOther,
            },
            {
              path: 'name',
              nodeId: NodeIds.healthSecondOwnerAnxietyHasOrWillConsultDoctorsName,
            },
            {
              path: 'address',
              nodeId: NodeIds.healthSecondOwnerAnxietyHasOrWillConsultDoctorsAddress,
            },
            {
              path: 'firstDate',
              nodeId: NodeIds.healthSecondOwnerAnxietyHasOrWillConsultDoctorsFirstDate,
            },
            {
              path: 'lastDate',
              nodeId: NodeIds.healthSecondOwnerAnxietyHasOrWillConsultDoctorsLastDate,
            },
            {
              path: 'result',
              nodeId: NodeIds.healthSecondOwnerAnxietyHasOrWillConsultDoctorsResult,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerAnxietyHasOrWillConsultDoctorsFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthSecondOwnerAnxietyHasOrWillConsultDoctorsFrequency,
            },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthSecondOwnerAnxietyHasOrWillConsultDoctorsFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthSecondOwnerAnxietyHasOrWillConsultDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'wentToEmergencyDepartmentDetails',
          nodeId: NodeIds.healthSecondOwnerAnxietyWentToEmergencyDepartmentDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthSecondOwnerAnxietyWentToEmergencyDepartmentDate,
            },
            {
              path: 'location',
              nodeId: NodeIds.healthSecondOwnerAnxietyWentToEmergencyDepartmentLocation,
            },
          ],
        },
        {
          path: 'wasHospitalizedDetails',
          nodeId: NodeIds.healthSecondOwnerAnxietyWasHospitalizedDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthSecondOwnerAnxietyWasHospitalizedDate,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerAnxietyWasHospitalizedFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthSecondOwnerAnxietyWasHospitalizedFrequency,
            },
            {
              path: 'location',
              nodeId: NodeIds.healthSecondOwnerAnxietyWasHospitalizedLocation,
            },
          ],
        },
        {
          path: 'suicidalThoughtsDetails',
          nodeId: NodeIds.healthSecondOwnerAnxietySuicidalThoughtsDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthSecondOwnerAnxietySuicidalThoughtsDate,
            },
          ],
        },
        {
          path: 'suicideAttemptsDetails',
          nodeId: NodeIds.healthSecondOwnerAnxietySuicideAttemptsDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthSecondOwnerAnxietySuicideAttemptsDate,
            },
          ],
        },
        {
          path: 'hadAbsenceDetails',
          nodeId: NodeIds.healthSecondOwnerAnxietyHadAbsenceDetails,
          isCollection: true,
          children: [
            {
              path: 'startDate',
              nodeId: NodeIds.healthSecondOwnerAnxietyAbsenceStartDate,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerAnxietyAbsenceFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthSecondOwnerAnxietyAbsenceFrequency,
            },
          ],
        },
      ],
    },
    {
      path: 'panicAttacks',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerPanicAttacksIsSingleEpisode },
        { path: 'diagnosticDate', nodeId: NodeIds.healthSecondOwnerPanicAttacksDiagnosticDate },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerPanicAttacksMedicationWasPrescribed },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerPanicAttacksHasConsultedDoctors },
        {
          path: 'hasConsultedOtherDoctorOrTherapist',
          nodeId: NodeIds.healthSecondOwnerPanicAttacksHasConsultedOtherDoctorsOrTherapists,
        },
        {
          path: 'went-to-emergency-department',
          nodeId: NodeIds.healthSecondOwnerPanicAttacksWentToEmergencyDepartment,
        },
        { path: 'was-hospitalized', nodeId: NodeIds.healthSecondOwnerPanicAttacksWasHospitalized },
        {
          path: 'suicidalThoughtsAndAttempt',
          children: [
            {
              path: 'hadSuicidalThoughtsAndAttempt',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksHadSuicidalThoughtsAndAttempt,
            },
            {
              path: 'choices',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksSuicidalThoughtsAndAttemptChoices,
            },
            {
              path: 'suicidalIdeation',
              children: [
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerPanicAttacksSuicidalThoughtsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerPanicAttacksSuicidalThoughtsFrequency,
                },
              ],
            },
            {
              path: 'attemptedSuicide',
              children: [
                {
                  path: 'attemptsNumber',
                  nodeId: NodeIds.healthSecondOwnerPanicAttacksSuicideAttemptsNumber,
                },
              ],
            },
          ],
        },
        { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerPanicAttacksHadAbsence },
        {
          path: 'absence',
          children: [
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerPanicAttacksAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerPanicAttacksAbsenceRecoveryDate },
                {
                  path: 'notRecoveredDetails',
                  nodeId: NodeIds.healthSecondOwnerPanicAttacksAbsenceNotRecoveredDetails,
                },
              ],
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthSecondOwnerPanicAttacksMedicationDetails,
          isCollection: true,
          children: [
            {
              path: 'name',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksMedicationName,
            },
            {
              path: 'dosage',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksMedicationDosage,
            },
            {
              path: 'startDate',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksMedicationStartDate,
            },
            {
              path: 'endDate',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksMedicationEndDate,
            },
            {
              path: 'stillUses',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksMedicationStillUses,
            },
            {
              path: 'hasChanged',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksMedicationHasChanged,
            },
            {
              path: 'hasChangedDetails',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksMedicationHasChangedDetails,
            },
          ],
        },
        {
          path: 'hasOrWillConsultDoctorsDetails',
          nodeId: NodeIds.healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsDetails,
          isCollection: true,
          children: [
            {
              path: 'profession',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsProfession,
            },
            {
              path: 'professionOther',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsProfessionOther,
            },
            {
              path: 'name',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsName,
            },
            {
              path: 'address',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsAddress,
            },
            {
              path: 'firstDate',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsFirstDate,
            },
            {
              path: 'lastDate',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsLastDate,
            },
            {
              path: 'result',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsResult,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsFrequency,
            },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksHasOrWillConsultDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'wentToEmergencyDepartmentDetails',
          nodeId: NodeIds.healthSecondOwnerPanicAttacksWentToEmergencyDepartmentDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksWentToEmergencyDepartmentDate,
            },
            {
              path: 'location',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksWentToEmergencyDepartmentLocation,
            },
          ],
        },
        {
          path: 'wasHospitalizedDetails',
          nodeId: NodeIds.healthSecondOwnerPanicAttacksWasHospitalizedDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksWasHospitalizedDate,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksWasHospitalizedFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksWasHospitalizedFrequency,
            },
            {
              path: 'location',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksWasHospitalizedLocation,
            },
          ],
        },
        {
          path: 'suicidalThoughtsDetails',
          nodeId: NodeIds.healthSecondOwnerPanicAttacksSuicidalThoughtsDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksSuicidalThoughtsDate,
            },
          ],
        },
        {
          path: 'suicideAttemptsDetails',
          nodeId: NodeIds.healthSecondOwnerPanicAttacksSuicideAttemptsDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksSuicideAttemptsDate,
            },
          ],
        },
        {
          path: 'hadAbsenceDetails',
          nodeId: NodeIds.healthSecondOwnerPanicAttacksHadAbsenceDetails,
          isCollection: true,
          children: [
            {
              path: 'startDate',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksAbsenceStartDate,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksAbsenceFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthSecondOwnerPanicAttacksAbsenceFrequency,
            },
          ],
        },
      ],
    },
    {
      path: 'burnout',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthSecondOwnerBurnoutIsSingleEpisode },
        { path: 'diagnosticDate', nodeId: NodeIds.healthSecondOwnerBurnoutDiagnosticDate },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthSecondOwnerBurnoutMedicationWasPrescribed },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthSecondOwnerBurnoutHasConsultedDoctors },
        {
          path: 'hasConsultedOtherDoctorOrTherapist',
          nodeId: NodeIds.healthSecondOwnerBurnoutHasConsultedOtherDoctorsOrTherapists,
        },
        { path: 'went-to-emergency-department', nodeId: NodeIds.healthSecondOwnerBurnoutWentToEmergencyDepartment },
        { path: 'was-hospitalized', nodeId: NodeIds.healthSecondOwnerBurnoutWasHospitalized },
        {
          path: 'suicidalThoughtsAndAttempt',
          children: [
            {
              path: 'hadSuicidalThoughtsAndAttempt',
              nodeId: NodeIds.healthSecondOwnerBurnoutHadSuicidalThoughtsAndAttempt,
            },
            {
              path: 'choices',
              nodeId: NodeIds.healthSecondOwnerBurnoutSuicidalThoughtsAndAttemptChoices,
            },
            {
              path: 'suicidalIdeation',
              children: [
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthSecondOwnerBurnoutSuicidalThoughtsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthSecondOwnerBurnoutSuicidalThoughtsFrequency,
                },
              ],
            },
            {
              path: 'attemptedSuicide',
              children: [
                {
                  path: 'attemptsNumber',
                  nodeId: NodeIds.healthSecondOwnerBurnoutSuicideAttemptsNumber,
                },
              ],
            },
          ],
        },
        { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerBurnoutHadAbsence },
        {
          path: 'absence',
          children: [
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthSecondOwnerBurnoutAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthSecondOwnerBurnoutAbsenceRecoveryDate },
                { path: 'notRecoveredDetails', nodeId: NodeIds.healthSecondOwnerBurnoutAbsenceNotRecoveredDetails },
              ],
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthSecondOwnerBurnoutMedicationDetails,
          isCollection: true,
          children: [
            {
              path: 'name',
              nodeId: NodeIds.healthSecondOwnerBurnoutMedicationName,
            },
            {
              path: 'dosage',
              nodeId: NodeIds.healthSecondOwnerBurnoutMedicationDosage,
            },
            {
              path: 'startDate',
              nodeId: NodeIds.healthSecondOwnerBurnoutMedicationStartDate,
            },
            {
              path: 'endDate',
              nodeId: NodeIds.healthSecondOwnerBurnoutMedicationEndDate,
            },
            {
              path: 'stillUses',
              nodeId: NodeIds.healthSecondOwnerBurnoutMedicationStillUses,
            },
            {
              path: 'hasChanged',
              nodeId: NodeIds.healthSecondOwnerBurnoutMedicationHasChanged,
            },
            {
              path: 'hasChangedDetails',
              nodeId: NodeIds.healthSecondOwnerBurnoutMedicationHasChangedDetails,
            },
          ],
        },
        {
          path: 'hasOrWillConsultDoctorsDetails',
          nodeId: NodeIds.healthSecondOwnerBurnoutHasOrWillConsultDoctorsDetails,
          isCollection: true,
          children: [
            {
              path: 'profession',
              nodeId: NodeIds.healthSecondOwnerBurnoutHasOrWillConsultDoctorsProfession,
            },
            {
              path: 'professionOther',
              nodeId: NodeIds.healthSecondOwnerBurnoutHasOrWillConsultDoctorsProfessionOther,
            },
            {
              path: 'name',
              nodeId: NodeIds.healthSecondOwnerBurnoutHasOrWillConsultDoctorsName,
            },
            {
              path: 'address',
              nodeId: NodeIds.healthSecondOwnerBurnoutHasOrWillConsultDoctorsAddress,
            },
            {
              path: 'firstDate',
              nodeId: NodeIds.healthSecondOwnerBurnoutHasOrWillConsultDoctorsFirstDate,
            },
            {
              path: 'lastDate',
              nodeId: NodeIds.healthSecondOwnerBurnoutHasOrWillConsultDoctorsLastDate,
            },
            {
              path: 'result',
              nodeId: NodeIds.healthSecondOwnerBurnoutHasOrWillConsultDoctorsResult,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerBurnoutHasOrWillConsultDoctorsFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthSecondOwnerBurnoutHasOrWillConsultDoctorsFrequency,
            },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthSecondOwnerBurnoutHasOrWillConsultDoctorsFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthSecondOwnerBurnoutHasOrWillConsultDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'wentToEmergencyDepartmentDetails',
          nodeId: NodeIds.healthSecondOwnerBurnoutWentToEmergencyDepartmentDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthSecondOwnerBurnoutWentToEmergencyDepartmentDate,
            },
            {
              path: 'location',
              nodeId: NodeIds.healthSecondOwnerBurnoutWentToEmergencyDepartmentLocation,
            },
          ],
        },
        {
          path: 'wasHospitalizedDetails',
          nodeId: NodeIds.healthSecondOwnerBurnoutWasHospitalizedDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthSecondOwnerBurnoutWasHospitalizedDate,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerBurnoutWasHospitalizedFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthSecondOwnerBurnoutWasHospitalizedFrequency,
            },
            {
              path: 'location',
              nodeId: NodeIds.healthSecondOwnerBurnoutWasHospitalizedLocation,
            },
          ],
        },
        {
          path: 'suicidalThoughtsDetails',
          nodeId: NodeIds.healthSecondOwnerBurnoutSuicidalThoughtsDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthSecondOwnerBurnoutSuicidalThoughtsDate,
            },
          ],
        },
        {
          path: 'suicideAttemptsDetails',
          nodeId: NodeIds.healthSecondOwnerBurnoutSuicideAttemptsDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthSecondOwnerBurnoutSuicideAttemptsDate,
            },
          ],
        },
        {
          path: 'hadAbsenceDetails',
          nodeId: NodeIds.healthSecondOwnerBurnoutHadAbsenceDetails,
          isCollection: true,
          children: [
            {
              path: 'startDate',
              nodeId: NodeIds.healthSecondOwnerBurnoutAbsenceStartDate,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthSecondOwnerBurnoutAbsenceFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthSecondOwnerBurnoutAbsenceFrequency,
            },
          ],
        },
      ],
    },
    {
      path: 'autoImmuneSystem',
      children: [
        { path: 'hasCondition', nodeId: NodeIds.healthSecondOwnerAutoImmuneSystemHasCondition },
        { path: 'autoImmuneSystemChoices', nodeId: NodeIds.healthSecondOwnerAutoImmuneSystemChoices },
        { path: 'aidsDetails', nodeId: NodeIds.healthSecondOwnerAidsDetails },
        { path: 'hivDetails', nodeId: NodeIds.healthSecondOwnerHivDetails },
        { path: 'lupusDetails', nodeId: NodeIds.healthSecondOwnerLupusDetails },
        { path: 'sclerodermaDetails', nodeId: NodeIds.healthSecondOwnerSclerodermaDetails },
        {
          path: 'otherAutoImmuneSystemDisorderDetails',
          nodeId: NodeIds.healthSecondOwnerOtherAutoImmuneSystemDisorderDetails,
        },
      ],
    },
    {
      path: 'cancerOrTumor',
      children: [
        {
          path: 'tumor',
          children: [
            { path: 'bodyPart', nodeId: NodeIds.healthSecondOwnerTumorBodyPart },
            { path: 'severity', nodeId: NodeIds.healthSecondOwnerTumorSeverity },
          ],
        },
        { path: 'leukemiaDetails', nodeId: NodeIds.healthSecondOwnerLeukemiaDetails },
        { path: 'cancerDetails', nodeId: NodeIds.healthSecondOwnerCancerDetails },
        { path: 'noduleDetails', nodeId: NodeIds.healthSecondOwnerNoduleDetails },
        { path: 'lymphNodeDisorderDetails', nodeId: NodeIds.healthSecondOwnerLymphNodeDisorderDetails },
        { path: 'cancerOtherDetails', nodeId: NodeIds.healthSecondOwnerCancerOtherDetails },
        { path: 'hasCondition', nodeId: NodeIds.healthSecondOwnerCancerHasCondition },
        { path: 'applicableConditions', nodeId: NodeIds.healthSecondOwnerCancerApplicableConditions },
        {
          path: 'breastDisorder',
          children: [
            { path: 'hasCondition', nodeId: NodeIds.healthSecondOwnerBreastDisorderHasCondition },
            { path: 'condition', nodeId: NodeIds.healthSecondOwnerBreastDisorderApplicableConditions },
            { path: 'lumpDetails', nodeId: NodeIds.healthSecondOwnerBreastDisorderLumpDetails },
            { path: 'bumpDetails', nodeId: NodeIds.healthSecondOwnerBreastDisorderBumpDetails },
            { path: 'otherDetails', nodeId: NodeIds.healthSecondOwnerBreastDisorderOtherDetails },
            { path: 'cystDetails', nodeId: NodeIds.healthSecondOwnerBreastCystDetails },
            { path: 'disorderCystDetails', nodeId: NodeIds.healthSecondOwnerBreastDisorderCystDetails },
          ],
        },
      ],
    },
    {
      path: 'eyeEarNoseOrThroatDisorder',
      children: [
        { path: 'partialOrTotalBlindnessDetails', nodeId: NodeIds.healthSecondOwnerPartialOrTotalBlindnessDetails },
        { path: 'macularDegenerationDetails', nodeId: NodeIds.healthSecondOwnerMacularDegenerationDetails },
        { path: 'glaucomaDetails', nodeId: NodeIds.healthSecondOwnerGlaucomaDetails },
        { path: 'partialOrTotalDeafnessDetails', nodeId: NodeIds.healthSecondOwnerPartialOrTotalDeafnessDetails },
        { path: 'tinnitusDetails', nodeId: NodeIds.healthSecondOwnerTinnitusDetails },
        { path: 'meniereDiseaseDetails', nodeId: NodeIds.healthSecondOwnerMeniereDiseaseDetails },
        { path: 'labyrinthitisDetails', nodeId: NodeIds.healthSecondOwnerLabyrinthitisDetails },
        {
          path: 'otherEyeEarNoseOrThroatDisorderDetails',
          nodeId: NodeIds.healthSecondOwnerOtherEyeEarNoseOrThroatDisorderDetails,
        },
        { path: 'hasCondition', nodeId: NodeIds.healthSecondOwnerEyeEarNoseThroatDisorderHasCondition },
        { path: 'applicableConditions', nodeId: NodeIds.healthSecondOwnerEyeEarNoseThroatDisorderApplicableConditions },
        { path: 'otherDisorderDetails', nodeId: NodeIds.healthSecondOwnerEyeEarNoseThroatOtherDisorderDetails },
      ],
    },
    {
      path: 'physiciansTestsAndMedicalConsultations',
      children: [
        { path: 'last5YearsTestsDetails', nodeId: NodeIds.healthSecondOwnerLast5YearsTestsDetails },
        { path: 'last5YearsTestsAnyOtherDetails', nodeId: NodeIds.healthSecondOwnerLast5YearsTestsAnyOtherDetails },

        { path: 'last5YearsHospitalizedDetails', nodeId: NodeIds.healthSecondOwnerLast5YearsHospitalizedDetails },
        {
          path: 'otherProfessionals',
          children: [
            { path: 'details', nodeId: NodeIds.healthSecondOwnerOtherProfessionalsDetails },
            { path: 'professionals', nodeId: NodeIds.healthSecondOwnerOtherProfessionals },
          ],
        },
        {
          path: 'moreMedicalAction',
          children: [
            { path: 'pending', nodeId: NodeIds.healthSecondOwnerMoreMedicalActionPending },
            { path: 'choices', nodeId: NodeIds.healthSecondOwnerMoreMedicalActionChoices },
            { path: 'treatmentsDetails', nodeId: NodeIds.healthSecondOwnerMoreMedicalActionTreatmentsDetails },
            { path: 'surgeryDetails', nodeId: NodeIds.healthSecondOwnerMoreMedicalActionSurgeryDetails },
            {
              path: 'awaitingResultsDetails',
              nodeId: NodeIds.healthSecondOwnerMoreMedicalActionAwaitingResultsDetails,
            },
          ],
        },
        { path: 'diagnosticExamsOrTestsDetails', nodeId: NodeIds.healthSecondOwnerDiagnosticExamsOrTestsDetails },
      ],
    },
    {
      path: 'familyHistory',
      children: [
        { path: 'hasFamilyHistory2', nodeId: NodeIds.healthSecondOwnerHasFamilyHistory2 },
        { path: 'familyMedicalHistory2', nodeId: NodeIds.healthSecondOwnerFamilyMedicalHistory2 },
        { path: 'hasFamilyMedicalHistory', nodeId: NodeIds.healthSecondOwnerHasFamilyMedicalHistory },
        { path: 'familyMedicalHistoryDetails', nodeId: NodeIds.healthSecondOwnerHasFamilyMedicalHistoryDetails },
      ],
    },
    {
      path: 'familyDoctorOrClinic',
      children: [
        { path: 'hasFamilyDoctorOrClinic', nodeId: NodeIds.healthSecondOwnerHasFamilyDoctorOrClinic },
        { path: 'name', nodeId: NodeIds.healthSecondOwnerFamilyDoctorOrClinicName },
        {
          path: 'address',
          children: [
            { path: 'street', nodeId: NodeIds.healthSecondOwnerFamilyDoctorOrClinicAddressStreet },
            { path: 'apartment', nodeId: NodeIds.healthSecondOwnerFamilyDoctorOrClinicAddressApartment },
            { path: 'city', nodeId: NodeIds.healthSecondOwnerFamilyDoctorOrClinicAddressCity },
            { path: 'country', nodeId: NodeIds.healthSecondOwnerFamilyDoctorOrClinicAddressCountry },
            { path: 'province', nodeId: NodeIds.healthSecondOwnerFamilyDoctorOrClinicAddressProvince },
            { path: 'postalCode', nodeId: NodeIds.healthSecondOwnerFamilyDoctorOrClinicAddressPostalCode },
          ],
        },
      ],
    },
    {
      path: 'ovarian',
      children: [{ path: 'cystDetails', nodeId: NodeIds.healthSecondOwnerOvarianCystDetails }],
    },
    {
      path: 'consultationTreatmentForBloodOrSkinDisease',
      children: [
        {
          path: 'hadConsultationTreatmentForBloodOrSkinDisease',
          nodeId: NodeIds.healthSecondOwnerHadConsultationTreatmentForBloodOrSkinDisease,
        },
        {
          path: 'hadConsultationTreatmentForBloodOrSkinDiseaseDetails',
          nodeId: NodeIds.healthsecondOwnerHadConsultationTreatmentForBloodOrSkinDiseaseDetails,
        },
        {
          path: 'hadConsultationTreatmentForBloodDiseaseDetails',
          nodeId: NodeIds.healthSecondOwnerHadConsultationTreatmentForBloodDiseaseDetails,
        },
        {
          path: 'hadConsultationTreatmentForSkinDiseaseDetails',
          nodeId: NodeIds.healthSecondOwnerHadConsultationTreatmentForSkinDiseaseDetails,
        },
      ],
    },
    {
      path: 'consultationTreatmentForOtherPhysicalOrMentalDisorder',
      children: [
        {
          path: 'hadConsultationTreatmentForOtherPhysicalOrMentalDisorder',
          nodeId: NodeIds.healthSecondOwnerHadConsultationTreatmentForOtherPhysicalOrMentalDisorder,
        },
        {
          path: 'hadConsultationTreatmentForOtherPhysicalOrMentalDisorderDetails',
          nodeId: NodeIds.healthSecondOwnerHadConsultationTreatmentForOtherPhysicalOrMentalDisorderDetails,
        },
        {
          path: 'hadConsultationTreatmentForOtherPhysicalDisorderDetails',
          nodeId: NodeIds.healthSecondOwnerHadConsultationTreatmentForOtherPhysicalDisorderDetails,
        },
        {
          path: 'hadConsultationTreatmentForOtherMentalDisorderDetails',
          nodeId: NodeIds.healthSecondOwnerHadConsultationTreatmentForOtherMentalDisorderDetails,
        },
      ],
    },
    {
      path: 'pregnancy',
      children: [
        { path: 'isPregnant', nodeId: NodeIds.healthSecondOwnerIsPregnant },
        { path: 'dueDate', nodeId: NodeIds.healthSecondOwnerPregnancyDueDate },
        {
          path: 'hadPregnancyOrChildbirthComplications',
          nodeId: NodeIds.healthSecondOwnerHadPregnancyOrChildbirthComplications,
        },
        {
          path: 'hadPregnancyOrChildbirthComplicationsDetails',
          nodeId: NodeIds.healthSecondOwnerHadPregnancyOrChildbirthComplicationsDetails,
        },
      ],
    },
    {
      path: 'medicalTests',
      children: [
        { path: 'hadOtherMedicalTestsLast5Years', nodeId: NodeIds.healthSecondOwnerHadOtherMedicalTestsLast5Years },
        {
          path: 'hadOtherMedicalTestsLast5YearsTests',
          nodeId: NodeIds.healthSecondOwnerHadOtherMedicalTestsLast5YearsTests,
        },
        {
          path: 'hadOtherMedicalTestsLast5YearsDetails',
          children: [
            { path: 'blood', nodeId: NodeIds.healthSecondOwnerHadOtherMedicalTestsLast5YearsBloodTestDetails },
            {
              path: 'restingElectrocardiogram',
              nodeId: NodeIds.healthSecondOwnerHadOtherMedicalTestsLast5YearsRestingElectrocardiogramDetails,
            },
            {
              path: 'stressElectrocardiogram',
              nodeId: NodeIds.healthSecondOwnerHadOtherMedicalTestsLast5YearsStressElectrocardiogramDetails,
            },
            {
              path: 'echocardiogram',
              nodeId: NodeIds.healthSecondOwnerHadOtherMedicalTestsLast5YearsEchocardiogramDetails,
            },
            { path: 'colonoscopy', nodeId: NodeIds.healthSecondOwnerHadOtherMedicalTestsLast5YearsColonoscopyDetails },
            { path: 'xRay', nodeId: NodeIds.healthSecondOwnerHadOtherMedicalTestsLast5YearsXRayDetails },
            { path: 'mammography', nodeId: NodeIds.healthSecondOwnerHadOtherMedicalTestsLast5YearsMammographyDetails },
            { path: 'ultrasound', nodeId: NodeIds.healthSecondOwnerHadOtherMedicalTestsLast5YearsUltrasoundDetails },
            { path: 'ctScan', nodeId: NodeIds.healthSecondOwnerHadOtherMedicalTestsLast5YearsCtScanDetails },
            { path: 'mri', nodeId: NodeIds.healthSecondOwnerHadOtherMedicalTestsLast5YearsMriDetails },
          ],
        },
      ],
    },
    {
      path: 'abnormalProblems',
      children: [
        {
          path: 'hasConsultedForAbnormalProblemsLast5Years',
          nodeId: NodeIds.healthSecondOwnerHasConsultedForAbnormalProblemsLast5Years,
        },
        { path: 'last5YearsDetails', nodeId: NodeIds.healthSecondOwnerAbnormalProblemsLast5YearsDetails },
      ],
    },
    {
      path: 'hospitalized',
      children: [
        { path: 'wasHospitalizedInLast5Years', nodeId: NodeIds.healthSecondOwnerWasHospitalizedInLast5Years },
        { path: 'inLast5YearsDetails', nodeId: NodeIds.healthSecondOwnerHospitalizedInLast5YearsDetails },
      ],
    },
    {
      path: 'medicalTestsAbnormalTestPhysician',
      children: [
        {
          path: 'hasNotYetConsulted',
          nodeId: NodeIds.healthSecondOwnerMedicalTestsAbnormalTestPhysicianHasNotYetConsulted,
        },
        {
          path: 'hasNotYetConsultedDetails',
          nodeId: NodeIds.healthSecondOwnerMedicalTestsAbnormalTestPhysicianHasNotYetConsultedDetails,
        },
      ],
    },
    {
      path: 'disability',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthSecondOwnerDisabilityHadAbsence },
        { path: 'absenceDetails', nodeId: NodeIds.healthSecondOwnerDisabilityAbsenceDetails },
      ],
    },
    {
      path: 'medication',
      children: [
        { path: 'hasOrHadMedication', nodeId: NodeIds.healthSecondOwnerHasOrHadMedication },
        { path: 'hasOrHadMedicationDetails', nodeId: NodeIds.healthSecondOwnerHasOrHadMedicationDetails },
      ],
    },
    {
      path: 'secondOwnerDetails',
      children: [
        { path: 'height', nodeId: NodeIds.healthSecondOwnerHeight },
        { path: 'weight', nodeId: NodeIds.healthSecondOwnerWeight },
        { path: 'hadUnexplainedWeightLoss', nodeId: NodeIds.healthSecondOwnerHadUnexplainedWeightLoss },
        { path: 'weightLossIsIntentional', nodeId: NodeIds.healthSecondOwnerWeightLossIsIntentional },
        { path: 'weightLoss', nodeId: NodeIds.healthSecondOwnerWeightLoss },
        { path: 'unexplainedWeightLossDetails', nodeId: NodeIds.healthSecondOwnerUnexplainedWeightLossDetails },
      ],
    },
    {
      path: 'phoneInterview',
      children: [
        { path: 'isPrescreening', nodeId: NodeIds.healthSecondOwnerPhoneInterviewIsPrescreening },
        { path: 'day', nodeId: NodeIds.healthSecondOwnerPhoneInterviewDay },
        { path: 'time', nodeId: NodeIds.healthSecondOwnerPhoneInterviewTime },
        { path: 'number', nodeId: NodeIds.healthSecondOwnerPhoneInterviewNumber },
        { path: 'day2', nodeId: NodeIds.healthSecondOwnerPhoneInterviewDay2 },
        { path: 'time2', nodeId: NodeIds.healthSecondOwnerPhoneInterviewTime2 },
        { path: 'number2', nodeId: NodeIds.healthSecondOwnerPhoneInterviewNumber2 },
      ],
    },
  ],
};

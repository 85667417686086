import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const breastDisorder: AnswerPathTreeNode = {
  path: 'breastDisorder',
  children: [
    { path: 'hasCondition', nodeId: NodeIds.healthInsuredBreastDisorderHasCondition },
    { path: 'applicableConditions', nodeId: NodeIds.healthInsuredBreastDisorderApplicableConditions },
    { path: 'lumpDetails', nodeId: NodeIds.healthInsuredBreastDisorderLumpDetails },
    { path: 'bumpDetails', nodeId: NodeIds.healthInsuredBreastDisorderBumpDetails },
    { path: 'cystDetails', nodeId: NodeIds.healthInsuredBreastDisorderCystDetails },
    { path: 'otherDetails', nodeId: NodeIds.healthInsuredBreastDisorderOtherDetails },
  ],
};

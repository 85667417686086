import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const ligamentTearMeniscus: AnswerPathTreeNode = {
  path: 'ligamentTearMeniscus',
  children: [
    { path: 'type', nodeId: NodeIds.healthInsuredLigamentTearMeniscusType },
    { path: 'diagnosisDate', nodeId: NodeIds.healthInsuredLigamentTearMeniscusDiagnosisDate },
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredLigamentTearMeniscusIsSingleEpisode },
    {
      path: 'previousEpisodesCount',
      nodeId: NodeIds.healthInsuredLigamentTearMeniscusPreviousEpisodesCount,
    },
    {
      path: 'dateSymptoms',
      nodeId: NodeIds.healthInsuredLigamentTearMeniscusDateSymptoms,
    },
    {
      path: 'painLocation',
      children: [
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusPainLocation,
        },
        {
          path: 'leftRight',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusPainLocationLeftRight,
        },
        {
          path: 'other',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusPainLocationOther,
        },
      ],
    },
    {
      path: 'painCause',
      children: [
        {
          path: 'cause',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusPainCause,
        },
        {
          path: 'details',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusPainCauseDetails,
        },
      ],
    },
    {
      path: 'hasConsultedDoctors',
      nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasConsultedDoctors,
    },
    {
      path: 'hasTakenTestsOrExams',
      nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasTakenTestsOrExams,
    },
    {
      path: 'medicationWasPrescribed',
      nodeId: NodeIds.healthInsuredLigamentTearMeniscusMedicationWasPrescribed,
    },
    {
      path: 'operation',
      children: [
        {
          path: 'hadOperation',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusHadOperation,
        },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusTypeOfOperationPerformed,
        },
        {
          path: 'performedDate',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusOperationPerformedDate,
        },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusTypeOfOperationPlanned,
        },
        {
          path: 'plannedDate',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusOperationPlannedDate,
        },
        {
          path: 'unknownDate',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusOperationUnknownDate,
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredLigamentTearMeniscusHadAbsence },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusAbsenceStartDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusAbsenceFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusAbsenceFrequency,
        },
        {
          path: 'recovery',
          children: [
            {
              path: 'hasRecovered',
              nodeId: NodeIds.healthInsuredLigamentTearMeniscusAbsenceHasRecovered,
            },
            {
              path: 'recoveryDate',
              nodeId: NodeIds.healthInsuredLigamentTearMeniscusAbsenceRecoveryDate,
            },
            {
              path: 'notRecoveredDetails',
              nodeId: NodeIds.healthInsuredLigamentTearMeniscusAbsenceNotRecoveredDetails,
            },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasConsultedDoctorsProfession },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasConsultedDoctorsProfessionDetails,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasConsultedDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasConsultedDoctorsResult },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasConsultedDoctorsFrequencyNumber,
        },
        { path: 'frequency', nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasConsultedDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasTakenTestsOrExamsResult },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredLigamentTearMeniscusHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredLigamentTearMeniscusMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredLigamentTearMeniscusMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredLigamentTearMeniscusMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredLigamentTearMeniscusMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredLigamentTearMeniscusMedicationEndDate },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const spouse: AnswerPathTreeNode = {
  path: 'spouse',
  children: [
    { path: 'exists', nodeId: NodeIds.insuredSpouseExists },
    { path: 'dateOfBirth', nodeId: NodeIds.insuredSpouseDateOfBirth },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const mainBeneficiaries: AnswerPathTreeNode = {
  path: 'mainBeneficiaries',
  children: [
    { path: 'exist', nodeId: NodeIds.insuredMainBeneficiariesExist },
    { path: 'recipients', nodeId: NodeIds.insuredMainBeneficiariesRecipients },
    { path: 'add', nodeId: NodeIds.insuredAddMainBeneficiary },
    {
      path: 'beneficiaries',
      nodeId: NodeIds.insuredMainBeneficiaries,
      isCollection: true,
      children: [
        { path: 'surrogateId', nodeId: NodeIds.insuredMainBeneficiariesSurrogateId },
        { path: 'share', nodeId: NodeIds.insuredMainBeneficiariesShare },
        {
          path: 'shareDetails',
          children: [
            { path: 'criticalIllnessInsurance', nodeId: NodeIds.insuredMainBeneficiariesShareCriticalIllnessInsurance },
            { path: 'criticalIllnessRider', nodeId: NodeIds.insuredMainBeneficiariesShareCriticalIllnessRider },
            { path: 'returnOfPremiumAtDeath', nodeId: NodeIds.insuredMainBeneficiariesShareReturnOfPremiumAtDeath },
            { path: 'returnOfPremiumAtExpiry', nodeId: NodeIds.insuredMainBeneficiariesShareReturnOfPremiumAtExpiry },
            {
              path: 'returnOfPremiumAtCancellation',
              nodeId: NodeIds.insuredMainBeneficiariesShareReturnOfPremiumAtCancellation,
            },
          ],
        },
        { path: 'firstName', nodeId: NodeIds.insuredMainBeneficiariesFirstName },
        { path: 'lastName', nodeId: NodeIds.insuredMainBeneficiariesLastName },
        { path: 'birthDate', nodeId: NodeIds.insuredMainBeneficiariesBirthDate },
        { path: 'relation', nodeId: NodeIds.insuredMainBeneficiariesRelation },
        { path: 'relationOther', nodeId: NodeIds.insuredMainBeneficiariesRelationOther },
        { path: 'revocable', nodeId: NodeIds.insuredMainBeneficiariesRevocable },
        { path: 'addressAutocomplete', nodeId: NodeIds.insuredMainBeneficiariesAddressAutocomplete },
        { path: 'address', nodeId: NodeIds.insuredMainBeneficiariesAddress },
        { path: 'address2', nodeId: 'insured-main-beneficiaries-address-2' },
        { path: 'city', nodeId: NodeIds.insuredMainBeneficiariesCity },
        { path: 'provinceOrState', nodeId: NodeIds.insuredMainBeneficiariesProvinceOrState },
        { path: 'postalCode', nodeId: NodeIds.insuredMainBeneficiariesPostalCode },
        { path: 'email', nodeId: NodeIds.insuredMainBeneficiariesEmail },
        { path: 'phone', nodeId: NodeIds.insuredMainBeneficiariesPhone },
        { path: 'type', nodeId: NodeIds.insuredMainBeneficiariesType },
        { path: 'middleName', nodeId: NodeIds.insuredMainBeneficiariesMiddleName },
        { path: 'gender', nodeId: NodeIds.insuredMainBeneficiariesGender },
        { path: 'phoneType', nodeId: NodeIds.insuredMainBeneficiariesPhoneType },
        { path: 'alternatePhone', nodeId: NodeIds.insuredMainBeneficiariesAlternatePhone },
        { path: 'alternatePhoneType', nodeId: NodeIds.insuredMainBeneficiariesAlternatePhoneType },
        { path: 'ssn', nodeId: NodeIds.insuredMainBeneficiariesSSN },
        { path: 'tin', nodeId: NodeIds.insuredMainBeneficiariesTIN },
        { path: 'trustName', nodeId: NodeIds.insuredMainBeneficiariesTrustName },
        { path: 'trustDate', nodeId: NodeIds.insuredMainBeneficiariesTrustDate },
        { path: 'entityName', nodeId: NodeIds.insuredMainBeneficiariesEntityName },
        { path: 'trustee', nodeId: NodeIds.insuredMainBeneficiariesTrustee },
        { path: 'officer', nodeId: NodeIds.insuredMainBeneficiariesOfficer },
      ],
    },
    { path: 'revocableWarning', nodeId: NodeIds.insuredMainBeneficiariesRevocableWarning },
  ],
};

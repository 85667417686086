import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const fibromyalgia: AnswerPathTreeNode = {
  path: 'fibromyalgia',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredFibromyalgiaIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredFibromyalgiaPreviousEpisodesCount },
    { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredFibromyalgiaDateSymptoms },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredFibromyalgiaHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredFibromyalgiaHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredFibromyalgiaMedicationWasPrescribed },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredFibromyalgiaPainLocation },
        { path: 'leftRight', nodeId: NodeIds.healthInsuredFibromyalgiaPainLocationLeftRight },
        { path: 'other', nodeId: NodeIds.healthInsuredFibromyalgiaPainLocationOther },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredFibromyalgiaPainCause },
        { path: 'details', nodeId: NodeIds.healthInsuredFibromyalgiaPainCauseDetails },
      ],
    },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredFibromyalgiaHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredFibromyalgiaOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredFibromyalgiaTypeOfOperationPerformed,
        },
        { path: 'performedDate', nodeId: NodeIds.healthInsuredFibromyalgiaOperationPerformedDate },
        { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthInsuredFibromyalgiaTypeOfOperationPlanned },
        { path: 'plannedDate', nodeId: NodeIds.healthInsuredFibromyalgiaOperationPlannedDate },
        { path: 'unknownDate', nodeId: NodeIds.healthInsuredFibromyalgiaOperationUnknownDate },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredFibromyalgiaHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredFibromyalgiaAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredFibromyalgiaAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredFibromyalgiaAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredFibromyalgiaAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredFibromyalgiaAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredFibromyalgiaAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredFibromyalgiaHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredFibromyalgiaHasConsultedDoctorsProfession,
        },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredFibromyalgiaHasConsultedDoctorsProfessionDetails,
        },
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredFibromyalgiaHasConsultedDoctorsName,
        },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredFibromyalgiaHasConsultedDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredFibromyalgiaHasConsultedDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredFibromyalgiaHasConsultedDoctorsLastDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredFibromyalgiaHasConsultedDoctorsResult,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredFibromyalgiaHasConsultedDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredFibromyalgiaHasConsultedDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredFibromyalgiaHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredFibromyalgiaHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredFibromyalgiaHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        {
          path: 'type',
          nodeId: NodeIds.healthInsuredFibromyalgiaHasTakenTestsOrExamsType,
        },
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredFibromyalgiaHasTakenTestsOrExamsDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredFibromyalgiaHasTakenTestsOrExamsResult,
        },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredFibromyalgiaHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredFibromyalgiaMedicationDetails,
      isCollection: true,
      children: [
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredFibromyalgiaMedicationName,
        },
        {
          path: 'dosage',
          nodeId: NodeIds.healthInsuredFibromyalgiaMedicationDosage,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredFibromyalgiaMedicationStartDate,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredFibromyalgiaMedicationEndDate,
        },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const military: AnswerPathTreeNode = {
  path: 'military',
  children: [
    { path: 'activeMilitary', nodeId: NodeIds.activeMilitary },
    { path: 'militaryMemberNationalGuard', nodeId: NodeIds.militaryMemberNationalGuard },
    { path: 'militaryCapacity', nodeId: NodeIds.militaryCapacity },
    { path: 'militaryCapacityOther', nodeId: NodeIds.militaryCapacityOther },
    { path: 'militaryCareer', nodeId: NodeIds.militaryCareer },
    { path: 'militaryMemberReserves', nodeId: NodeIds.militaryMemberReserves },
    { path: 'militaryReservesOther', nodeId: NodeIds.militaryReservesOther },
    { path: 'militaryRank', nodeId: NodeIds.militaryRank },
    { path: 'militaryPayGrade', nodeId: NodeIds.militaryPayGrade },
    { path: 'militaryUnitDesignation', nodeId: NodeIds.militaryUnitDesignation },
    { path: 'militaryOccupationalSpecialty', nodeId: NodeIds.militaryOccupationalSpecialty },
    { path: 'militaryMosDetails', nodeId: NodeIds.militaryMosDetails },
    { path: 'militaryMosAviation', nodeId: NodeIds.militaryMosAviation },
    { path: 'militaryOverseasDuty', nodeId: NodeIds.militaryOverseasDuty },
    { path: 'militaryOverseasDutyDetails', nodeId: NodeIds.militaryOverseasDutyDetails },
    { path: 'militaryPcsDetails', nodeId: NodeIds.militaryPcsDetails },
    { path: 'militaryPcsDate', nodeId: NodeIds.militaryPcsDate },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const burnout: AnswerPathTreeNode = {
  path: 'burnout',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredBurnoutIsSingleEpisode },
    { path: 'diagnosticDate', nodeId: NodeIds.healthInsuredBurnoutDiagnosticDate },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredBurnoutMedicationWasPrescribed },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredBurnoutHasConsultedDoctors },
    {
      path: 'hasOrWillConsultOtherDoctorsOrTherapists',
      nodeId: NodeIds.healthInsuredBurnoutHasOrWillConsultOtherDoctorsOrTherapists,
    },
    { path: 'wentToEmergencyDepartment', nodeId: NodeIds.healthInsuredBurnoutWentToEmergencyDepartment },
    { path: 'wasHospitalized', nodeId: NodeIds.healthInsuredBurnoutWasHospitalized },
    {
      path: 'suicidalThoughtsAndAttempt',
      children: [
        {
          path: 'hadSuicidalThoughtsAndAttempt',
          nodeId: NodeIds.healthInsuredBurnoutHadSuicidalThoughtsAndAttempt,
        },
        {
          path: 'choices',
          nodeId: NodeIds.healthInsuredBurnoutSuicidalThoughtsAndAttemptChoices,
        },
        {
          path: 'suicidalIdeation',
          children: [
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthInsuredBurnoutSuicidalThoughtsFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthInsuredBurnoutSuicidalThoughtsFrequency,
            },
          ],
        },
        {
          path: 'attemptedSuicide',
          children: [
            {
              path: 'attemptsNumber',
              nodeId: NodeIds.healthInsuredBurnoutSuicideAttemptsNumber,
            },
          ],
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredBurnoutHadAbsence },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredBurnoutAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredBurnoutAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredBurnoutAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredBurnoutMedicationDetails,
      isCollection: true,
      children: [
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredBurnoutMedicationName,
        },
        {
          path: 'dosage',
          nodeId: NodeIds.healthInsuredBurnoutMedicationDosage,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredBurnoutMedicationStartDate,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredBurnoutMedicationEndDate,
        },
        {
          path: 'stillUses',
          nodeId: NodeIds.healthInsuredBurnoutMedicationStillUses,
        },
      ],
    },
    {
      path: 'hasOrWillConsultDoctorsDetails',
      nodeId: NodeIds.healthInsuredBurnoutHasOrWillConsultDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredBurnoutHasOrWillConsultDoctorsProfession,
        },
        {
          path: 'professionOther',
          nodeId: NodeIds.healthInsuredBurnoutHasOrWillConsultDoctorsProfessionOther,
        },
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredBurnoutHasOrWillConsultDoctorsName,
        },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredBurnoutHasOrWillConsultDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredBurnoutHasOrWillConsultDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredBurnoutHasOrWillConsultDoctorsLastDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredBurnoutHasOrWillConsultDoctorsResult,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredBurnoutHasOrWillConsultDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredBurnoutHasOrWillConsultDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredBurnoutHasOrWillConsultDoctorsFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredBurnoutHasOrWillConsultDoctorsNextConsultationDate,
        },
        {
          path: 'nextConsultationDateUnknown',
          nodeId: NodeIds.healthInsuredBurnoutHasOrWillConsultDoctorsNextConsultationDateUnknown,
        },
      ],
    },
    {
      path: 'wentToEmergencyDepartmentDetails',
      nodeId: NodeIds.healthInsuredBurnoutWentToEmergencyDepartmentDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredBurnoutWentToEmergencyDepartmentDate,
        },
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredBurnoutWentToEmergencyDepartmentLocation,
        },
      ],
    },
    {
      path: 'wasHospitalizedDetails',
      nodeId: NodeIds.healthInsuredBurnoutWasHospitalizedDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredBurnoutWasHospitalizedDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredBurnoutWasHospitalizedFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredBurnoutWasHospitalizedFrequency,
        },
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredBurnoutWasHospitalizedLocation,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredBurnoutWasHospitalizedEndDate,
        },
      ],
    },
    {
      path: 'suicidalThoughtsDetails',
      nodeId: NodeIds.healthInsuredBurnoutSuicidalThoughtsDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredBurnoutSuicidalThoughtsDate,
        },
      ],
    },
    {
      path: 'suicideAttemptsDetails',
      nodeId: NodeIds.healthInsuredBurnoutSuicideAttemptsDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredBurnoutSuicideAttemptsDate,
        },
      ],
    },
    {
      path: 'hadAbsenceDetails',
      nodeId: NodeIds.healthInsuredBurnoutHadAbsenceDetails,
      isCollection: true,
      children: [
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredBurnoutAbsenceStartDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredBurnoutAbsenceFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredBurnoutAbsenceFrequency,
        },
      ],
    },
  ],
};

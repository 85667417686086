import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const spondylitisAnkylosing: AnswerPathTreeNode = {
  path: 'spondylitisAnkylosing',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredSpondylitisAnkylosingIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredSpondylitisAnkylosingPreviousEpisodesCount },
    {
      path: 'painLocation',
      children: [
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingPainLocation,
        },
        {
          path: 'dateSymptoms',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingDateSymptoms,
        },
      ],
    },
    {
      path: 'painCause',
      children: [
        {
          path: 'cause',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingPainCause,
        },
        {
          path: 'details',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingPainCauseDetails,
        },
      ],
    },
    {
      path: 'hasConsultedDoctors',
      nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasConsultedDoctors,
    },
    {
      path: 'hasTakenTestsOrExams',
      nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasTakenTestsOrExams,
    },
    {
      path: 'medicationWasPrescribed',
      nodeId: NodeIds.healthInsuredSpondylitisAnkylosingMedicationWasPrescribed,
    },
    {
      path: 'operation',
      children: [
        {
          path: 'hadOperation',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHadOperation,
        },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingTypeOfOperationPerformed,
        },
        {
          path: 'performedDate',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingOperationPerformedDate,
        },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingTypeOfOperationPlanned,
        },
        {
          path: 'plannedDate',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingOperationPlannedDate,
        },
        {
          path: 'unknownDate',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingOperationUnknownDate,
        },
      ],
    },
    {
      path: 'absence',
      children: [
        {
          path: 'hadAbsence',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHadAbsence,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingAbsenceStartDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingAbsenceFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingAbsenceFrequency,
        },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredSpondylitisAnkylosingAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredSpondylitisAnkylosingAbsenceRecoveryDate },
            {
              path: 'notRecoveredDetails',
              nodeId: NodeIds.healthInsuredSpondylitisAnkylosingAbsenceNotRecoveredDetails,
            },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasConsultedDoctorsProfession,
        },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasConsultedDoctorsProfessionDetails,
        },
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasConsultedDoctorsName,
        },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasConsultedDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasConsultedDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasConsultedDoctorsLastDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasConsultedDoctorsResult,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasConsultedDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasConsultedDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        {
          path: 'type',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasTakenTestsOrExamsType,
        },
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasTakenTestsOrExamsDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasTakenTestsOrExamsResult,
        },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredSpondylitisAnkylosingMedicationDetails,
      isCollection: true,
      children: [
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingMedicationName,
        },
        {
          path: 'dosage',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingMedicationDosage,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingMedicationStartDate,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredSpondylitisAnkylosingMedicationEndDate,
        },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const intellectualImpairment: AnswerPathTreeNode = {
  path: 'intellectualImpairment',
  children: [
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredIntellectualImpairmentMedicationWasPrescribed },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredIntellectualImpairmentHasConsultedDoctors },
    {
      path: 'otherDoctorOrTherapist',
      nodeId: NodeIds.healthInsuredIntellectualImpairmentHasOrWillConsultOtherDoctorsOrTherapists,
    },
    {
      path: 'performsDailyActivitiesWithoutHelp',
      nodeId: NodeIds.healthInsuredIntellectualImpairmentPerfromsDailyActivitiesWithoutHelp,
    },
    {
      path: 'performsDailyActivitiesWithoutHelpDetails',
      nodeId: NodeIds.healthInsuredIntellectualImpairmentPerfromsDailyActivitiesWithoutHelpDetails,
    },
    { path: 'accommodation', nodeId: NodeIds.healthInsuredIntellectualImpairmentNeedsAccommodation },
    { path: 'accommodationDetails', nodeId: NodeIds.healthInsuredIntellectualImpairmentAccommodationDetails },
    {
      path: 'hasOrWillConsultDoctorsDetails',
      nodeId: NodeIds.healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsProfession,
        },
        {
          path: 'professionOther',
          nodeId: NodeIds.healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsProfessionOther,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsAddress },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsFirstDate,
        },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsResult },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredIntellectualImpairmentHasOrWillConsultDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredIntellectualImpairmentMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredIntellectualImpairmentMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredIntellectualImpairmentMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredIntellectualImpairmentMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredIntellectualImpairmentMedicationEndDate },
        { path: 'stillUses', nodeId: NodeIds.healthInsuredIntellectualImpairmentMedicationStillUses },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const occupation: AnswerPathTreeNode = {
  path: 'occupation',
  nodeId: NodeIds.insuredOccupation,
};

export const occupationOther: AnswerPathTreeNode = {
  path: 'occupationOther',
  nodeId: NodeIds.insuredOccupationOther,
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';
import { glaucoma } from './glaucoma';
import { labyrinthitis } from './labyrinthitis';
import { macularDegeneration } from './macularDegeneration';
import { meniereDisease } from './meniereDisease';
import { partialOrTotalBlindness } from './partialOrTotalBlindness';
import { partialOrTotalDeafness } from './partialOrTotalDeafness';
import { tinnitus } from './tinnitus';

export const earEyeNoseThroatDisorders: AnswerPathTreeNode = {
  path: 'earEyeNoseThroatDisorders',
  children: [
    { path: 'hasCondition', nodeId: NodeIds.healthInsuredEyeEarNoseThroatDisorderHasCondition },
    { path: 'choices', nodeId: NodeIds.healthInsuredEyeEarNoseThroatDisorderChoices },
    { path: 'otherDisorderDetails', nodeId: NodeIds.healthInsuredEyeEarNoseThroatOtherDisorderDetails },
    partialOrTotalDeafness,
    labyrinthitis,
    meniereDisease,
    tinnitus,
    glaucoma,
    partialOrTotalBlindness,
    macularDegeneration,
  ],
};

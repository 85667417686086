import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const criminalOffenseDetails: AnswerPathTreeNode = {
  path: 'criminalOffenceDetails',
  nodeId: NodeIds.criminalOffenceDetails,
  isCollection: true,
  children: [
    { path: 'circumstances', nodeId: NodeIds.criminalOffenceCircumstances },
    { path: 'date', nodeId: NodeIds.criminalOffenceDate },
    { path: 'charges', nodeId: NodeIds.criminalOffenceCharges },
    { path: 'sentenceDetails', nodeId: NodeIds.criminalOffenceSentenceDetails },
  ],
};

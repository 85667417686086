import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const eatingDisorder: AnswerPathTreeNode = {
  path: 'eatingDisorder',
  children: [
    { path: 'diagnosticDate', nodeId: NodeIds.healthInsuredEatingDisorderDiagnosticDate },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredEatingDisorderMedicationWasPrescribed },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredEatingDisorderHasConsultedDoctors },
    {
      path: 'hasOrWillConsultOtherDoctorsOrTherapists',
      nodeId: NodeIds.healthInsuredEatingDisorderHasOrWillConsultOtherDoctorsOrTherapists,
    },
    { path: 'wasHospitalized', nodeId: NodeIds.healthInsuredEatingDisorderWasHospitalized },
    { path: 'isControlled', nodeId: NodeIds.healthInsuredEatingDisorderIsControlled },
    { path: 'notControlledDetails', nodeId: NodeIds.healthInsuredEatingDisorderNotControlledDetails },
    { path: 'hasStableWeightDate', nodeId: NodeIds.healthInsuredEatingDisorderHasStableWeightDate },
    {
      path: 'hasOrWillConsultDoctorsDetails',
      nodeId: NodeIds.healthInsuredEatingDisorderHasOrWillConsultDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredEatingDisorderHasOrWillConsultDoctorsProfession },
        {
          path: 'professionOther',
          nodeId: NodeIds.healthInsuredEatingDisorderHasOrWillConsultDoctorsProfessionOther,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredEatingDisorderHasOrWillConsultDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredEatingDisorderHasOrWillConsultDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredEatingDisorderHasOrWillConsultDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredEatingDisorderHasOrWillConsultDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredEatingDisorderHasOrWillConsultDoctorsResult },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredEatingDisorderHasOrWillConsultDoctorsFrequencyNumber,
        },
        { path: 'frequency', nodeId: NodeIds.healthInsuredEatingDisorderHasOrWillConsultDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredEatingDisorderHasOrWillConsultDoctorsFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredEatingDisorderHasOrWillConsultDoctorsNextConsultationDate,
        },
        {
          path: 'nextConsultationDateUnknown',
          nodeId: NodeIds.healthInsuredEatingDisorderHasOrWillConsultDoctorsNextConsultationDateUnknown,
        },
      ],
    },
    {
      path: 'wasHospitalizedDetails',
      nodeId: NodeIds.healthInsuredEatingDisorderWasHospitalizedDetails,
      isCollection: true,
      children: [
        { path: 'date', nodeId: NodeIds.healthInsuredEatingDisorderWasHospitalizedDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredEatingDisorderWasHospitalizedFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredEatingDisorderWasHospitalizedFrequency },
        { path: 'location', nodeId: NodeIds.healthInsuredEatingDisorderWasHospitalizedLocation },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredEatingDisorderWasHospitalizedEndDate,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredEatingDisorderMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredEatingDisorderMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredEatingDisorderMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredEatingDisorderMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredEatingDisorderMedicationEndDate },
        { path: 'stillUses', nodeId: NodeIds.healthInsuredEatingDisorderMedicationStillUses },
      ],
    },
  ],
};

export enum NeedsAnalysisStepId {
  personalInformation = 'personalInformation',
  acknowledgeSmokerStatusYes = 'acknowledgeSmokerStatusYes',
  acknowledgeSmokerStatusNo = 'acknowledgeSmokerStatusNo',
  familyInformation = 'familyInformation',
  educationFund = 'educationFund',
  educationCoverageCalculator = 'educationCoverageCalculator',
  customEducationCoverage = 'customEducationCoverage',
  familyIncome = 'familyIncome',
  existingCoverage = 'existingCoverage',
  savingsAndDebts = 'savingsAndDebts',
  finalExpenses = 'finalExpenses',
  afterDeath = 'afterDeath',
  currentHealth = 'currentHealth',
  futureGoals = 'futureGoals',
  coverageDuration = 'coverageDuration',
  preference = 'preference',
  matters = 'matters',
  contactInfo = 'contactInfo',
  quoter = 'quoter',
  retirementInsuredIncome = 'retirementInsuredIncome',
  retirementCurrentFinancialHealthStatus = 'retirementCurrentFinancialHealthStatus',
  retirementIsCurrentlySaving = 'retirementIsCurrentlySaving',
  retirementSavingsObstacles = 'retirementSavingsObstacles',
  retirementSavingsGoals = 'retirementSavingsGoals',
  retirementSavingsPreference = 'retirementSavingsPreference',
}

export enum InsuranceApplicationStepId {
  proposedInsuredInformation = 'proposedInsuredInformation',
  productSummary = 'productSummary',
  existingCoverageDetails = 'existingCoverageDetails',
  admissibilityCanadian = 'admissibilityCanadian',
  admissibilityDeclined = 'admissibilityDeclined',
  healthCovid19 = 'healthCovid19',
  healthFullTimeCare = 'healthFullTimeCare',
  healthCerebrovascular = 'healthCerebrovascular',
  healthCardiovascular = 'healthCardiovascular',
  healthThyroid = 'healthThyroid',
  healthCancer = 'healthCancer',
  healthDiabetes = 'healthDiabetes',
  healthHepatitis = 'healthHepatitis',
  healthAnemia = 'healthAnemia',
  healthMentalHealth = 'healthMentalHealth',
  healthNeurological = 'healthNeurological',
  healthHiv = 'healthHiv',
  healthBowel = 'healthBowel',
  healthPulmonary = 'healthPulmonary',
  healthInformedAbnormalLastYear = 'healthInformedAbnormalLastYear',
  healthInformedAbnormalLast2Years = 'healthInformedAbnormalLast2Years',
  healthAbsent = 'healthAbsent',
  lifestyleDrugOrAlcoholCounselling = 'lifestyleDrugOrAlcoholCounselling',
  lifestyleChargedOrConvicted = 'lifestyleChargedOrConvicted',
  lifestyleDriversLicence = 'lifestyleDriversLicence',
  lifestyleOccupation = 'lifestyleOccupation',
  lifestyleExtreme = 'lifestyleExtreme',
  lifestyleTravel = 'lifestyleTravel',
  lifestyleTobaccoUse = 'lifestyleTobaccoUse',
  lifestyleMarijuanaUse = 'lifestyleMarijuanaUse',
  lifestyleDrugUse = 'lifestyleDrugUse',
  lifestyleAlcoholUse = 'lifestyleAlcoholUse',
  heightAndWeight = 'heightAndWeight',
  contactAndConfirmation = 'contactAndConfirmation',
  consentCreditRating = 'consentCreditRating',
  consentPersonalFilesAndInformation = 'consentPersonalFilesAndInformation',
  consentGeneral = 'consentGeneral',
  consentPreviousRecords = 'consentPreviousRecords',
  consentMibPrenotice = 'mibPrenotice',
  consentMib = 'consentMib',
  consentMibDoctor = 'consentMibDoctor',
  consentMibDisclosure = 'consentMibDisclosure',
  summary = 'summary',
  payment = 'payment',
  paymentConsent = 'consentPayment',
  tobaccoPriceUpdate = 'tobaccoPriceUpdate',
  submission = 'submission',
  addons = 'addons',
  simpleQuoter = 'simpleQuoter',
}

export enum BlockingId {
  halt = 'halt',
}

export enum GenderChoice {
  male = 'male',
  female = 'female',
}

export enum EducationCost {
  moreExpensive = 'moreExpensive',
  lessExpensive = 'lessExpensive',
}

export enum CurrentHealth {
  poor = 'poor',
  good = 'good',
  great = 'great',
  excellent = 'excellent',
}

export enum CurrentFinancialHealth {
  poor = 'poor',
  average = 'average',
  good = 'good',
  excellent = 'excellent',
}

export enum SavingsObstacles {
  notUnderstandingAssets = 'notUnderstandingAssets',
  notUnderstandingOptions = 'notUnderstandingOptions',
  livingPaycheckToPaycheck = 'livingPaycheckToPaycheck',
  debts = 'debts',
  pensionWillCoverNeeds = 'pensionWillCoverNeeds',
}

export enum SavingsGoals {
  generateIncomeOverTime = 'generateIncomeOverTime',
  manageOwnIncomeInRetirement = 'manageOwnIncomeInRetirement',
}

export enum SavingsPreference {
  regularSmallContributionsOverLongerTime = 'regularSmallContributionsOverLongerTime',
  largeContributionsOverShorterTime = 'largeContributionsOverShorterTime',
}

export enum FutureGoals {
  accessCash = 'accessCash',
  accessDeathBenefits = 'accessDeathBenefits',
  debtsPaidOff = 'debtsPaidOff',
  supplementOther = 'supplementOther',
}

export enum CoverageDuration {
  lifetime = 'lifetime',
  specifiedPeriod = 'specifiedPeriod',
}

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const anxiety: AnswerPathTreeNode = {
  path: 'anxiety',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredAnxietyIsSingleEpisode },
    { path: 'diagnosticDate', nodeId: NodeIds.healthInsuredAnxietyDiagnosticDate },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredAnxietyMedicationWasPrescribed },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredAnxietyHasConsultedDoctors },
    {
      path: 'hasOrWillConsultOtherDoctorsOrTherapists',
      nodeId: NodeIds.healthInsuredAnxietyHasOrWillConsultOtherDoctorsOrTherapists,
    },
    { path: 'wentToEmergencyDepartment', nodeId: NodeIds.healthInsuredAnxietyWentToEmergencyDepartment },
    { path: 'wasHospitalized', nodeId: NodeIds.healthInsuredAnxietyWasHospitalized },
    {
      path: 'suicidalThoughtsAndAttempt',
      children: [
        {
          path: 'hadSuicidalThoughtsAndAttempt',
          nodeId: NodeIds.healthInsuredAnxietyHadSuicidalThoughtsAndAttempt,
        },
        {
          path: 'choices',
          nodeId: NodeIds.healthInsuredAnxietySuicidalThoughtsAndAttemptChoices,
        },
        {
          path: 'suicidalIdeation',
          children: [
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthInsuredAnxietySuicidalThoughtsFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthInsuredAnxietySuicidalThoughtsFrequency,
            },
          ],
        },
        {
          path: 'attemptedSuicide',
          children: [
            {
              path: 'attemptsNumber',
              nodeId: NodeIds.healthInsuredAnxietySuicideAttemptsNumber,
            },
          ],
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredAnxietyHadAbsence },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredAnxietyAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredAnxietyAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredAnxietyAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredAnxietyMedicationDetails,
      isCollection: true,
      children: [
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredAnxietyMedicationName,
        },
        {
          path: 'dosage',
          nodeId: NodeIds.healthInsuredAnxietyMedicationDosage,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredAnxietyMedicationStartDate,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredAnxietyMedicationEndDate,
        },
        {
          path: 'stillUses',
          nodeId: NodeIds.healthInsuredAnxietyMedicationStillUses,
        },
      ],
    },
    {
      path: 'hasOrWillConsultDoctorsDetails',
      nodeId: NodeIds.healthInsuredAnxietyHasOrWillConsultDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredAnxietyHasOrWillConsultDoctorsProfession,
        },
        {
          path: 'professionOther',
          nodeId: NodeIds.healthInsuredAnxietyHasOrWillConsultDoctorsProfessionOther,
        },
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredAnxietyHasOrWillConsultDoctorsName,
        },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredAnxietyHasOrWillConsultDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredAnxietyHasOrWillConsultDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredAnxietyHasOrWillConsultDoctorsLastDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredAnxietyHasOrWillConsultDoctorsResult,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredAnxietyHasOrWillConsultDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredAnxietyHasOrWillConsultDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredAnxietyHasOrWillConsultDoctorsFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredAnxietyHasOrWillConsultDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'wentToEmergencyDepartmentDetails',
      nodeId: NodeIds.healthInsuredAnxietyWentToEmergencyDepartmentDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredAnxietyWentToEmergencyDepartmentDate,
        },
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredAnxietyWentToEmergencyDepartmentLocation,
        },
      ],
    },
    {
      path: 'wasHospitalizedDetails',
      nodeId: NodeIds.healthInsuredAnxietyWasHospitalizedDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredAnxietyWasHospitalizedDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredAnxietyWasHospitalizedFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredAnxietyWasHospitalizedFrequency,
        },
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredAnxietyWasHospitalizedLocation,
        },
      ],
    },
    {
      path: 'suicidalThoughtsDetails',
      nodeId: NodeIds.healthInsuredAnxietySuicidalThoughtsDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredAnxietySuicidalThoughtsDate,
        },
      ],
    },
    {
      path: 'suicideAttemptsDetails',
      nodeId: NodeIds.healthInsuredAnxietySuicideAttemptsDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredAnxietySuicideAttemptsDate,
        },
      ],
    },
    {
      path: 'hadAbsenceDetails',
      nodeId: NodeIds.healthInsuredAnxietyHadAbsenceDetails,
      isCollection: true,
      children: [
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredAnxietyAbsenceStartDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredAnxietyAbsenceFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredAnxietyAbsenceFrequency,
        },
      ],
    },
  ],
};

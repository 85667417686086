import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const musculoskeletalOsteoarthritis: AnswerPathTreeNode = {
  path: 'musculoskeletalOsteoarthritis',
  children: [
    {
      path: 'isSingleEpisode',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisIsSingleEpisode,
    },
    {
      path: 'previousEpisodesCount',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisPreviousEpisodesCount,
    },
    {
      path: 'dateSymptoms',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisDateSymptoms,
    },
    {
      path: 'hasConsultedDoctors',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctors,
    },
    {
      path: 'hasTakenTestsOrExams',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasTakenTestsOrExams,
    },
    {
      path: 'medicationWasPrescribed',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisMedicationWasPrescribed,
    },
    {
      path: 'painLocation',
      children: [
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisPainLocation,
        },
        {
          path: 'leftRight',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisPainLocationLeftRight,
        },
        {
          path: 'other',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisPainLocationOther,
        },
      ],
    },
    {
      path: 'painCause',
      children: [
        {
          path: 'cause',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisPainCause,
        },
        {
          path: 'details',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisPainCauseDetails,
        },
      ],
    },
    {
      path: 'operation',
      children: [
        {
          path: 'hadOperation',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHadOperation,
        },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisTypeOfOperationPerformed,
        },
        {
          path: 'performedDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisOperationPerformedDate,
        },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisTypeOfOperationPlanned,
        },
        {
          path: 'plannedDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisOperationPlannedDate,
        },
        {
          path: 'unknownDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisOperationUnknownDate,
        },
      ],
    },
    {
      path: 'absence',
      children: [
        {
          path: 'hadAbsence',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHadAbsence,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisAbsenceStartDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisAbsenceFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisAbsenceFrequency,
        },
        {
          path: 'recovery',
          children: [
            {
              path: 'hasRecovered',
              nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisAbsenceHasRecovered,
            },
            {
              path: 'recoveryDate',
              nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisAbsenceRecoveryDate,
            },
            {
              path: 'notRecoveredDetails',
              nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisAbsenceNotRecoveredDetails,
            },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsProfession },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsProfessionDetails,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsResult },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsFrequencyNumber,
        },
        { path: 'frequency', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasTakenTestsOrExamsResult },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredMusculoskeletalOsteoarthritisMedicationEndDate },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const copd: AnswerPathTreeNode = {
  path: 'copd',
  children: [
    { path: 'healthOtherCopdFirstAttackDate', nodeId: NodeIds.healthOtherCopdFirstAttackDate },
    { path: 'healthOtherCopdAttacksFrequency', nodeId: NodeIds.healthOtherCopdAttacksFrequency },
    { path: 'healthOtherCopdLastAttackDate', nodeId: NodeIds.healthOtherCopdLastAttackDate },
    { path: 'healthOtherCopdSeverity', nodeId: NodeIds.healthOtherCopdSeverity },
    { path: 'healthOtherCopdPhysicianCount', nodeId: NodeIds.healthOtherCopdPhysicianCount },
    { path: 'healthOtherCopdPhysicianName1', nodeId: 'health-other-copd-physician-name1' },
    { path: 'healthOtherCopdPhysicianAddress1', nodeId: 'health-other-copd-physician-address1' },
    { path: 'healthOtherCopdPhysicianName2', nodeId: 'health-other-copd-physician-name2' },
    { path: 'healthOtherCopdPhysicianAddress2', nodeId: 'health-other-copd-physician-address2' },
    { path: 'healthOtherCopdPhysicianName3', nodeId: 'health-other-copd-physician-name3' },
    { path: 'healthOtherCopdPhysicianAddress3', nodeId: 'health-other-copd-physician-address3' },
    { path: 'healthOtherCopdPhysicianName4', nodeId: 'health-other-copd-physician-name4' },
    { path: 'healthOtherCopdPhysicianAddress4', nodeId: 'health-other-copd-physician-address4' },
    { path: 'healthOtherCopdPhysicianName5', nodeId: 'health-other-copd-physician-name5' },
    { path: 'healthOtherCopdPhysicianAddress5', nodeId: 'health-other-copd-physician-address5' },
    { path: 'healthOtherCopdPhysicianName6', nodeId: 'health-other-copd-physician-name6' },
    { path: 'healthOtherCopdPhysicianAddress6', nodeId: 'health-other-copd-physician-address6' },
    { path: 'healthOtherCopdPhysicianName7', nodeId: 'health-other-copd-physician-name7' },
    { path: 'healthOtherCopdPhysicianAddress7', nodeId: 'health-other-copd-physician-address7' },
    { path: 'healthOtherCopdPhysicianName8', nodeId: 'health-other-copd-physician-name8' },
    { path: 'healthOtherCopdPhysicianAddress8', nodeId: 'health-other-copd-physician-address8' },
    { path: 'healthOtherCopdPhysicianName9', nodeId: 'health-other-copd-physician-name9' },
    { path: 'healthOtherCopdPhysicianAddress9', nodeId: 'health-other-copd-physician-address9' },
    { path: 'healthOtherCopdPhysicianName10', nodeId: 'health-other-copd-physician-name10' },
    { path: 'healthOtherCopdPhysicianAddress10', nodeId: 'health-other-copd-physician-address10' },
    { path: 'hasHealthOtherCopdInsuredHospitalized', nodeId: NodeIds.hasHealthOtherCopdInsuredHospitalized },
    { path: 'healthOtherCopdInsuredHospitalizedDate', nodeId: NodeIds.healthOtherCopdInsuredHospitalizedDate },
    { path: 'healthOtherCopdInsuredHospitalName', nodeId: NodeIds.healthOtherCopdInsuredHospitalName },
    { path: 'healthOtherCopdHospitalAddress', nodeId: NodeIds.healthOtherCopdHospitalAddress },
    { path: 'hasHealthOtherCopdInsuredEmergencyVisit', nodeId: NodeIds.hasHealthOtherCopdInsuredEmergencyVisit },
    { path: 'healthOtherCopdInsuredEmergencyVisitDate', nodeId: NodeIds.healthOtherCopdInsuredEmergencyVisitDate },
    { path: 'healthOtherCopdInsuredEmergencyName', nodeId: NodeIds.healthOtherCopdInsuredEmergencyName },
    { path: 'healthOtherCopdInsuredEmergencyAddress', nodeId: NodeIds.healthOtherCopdInsuredEmergencyAddress },
    { path: 'healthOtherCopdInsuredNumberOfMedications', nodeId: NodeIds.healthOtherCopdInsuredNumberOfMedications },
    {
      path: 'healthOtherCopdInsuredNumberOfMedicationsDetails',
      nodeId: NodeIds.healthOtherCopdInsuredNumberOfMedicationsDetails,
    },
    { path: 'isHealthOtherCopdInsuredShortOfBreath', nodeId: NodeIds.isHealthOtherCopdInsuredShortOfBreath },
    { path: 'healthOtherCopdLimitations', nodeId: NodeIds.healthOtherCopdLimitations },
    { path: 'healthOtherCopdLimitationsDetails', nodeId: NodeIds.healthOtherCopdLimitationsDetails },
    { path: 'isHealthOtherCopdInsuredTobaccoUser', nodeId: NodeIds.isHealthOtherCopdInsuredTobaccoUser },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const familyDoctor: AnswerPathTreeNode = {
  path: 'familyDoctorOrClinic',
  children: [
    { path: 'hasFamilyDoctorOrClinic', nodeId: NodeIds.healthInsuredHasFamilyDoctorOrClinic },
    { path: 'name', nodeId: NodeIds.healthInsuredFamilyDoctorOrClinicName },
    {
      path: 'address',
      children: [
        { path: 'street', nodeId: NodeIds.healthInsuredFamilyDoctorOrClinicAddressStreet },
        { path: 'apartment', nodeId: NodeIds.healthInsuredFamilyDoctorOrClinicAddressApartment },
        { path: 'city', nodeId: NodeIds.healthInsuredFamilyDoctorOrClinicAddressCity },
        { path: 'country', nodeId: NodeIds.healthInsuredFamilyDoctorOrClinicAddressCountry },
        { path: 'province', nodeId: NodeIds.healthInsuredFamilyDoctorOrClinicAddressProvince },
        {
          path: 'postalCode',
          nodeId: NodeIds.healthInsuredFamilyDoctorOrClinicAddressPostalCode,
        },
      ],
    },
  ],
};

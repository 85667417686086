import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const ownerEmployment: AnswerPathTreeNode = {
  path: 'employment',
  children: [
    { path: 'status', nodeId: NodeIds.ownerEmploymentStatus },
    { path: 'companyName', nodeId: NodeIds.ownerEmployerCompanyName },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const previousName: AnswerPathTreeNode = {
  path: 'previousName',
  children: [
    { path: 'firstName', nodeId: NodeIds.previousFirstName },
    { path: 'middleName', nodeId: NodeIds.previousMiddleName },
    { path: 'lastName', nodeId: NodeIds.previousLastName },
    { path: 'hasChangedName', nodeId: NodeIds.insuredHasChangedName },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const arthritis: AnswerPathTreeNode = {
  path: 'arthritis',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredArthritisIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredArthritisPreviousEpisodesCount },
    {
      path: 'painLocation',
      children: [
        { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredArthritisDateSymptoms },
        { path: 'location', nodeId: NodeIds.healthInsuredArthritisPainLocation },
        {
          path: 'leftRight',
          nodeId: NodeIds.healthInsuredArthritisPainLocationLeftRight,
        },
        { path: 'other', nodeId: NodeIds.healthInsuredArthritisPainLocationOther },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredArthritisPainCause },
        { path: 'details', nodeId: NodeIds.healthInsuredArthritisPainCauseDetails },
      ],
    },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredArthritisHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredArthritisHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredArthritisMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredArthritisHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredArthritisOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredArthritisTypeOfOperationPerformed,
        },
        {
          path: 'performedDate',
          nodeId: NodeIds.healthInsuredArthritisOperationPerformedDate,
        },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredArthritisTypeOfOperationPlanned,
        },
        {
          path: 'plannedDate',
          nodeId: NodeIds.healthInsuredArthritisOperationPlannedDate,
        },
        {
          path: 'unknownDate',
          nodeId: NodeIds.healthInsuredArthritisOperationUnknownDate,
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredArthritisHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredArthritisAbsenceStartDate },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredArthritisAbsenceFrequencyNumber,
        },
        { path: 'frequency', nodeId: NodeIds.healthInsuredArthritisAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredArthritisAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredArthritisAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredArthritisAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredArthritisHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredArthritisHasConsultedDoctorsProfession },
        { path: 'professionDetails', nodeId: NodeIds.healthInsuredArthritisHasConsultedDoctorsProfessionDetails },
        { path: 'name', nodeId: NodeIds.healthInsuredArthritisHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredArthritisHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredArthritisHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredArthritisHasConsultedDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredArthritisHasConsultedDoctorsResult },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredArthritisHasConsultedDoctorsFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredArthritisHasConsultedDoctorsFrequency },
        { path: 'furtherConsultation', nodeId: NodeIds.healthInsuredArthritisHasConsultedDoctorFurtherConsultation },
        { path: 'nextConsultationDate', nodeId: NodeIds.healthInsuredArthritisHasConsultedDoctorsNextConsultationDate },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredArthritisHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredArthritisHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredArthritisHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredArthritisHasTakenTestsOrExamsResult },
        { path: 'abnormalResult', nodeId: NodeIds.healthInsuredArthritisHasTakenTestsOrExamsDetailsAbnormalResult },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredArthritisMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredArthritisMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredArthritisMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredArthritisMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredArthritisMedicationEndDate },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const insuredSiblings: AnswerPathTreeNode = {
  path: 'insuredSiblings',
  children: [
    {
      path: 'insuredBrotherHoldInsuranceInfo',
      nodeId: NodeIds.insuredBrotherHoldInsuranceContract,
      isCollection: true,
      children: [
        { path: 'lifeAmountInForce', nodeId: NodeIds.insuredBrotherLifeAmountInForce },
        {
          path: 'criticalIllnessAmountInForce',
          nodeId: NodeIds.insuredBrotherCriticalIllnessAmountInForce,
        },
      ],
    },
    {
      path: 'insuredSisterHoldInsuranceInfo',
      nodeId: NodeIds.insuredSisterHoldInsuranceContract,
      isCollection: true,
      children: [
        { path: 'lifeAmountInForce', nodeId: NodeIds.insuredSisterLifeAmountInForce },
        {
          path: 'criticalIllnessAmountInForce',
          nodeId: NodeIds.insuredSisterCriticalIllnessAmountInForce,
        },
      ],
    },
  ],
};

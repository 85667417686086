import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const lifestyleInsuredDrugsOrNarcotics: AnswerPathTreeNode = {
  path: 'lifestyleInsuredDrugsOrNarcotics',
  children: [
    {
      path: 'drugsOther',
      children: [
        {
          path: 'use',
          children: [
            { path: 'stillConsume', nodeId: NodeIds.lifestyleInsuredDrugsOtherUseStillConsume },
            { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsOtherUseStartDate },
            { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsOtherUseEndDate },
          ],
        },
        {
          path: 'consumptionHabits',
          children: [
            { path: 'quantity', nodeId: NodeIds.lifestyleInsuredDrugsOtherConsumptionHabitsQuantity },
            { path: 'frequency', nodeId: NodeIds.lifestyleInsuredDrugsOtherConsumptionHabitsFrequency },
          ],
        },
        {
          path: 'pastUse',
          children: [
            { path: 'use', nodeId: NodeIds.lifestyleInsuredDrugsOtherPastUse },
            { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsOtherPastUseStartDate },
            { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsOtherPastUseEndDate },
          ],
        },
        {
          path: 'pastConsumptionHabits',
          children: [
            { path: 'quantity', nodeId: NodeIds.lifestyleInsuredDrugsOtherPastConsumptionHabitsQuantity },
            { path: 'frequency', nodeId: NodeIds.lifestyleInsuredDrugsOtherPastConsumptionHabitsFrequency },
          ],
        },
      ],
    },
    {
      path: 'drugsOrNarcotics',
      children: [
        {
          path: 'drugsOrNarcoticsUse',
          children: [
            { path: 'use', nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsUse },
            { path: 'type', nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsUseType },
            { path: 'formOther', nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsUseFormOther },
          ],
        },
        {
          path: 'advisedCease',
          children: [
            { path: 'consumptionDate', nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsAdvisedCeaseConsumptionDate },
            {
              path: 'contactInformation',
              nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsAdvisedCeaseConsumptionProfessionalContactInformation,
            },
          ],
        },
        {
          path: 'therapyOrTreatment',
          children: [
            { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsTherapyOrTreatmentStartDate },
            { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsTherapyOrTreatmentEndDate },
            { path: 'type', nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsTherapyOrTreatmentType },
            {
              path: 'contactInformation',
              nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsTherapyOrTreatmentProfessionalContactInformation,
            },
            {
              path: 'additionnalPeriod',
              nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsTherapyOrTreatmentAdditionnalPeriod,
            },
            {
              path: 'additionnalPeriodDetails',
              nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsTherapyOrTreatmentAdditionnalPeriodDetails,
            },
          ],
        },
        {
          path: 'supportGroup',
          children: [
            { path: 'stillAttending', nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsSupportGroupStillAttending },
            { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsSupportGroupStartDate },
            { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsSupportGroupEndDate },
          ],
        },
      ],
    },
  ],
};

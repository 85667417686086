import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const gerd: AnswerPathTreeNode = {
  path: 'gerd',
  children: [
    { path: 'hasConsulted', nodeId: NodeIds.healthInsuredGerdHasConsulted },
    { path: 'consulted', nodeId: NodeIds.healthInsuredGerdConsulted },
    { path: 'isControlled', nodeId: NodeIds.healthInsuredGerdIsControlled },
    { path: 'details', nodeId: NodeIds.healthInsuredGerdDetails },
    { path: 'complications', nodeId: NodeIds.healthInsuredGerdComplications },
    { path: 'complicationDetails', nodeId: NodeIds.healthInsuredGerdComplicationsDetails },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

export const children: AnswerPathTreeNode = {
  path: 'children',
  children: [
    { path: '0', children: [{ path: 'dateOfBirth', nodeId: 'child-1-birth-date' }] },
    { path: '1', children: [{ path: 'dateOfBirth', nodeId: 'child-2-birth-date' }] },
    { path: '2', children: [{ path: 'dateOfBirth', nodeId: 'child-3-birth-date' }] },
    { path: '3', children: [{ path: 'dateOfBirth', nodeId: 'child-4-birth-date' }] },
    { path: '4', children: [{ path: 'dateOfBirth', nodeId: 'child-5-birth-date' }] },
    { path: '5', children: [{ path: 'dateOfBirth', nodeId: 'child-6-birth-date' }] },
    { path: '6', children: [{ path: 'dateOfBirth', nodeId: 'child-7-birth-date' }] },
    { path: '7', children: [{ path: 'dateOfBirth', nodeId: 'child-8-birth-date' }] },
    { path: '8', children: [{ path: 'dateOfBirth', nodeId: 'child-9-birth-date' }] },
    { path: '9', children: [{ path: 'dateOfBirth', nodeId: 'child-10-birth-date' }] },
    { path: '10', children: [{ path: 'dateOfBirth', nodeId: 'child-11-birth-date' }] },
    { path: '11', children: [{ path: 'dateOfBirth', nodeId: 'child-12-birth-date' }] },
    { path: '12', children: [{ path: 'dateOfBirth', nodeId: 'child-13-birth-date' }] },
    { path: '13', children: [{ path: 'dateOfBirth', nodeId: 'child-14-birth-date' }] },
    { path: '14', children: [{ path: 'dateOfBirth', nodeId: 'child-15-birth-date' }] },
    { path: '15', children: [{ path: 'dateOfBirth', nodeId: 'child-16-birth-date' }] },
    { path: '16', children: [{ path: 'dateOfBirth', nodeId: 'child-17-birth-date' }] },
    { path: '17', children: [{ path: 'dateOfBirth', nodeId: 'child-18-birth-date' }] },
    { path: '18', children: [{ path: 'dateOfBirth', nodeId: 'child-19-birth-date' }] },
    { path: '19', children: [{ path: 'dateOfBirth', nodeId: 'child-20-birth-date' }] },
  ],
};

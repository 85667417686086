import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const replacementInsurance: AnswerPathTreeNode = {
  path: 'replacementInsurance',
  children: [
    { path: 'childRiderHistory', nodeId: NodeIds.replacementInsuranceChildRiderHistory },
    { path: 'childEndorsementHistory', nodeId: NodeIds.replacementInsuranceChildEndorsementHistory },
    {
      path: 'childRider',
      nodeId: NodeIds.replacementInsuranceChildRiderInfo,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.replacementInsuranceChildRiderName },
        { path: 'lastName', nodeId: NodeIds.replacementInsuranceChildRiderLastName },
        { path: 'firstName', nodeId: NodeIds.replacementInsuranceChildRiderFirstName },
        { path: 'choices', nodeId: NodeIds.replacementInsuranceChildRiderChoices },
        { path: 'companyName', nodeId: NodeIds.replacementInsuranceChildRiderCompanyName },
        { path: 'amount', nodeId: NodeIds.replacementInsuranceChildRiderAmount },
        { path: 'date', nodeId: NodeIds.replacementInsuranceChildRiderDate },
        { path: 'pending', nodeId: NodeIds.replacementInsuranceChildRiderPending },
      ],
    },
    {
      path: 'childCriticalIllnessRider',
      nodeId: NodeIds.replacementInsuranceChildCriticalIllnessRiderInfo,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.replacementInsuranceChildCriticalIllnessRiderName },
        { path: 'lastName', nodeId: NodeIds.replacementInsuranceChildCriticalIllnessRiderLastName },
        { path: 'firstName', nodeId: NodeIds.replacementInsuranceChildCriticalIllnessRiderFirstName },
        { path: 'choices', nodeId: NodeIds.replacementInsuranceChildCriticalIllnessRiderChoices },
        { path: 'companyName', nodeId: NodeIds.replacementInsuranceChildCriticalIllnessRiderCompanyName },
        { path: 'amount', nodeId: NodeIds.replacementInsuranceChildCriticalIllnessRiderAmount },
        { path: 'date', nodeId: NodeIds.replacementInsuranceChildCriticalIllnessRiderDate },
        { path: 'pending', nodeId: NodeIds.replacementInsuranceChildCriticalIllnessRiderPending },
      ],
    },
    { path: 'replacementInsuranceJoined', nodeId: NodeIds.insuredReplacementInsuranceJoined },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const herniatedDisc: AnswerPathTreeNode = {
  path: 'herniatedDisc',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredHerniatedDiscIsSingleEpisode },
    {
      path: 'previousEpisodesCount',
      nodeId: NodeIds.healthInsuredHerniatedDiscPreviousEpisodesCount,
    },
    {
      path: 'painLocation',
      children: [
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredHerniatedDiscPainLocation,
        },
        {
          path: 'dateSymptoms',
          nodeId: NodeIds.healthInsuredHerniatedDiscDateSymptoms,
        },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredHerniatedDiscPainCause },
        {
          path: 'details',
          nodeId: NodeIds.healthInsuredHerniatedDiscPainCauseDetails,
        },
      ],
    },
    {
      path: 'hasConsultedDoctors',
      nodeId: NodeIds.healthInsuredHerniatedDiscHasConsultedDoctors,
    },
    {
      path: 'hasTakenTestsOrExams',
      nodeId: NodeIds.healthInsuredHerniatedDiscHasTakenTestsOrExams,
    },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredHerniatedDiscMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredHerniatedDiscHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredHerniatedDiscOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredHerniatedDiscTypeOfOperationPerformed,
        },
        {
          path: 'performedDate',
          nodeId: NodeIds.healthInsuredHerniatedDiscOperationPerformedDate,
        },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredHerniatedDiscTypeOfOperationPlanned,
        },
        {
          path: 'plannedDate',
          nodeId: NodeIds.healthInsuredHerniatedDiscOperationPlannedDate,
        },
        {
          path: 'unknownDate',
          nodeId: NodeIds.healthInsuredHerniatedDiscOperationUnknownDate,
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredHerniatedDiscHadAbsence },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredHerniatedDiscAbsenceStartDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredHerniatedDiscAbsenceFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredHerniatedDiscAbsenceFrequency,
        },
        {
          path: 'recovery',
          children: [
            {
              path: 'hasRecovered',
              nodeId: NodeIds.healthInsuredHerniatedDiscAbsenceHasRecovered,
            },
            {
              path: 'recoveryDate',
              nodeId: NodeIds.healthInsuredHerniatedDiscAbsenceRecoveryDate,
            },
            {
              path: 'notRecoveredDetails',
              nodeId: NodeIds.healthInsuredHerniatedDiscAbsenceNotRecoveredDetails,
            },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredHerniatedDiscHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredHerniatedDiscHasConsultedDoctorsProfession },
        { path: 'professionDetails', nodeId: NodeIds.healthInsuredHerniatedDiscHasConsultedDoctorsProfessionDetails },
        { path: 'name', nodeId: NodeIds.healthInsuredHerniatedDiscHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredHerniatedDiscHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredHerniatedDiscHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredHerniatedDiscHasConsultedDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredHerniatedDiscHasConsultedDoctorsResult },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredHerniatedDiscHasConsultedDoctorsFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredHerniatedDiscHasConsultedDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredHerniatedDiscHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredHerniatedDiscHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredHerniatedDiscHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredHerniatedDiscHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredHerniatedDiscHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredHerniatedDiscHasTakenTestsOrExamsResult },
        { path: 'abnormalResult', nodeId: NodeIds.healthInsuredHerniatedDiscHasTakenTestsOrExamsDetailsAbnormalResult },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredHerniatedDiscMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredHerniatedDiscMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredHerniatedDiscMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredHerniatedDiscMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredHerniatedDiscMedicationEndDate },
      ],
    },
  ],
};

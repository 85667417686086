import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const autism: AnswerPathTreeNode = {
  path: 'autism',
  children: [
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredAutismMedicationWasPrescribed },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredAutismHasConsultedDoctors },
    {
      path: 'hasOrWillConsultOtherDoctorsOrTherapists',
      nodeId: NodeIds.healthInsuredAutismHasOrWillOtherDoctorsOrTherapists,
    },
    {
      path: 'performsDailyActivitiesWithoutHelp',
      nodeId: NodeIds.healthInsuredAutismPerformsDailyActivitiesWithoutHelp,
    },
    {
      path: 'performsDailyActivitiesWithoutHelpDetails',
      nodeId: NodeIds.healthInsuredAutismPerformsDailyActivitiesWithoutHelpDetails,
    },
    { path: 'accommodation', nodeId: NodeIds.healthInsuredAutismNeedsAccommodation },
    { path: 'accommodationDetails', nodeId: NodeIds.healthInsuredAutismAccommodationDetails },
    {
      path: 'hasOrWillConsultDoctorsDetails',
      nodeId: NodeIds.healthInsuredAutismHasOrWillConsultDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredAutismHasOrWillConsultDoctorsProfession },
        { path: 'professionOther', nodeId: NodeIds.healthInsuredAutismHasOrWillConsultDoctorsProfessionOther },
        { path: 'name', nodeId: NodeIds.healthInsuredAutismHasOrWillConsultDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredAutismHasOrWillConsultDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredAutismHasOrWillConsultDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredAutismHasOrWillConsultDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredAutismHasOrWillConsultDoctorsResult },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredAutismHasOrWillConsultDoctorsFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredAutismHasOrWillConsultDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredAutismHasOrWillConsultDoctorsFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredAutismHasOrWillConsultDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredAutismMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredAutismMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredAutismMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredAutismMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredAutismMedicationEndDate },
        { path: 'stillUses', nodeId: NodeIds.healthInsuredAutismMedicationStillUses },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { lifestyleInsuredAlcohol } from './lifestyleInsuredAlcohol';
import { lifestyleInsuredDrugsAnabolicSteroids } from './lifestyleInsuredDrugsAnabolicSteroids';
import { lifestyleInsuredDrugsCocaine } from './lifestyleInsuredDrugsCocaine';
import { lifestyleInsuredDrugsEcstasy } from './lifestyleInsuredDrugsEcstasy';
import { lifestyleInsuredDrugsFentanyl } from './lifestyleInsuredDrugsFentanyl';
import { lifestyleInsuredDrugsHeroin } from './lifestyleInsuredDrugsHeroin';
import { lifestyleInsuredDrugsLsd } from './lifestyleInsuredDrugsLsd';
import { lifestyleInsuredDrugsMagicMushrooms } from './lifestyleInsuredDrugsMagicMushrooms';
import { lifestyleInsuredDrugsOrNarcotics } from './lifestyleInsuredDrugsOrNarcotics';
import { lifestyleInsuredMarijuana } from './lifestyleInsuredMarijuana';

export const drugs: AnswerPathTreeNode = {
  path: 'drugs',
  children: [
    lifestyleInsuredDrugsCocaine,
    lifestyleInsuredDrugsEcstasy,
    lifestyleInsuredDrugsLsd,
    lifestyleInsuredDrugsMagicMushrooms,
    lifestyleInsuredDrugsHeroin,
    lifestyleInsuredDrugsFentanyl,
    lifestyleInsuredDrugsAnabolicSteroids,
    lifestyleInsuredDrugsOrNarcotics,
    lifestyleInsuredMarijuana,
    lifestyleInsuredAlcohol,
  ],
};

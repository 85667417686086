import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const backNeckOther: AnswerPathTreeNode = {
  path: 'backNeckOther',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredBackNeckOtherIsSingleEpisode },
    {
      path: 'previousEpisodesCount',
      nodeId: NodeIds.healthInsuredBackNeckOtherPreviousEpisodesCount,
    },
    {
      path: 'painLocation',
      children: [
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredBackNeckOtherPainLocation,
        },
        {
          path: 'dateSymptoms',
          nodeId: NodeIds.healthInsuredBackNeckOtherDateSymptoms,
        },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredBackNeckOtherPainCause },
        {
          path: 'details',
          nodeId: NodeIds.healthInsuredBackNeckOtherPainCauseDetails,
        },
      ],
    },
    {
      path: 'hasConsultedDoctors',
      nodeId: NodeIds.healthInsuredBackNeckOtherHasConsultedDoctors,
    },
    {
      path: 'hasTakenTestsOrExams',
      nodeId: NodeIds.healthInsuredBackNeckOtherHasTakenTestsOrExams,
    },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredBackNeckOtherMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredBackNeckOtherHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredBackNeckOtherOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredBackNeckOtherTypeOfOperationPerformed,
        },
        {
          path: 'performedDate',
          nodeId: NodeIds.healthInsuredBackNeckOtherOperationPerformedDate,
        },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredBackNeckOtherTypeOfOperationPlanned,
        },
        {
          path: 'plannedDate',
          nodeId: NodeIds.healthInsuredBackNeckOtherOperationPlannedDate,
        },
        {
          path: 'unknownDate',
          nodeId: NodeIds.healthInsuredBackNeckOtherOperationUnknownDate,
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredBackNeckOtherHadAbsence },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredBackNeckOtherAbsenceStartDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredBackNeckOtherAbsenceFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredBackNeckOtherAbsenceFrequency,
        },
        {
          path: 'recovery',
          children: [
            {
              path: 'hasRecovered',
              nodeId: NodeIds.healthInsuredBackNeckOtherAbsenceHasRecovered,
            },
            {
              path: 'recoveryDate',
              nodeId: NodeIds.healthInsuredBackNeckOtherAbsenceRecoveryDate,
            },
            {
              path: 'notRecoveredDetails',
              nodeId: NodeIds.healthInsuredBackNeckOtherAbsenceNotRecoveredDetails,
            },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredBackNeckOtherHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredBackNeckOtherHasConsultedDoctorsProfession,
        },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredBackNeckOtherHasConsultedDoctorsProfessionDetails,
        },
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredBackNeckOtherHasConsultedDoctorsName,
        },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredBackNeckOtherHasConsultedDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredBackNeckOtherHasConsultedDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredBackNeckOtherHasConsultedDoctorsLastDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredBackNeckOtherHasConsultedDoctorsResult,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredBackNeckOtherHasConsultedDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredBackNeckOtherHasConsultedDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredBackNeckOtherHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredBackNeckOtherHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredBackNeckOtherHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        {
          path: 'type',
          nodeId: NodeIds.healthInsuredBackNeckOtherHasTakenTestsOrExamsType,
        },
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredBackNeckOtherHasTakenTestsOrExamsDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredBackNeckOtherHasTakenTestsOrExamsResult,
        },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredBackNeckOtherHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredBackNeckOtherMedicationDetails,
      isCollection: true,
      children: [
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredBackNeckOtherMedicationName,
        },
        {
          path: 'dosage',
          nodeId: NodeIds.healthInsuredBackNeckOtherMedicationDosage,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredBackNeckOtherMedicationStartDate,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredBackNeckOtherMedicationEndDate,
        },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const needs: AnswerPathTreeNode = {
  path: 'needs',
  children: [
    { path: 'useNeedsAnalysis', nodeId: NodeIds.useNeedsAnalysis },
    { path: 'finalExpensesAmount', nodeId: NodeIds.finalExpensesAmount },
    { path: 'numberOfYearsToProvideForDependents', nodeId: NodeIds.numberOfYearsToProvideForDependents },
    { path: 'healthStatus', nodeId: NodeIds.insuredCurrentHealth },
    { path: 'financialGoals', nodeId: NodeIds.insuredFinancialGoals },
    { path: 'insurancePurpose', nodeId: NodeIds.insuredPurposeOfInsurance },
    { path: 'insuranceOtherPurpose', nodeId: NodeIds.insuredOtherPurposeOfInsurance },
    { path: 'costPreference', nodeId: NodeIds.insuredCostPreference },
    {
      path: 'retirement',
      children: [
        { path: 'financialHealthStatus', nodeId: NodeIds.retirementCurrentFinancialHealthStatus },
        { path: 'isCurrentlySaving', nodeId: NodeIds.retirementIsCurrentlySaving },
        { path: 'savingsObstacles', nodeId: NodeIds.retirementSavingsObstacles },
        { path: 'savingGoals', nodeId: NodeIds.retirementSavingsGoals },
        { path: 'savingsPreference', nodeId: NodeIds.retirementSavingsPreference },
      ],
    },
    {
      path: 'financialData',
      children: [
        {
          path: 'savings',
          children: [
            { path: 'lifeInsurance', nodeId: NodeIds.financialDataSavingsLifeInsurance },
            { path: 'accumulatedSavings', nodeId: NodeIds.financialDataSavingsAccumulatedSavings },
            { path: 'investments', nodeId: NodeIds.financialDataSavingsRegistered },
            { path: 'stocks', nodeId: NodeIds.financialDataSavingsNonRegistered },
            { path: 'otherSavings', nodeId: NodeIds.financialDataSavingsOtherSavings },
          ],
        },
        {
          path: 'expenses',
          children: [
            { path: 'mortgage', nodeId: NodeIds.financialDataDebtsMortgage },
            { path: 'unpaidDebts', nodeId: NodeIds.financialDataDebtsUnpaidDebts },
            { path: 'familyNeeds', nodeId: NodeIds.financialDataDebtsFamilyNeeds },
            { path: 'heritage', nodeId: NodeIds.financialDataDebtsHeritage },
            { path: 'funeral', nodeId: NodeIds.financialDataDebtsFuneral },
          ],
        },
        {
          path: 'summaryTotals',
          children: [
            { path: 'savings', nodeId: NodeIds.financialDataSummaryTotalSavings },
            { path: 'expenses', nodeId: NodeIds.financialDataSummaryTotalExpenses },
            { path: 'neededAmount', nodeId: NodeIds.financialDataSummaryTotalNeededAmount },
          ],
        },
      ],
    },
  ],
};

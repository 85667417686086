import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const medProvider: AnswerPathTreeNode = {
  path: 'medProvider',
  children: [
    { path: 'name', nodeId: NodeIds.insuredMedProviderName },
    { path: 'address', nodeId: NodeIds.insuredMedProviderAddress },
    { path: 'apartment', nodeId: NodeIds.insuredMedProviderApartment },
    { path: 'city', nodeId: NodeIds.insuredMedProviderCity },
    { path: 'state', nodeId: NodeIds.insuredMedProviderState },
    { path: 'zipCode', nodeId: NodeIds.insuredMedProviderZipCode },
    { path: 'phone', nodeId: NodeIds.insuredMedProviderPhone },
    { path: 'visitDate', nodeId: NodeIds.insuredMedProviderVisitDate },
    { path: 'visitReason', nodeId: NodeIds.insuredMedProviderVisitReason },
    { path: 'treatment', nodeId: NodeIds.insuredMedProviderTreatment },
    { path: 'treatmentGiven', nodeId: NodeIds.insuredMedProviderTreatmentGiven },
    { path: 'visitDiagnosis', nodeId: NodeIds.insuredMedProviderVisitDiagnosis },
    { path: 'visitPrescriptionQuestions', nodeId: NodeIds.insuredMedProviderVisitPrescriptionQuestions },
    { path: 'visitPrescriptionList', nodeId: NodeIds.insuredMedProviderVisitPrescriptionList },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const investment: AnswerPathTreeNode = {
  path: 'investment',
  isCollection: true,
  nodeId: NodeIds.investmentInformation,
  children: [
    { path: 'accountType', nodeId: NodeIds.investmentAccountType },
    { path: 'managedAccount', nodeId: NodeIds.investmentManagedAccount },
    { path: 'interestAccount', nodeId: NodeIds.investmentInterestAccount },
    { path: 'indexedAccount', nodeId: NodeIds.investmentIndexedAccount },
    { path: 'otherAccount', nodeId: NodeIds.investmentOtherAccount },
    { path: 'percentage', nodeId: NodeIds.investmentPercentage },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const lifestyleInsuredDrugsEcstasy: AnswerPathTreeNode = {
  path: 'lifestyleInsuredDrugsEcstasy',
  children: [
    {
      path: 'use',
      children: [
        { path: 'stillConsume', nodeId: NodeIds.lifestyleInsuredDrugsEcstasyUseStillConsume },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsEcstasyUseStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsEcstasyUseEndDate },
      ],
    },
    {
      path: 'consumptionHabits',
      children: [
        { path: 'quantity', nodeId: NodeIds.lifestyleInsuredDrugsEcstasyConsumptionHabitsQuantity },
        { path: 'frequency', nodeId: NodeIds.lifestyleInsuredDrugsEcstasyConsumptionHabitsFrequency },
      ],
    },
    {
      path: 'pastConsumptionHabits',
      children: [
        { path: 'quantity', nodeId: NodeIds.lifestyleInsuredDrugsEcstasyPastConsumptionHabitsQuantity },
        { path: 'frequency', nodeId: NodeIds.lifestyleInsuredDrugsEcstasyPastConsumptionHabitsFrequency },
      ],
    },
    {
      path: 'pastUse',
      children: [
        { path: 'use', nodeId: NodeIds.lifestyleInsuredDrugsEcstasyPastUse },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsEcstasyPastUseStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsEcstasyPastUseEndDate },
      ],
    },
    {
      path: 'pastChangeHabitConsumption',
      children: [
        { path: 'date', nodeId: NodeIds.lifestyleInsuredDrugsEcstasyPastChangeHabitConsumptionDate },
        { path: 'reason', nodeId: NodeIds.lifestyleInsuredDrugsEcstasyPastChangeHabitConsumptionReason },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const lifestyleInsuredDrugsAnabolicSteroids: AnswerPathTreeNode = {
  path: 'lifestyleInsuredDrugsAnabolicSteroids',
  children: [
    {
      path: 'use',
      children: [
        { path: 'stillConsume', nodeId: NodeIds.lifestyleInsuredDrugsAnabolicSteroidsUseStillConsume },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsAnabolicSteroidsUseStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsAnabolicSteroidsUseEndDate },
      ],
    },
    {
      path: 'consumptionHabits',
      children: [
        { path: 'quantity', nodeId: NodeIds.lifestyleInsuredDrugsAnabolicSteroidsConsumptionHabitsQuantity },
        { path: 'frequency', nodeId: NodeIds.lifestyleInsuredDrugsAnabolicSteroidsConsumptionHabitsFrequency },
      ],
    },
    {
      path: 'pastConsumptionHabits',
      children: [
        { path: 'quantity', nodeId: NodeIds.lifestyleInsuredDrugsAnabolicSteroidsPastConsumptionHabitsQuantity },
        { path: 'frequency', nodeId: NodeIds.lifestyleInsuredDrugsAnabolicSteroidsPastConsumptionHabitsFrequency },
      ],
    },
    {
      path: 'pastUse',
      children: [
        { path: 'use', nodeId: NodeIds.lifestyleInsuredDrugsAnabolicSteroidsPastUse },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsAnabolicSteroidsPastUseStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsAnabolicSteroidsPastUseEndDate },
      ],
    },
    {
      path: 'pastChangeHabitConsumption',
      children: [
        { path: 'date', nodeId: NodeIds.lifestyleInsuredDrugsAnabolicSteroidsPastChangeHabitConsumptionDate },
        { path: 'reason', nodeId: NodeIds.lifestyleInsuredDrugsAnabolicSteroidsPastChangeHabitConsumptionReason },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const numbnessTremorsWeaknessInExtremitiesLossOfSensation: AnswerPathTreeNode = {
  path: 'numbnessTremorsWeaknessInExtremitiesLossOfSensation',
  children: [
    { path: 'details', nodeId: NodeIds.healthInsuredNumbnessTremorsWeaknessInExtremitiesLossOfSensationDetails },
  ],
};

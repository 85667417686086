import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const alcohol: AnswerPathTreeNode = {
  path: 'alcohol',
  children: [
    { path: 'counsellingBeganDate', nodeId: NodeIds.alcoholCounsellingBeganDate },
    { path: 'counsellingHealthProfessionName', nodeId: NodeIds.alcoholCounsellingHealthProfessionName },
    { path: 'counsellingHealthProfessionAddress', nodeId: NodeIds.alcoholCounsellingHealthProfessionAddress },
    { path: 'counsellingNow', nodeId: NodeIds.alcoholCounsellingNow },
    { path: 'counsellingEndDate', nodeId: NodeIds.alcoholCounsellingEndDate },
    { path: 'treatmentBeganDate', nodeId: NodeIds.alcoholTreatmentBeganDate },
    { path: 'treatmentHealthProfessionName', nodeId: NodeIds.alcoholTreatmentHealthProfessionName },
    { path: 'treatmentHealthProfessionAddress', nodeId: NodeIds.alcoholTreatmentHealthProfessionAddress },
    { path: 'treatmentNow', nodeId: NodeIds.alcoholTreatmentNow },
    { path: 'treatmentEndDate', nodeId: NodeIds.alcoholTreatmentEndDate },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const thirdProduct: AnswerPathTreeNode = {
  path: 'thirdProduct',
  children: [
    {
      path: 'child',
      children: [
        {
          path: 'rider',
          nodeId: NodeIds.thirdChildRiderInfo,
          isCollection: true,
          children: [
            { path: 'lastName', nodeId: NodeIds.thirdChildRiderLastName },
            { path: 'firstName', nodeId: NodeIds.thirdChildRiderFirstName },
            { path: 'middleName', nodeId: NodeIds.thirdChildRiderMiddleName },
            { path: 'sex', nodeId: NodeIds.thirdChildRiderSex },
            { path: 'dateOfBirth', nodeId: NodeIds.thirdChildRiderDateBirth },
            { path: 'height', nodeId: NodeIds.thirdChildRiderHeight },
            { path: 'weight', nodeId: NodeIds.thirdChildRiderWeight },
            { path: 'relationship', nodeId: NodeIds.thirdChildRiderRelationship },
            { path: 'dateAndReasonLastConsultation', nodeId: NodeIds.thirdChildRiderDateAndReasonLastConsultation },
            { path: 'results', nodeId: NodeIds.thirdChildRiderResults },
            { path: 'treatmentAndFollowUp', nodeId: NodeIds.thirdChildRiderTreatmentAndFollowUp },
            { path: 'physicianInformation', nodeId: NodeIds.thirdChildRiderPhysicianInformation },
            { path: 'prematureBirth', nodeId: NodeIds.thirdChildRiderPrematureBirth },
            { path: 'medicalInformation', nodeId: NodeIds.thirdChildRiderMedicalInformation },
            { path: 'medicalInformationDetails', nodeId: NodeIds.thirdChildRiderMedicalInformationDetails },
            { path: 'otherMedicalInformation', nodeId: NodeIds.thirdChildRiderOtherMedicalInformation },
            {
              path: 'otherMedicalInformationDetails',
              nodeId: NodeIds.thirdChildRiderOtherMedicalInformationDetails,
            },
            { path: 'hasNotYetConsulted', nodeId: NodeIds.thirdChildRiderNotYetConsulted },
            {
              path: 'notYetConsultedDetails',
              nodeId: NodeIds.thirdChildRiderNotYetConsultedDetails,
            },
            { path: 'familyHistory', nodeId: NodeIds.thirdChildRiderFamilyHistory },
            { path: 'historyDetails', nodeId: NodeIds.thirdChildRiderHistoryDetails },
            { path: 'occupation', nodeId: NodeIds.thirdChildRiderOccupation },
          ],
        },
        {
          path: 'childLimiter',
          children: [
            { path: 'riderLimiter', nodeId: NodeIds.thirdChildRiderLimiter },
            { path: 'endorsementLimiter', nodeId: NodeIds.thirdChildrenEndorsementLimiter },
          ],
        },
        {
          path: 'criticalIllnessRider',
          nodeId: NodeIds.thirdChildCriticalIllnessRiderInfo,
          isCollection: true,
          children: [
            { path: 'lastName', nodeId: NodeIds.thirdChildCriticalIllnessRiderLastName },
            { path: 'firstName', nodeId: NodeIds.thirdChildCriticalIllnessRiderFirstName },
            { path: 'middleName', nodeId: NodeIds.thirdChildCriticalIllnessRiderMiddleName },
            { path: 'sex', nodeId: NodeIds.thirdChildCriticalIllnessRiderSex },
            { path: 'dateOfBirth', nodeId: NodeIds.thirdChildCriticalIllnessRiderDateBirth },
            { path: 'height', nodeId: NodeIds.thirdChildCriticalIllnessRiderHeight },
            { path: 'weight', nodeId: NodeIds.thirdChildCriticalIllnessRiderWeight },
            { path: 'relationship', nodeId: NodeIds.thirdChildCriticalIllnessRiderRelationship },
            {
              path: 'dateAndReasonLastConsultation',
              nodeId: NodeIds.thirdChildCriticalIllnessRiderDateAndReasonLastConsultation,
            },
            { path: 'results', nodeId: NodeIds.thirdChildCriticalIllnessRiderResults },
            { path: 'treatmentAndFollowUp', nodeId: NodeIds.thirdChildCriticalIllnessRiderTreatmentAndFollowUp },
            { path: 'physicianInformation', nodeId: NodeIds.thirdChildCriticalIllnessRiderPhysicianInformation },
            { path: 'prematureBirth', nodeId: NodeIds.thirdChildCriticalIllnessRiderPrematureBirth },
            { path: 'medicalInformation', nodeId: NodeIds.thirdChildCriticalIllnessRiderMedicalInformation },
            {
              path: 'medicalInformationDetails',
              nodeId: NodeIds.thirdChildCriticalIllnessRiderMedicalInformationDetails,
            },
            {
              path: 'otherMedicalInformation',
              nodeId: NodeIds.thirdChildCriticalIllnessRiderOtherMedicalInformation,
            },
            {
              path: 'otherMedicalInformationDetails',
              nodeId: NodeIds.thirdChildCriticalIllnessRiderOtherMedicalInformationDetails,
            },
            {
              path: 'hasNotYetConsulted',
              nodeId: NodeIds.thirdChildCriticalIllnessRiderNotYetConsulted,
            },
            {
              path: 'notYetConsultedDetails',
              nodeId: NodeIds.thirdChildCriticalIllnessRiderNotYetConsultedDetails,
            },
            { path: 'familyHistory', nodeId: NodeIds.thirdChildCriticalIllnessRiderFamilyHistory },
            { path: 'historyDetails', nodeId: NodeIds.thirdChildCriticalIllnessRiderHistoryDetails },
            { path: 'occupation', nodeId: NodeIds.thirdChildCriticalIllnessRiderOccupation },
          ],
        },
      ],
    },
    {
      path: 'pastChild',
      children: [
        {
          path: 'riderApplicationInfo',
          children: [
            { path: 'riderApplication', nodeId: NodeIds.thirdPastChildRiderApplication },
            {
              path: 'criticalIllnessRiderApplication',
              nodeId: NodeIds.thirdPastChildCriticalIllnessRiderApplication,
            },
          ],
        },
        {
          path: 'riderApplication',
          nodeId: NodeIds.thirdPastChildRiderApplicationInfo,
          isCollection: true,
          children: [
            { path: 'firstName', nodeId: NodeIds.thirdPastChildRiderApplicationFirstName },
            { path: 'lastName', nodeId: NodeIds.thirdPastChildRiderApplicationLastName },
            { path: 'choices', nodeId: NodeIds.thirdPastChildRiderApplicationChoices },
            { path: 'date', nodeId: NodeIds.thirdPastChildRiderApplicationDate },
            { path: 'reason', nodeId: NodeIds.thirdPastChildRiderApplicationReason },
          ],
        },
        {
          path: 'criticalIllnessRiderApplication',
          nodeId: NodeIds.thirdPastChildCriticalIllnessRiderApplicationInfo,
          isCollection: true,
          children: [
            { path: 'firstName', nodeId: NodeIds.thirdPastChildCriticalIllnessRiderApplicationFirstName },
            { path: 'lastName', nodeId: NodeIds.thirdPastChildCriticalIllnessRiderApplicationLastName },
            { path: 'choices', nodeId: NodeIds.thirdPastChildCriticalIllnessRiderApplicationChoices },
            { path: 'date', nodeId: NodeIds.thirdPastChildCriticalIllnessRiderApplicationDate },
            { path: 'reason', nodeId: NodeIds.thirdPastChildCriticalIllnessRiderApplicationReason },
          ],
        },
      ],
    },
    {
      path: 'replacementInsurance',
      children: [
        { path: 'childRiderHistory', nodeId: NodeIds.thirdReplacementInsuranceChildRiderHistory },
        { path: 'childEndorsementHistory', nodeId: NodeIds.thirdInsuranceChildEndorsementHistory },
        {
          path: 'childRider',
          nodeId: NodeIds.thirdReplacementInsuranceChildRiderInfo,
          isCollection: true,
          children: [
            { path: 'lastName', nodeId: NodeIds.thirdReplacementInsuranceChildRiderLastName },
            { path: 'firstName', nodeId: NodeIds.thirdReplacementInsuranceChildRiderFirstName },
            { path: 'choices', nodeId: NodeIds.thirdReplacementInsuranceChildRiderChoices },
            { path: 'companyName', nodeId: NodeIds.thirdReplacementInsuranceChildRiderCompanyName },
            { path: 'amount', nodeId: NodeIds.thirdReplacementInsuranceChildRiderAmount },
            { path: 'date', nodeId: NodeIds.thirdReplacementInsuranceChildRiderDate },
            { path: 'pending', nodeId: NodeIds.thirdReplacementInsuranceChildRiderPending },
          ],
        },
        {
          path: 'childCriticalIllnessRider',
          nodeId: NodeIds.thirdReplacementInsuranceChildCriticalIllnessRiderInfo,
          isCollection: true,
          children: [
            { path: 'lastName', nodeId: NodeIds.thirdReplacementInsuranceChildCriticalIllnessRiderLastName },
            { path: 'firstName', nodeId: NodeIds.thirdReplacementInsuranceChildCriticalIllnessRiderFirstName },
            { path: 'choices', nodeId: NodeIds.thirdReplacementInsuranceChildCriticalIllnessRiderChoices },
            { path: 'companyName', nodeId: NodeIds.thirdReplacementInsuranceChildCriticalIllnessRiderCompanyName },
            { path: 'amount', nodeId: NodeIds.thirdReplacementInsuranceChildCriticalIllnessRiderAmount },
            { path: 'date', nodeId: NodeIds.thirdReplacementInsuranceChildCriticalIllnessRiderDate },
            { path: 'pending', nodeId: NodeIds.thirdReplacementInsuranceChildCriticalIllnessRiderPending },
          ],
        },
      ],
    },
  ],
};

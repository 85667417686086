import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const lifestyleInsuredDrugsLsd: AnswerPathTreeNode = {
  path: 'lifestyleInsuredDrugsLsd',
  children: [
    {
      path: 'use',
      children: [
        { path: 'stillConsume', nodeId: NodeIds.lifestyleInsuredDrugsLsdUseStillConsume },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsLsdUseStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsLsdUseEndDate },
      ],
    },
    {
      path: 'consumptionHabits',
      children: [
        { path: 'quantity', nodeId: NodeIds.lifestyleInsuredDrugsLsdConsumptionHabitsQuantity },
        { path: 'frequency', nodeId: NodeIds.lifestyleInsuredDrugsLsdConsumptionHabitsFrequency },
      ],
    },
    {
      path: 'pastConsumptionHabits',
      children: [
        { path: 'quantity', nodeId: NodeIds.lifestyleInsuredDrugsLsdPastConsumptionHabitsQuantity },
        { path: 'frequency', nodeId: NodeIds.lifestyleInsuredDrugsLsdPastConsumptionHabitsFrequency },
      ],
    },
    {
      path: 'pastUse',
      children: [
        { path: 'use', nodeId: NodeIds.lifestyleInsuredDrugsLsdPastUse },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsLsdPastUseStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsLsdPastUseEndDate },
      ],
    },
    {
      path: 'pastChangeHabitConsumption',
      children: [
        { path: 'date', nodeId: NodeIds.lifestyleInsuredDrugsLsdPastChangeHabitConsumptionDate },
        { path: 'reason', nodeId: NodeIds.lifestyleInsuredDrugsLsdPastChangeHabitConsumptionReason },
      ],
    },
  ],
};

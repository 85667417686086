import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const tumor: AnswerPathTreeNode = {
  path: 'tumor',
  children: [
    { path: 'bodyPart', nodeId: NodeIds.healthInsuredTumorBodyPart },
    { path: 'severity', nodeId: NodeIds.healthInsuredTumorSeverity },
  ],
};

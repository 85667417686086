import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const address: AnswerPathTreeNode = {
  path: 'address',
  children: [
    { path: 'street', nodeId: NodeIds.insuredAddressStreet },
    { path: 'apartment', nodeId: NodeIds.insuredAddressApartment },
    { path: 'city', nodeId: NodeIds.insuredAddressCity },
    { path: 'provinceOrState', nodeId: NodeIds.insuredAddressProvinceOrState },
    { path: 'postalCode', nodeId: NodeIds.insuredAddressPostalCode },
    { path: 'country', nodeId: NodeIds.insuredAddressCountry },
  ],
};

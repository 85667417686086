import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const addAdhd: AnswerPathTreeNode = {
  path: 'addAdhd',
  children: [
    {
      path: 'isControlled',
      nodeId: NodeIds.healthInsuredAddAdhdIsControlled,
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredAddAdhdMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredAddAdhdMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredAddAdhdMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredAddAdhdMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredAddAdhdMedicationEndDate },
        { path: 'stillUses', nodeId: NodeIds.healthInsuredAddAdhdMedicationStillUses },
      ],
    },
    {
      path: 'hasOrWillConsultDoctorsDetails',
      nodeId: NodeIds.healthInsuredAddAdhdHasOrWillConsultDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredAddAdhdHasOrWillConsultDoctorsProfession },
        { path: 'professionOther', nodeId: NodeIds.healthInsuredAddAdhdHasOrWillConsultDoctorsProfessionOther },
        { path: 'name', nodeId: NodeIds.healthInsuredAddAdhdHasOrWillConsultDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredAddAdhdHasOrWillConsultDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredAddAdhdHasOrWillConsultDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredAddAdhdHasOrWillConsultDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredAddAdhdHasOrWillConsultDoctorsResult },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredAddAdhdHasOrWillConsultDoctorsFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredAddAdhdHasOrWillConsultDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredAddAdhdHasOrWillConsultDoctorsFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredAddAdhdHasOrWillConsultDoctorsNextConsultationDate,
        },
        {
          path: 'nextConsultationDateUnknown',
          nodeId: NodeIds.healthInsuredAddAdhdHasOrWillConsultDoctorsNextConsultationDateUnknown,
        },
      ],
    },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredAddAdhdMedicationWasPrescribed },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredAddAdhdHasConsultedDoctors },
    {
      path: 'hasOrWillConsultOtherDoctorsOrTherapists',
      nodeId: NodeIds.healthInsuredAddAdhdHasOrWillConsultOtherDoctorsOrTherapists,
    },
    { path: 'isControlledDate', nodeId: NodeIds.healthInsuredAddAdhdIsControlledDate },
    { path: 'notControlledDetails', nodeId: NodeIds.healthInsuredAddAdhdNotControlledDetails },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const musculoskeletalOther: AnswerPathTreeNode = {
  path: 'musculoskeletalOther',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredMusculoskeletalOtherIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredMusculoskeletalOtherPreviousEpisodesCount },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredMusculoskeletalOtherPainLocation },
        { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredMusculoskeletalOtherDateSymptoms },
        { path: 'locationLeftRight', nodeId: NodeIds.healthInsuredMusculoskeletalOtherPainLocationLeftRight },
        { path: 'locationOther', nodeId: NodeIds.healthInsuredMusculoskeletalOtherPainLocationOther },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredMusculoskeletalOtherPainCause },
        { path: 'causeDetails', nodeId: NodeIds.healthInsuredMusculoskeletalOtherPainCauseDetails },
      ],
    },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredMusculoskeletalOtherMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredMusculoskeletalOtherHadOperation },
        { path: 'performedOrPlanned', nodeId: NodeIds.healthInsuredMusculoskeletalOtherOperationPerformedOrPlanned },
        { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthInsuredMusculoskeletalOtherTypeOfOperationPerformed },
        { path: 'performedDate', nodeId: NodeIds.healthInsuredMusculoskeletalOtherOperationPerformedDate },
        { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthInsuredMusculoskeletalOtherTypeOfOperationPlanned },
        { path: 'plannedDate', nodeId: NodeIds.healthInsuredMusculoskeletalOtherOperationPlannedDate },
        { path: 'unknownDate', nodeId: NodeIds.healthInsuredMusculoskeletalOtherOperationUnknownDate },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredMusculoskeletalOtherHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredMusculoskeletalOtherAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredMusculoskeletalOtherAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredMusculoskeletalOtherAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredMusculoskeletalOtherAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredMusculoskeletalOtherAbsenceRecoveryDate },
            {
              path: 'notRecoveredDetails',
              nodeId: NodeIds.healthInsuredMusculoskeletalOtherAbsenceNotRecoveredDetails,
            },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasConsultedDoctorsProfession },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasConsultedDoctorsProfessionDetails,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasConsultedDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasConsultedDoctorsResult },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasConsultedDoctorsFrequencyNumber,
        },
        { path: 'frequency', nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasConsultedDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasTakenTestsOrExamsResult },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredMusculoskeletalOtherHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredMusculoskeletalOtherMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredMusculoskeletalOtherMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredMusculoskeletalOtherMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredMusculoskeletalOtherMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredMusculoskeletalOtherMedicationEndDate },
      ],
    },
  ],
};

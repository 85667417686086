import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const retirement: AnswerPathTreeNode = {
  path: 'retirement',
  children: [
    { path: 'retirementDate', nodeId: NodeIds.retirementDate },
    { path: 'grossAnnualIncome', nodeId: NodeIds.retirementInsuredAnnualIncome },
  ],
};

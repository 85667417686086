import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const lifestyleInsuredDrugsHeroin: AnswerPathTreeNode = {
  path: 'lifestyleInsuredDrugsHeroin',
  children: [
    {
      path: 'use',
      children: [
        { path: 'stillConsume', nodeId: NodeIds.lifestyleInsuredDrugsHeroinUseStillConsume },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsHeroinUseStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsHeroinUseEndDate },
      ],
    },
    {
      path: 'consumptionHabits',
      children: [
        { path: 'quantity', nodeId: NodeIds.lifestyleInsuredDrugsHeroinConsumptionHabitsQuantity },
        { path: 'frequency', nodeId: NodeIds.lifestyleInsuredDrugsHeroinConsumptionHabitsFrequency },
      ],
    },
    {
      path: 'pastConsumptionHabits',
      children: [
        { path: 'quantity', nodeId: NodeIds.lifestyleInsuredDrugsHeroinPastConsumptionHabitsQuantity },
        { path: 'frequency', nodeId: NodeIds.lifestyleInsuredDrugsHeroinPastConsumptionHabitsFrequency },
      ],
    },
    {
      path: 'pastUse',
      children: [
        { path: 'use', nodeId: NodeIds.lifestyleInsuredDrugsHeroinPastUse },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsHeroinPastUseStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsHeroinPastUseEndDate },
      ],
    },
    {
      path: 'pastChangeHabitConsumption',
      children: [
        { path: 'date', nodeId: NodeIds.lifestyleInsuredDrugsHeroinPastChangeHabitConsumptionDate },
        { path: 'reason', nodeId: NodeIds.lifestyleInsuredDrugsHeroinPastChangeHabitConsumptionReason },
      ],
    },
  ],
};

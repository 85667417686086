import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const torticollis: AnswerPathTreeNode = {
  path: 'torticollis',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredTorticollisIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredTorticollisPreviousEpisodesCount },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredTorticollisPainLocation },
        { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredTorticollisDateSymptoms },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredTorticollisPainCause },
        { path: 'details', nodeId: NodeIds.healthInsuredTorticollisPainCauseDetails },
      ],
    },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredTorticollisHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredTorticollisHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredTorticollisMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredTorticollisHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredTorticollisOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredTorticollisTypeOfOperationPerformed,
        },
        { path: 'performedDate', nodeId: NodeIds.healthInsuredTorticollisOperationPerformedDate },
        { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthInsuredTorticollisTypeOfOperationPlanned },
        { path: 'plannedDate', nodeId: NodeIds.healthInsuredTorticollisOperationPlannedDate },
        { path: 'unknownDate', nodeId: NodeIds.healthInsuredTorticollisOperationUnknownDate },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredTorticollisHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredTorticollisAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredTorticollisAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredTorticollisAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredTorticollisAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredTorticollisAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredTorticollisAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredTorticollisHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredTorticollisHasConsultedDoctorsProfession },
        { path: 'professionDetails', nodeId: NodeIds.healthInsuredTorticollisHasConsultedDoctorsProfessionDetails },
        { path: 'name', nodeId: NodeIds.healthInsuredTorticollisHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredTorticollisHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredTorticollisHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredTorticollisHasConsultedDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredTorticollisHasConsultedDoctorsResult },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredTorticollisHasConsultedDoctorsFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredTorticollisHasConsultedDoctorsFrequency },
        { path: 'furtherConsultation', nodeId: NodeIds.healthInsuredTorticollisHasConsultedDoctorFurtherConsultation },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredTorticollisHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredTorticollisHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredTorticollisHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredTorticollisHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredTorticollisHasTakenTestsOrExamsResult },
        { path: 'abnormalResult', nodeId: NodeIds.healthInsuredTorticollisHasTakenTestsOrExamsDetailsAbnormalResult },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredTorticollisMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredTorticollisMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredTorticollisMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredTorticollisMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredTorticollisMedicationEndDate },
      ],
    },
  ],
};

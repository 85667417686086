import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const consent: AnswerPathTreeNode = {
  path: 'consent',
  children: [
    { path: 'creditRating', nodeId: NodeIds.consentCreditRating },
    { path: 'general', nodeId: NodeIds.consentGeneral },
    { path: 'mibPrenotice', nodeId: NodeIds.consentMibPrenotice },
    { path: 'mib', nodeId: NodeIds.consentMib },
    { path: 'medicalInformation', nodeId: NodeIds.consentMedicalInformation },
    { path: 'mibDisclosure', nodeId: NodeIds.consentMibDisclosure },
    { path: 'previousInformation', nodeId: NodeIds.consentPreviousInformation },
    { path: 'personalFilesAndInformation', nodeId: NodeIds.consentPersonalFilesAndInformation },
    { path: 'personalInformation', nodeId: NodeIds.consentPersonalInfo },
    { path: 'declarations', nodeId: NodeIds.consentDeclarations },
    { path: 'payment', nodeId: NodeIds.consentPayment },
    { path: 'marketingCommunication', nodeId: NodeIds.consentMarketingCommunication },
    { path: 'identityVerification', nodeId: NodeIds.consentIdentityVerification },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const osteoporosis: AnswerPathTreeNode = {
  path: 'osteoporosis',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredOsteoporosisIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredOsteoporosisPreviousEpisodesCount },
    { path: 'painLocation', nodeId: NodeIds.healthInsuredOsteoporosisPainLocation },
    { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredOsteoporosisDateSymptoms },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredOsteoporosisPainCause },
        { path: 'details', nodeId: NodeIds.healthInsuredOsteoporosisPainCauseDetails },
      ],
    },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredOsteoporosisHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredOsteoporosisHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredOsteoporosisMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredOsteoporosisHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredOsteoporosisOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredOsteoporosisTypeOfOperationPerformed,
        },
        { path: 'performedDate', nodeId: NodeIds.healthInsuredOsteoporosisOperationPerformedDate },
        { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthInsuredOsteoporosisTypeOfOperationPlanned },
        { path: 'plannedDate', nodeId: NodeIds.healthInsuredOsteoporosisOperationPlannedDate },
        { path: 'unknownDate', nodeId: NodeIds.healthInsuredOsteoporosisOperationUnknownDate },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredOsteoporosisHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredOsteoporosisAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredOsteoporosisAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredOsteoporosisAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredOsteoporosisAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredOsteoporosisAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredOsteoporosisAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredOsteoporosisHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredOsteoporosisHasConsultedDoctorsProfession,
        },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredOsteoporosisHasConsultedDoctorsProfessionDetails,
        },
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredOsteoporosisHasConsultedDoctorsName,
        },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredOsteoporosisHasConsultedDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredOsteoporosisHasConsultedDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredOsteoporosisHasConsultedDoctorsLastDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredOsteoporosisHasConsultedDoctorsResult,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredOsteoporosisHasConsultedDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredOsteoporosisHasConsultedDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredOsteoporosisHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredOsteoporosisHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredOsteoporosisHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        {
          path: 'type',
          nodeId: NodeIds.healthInsuredOsteoporosisHasTakenTestsOrExamsType,
        },
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredOsteoporosisHasTakenTestsOrExamsDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredOsteoporosisHasTakenTestsOrExamsResult,
        },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredOsteoporosisHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredOsteoporosisMedicationDetails,
      isCollection: true,
      children: [
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredOsteoporosisMedicationName,
        },
        {
          path: 'dosage',
          nodeId: NodeIds.healthInsuredOsteoporosisMedicationDosage,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredOsteoporosisMedicationStartDate,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredOsteoporosisMedicationEndDate,
        },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const pad: AnswerPathTreeNode = {
  path: 'pad',
  children: [
    { path: 'branchNumber', nodeId: NodeIds.padBranchNumber },
    { path: 'financialInstitutionNumber', nodeId: NodeIds.padFinancialInstitutionNumber },
    { path: 'accountNumber', nodeId: NodeIds.padAccountNumber },
    { path: 'consentPayment', nodeId: NodeIds.padConsentPayment },
    { path: 'consentElectronicSignature', nodeId: NodeIds.padConsentElectronicSignature },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const aviation: AnswerPathTreeNode = {
  path: 'aviation',
  children: [
    { path: 'experienceDetailsForPast05Years', nodeId: 'aviation-experience-details-for-past-05years' },
    { path: 'choices', nodeId: NodeIds.aviationChoices },
    { path: 'other', nodeId: NodeIds.aviationOther },
    { path: 'licenceType', nodeId: NodeIds.aviationLicenceType },
    { path: 'licenceDate', nodeId: NodeIds.aviationLicenceDate },
    { path: 'certificateType', nodeId: NodeIds.aviationCertificateType },
    { path: 'annualFlyingHours', nodeId: NodeIds.aviationAnnualFlyingHours },
    { path: 'soloFlyingHours', nodeId: NodeIds.aviationSoloFlyingHours },
    { path: 'dateOfLastFlight', nodeId: NodeIds.aviationDateOfLastFlight },
  ],
};

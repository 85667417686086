import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import * as answerPaths from './paths';

export const contract: AnswerPathTreeNode = {
  path: 'contract',
  children: [
    answerPaths.advisor,
    answerPaths.healthOwner,
    answerPaths.lifestyleOwner,
    answerPaths.healthSecondOwner,
    answerPaths.lifestyleSecondOwner,
    answerPaths.signature,
    answerPaths.contractCorrespondenceLanguage,
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const discDegeneration: AnswerPathTreeNode = {
  path: 'discDegeneration',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredDiscDegenerationIsSingleEpisode },
    {
      path: 'previousEpisodesCount',
      nodeId: NodeIds.healthInsuredDiscDegenerationPreviousEpisodesCount,
    },
    {
      path: 'painLocation',
      children: [
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredDiscDegenerationPainLocation,
        },
        {
          path: 'dateSymptoms',
          nodeId: NodeIds.healthInsuredDiscDegenerationDateSymptoms,
        },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredDiscDegenerationPainCause },
        {
          path: 'causeDetails',
          nodeId: NodeIds.healthInsuredDiscDegenerationPainCauseDetails,
        },
      ],
    },
    {
      path: 'hasConsultedDoctors',
      nodeId: NodeIds.healthInsuredDiscDegenerationHasConsultedDoctors,
    },
    {
      path: 'hasTakenTestsOrExams',
      nodeId: NodeIds.healthInsuredDiscDegenerationHasTakenTestsOrExams,
    },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredDiscDegenerationMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredDiscDegenerationHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredDiscDegenerationOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredDiscDegenerationTypeOfOperationPerformed,
        },
        {
          path: 'performedDate',
          nodeId: NodeIds.healthInsuredDiscDegenerationOperationPerformedDate,
        },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredDiscDegenerationTypeOfOperationPlanned,
        },
        {
          path: 'plannedDate',
          nodeId: NodeIds.healthInsuredDiscDegenerationOperationPlannedDate,
        },
        {
          path: 'unknownDate',
          nodeId: NodeIds.healthInsuredDiscDegenerationOperationUnknownDate,
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredDiscDegenerationHadAbsence },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredDiscDegenerationAbsenceStartDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredDiscDegenerationAbsenceFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredDiscDegenerationAbsenceFrequency,
        },
        {
          path: 'recovery',
          children: [
            {
              path: 'hasRecovered',
              nodeId: NodeIds.healthInsuredDiscDegenerationAbsenceHasRecovered,
            },
            {
              path: 'recoveryDate',
              nodeId: NodeIds.healthInsuredDiscDegenerationAbsenceRecoveryDate,
            },
            {
              path: 'notRecoveredDetails',
              nodeId: NodeIds.healthInsuredDiscDegenerationAbsenceNotRecoveredDetails,
            },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredDiscDegenerationHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredDiscDegenerationHasConsultedDoctorsProfession },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredDiscDegenerationHasConsultedDoctorsProfessionDetails,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredDiscDegenerationHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredDiscDegenerationHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredDiscDegenerationHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredDiscDegenerationHasConsultedDoctorsLasttDate },
        { path: 'result', nodeId: NodeIds.healthInsuredDiscDegenerationHasConsultedDoctorsResult },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredDiscDegenerationHasConsultedDoctorsFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredDiscDegenerationHasConsultedDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredDiscDegenerationHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredDiscDegenerationHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredDiscDegenerationHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredDiscDegenerationHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredDiscDegenerationHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredDiscDegenerationHasTakenTestsOrExamsResult },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredDiscDegenerationHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredDiscDegenerationMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredDiscDegenerationMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredDiscDegenerationMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredDiscDegenerationMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredDiscDegenerationMedicationEndDate },
      ],
    },
  ],
};

import { maximumAgeCondition, minimumAgeCondition } from '@breathelife/condition-engine';
import { AgeRoundingType, Condition, DateUnit, NodeId } from '@breathelife/types';

export function ageIsLessThanOrEqualTo(
  nodeId: NodeId,
  age: number,
  ageRoundingType: AgeRoundingType = AgeRoundingType.closestBirthday,
  unit?: DateUnit.year | DateUnit.month,
): Condition {
  return maximumAgeCondition(nodeId, age, unit || DateUnit.year, ageRoundingType);
}

export function ageIsGreaterThanOrEqualTo(
  nodeId: NodeId,
  age: number,
  ageRoundingType: AgeRoundingType = AgeRoundingType.closestBirthday,
  unit?: DateUnit.year | DateUnit.month,
): Condition {
  return minimumAgeCondition(nodeId, age, unit || DateUnit.year, ageRoundingType);
}

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const musculoskeletalSprain: AnswerPathTreeNode = {
  path: 'musculoskeletalSprain',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredMusculoskeletalSprainIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredMusculoskeletalSprainPreviousEpisodesCount },
    { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredMusculoskeletalSprainDateSymptoms },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredMusculoskeletalSprainMedicationWasPrescribed },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredMusculoskeletalSprainPainLocation },
        { path: 'leftRight', nodeId: NodeIds.healthInsuredMusculoskeletalSprainPainLocationLeftRight },
        { path: 'other', nodeId: NodeIds.healthInsuredMusculoskeletalSprainPainLocationOther },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredMusculoskeletalSprainPainCause },
        { path: 'details', nodeId: NodeIds.healthInsuredMusculoskeletalSprainPainCauseDetails },
      ],
    },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredMusculoskeletalSprainHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainTypeOfOperationPerformed,
        },
        {
          path: 'performedDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainOperationPerformedDate,
        },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainTypeOfOperationPlanned,
        },
        {
          path: 'plannedDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainOperationPlannedDate,
        },
        {
          path: 'unknownDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainOperationUnknownDate,
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredMusculoskeletalSprainHadAbsence },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainAbsenceStartDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainAbsenceFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainAbsenceFrequency,
        },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredMusculoskeletalSprainAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredMusculoskeletalSprainAbsenceRecoveryDate },
            {
              path: 'notRecoveredDetails',
              nodeId: NodeIds.healthInsuredMusculoskeletalSprainAbsenceNotRecoveredDetails,
            },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasConsultedDoctorsProfession,
        },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasConsultedDoctorsProfessionDetails,
        },
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasConsultedDoctorsName,
        },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasConsultedDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasConsultedDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasConsultedDoctorsLastDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasConsultedDoctorsResult,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasConsultedDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasConsultedDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        {
          path: 'type',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasTakenTestsOrExamsType,
        },
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasTakenTestsOrExamsDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasTakenTestsOrExamsResult,
        },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredMusculoskeletalSprainMedicationDetails,
      isCollection: true,
      children: [
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainMedicationName,
        },
        {
          path: 'dosage',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainMedicationDosage,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainMedicationStartDate,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalSprainMedicationEndDate,
        },
      ],
    },
  ],
};

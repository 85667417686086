import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const kidneyStone: AnswerPathTreeNode = {
  path: 'kidneyStone',
  children: [
    { path: 'lastEpisode', nodeId: NodeIds.healthInsuredKidneyStoneLastEpisode },
    { path: 'episodesCount', nodeId: NodeIds.healthInsuredKidneyStoneEpisodesCount },
    { path: 'hasRecovered', nodeId: NodeIds.healthInsuredKidneyStoneHasRecovered },
  ],
};

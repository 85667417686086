import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const producerReport: AnswerPathTreeNode = {
  path: 'producerReport',
  children: [
    { path: 'sourceOfBusiness', nodeId: NodeIds.producerReportSourceOfBusiness },
    { path: 'presentInsuredTypeDetails', nodeId: NodeIds.producerReportPresentInsuredTypeDetails },
    { path: 'otherSourceOfBusiness', nodeId: NodeIds.producerReportOtherSourceOfBusiness },
    { path: 'marketType', nodeId: NodeIds.producerReportMarketType },
    { path: 'otherMarketType', nodeId: NodeIds.producerReportOtherMarketType },
    { path: 'deathBenefitReason', nodeId: NodeIds.producerReportDeathBenefitReason },
    { path: 'otherDeathBenefitReason', nodeId: NodeIds.producerReportOtherDeathBenefitReason },
    { path: 'rateClassQuote', nodeId: NodeIds.producerReportRateClassQuote },
    { path: 'relatedToInsured', nodeId: NodeIds.producerReportRelatedToInsured },
    { path: 'relationship', nodeId: NodeIds.producerReportRelationship },
    { path: 'otherRelationship', nodeId: NodeIds.producerReportOtherRelationship },
    { path: 'applicationTakenInPerson', nodeId: NodeIds.producerReportApplicationTakenInPerson },
    { path: 'applicationTakenBy', nodeId: NodeIds.producerReportApplicationTakenBy },
    { path: 'policyHolderInsuredSameLanguage', nodeId: NodeIds.policyHolderInsuredSameLanguage },
    { path: 'propositionExplainerFullName', nodeId: NodeIds.propositionExplainerFullName },
    { path: 'explanationsComprehension', nodeId: NodeIds.explanationsComprehension },
    { path: 'generalInformation', nodeId: NodeIds.generalInformation },
    { path: 'insuredPresent', nodeId: NodeIds.producerReportInsuredPresent },
    { path: 'underwriting', nodeId: NodeIds.producerReportUnderwriting },
    { path: 'underwritingDetails', nodeId: NodeIds.producerReportUnderwritingDetails },
    { path: 'pendingCoverage', nodeId: NodeIds.producerReportPendingCoverage },
    { path: 'coverageHistory', nodeId: NodeIds.producerReportCoverageHistory },
    { path: 'replacement', nodeId: NodeIds.producerReportReplacement },
    { path: 'questions', nodeId: NodeIds.producerReportQuestions },
    { path: 'knowsTheInsured', nodeId: NodeIds.producerReportKnowsTheInsured },
    {
      path: 'family',
      children: [
        { path: 'numberBrothers', nodeId: NodeIds.producerReportFamilyNumberBrothers },
        { path: 'numberSisters', nodeId: NodeIds.producerReportFamilyNumberSisters },
        { path: 'parentsTwoTimesInsurance', nodeId: NodeIds.producerReportFamilyParentsTwoTimesInsurance },
        { path: 'father', nodeId: NodeIds.producerReportFamilyFather },
        { path: 'mother', nodeId: NodeIds.producerReportFamilyMother },
        { path: 'siblings', nodeId: NodeIds.producerReportFamilySiblings },
        { path: 'sibiling1', nodeId: 'producer-report-family-sibiling-1' },
        { path: 'sibiling2', nodeId: 'producer-report-family-sibiling-2' },
        { path: 'sibiling3', nodeId: 'producer-report-family-sibiling-3' },
        { path: 'sibling4', nodeId: 'producer-report-family-sibling-4' },
        { path: 'sibling5', nodeId: 'producer-report-family-sibling-5' },
        { path: 'sibling6', nodeId: 'producer-report-family-sibling-6' },
        { path: 'sibling7', nodeId: 'producer-report-family-sibling-7' },
        { path: 'sibling8', nodeId: 'producer-report-family-sibling-8' },
        { path: 'sibling9', nodeId: 'producer-report-family-sibling-9' },
        { path: 'sibling10', nodeId: 'producer-report-family-sibling-10' },
      ],
    },
    { path: 'date', nodeId: NodeIds.producerReportDate },
    {
      path: 'insuranceProducer',
      children: [
        // Agent 1
        { path: 'agentCode', nodeId: NodeIds.producerReportAgentCode },
        { path: 'firstProducerFirstName', nodeId: NodeIds.producerReportInsuranceProducerFirstName },
        { path: 'firstProducerMiddleName', nodeId: NodeIds.producerReportInsuranceProducerMiddleName },
        { path: 'firstProducerLastName', nodeId: NodeIds.producerReportInsuranceProducerLastName },

        // Agent 2
        { path: 'hasSecondInsuranceProducer', nodeId: NodeIds.producerReportHasSecondInsuranceProducer },
        { path: 'secondAgentCode', nodeId: NodeIds.producerReportSecondAgentCode },
        { path: 'secondProducerFirstName', nodeId: NodeIds.producerReportSecondInsuranceProducerFirstName },
        { path: 'secondProducerMiddleName', nodeId: NodeIds.producerReportSecondInsuranceProducerMiddleName },
        { path: 'secondProducerLastName', nodeId: NodeIds.producerReportSecondInsuranceProducerLastName },
        { path: 'secondSplitPercentage', nodeId: NodeIds.producerReportSecondInsuranceProducerSplitPercentage },
        {
          path: 'secondProducerGeneralAgentCode',
          nodeId: NodeIds.producerReportSecondInsuranceProducerGeneralAgentCode,
        },

        // Agent 3
        { path: 'hasThirdInsuranceProducer', nodeId: NodeIds.producerReportHasThirdInsuranceProducer },
        { path: 'thirdAgentCode', nodeId: NodeIds.producerReportThirdAgentCode },
        { path: 'thirdAgentLastName', nodeId: NodeIds.producerReportThirdAgentLastName },
        { path: 'thirdAgentFirstName', nodeId: NodeIds.producerReportThirdAgentFirstName },
        { path: 'thirdProducerGeneralAgentCode', nodeId: NodeIds.producerReportThirdInsuranceProducerGeneralAgentCode },
        {
          path: 'thirdSplitPercentage',
          nodeId: NodeIds.producerReportThirdInsuranceProducerSplitPercentage,
        },

        // Misc
        { path: 'producerUnderwritingRemarks', nodeId: NodeIds.producerReportProducerUnderwritingRemarks },
        { path: 'splitPercentage', nodeId: NodeIds.producerReportInsuranceProducerSplitPercentage },
        { path: 'comissionSplit', nodeId: NodeIds.producerReportComissionSplit },
        { path: 'addAgent', nodeId: NodeIds.producerReportAddAgent },
      ],
    },
  ],
};

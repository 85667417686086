import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const insuredParentSiblings: AnswerPathTreeNode = {
  path: 'insuredParentSiblings',
  children: [
    {
      path: 'parents',
      children: [
        { path: 'holdInsuranceContract', nodeId: NodeIds.insuredParentHoldInsuranceContract },
        {
          path: 'father',
          children: [
            { path: 'lifeAmountInForce', nodeId: NodeIds.insuredFatherLifeAmountInForce },
            { path: 'criticalIllnessAmountInForce', nodeId: NodeIds.insuredFatherCriticalIllnessAmountInForce },
          ],
        },
        {
          path: 'mother',
          children: [
            { path: 'lifeAmountInForce', nodeId: NodeIds.insuredMotherLifeAmountInForce },
            { path: 'criticalIllnessAmountInForce', nodeId: NodeIds.insuredMotherCriticalIllnessAmountInForce },
          ],
        },
      ],
    },
    {
      path: 'siblings',
      children: [{ path: 'holdInsuranceContract', nodeId: NodeIds.insuredSiblingsHoldInsuranceContract }],
    },
  ],
};

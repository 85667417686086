import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const admissibility: AnswerPathTreeNode = {
  path: 'admissibility',
  children: [
    { path: 'isProposedInsured', nodeId: NodeIds.admissibilityIsProposedInsured },
    { path: 'hasChangedName', nodeId: NodeIds.admissibilityHasChangedName },
    { path: 'canadianStatus', nodeId: NodeIds.admissibilityCanadianStatus },
    { path: 'canadianResidence', nodeId: NodeIds.admissibilityCanadianResidence },
    { path: 'declined', nodeId: NodeIds.admissibilityDeclined },
  ],
};

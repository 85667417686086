import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const genitourinaryCondition: AnswerPathTreeNode = {
  path: 'genitourinaryCondition',
  children: [
    { path: 'hasCondition', nodeId: NodeIds.healthInsuredGenitourinaryHasCondition },
    { path: 'choices', nodeId: NodeIds.healthInsuredGenitourinaryChoices },
  ],
};

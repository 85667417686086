import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const cancer: AnswerPathTreeNode = {
  path: 'cancer',
  children: [
    { path: 'healthCancerRisk', nodeId: NodeIds.healthCancerRisk },
    { path: 'healthCancerChoices', nodeId: NodeIds.healthCancerChoices },
    { path: 'healthCancerTypes', nodeId: NodeIds.healthCancerTypes },
    { path: 'healthSkinCancerTypes', nodeId: NodeIds.healthSkinCancerTypes },
    { path: 'healthSkinCancerTypesOther', nodeId: NodeIds.healthSkinCancerTypesOther },
    {
      path: 'melanoma',
      children: [
        { path: 'healthMelanomaStage', nodeId: NodeIds.healthMelanomaStage },
        { path: 'healthMelanomaTreatmentTypes', nodeId: NodeIds.healthMelanomaTreatmentTypes },
        { path: 'healthMelanomaTreatmentDateCompletion', nodeId: NodeIds.healthMelanomaTreatmentDateCompletion },
        { path: 'healthMelanomaTreatmentHistory', nodeId: NodeIds.healthMelanomaTreatmentHistory },
        { path: 'healthMelanomaTreatmentDateOfPrior', nodeId: NodeIds.healthMelanomaTreatmentDateOfPrior },
        { path: 'hasInsuredHealthMelanomaMedProvider', nodeId: NodeIds.hasInsuredHealthMelanomaMedProvider },
        { path: 'healthMelanomaMedProviderName', nodeId: NodeIds.healthMelanomaMedProviderName },
        { path: 'healthMelanomaMedProviderAddress', nodeId: NodeIds.healthMelanomaMedProviderAddress },
        { path: 'healthMelanomaMedProviderPhone', nodeId: NodeIds.healthMelanomaMedProviderPhone },
        { path: 'isInsuredHealthMelanomaHospitalized', nodeId: NodeIds.isInsuredHealthMelanomaHospitalized },
        { path: 'healthMelanomaHospitalizedDate', nodeId: NodeIds.healthMelanomaHospitalizedDate },
        { path: 'healthMelanomaHospitalName', nodeId: NodeIds.healthMelanomaHospitalName },
        { path: 'healthMelanomaHospitalAddress', nodeId: NodeIds.healthMelanomaHospitalAddress },
      ],
    },
    {
      path: 'basal',
      children: [
        { path: 'isHealthBasalCellRemoved', nodeId: NodeIds.isHealthBasalCellRemoved },
        { path: 'healthBasalCellRemovedDate', nodeId: NodeIds.healthBasalCellRemovedDate },
        { path: 'isHealthBasalCellRecurrence', nodeId: NodeIds.isHealthBasalCellRecurrence },
        { path: 'hasInsuredHealthBasalMedProvider', nodeId: NodeIds.hasInsuredHealthBasalMedProvider },
        { path: 'healthBasalMedProviderName', nodeId: NodeIds.healthBasalMedProviderName },
        { path: 'healthBasalMedProviderAddress', nodeId: NodeIds.healthBasalMedProviderAddress },
        { path: 'healthBasalMedProviderPhone', nodeId: NodeIds.healthBasalMedProviderPhone },
        { path: 'isInsuredHealthBasalHospitalized', nodeId: NodeIds.isInsuredHealthBasalHospitalized },
        { path: 'healthBasalHospitalizedDate', nodeId: NodeIds.healthBasalHospitalizedDate },
        { path: 'healthBasalHospitalName', nodeId: NodeIds.healthBasalHospitalName },
        { path: 'healthBasalHospitalAddress', nodeId: NodeIds.healthBasalHospitalAddress },
      ],
    },
    {
      path: 'squamous',
      children: [
        { path: 'isHealthSquamousCellRemoved', nodeId: NodeIds.isHealthSquamousCellRemoved },
        { path: 'healthSquamousCellRemovedDate', nodeId: NodeIds.healthSquamousCellRemovedDate },
        { path: 'isHealthSquamousCellRecurrence', nodeId: NodeIds.isHealthSquamousCellRecurrence },
        { path: 'hasInsuredHealthSquamousMedProvider', nodeId: NodeIds.hasInsuredHealthSquamousMedProvider },
        { path: 'healthSquamousMedProviderName', nodeId: NodeIds.healthSquamousMedProviderName },
        { path: 'healthSquamousMedProviderAddress', nodeId: NodeIds.healthSquamousMedProviderAddress },
        { path: 'healthSquamousMedProviderPhone', nodeId: NodeIds.healthSquamousMedProviderPhone },
        { path: 'isInsuredHealthSquamousHospitalized', nodeId: NodeIds.isInsuredHealthSquamousHospitalized },
        { path: 'healthSquamousHospitalizedDate', nodeId: NodeIds.healthSquamousHospitalizedDate },
        { path: 'healthSquamousHospitalName', nodeId: NodeIds.healthSquamousHospitalName },
        { path: 'healthSquamousHospitalAddress', nodeId: NodeIds.healthSquamousHospitalAddress },
      ],
    },
    {
      path: 'prostate',
      children: [
        { path: 'healthProstateStage', nodeId: NodeIds.healthProstateStage },
        { path: 'healthProstateGleasonScore', nodeId: NodeIds.healthProstateGleasonScore },
        { path: 'healthProstateLastPsaNumber', nodeId: NodeIds.healthProstateLastPsaNumber },
        { path: 'healthProstateLastPsaDate', nodeId: NodeIds.healthProstateLastPsaDate },
        { path: 'healthProstateTreatment', nodeId: NodeIds.healthProstateTreatment },
        { path: 'healthProstateTreatmentDate', nodeId: NodeIds.healthProstateTreatmentDate },
        { path: 'hasInsuredHealthProstateMedProvider', nodeId: NodeIds.hasInsuredHealthProstateMedProvider },
        { path: 'healthProstateMedProviderName', nodeId: NodeIds.healthProstateMedProviderName },
        { path: 'healthProstateMedProviderAddress', nodeId: NodeIds.healthProstateMedProviderAddress },
        { path: 'healthProstateMedProviderPhone', nodeId: NodeIds.healthProstateMedProviderPhone },
        { path: 'isInsuredHealthProstateHospitalized', nodeId: NodeIds.isInsuredHealthProstateHospitalized },
        { path: 'healthProstateHospitalizedDate', nodeId: NodeIds.healthProstateHospitalizedDate },
        { path: 'healthProstateHospitalName', nodeId: NodeIds.healthProstateHospitalName },
        { path: 'healthProstateHospitalAddress', nodeId: NodeIds.healthProstateHospitalAddress },
      ],
    },
    {
      path: 'polyps',
      children: [
        { path: 'healthPolypsLastDateColonoscopy', nodeId: NodeIds.healthPolypsLastDateColonoscopy },
        { path: 'healthPolypsColonoscopyReason', nodeId: NodeIds.healthPolypsColonoscopyReason },
        { path: 'healthPolypsNumber', nodeId: NodeIds.healthPolypsNumber },
        { path: 'isHealthPolypsRemoved', nodeId: NodeIds.isHealthPolypsRemoved },
        { path: 'isHealthPolypsDiagnosedWithGsfap', nodeId: NodeIds.isHealthPolypsDiagnosedWithGsfap },
        { path: 'isInsuredHealthPolypsGsfapFamilyHistory', nodeId: NodeIds.isInsuredHealthPolypsGsfapFamilyHistory },
        { path: 'hasInsuredHealthPolypsMedProvider', nodeId: NodeIds.hasInsuredHealthPolypsMedProvider },
        { path: 'healthPolypsMedProviderName', nodeId: NodeIds.healthPolypsMedProviderName },
        { path: 'healthPolypsMedProviderAddress', nodeId: NodeIds.healthPolypsMedProviderAddress },
        { path: 'healthPolypsMedProviderPhone', nodeId: NodeIds.healthPolypsMedProviderPhone },
        { path: 'isInsuredHealthPolypsHospitalized', nodeId: NodeIds.isInsuredHealthPolypsHospitalized },
        { path: 'healthPolypsHospitalizedDate', nodeId: NodeIds.healthPolypsHospitalizedDate },
        { path: 'healthPolypsHospitalName', nodeId: NodeIds.healthPolypsHospitalName },
        { path: 'healthPolypsHospitalAddress', nodeId: NodeIds.healthPolypsHospitalAddress },
      ],
    },
    {
      path: 'tcg',
      children: [
        { path: 'healthTcgLocation', nodeId: NodeIds.healthTcgLocation },
        { path: 'healthTcgDiagnosisDate', nodeId: NodeIds.healthTcgDiagnosisDate },
        { path: 'isHealthTcgMalignant', nodeId: NodeIds.isHealthTcgMalignant },
        { path: 'healthTcgMalignantTreatmentChoice', nodeId: NodeIds.healthTcgMalignantTreatmentChoice },
        { path: 'healthTcgRemovedDate', nodeId: NodeIds.healthTcgRemovedDate },
        { path: 'healthTcgTreatmentType', nodeId: NodeIds.healthTcgTreatmentType },
        { path: 'healthTcgTreatmentLastDate', nodeId: NodeIds.healthTcgTreatmentLastDate },
        { path: 'healthTcgTreatmentCurrent', nodeId: NodeIds.healthTcgTreatmentCurrent },
        { path: 'hasInsuredHealthTcgMedProvider', nodeId: NodeIds.hasInsuredHealthTcgMedProvider },
        { path: 'healthTcgMedProviderName', nodeId: NodeIds.healthTcgMedProviderName },
        { path: 'healthTcgMedProviderAddress', nodeId: NodeIds.healthTcgMedProviderAddress },
        { path: 'healthTcgMedProviderPhone', nodeId: NodeIds.healthTcgMedProviderPhone },
        { path: 'isInsuredHealthTcgHospitalized', nodeId: NodeIds.isInsuredHealthTcgHospitalized },
        { path: 'healthTcgHospitalizedDate', nodeId: NodeIds.healthTcgHospitalizedDate },
        { path: 'healthTcgHospitalName', nodeId: NodeIds.healthTcgHospitalName },
        { path: 'healthTcgHospitalAddress', nodeId: NodeIds.healthTcgHospitalAddress },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const lifestyleInsuredDrugsMagicMushrooms: AnswerPathTreeNode = {
  path: 'lifestyleInsuredDrugsMagicMushrooms',
  children: [
    {
      path: 'use',
      children: [
        { path: 'stillConsume', nodeId: NodeIds.lifestyleInsuredDrugsMagicMushroomsUseStillConsume },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsMagicMushroomsUseStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsMagicMushroomsUseEndDate },
      ],
    },
    {
      path: 'consumptionHabits',
      children: [
        { path: 'quantity', nodeId: NodeIds.lifestyleInsuredDrugsMagicMushroomsConsumptionHabitsQuantity },
        { path: 'frequency', nodeId: NodeIds.lifestyleInsuredDrugsMagicMushroomsConsumptionHabitsFrequency },
      ],
    },
    {
      path: 'pastConsumptionHabits',
      children: [
        { path: 'quantity', nodeId: NodeIds.lifestyleInsuredDrugsMagicMushroomsPastConsumptionHabitsQuantity },
        { path: 'frequency', nodeId: NodeIds.lifestyleInsuredDrugsMagicMushroomsPastConsumptionHabitsFrequency },
      ],
    },
    {
      path: 'pastUse',
      children: [
        { path: 'use', nodeId: NodeIds.lifestyleInsuredDrugsMagicMushroomsPastUse },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsMagicMushroomsPastUseStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsMagicMushroomsPastUseEndDate },
      ],
    },
    {
      path: 'pastChangeHabitConsumption',
      children: [
        { path: 'date', nodeId: NodeIds.lifestyleInsuredDrugsMagicMushroomsPastChangeHabitConsumptionDate },
        { path: 'reason', nodeId: NodeIds.lifestyleInsuredDrugsMagicMushroomsPastChangeHabitConsumptionReason },
      ],
    },
  ],
};

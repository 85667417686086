import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const fourthProduct: AnswerPathTreeNode = {
  path: 'fourthProduct',
  children: [
    {
      path: 'child',
      children: [
        {
          path: 'rider',
          nodeId: NodeIds.fourthChildRiderInfo,
          isCollection: true,
          children: [
            { path: 'lastName', nodeId: NodeIds.fourthChildRiderLastName },
            { path: 'firstName', nodeId: NodeIds.fourthChildRiderFirstName },
            { path: 'middleName', nodeId: NodeIds.fourthChildRiderMiddleName },
            { path: 'sex', nodeId: NodeIds.fourthChildRiderSex },
            { path: 'dateOfBirth', nodeId: NodeIds.fourthChildRiderDateBirth },
            { path: 'height', nodeId: NodeIds.fourthChildRiderHeight },
            { path: 'weight', nodeId: NodeIds.fourthChildRiderWeight },
            { path: 'relationship', nodeId: NodeIds.fourthChildRiderRelationship },
            { path: 'dateAndReasonLastConsultation', nodeId: NodeIds.fourthChildRiderDateAndReasonLastConsultation },
            { path: 'results', nodeId: NodeIds.fourthChildRiderResults },
            { path: 'treatmentAndFollowUp', nodeId: NodeIds.fourthChildRiderTreatmentAndFollowUp },
            { path: 'physicianInformation', nodeId: NodeIds.fourthChildRiderPhysicianInformation },
            { path: 'prematureBirth', nodeId: NodeIds.fourthChildRiderPrematureBirth },
            { path: 'medicalInformation', nodeId: NodeIds.fourthChildRiderMedicalInformation },
            { path: 'medicalInformationDetails', nodeId: NodeIds.fourthChildRiderMedicalInformationDetails },
            { path: 'otherMedicalInformation', nodeId: NodeIds.fourthChildRiderOtherMedicalInformation },
            {
              path: 'otherMedicalInformationDetails',
              nodeId: NodeIds.fourthChildRiderOtherMedicalInformationDetails,
            },
            { path: 'hasNotYetConsulted', nodeId: NodeIds.fourthChildRiderNotYetConsulted },
            {
              path: 'notYetConsultedDetails',
              nodeId: NodeIds.fourthChildRiderNotYetConsultedDetails,
            },
            { path: 'familyHistory', nodeId: NodeIds.fourthChildRiderFamilyHistory },
            { path: 'historyDetails', nodeId: NodeIds.fourthChildRiderHistoryDetails },
            { path: 'occupation', nodeId: NodeIds.fourthChildRiderOccupation },
          ],
        },
        {
          path: 'childLimiter',
          children: [
            { path: 'riderLimiter', nodeId: NodeIds.fourthChildRiderLimiter },
            { path: 'endorsementLimiter', nodeId: NodeIds.fourthChildrenEndorsementLimiter },
          ],
        },
        {
          path: 'criticalIllnessRider',
          nodeId: NodeIds.fourthChildCriticalIllnessRiderInfo,
          isCollection: true,
          children: [
            { path: 'lastName', nodeId: NodeIds.fourthChildCriticalIllnessRiderLastName },
            { path: 'firstName', nodeId: NodeIds.fourthChildCriticalIllnessRiderFirstName },
            { path: 'middleName', nodeId: NodeIds.fourthChildCriticalIllnessRiderMiddleName },
            { path: 'sex', nodeId: NodeIds.fourthChildCriticalIllnessRiderSex },
            { path: 'dateOfBirth', nodeId: NodeIds.fourthChildCriticalIllnessRiderDateBirth },
            { path: 'height', nodeId: NodeIds.fourthChildCriticalIllnessRiderHeight },
            { path: 'weight', nodeId: NodeIds.fourthChildCriticalIllnessRiderWeight },
            { path: 'relationship', nodeId: NodeIds.fourthChildCriticalIllnessRiderRelationship },
            {
              path: 'dateAndReasonLastConsultation',
              nodeId: NodeIds.fourthChildCriticalIllnessRiderDateAndReasonLastConsultation,
            },
            { path: 'results', nodeId: NodeIds.fourthChildCriticalIllnessRiderResults },
            { path: 'treatmentAndFollowUp', nodeId: NodeIds.fourthChildCriticalIllnessRiderTreatmentAndFollowUp },
            { path: 'physicianInformation', nodeId: NodeIds.fourthChildCriticalIllnessRiderPhysicianInformation },
            { path: 'prematureBirth', nodeId: NodeIds.fourthChildCriticalIllnessRiderPrematureBirth },
            { path: 'medicalInformation', nodeId: NodeIds.fourthChildCriticalIllnessRiderMedicalInformation },
            {
              path: 'medicalInformationDetails',
              nodeId: NodeIds.fourthChildCriticalIllnessRiderMedicalInformationDetails,
            },
            {
              path: 'otherMedicalInformation',
              nodeId: NodeIds.fourthChildCriticalIllnessRiderOtherMedicalInformation,
            },
            {
              path: 'otherMedicalInformationDetails',
              nodeId: NodeIds.fourthChildCriticalIllnessRiderOtherMedicalInformationDetails,
            },
            {
              path: 'hasNotYetConsulted',
              nodeId: NodeIds.fourthChildCriticalIllnessRiderNotYetConsulted,
            },
            {
              path: 'notYetConsultedDetails',
              nodeId: NodeIds.fourthChildCriticalIllnessRiderNotYetConsultedDetails,
            },
            { path: 'familyHistory', nodeId: NodeIds.fourthChildCriticalIllnessRiderFamilyHistory },
            { path: 'historyDetails', nodeId: NodeIds.fourthChildCriticalIllnessRiderHistoryDetails },
            { path: 'occupation', nodeId: NodeIds.fourthChildCriticalIllnessRiderOccupation },
          ],
        },
      ],
    },
    {
      path: 'pastChild',
      children: [
        {
          path: 'riderApplicationInfo',
          children: [
            { path: 'riderApplication', nodeId: NodeIds.fourthPastChildRiderApplication },
            {
              path: 'criticalIllnessRiderApplication',
              nodeId: NodeIds.fourthPastChildCriticalIllnessRiderApplication,
            },
          ],
        },
        {
          path: 'riderApplication',
          nodeId: NodeIds.fourthPastChildRiderApplicationInfo,
          isCollection: true,
          children: [
            { path: 'firstName', nodeId: NodeIds.fourthPastChildRiderApplicationFirstName },
            { path: 'lastName', nodeId: NodeIds.fourthPastChildRiderApplicationLastName },
            { path: 'choices', nodeId: NodeIds.fourthPastChildRiderApplicationChoices },
            { path: 'date', nodeId: NodeIds.fourthPastChildRiderApplicationDate },
            { path: 'reason', nodeId: NodeIds.fourthPastChildRiderApplicationReason },
          ],
        },
        {
          path: 'criticalIllnessRiderApplication',
          nodeId: NodeIds.fourthPastChildCriticalIllnessRiderApplicationInfo,
          isCollection: true,
          children: [
            { path: 'firstName', nodeId: NodeIds.fourthPastChildCriticalIllnessRiderApplicationFirstName },
            { path: 'lastName', nodeId: NodeIds.fourthPastChildCriticalIllnessRiderApplicationLastName },
            { path: 'choices', nodeId: NodeIds.fourthPastChildCriticalIllnessRiderApplicationChoices },
            { path: 'date', nodeId: NodeIds.fourthPastChildCriticalIllnessRiderApplicationDate },
            { path: 'reason', nodeId: NodeIds.fourthPastChildCriticalIllnessRiderApplicationReason },
          ],
        },
      ],
    },
    {
      path: 'replacementInsurance',
      children: [
        { path: 'childRiderHistory', nodeId: NodeIds.fourthReplacementInsuranceChildRiderHistory },
        { path: 'childEndorsementHistory', nodeId: NodeIds.fourthInsuranceChildEndorsementHistory },
        {
          path: 'childRider',
          nodeId: NodeIds.fourthReplacementInsuranceChildRiderInfo,
          isCollection: true,
          children: [
            { path: 'lastName', nodeId: NodeIds.fourthReplacementInsuranceChildRiderLastName },
            { path: 'firstName', nodeId: NodeIds.fourthReplacementInsuranceChildRiderFirstName },
            { path: 'choices', nodeId: NodeIds.fourthReplacementInsuranceChildRiderChoices },
            { path: 'companyName', nodeId: NodeIds.fourthReplacementInsuranceChildRiderCompanyName },
            { path: 'amount', nodeId: NodeIds.fourthReplacementInsuranceChildRiderAmount },
            { path: 'date', nodeId: NodeIds.fourthReplacementInsuranceChildRiderDate },
            { path: 'pending', nodeId: NodeIds.fourthReplacementInsuranceChildRiderPending },
          ],
        },
        {
          path: 'childCriticalIllnessRider',
          nodeId: NodeIds.fourthReplacementInsuranceChildCriticalIllnessRiderInfo,
          isCollection: true,
          children: [
            { path: 'lastName', nodeId: NodeIds.fourthReplacementInsuranceChildCriticalIllnessRiderLastName },
            { path: 'firstName', nodeId: NodeIds.fourthReplacementInsuranceChildCriticalIllnessRiderFirstName },
            { path: 'choices', nodeId: NodeIds.fourthReplacementInsuranceChildCriticalIllnessRiderChoices },
            { path: 'companyName', nodeId: NodeIds.fourthReplacementInsuranceChildCriticalIllnessRiderCompanyName },
            { path: 'amount', nodeId: NodeIds.fourthReplacementInsuranceChildCriticalIllnessRiderAmount },
            { path: 'date', nodeId: NodeIds.fourthReplacementInsuranceChildCriticalIllnessRiderDate },
            { path: 'pending', nodeId: NodeIds.fourthReplacementInsuranceChildCriticalIllnessRiderPending },
          ],
        },
      ],
    },
  ],
};

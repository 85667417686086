import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const whiplash: AnswerPathTreeNode = {
  path: 'whiplash',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredWhiplashIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredWhiplashPreviousEpisodesCount },
    { path: 'painLocation', nodeId: NodeIds.healthInsuredWhiplashPainLocation },
    { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredWhiplashDateSymptoms },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredWhiplashPainCause },
        { path: 'details', nodeId: NodeIds.healthInsuredWhiplashPainCauseDetails },
      ],
    },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredWhiplashHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredWhiplashHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredWhiplashMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredWhiplashHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredWhiplashOperationPerformedOrPlanned,
        },
        { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthInsuredWhiplashTypeOfOperationPerformed },
        { path: 'performedDate', nodeId: NodeIds.healthInsuredWhiplashOperationPerformedDate },
        { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthInsuredWhiplashTypeOfOperationPlanned },
        { path: 'plannedDate', nodeId: NodeIds.healthInsuredWhiplashOperationPlannedDate },
        { path: 'unknownDate', nodeId: NodeIds.healthInsuredWhiplashOperationUnknownDate },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredWhiplashHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredWhiplashAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredWhiplashAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredWhiplashAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredWhiplashAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredWhiplashAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredWhiplashAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredWhiplashHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredWhiplashHasConsultedDoctorsProfession,
        },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredWhiplashHasConsultedDoctorsProfessionDetails,
        },
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredWhiplashHasConsultedDoctorsName,
        },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredWhiplashHasConsultedDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredWhiplashHasConsultedDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredWhiplashHasConsultedDoctorsLastDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredWhiplashHasConsultedDoctorsResult,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredWhiplashHasConsultedDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredWhiplashHasConsultedDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredWhiplashHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredWhiplashHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredWhiplashHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        {
          path: 'type',
          nodeId: NodeIds.healthInsuredWhiplashHasTakenTestsOrExamsType,
        },
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredWhiplashHasTakenTestsOrExamsDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredWhiplashHasTakenTestsOrExamsResult,
        },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredWhiplashHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredWhiplashMedicationDetails,
      isCollection: true,
      children: [
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredWhiplashMedicationName,
        },
        {
          path: 'dosage',
          nodeId: NodeIds.healthInsuredWhiplashMedicationDosage,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredWhiplashMedicationStartDate,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredWhiplashMedicationEndDate,
        },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const bipolarDisorder: AnswerPathTreeNode = {
  path: 'bipolarDisorder',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredBipolarDisorderIsSingleEpisode },
    { path: 'diagnosticDate', nodeId: NodeIds.healthInsuredBipolarDisorderDiagnosticDate },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredBipolarDisorderMedicationWasPrescribed },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredBipolarDisorderHasConsultedDoctors },
    {
      path: 'hasOrWillConsultOtherDoctorsOrTherapists',
      nodeId: NodeIds.healthInsuredBipolarDisorderHasOrWillConsultOtherDoctorsOrTherapists,
    },
    { path: 'wentToEmergencyDepartment', nodeId: NodeIds.healthInsuredBipolarDisorderWentToEmergencyDepartment },
    { path: 'wasHospitalized', nodeId: NodeIds.healthInsuredBipolarDisorderWasHospitalized },
    {
      path: 'suicidalThoughtsAndAttempts',
      children: [
        {
          path: 'hadSuicidalThoughtsAndAttempts',
          nodeId: NodeIds.healthInsuredBipolarDisorderHadSuicidalThoughtsAndAttempt,
        },
        {
          path: 'choices',
          nodeId: NodeIds.healthInsuredBipolarDisorderSuicidalThoughtsAndAttemptChoices,
        },
        {
          path: 'suicidalIdeation',
          children: [
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthInsuredBipolarDisorderSuicidalThoughtsFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthInsuredBipolarDisorderSuicidalThoughtsFrequency,
            },
          ],
        },
        {
          path: 'attemptedSuicide',
          children: [
            {
              path: 'attemptsNumber',
              nodeId: NodeIds.healthInsuredBipolarDisorderSuicideAttemptsNumber,
            },
          ],
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredBipolarDisorderHadAbsence },
        { path: 'hasRecovered', nodeId: NodeIds.healthInsuredBipolarDisorderAbsenceHasRecovered },
        { path: 'recoveredDate', nodeId: NodeIds.healthInsuredBipolarDisorderAbsenceRecoveryDate },
        { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredBipolarDisorderAbsenceNotRecoveredDetails },
      ],
    },
    {
      path: 'wasHospitalizedDetails',
      nodeId: NodeIds.healthInsuredBipolarDisorderWasHospitalizedDetails,
      isCollection: true,
      children: [
        { path: 'date', nodeId: NodeIds.healthInsuredBipolarDisorderWasHospitalizedDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredBipolarDisorderWasHospitalizedFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredBipolarDisorderWasHospitalizedFrequency },
        { path: 'location', nodeId: NodeIds.healthInsuredBipolarDisorderWasHospitalizedLocation },
        { path: 'endDate', nodeId: NodeIds.healthInsuredBipolarDisorderWasHospitalizedEndDate },
      ],
    },
    {
      path: 'suicidalThoughtsDetails',
      nodeId: NodeIds.healthInsuredBipolarDisorderSuicidalThoughtsDetails,
      isCollection: true,
      children: [{ path: 'date', nodeId: NodeIds.healthInsuredBipolarDisorderSuicidalThoughtsDate }],
    },
    {
      path: 'suicideAttemptsDetails',
      nodeId: NodeIds.healthInsuredBipolarDisorderSuicideAttemptsDetails,
      isCollection: true,
      children: [{ path: 'date', nodeId: NodeIds.healthInsuredBipolarDisorderSuicideAttemptsDate }],
    },
    {
      path: 'hasOrWillConsultDoctorsDetails',
      nodeId: NodeIds.healthInsuredBipolarDisorderHasOrWillConsultDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredBipolarDisorderHasOrWillConsultDoctorsProfession },
        {
          path: 'professionOther',
          nodeId: NodeIds.healthInsuredBipolarDisorderHasOrWillConsultDoctorsProfessionOther,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredBipolarDisorderHasOrWillConsultDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredBipolarDisorderHasOrWillConsultDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredBipolarDisorderHasOrWillConsultDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredBipolarDisorderHasOrWillConsultDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredBipolarDisorderHasOrWillConsultDoctorsResult },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredBipolarDisorderHasOrWillConsultDoctorsFrequencyNumber,
        },
        { path: 'frequency', nodeId: NodeIds.healthInsuredBipolarDisorderHasOrWillConsultDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredBipolarDisorderHasOrWillConsultDoctorsFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredBipolarDisorderHasOrWillConsultDoctorsNextConsultationDate,
        },
        {
          path: 'nextConsultationDateUnknown',
          nodeId: NodeIds.healthInsuredBipolarDisorderHasOrWillConsultDoctorsNextConsultationDateUnknown,
        },
      ],
    },
    {
      path: 'wentToEmergencyDepartmentDetails',
      nodeId: NodeIds.healthInsuredBipolarDisorderWentToEmergencyDepartmentDetails,
      isCollection: true,
      children: [
        { path: 'date', nodeId: NodeIds.healthInsuredBipolarDisorderWentToEmergencyDepartmentDate },
        { path: 'location', nodeId: NodeIds.healthInsuredBipolarDisorderWentToEmergencyDepartmentLocation },
      ],
    },
    {
      path: 'hadAbsenceDetails',
      nodeId: NodeIds.healthInsuredBipolarDisorderHadAbsenceDetails,
      isCollection: true,
      children: [
        { path: 'startDate', nodeId: NodeIds.healthInsuredBipolarDisorderAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredBipolarDisorderAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredBipolarDisorderAbsenceFrequency },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredBipolarDisorderMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredBipolarDisorderMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredBipolarDisorderMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredBipolarDisorderMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredBipolarDisorderMedicationEndDate },
        { path: 'stillUses', nodeId: NodeIds.healthInsuredBipolarDisorderMedicationStillUses },
      ],
    },
  ],
};

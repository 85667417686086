import { Subsection } from '@breathelife/questionnaire-engine';
import { SubsectionVariant } from '@breathelife/types';

export const blockingStep: Subsection = {
  id: 'halt',
  blueprintId: 'eaeac006-0f4c-460a-a0d7-87fd919cec56',
  title: {
    en: '',
    fr: '',
  },
  index: 'blocking',
  variant: SubsectionVariant.blocking,
  questions: [],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const musculoskeletalDetails: AnswerPathTreeNode = {
  path: 'musculoskeletalDetails',
  children: [
    {
      path: 'hasCondition',
      nodeId: NodeIds.healthInsuredMusculoskeletalHasCondition,
    },
    {
      path: 'conditions',
      nodeId: NodeIds.healthInsuredMusculoskeletalConditions,
    },
    {
      path: 'conditionsOther',
      nodeId: NodeIds.healthInsuredMusculoskeletalConditionsOther,
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';
import { als } from './als';
import { alzheimers } from './alzheimers';
import { anyOtherCognitiveImpairment } from './anyOtherCognitiveImpairments';
import { blurredVisionOrOpticNeuritis } from './blurredVisionOrOpticNeuritis';
import { cerebralPalsy } from './cerebralPalsy';
import { concussion } from './concussion';
import { degenerativeDisease } from './degenerativeDisease';
import { epilepsy } from './epilepsy';
import { lossOfConsciousnessBalanceDizziness } from './lossOfConsciousnessBalanceDizziness';
import { memoryLoss } from './memoryLoss';
import { migraines } from './migraines';
import { multipleSclerosis } from './multipleSclerosis';
import { numbnessTremorsWeaknessInExtremitiesLossOfSensation } from './numbnessTremorsWeaknessInExtremitiesLossOfSensation';
import { paralysis } from './paralysis';
import { parkinsons } from './parkinsons';

export const neurologicalDisorder: AnswerPathTreeNode = {
  path: 'neurologicalDisorder',
  children: [
    { path: 'hasCondition', nodeId: NodeIds.healthInsuredNeurologicalDisordersHasCondition },
    { path: 'choices', nodeId: NodeIds.healthInsuredNeurologicalDisordersChoices },
    als,
    alzheimers,
    cerebralPalsy,
    degenerativeDisease,
    memoryLoss,
    migraines,
    concussion,
    epilepsy,
    anyOtherCognitiveImpairment,
    paralysis,
    blurredVisionOrOpticNeuritis,
    multipleSclerosis,
    parkinsons,
    lossOfConsciousnessBalanceDizziness,
    numbnessTremorsWeaknessInExtremitiesLossOfSensation,
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';
import { addAdhd } from './addAdhd';
import { anxiety } from './anxiety';
import { autism } from './autism';
import { bipolarDisorder } from './bipolarDisorder';
import { burnout } from './burnout';
import { depression } from './depression';
import { downSyndrome } from './downSyndrome';
import { eatingDisorder } from './eatingDisorder';
import { insomnia } from './insomnia';
import { intellectualImpairment } from './intellectualImpairment';
import { mentalHealthOther } from './mentalHealthOther';
import { panicAttacks } from './panicAttacks';
import { psychosis } from './psychosis';
import { suicideAttempts } from './suicideAttempts';

export const mentalHealth: AnswerPathTreeNode = {
  path: 'mentalHealth',
  children: [
    { path: 'hasCondition', nodeId: NodeIds.healthInsuredMentalHealthHasCondition },
    { path: 'choices', nodeId: NodeIds.healthInsuredMentalHealthChoices },
    addAdhd,
    autism,
    bipolarDisorder,
    downSyndrome,
    eatingDisorder,
    intellectualImpairment,
    mentalHealthOther,
    psychosis,
    suicideAttempts,
    anxiety,
    burnout,
    depression,
    insomnia,
    panicAttacks,
  ],
};

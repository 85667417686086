import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const lifestyleInsuredDrugsFentanyl: AnswerPathTreeNode = {
  path: 'lifestyleInsuredDrugsFentanyl',
  children: [
    {
      path: 'use',
      children: [
        { path: 'stillConsume', nodeId: NodeIds.lifestyleInsuredDrugsFentanylUseStillConsume },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsFentanylUseStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsFentanylUseEndDate },
      ],
    },
    {
      path: 'consumptionHabits',
      children: [
        { path: 'quantity', nodeId: NodeIds.lifestyleInsuredDrugsFentanylConsumptionHabitsQuantity },
        { path: 'frequency', nodeId: NodeIds.lifestyleInsuredDrugsFentanylConsumptionHabitsFrequency },
      ],
    },
    {
      path: 'pastConsumptionHabits',
      children: [
        { path: 'quantity', nodeId: NodeIds.lifestyleInsuredDrugsFentanylPastConsumptionHabitsQuantity },
        { path: 'frequency', nodeId: NodeIds.lifestyleInsuredDrugsFentanylPastConsumptionHabitsFrequency },
      ],
    },
    {
      path: 'pastUse',
      children: [
        { path: 'use', nodeId: NodeIds.lifestyleInsuredDrugsFentanylPastUse },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredDrugsFentanylPastUseStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredDrugsFentanylPastUseEndDate },
      ],
    },
    {
      path: 'pastChangeHabitConsumption',
      children: [
        { path: 'date', nodeId: NodeIds.lifestyleInsuredDrugsFentanylPastChangeHabitConsumptionDate },
        { path: 'reason', nodeId: NodeIds.lifestyleInsuredDrugsFentanylPastChangeHabitConsumptionReason },
      ],
    },
  ],
};

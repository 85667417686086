import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const emphysema: AnswerPathTreeNode = {
  path: 'emphysema',
  children: [
    { path: 'healthOtherEmphysemaFirstAttackDate', nodeId: NodeIds.healthOtherEmphysemaFirstAttackDate },
    { path: 'healthOtherEmphysemaAttacksFrequency', nodeId: NodeIds.healthOtherEmphysemaAttacksFrequency },
    { path: 'healthOtherEmphysemaLastAttackDate', nodeId: NodeIds.healthOtherEmphysemaLastAttackDate },
    { path: 'healthOtherEmphysemaSeverity', nodeId: NodeIds.healthOtherEmphysemaSeverity },
    { path: 'healthOtherEmphysemaPhysicianCount', nodeId: NodeIds.healthOtherEmphysemaPhysicianCount },
    { path: 'healthOtherEmphysemaPhysicianName1', nodeId: 'health-other-emphysema-physician-name1' },
    { path: 'healthOtherEmphysemaPhysicianAddress1', nodeId: 'health-other-emphysema-physician-address1' },
    { path: 'healthOtherEmphysemaPhysicianName2', nodeId: 'health-other-emphysema-physician-name2' },
    { path: 'healthOtherEmphysemaPhysicianAddress2', nodeId: 'health-other-emphysema-physician-address2' },
    { path: 'healthOtherEmphysemaPhysicianName3', nodeId: 'health-other-emphysema-physician-name3' },
    { path: 'healthOtherEmphysemaPhysicianAddress3', nodeId: 'health-other-emphysema-physician-address3' },
    { path: 'healthOtherEmphysemaPhysicianName4', nodeId: 'health-other-emphysema-physician-name4' },
    { path: 'healthOtherEmphysemaPhysicianAddress4', nodeId: 'health-other-emphysema-physician-address4' },
    { path: 'healthOtherEmphysemaPhysicianName5', nodeId: 'health-other-emphysema-physician-name5' },
    { path: 'healthOtherEmphysemaPhysicianAddress5', nodeId: 'health-other-emphysema-physician-address5' },
    { path: 'healthOtherEmphysemaPhysicianName6', nodeId: 'health-other-emphysema-physician-name6' },
    { path: 'healthOtherEmphysemaPhysicianAddress6', nodeId: 'health-other-emphysema-physician-address6' },
    { path: 'healthOtherEmphysemaPhysicianName7', nodeId: 'health-other-emphysema-physician-name7' },
    { path: 'healthOtherEmphysemaPhysicianAddress7', nodeId: 'health-other-emphysema-physician-address7' },
    { path: 'healthOtherEmphysemaPhysicianName8', nodeId: 'health-other-emphysema-physician-name8' },
    { path: 'healthOtherEmphysemaPhysicianAddress8', nodeId: 'health-other-emphysema-physician-address8' },
    { path: 'healthOtherEmphysemaPhysicianName9', nodeId: 'health-other-emphysema-physician-name9' },
    { path: 'healthOtherEmphysemaPhysicianAddress9', nodeId: 'health-other-emphysema-physician-address9' },
    { path: 'healthOtherEmphysemaPhysicianName10', nodeId: 'health-other-emphysema-physician-name10' },
    { path: 'healthOtherEmphysemaPhysicianAddress10', nodeId: 'health-other-emphysema-physician-address10' },
    { path: 'hasHealthOtherEmphysemaInsuredHospitalized', nodeId: NodeIds.hasHealthOtherEmphysemaInsuredHospitalized },
    {
      path: 'healthOtherEmphysemaInsuredHospitalizedDate',
      nodeId: NodeIds.healthOtherEmphysemaInsuredHospitalizedDate,
    },
    { path: 'healthOtherEmphysemaInsuredHospitalName', nodeId: NodeIds.healthOtherEmphysemaInsuredHospitalName },
    { path: 'healthOtherEmphysemaInsuredHospitalAddress', nodeId: NodeIds.healthOtherEmphysemaInsuredHospitalAddress },
    {
      path: 'hasHealthOtherEmphysemaInsuredEmergencyVisit',
      nodeId: NodeIds.hasHealthOtherEmphysemaInsuredEmergencyVisit,
    },
    {
      path: 'healthOtherEmphysemaInsuredEmergencyVisitDate',
      nodeId: NodeIds.healthOtherEmphysemaInsuredEmergencyVisitDate,
    },
    { path: 'healthOtherEmphysemaInsuredEmergencyName', nodeId: NodeIds.healthOtherEmphysemaInsuredEmergencyName },
    {
      path: 'healthOtherEmphysemaInsuredEmergencyAddress',
      nodeId: NodeIds.healthOtherEmphysemaInsuredEmergencyAddress,
    },
    {
      path: 'healthOtherEmphysemaInsuredNumberOfMedications',
      nodeId: NodeIds.healthOtherEmphysemaInsuredNumberOfMedications,
    },
    {
      path: 'healthOtherEmphysemaInsuredNumberOfMedicationsDetails',
      nodeId: NodeIds.healthOtherEmphysemaInsuredNumberOfMedicationsDetails,
    },
    { path: 'isHealthOtherEmphysemaInsuredShortOfBreath', nodeId: NodeIds.isHealthOtherEmphysemaInsuredShortOfBreath },
    { path: 'healthOtherEmphysemaLimitations', nodeId: NodeIds.healthOtherEmphysemaLimitations },
    { path: 'healthOtherEmphysemaLimitationsDetails', nodeId: NodeIds.healthOtherEmphysemaLimitationsDetails },
    { path: 'isHealthOtherEmphysemaInsuredTobaccoUser', nodeId: NodeIds.isHealthOtherEmphysemaInsuredTobaccoUser },
    { path: 'healthOtherBronchitisFirstAttackDate', nodeId: NodeIds.healthOtherBronchitisFirstAttackDate },
    { path: 'healthOtherBronchitisAttacksFrequency', nodeId: NodeIds.healthOtherBronchitisAttacksFrequency },
    { path: 'healthOtherBronchitisLastAttackDate', nodeId: NodeIds.healthOtherBronchitisLastAttackDate },
    { path: 'healthOtherBronchitisSeverity', nodeId: NodeIds.healthOtherBronchitisSeverity },
    { path: 'healthOtherBronchitisPhysicianCount', nodeId: NodeIds.healthOtherBronchitisPhysicianCount },
    { path: 'healthOtherBronchitisPhysicianName1', nodeId: 'health-other-bronchitis-physician-name1' },
    { path: 'healthOtherBronchitisPhysicianAddress1', nodeId: 'health-other-bronchitis-physician-address1' },
    { path: 'healthOtherBronchitisPhysicianName2', nodeId: 'health-other-bronchitis-physician-name2' },
    { path: 'healthOtherBronchitisPhysicianAddress2', nodeId: 'health-other-bronchitis-physician-address2' },
    { path: 'healthOtherBronchitisPhysicianName3', nodeId: 'health-other-bronchitis-physician-name3' },
    { path: 'healthOtherBronchitisPhysicianAddress3', nodeId: 'health-other-bronchitis-physician-address3' },
    { path: 'healthOtherBronchitisPhysicianName4', nodeId: 'health-other-bronchitis-physician-name4' },
    { path: 'healthOtherBronchitisPhysicianAddress4', nodeId: 'health-other-bronchitis-physician-address4' },
    { path: 'healthOtherBronchitisPhysicianName5', nodeId: 'health-other-bronchitis-physician-name5' },
    { path: 'healthOtherBronchitisPhysicianAddress5', nodeId: 'health-other-bronchitis-physician-address5' },
    { path: 'healthOtherBronchitisPhysicianName6', nodeId: 'health-other-bronchitis-physician-name6' },
    { path: 'healthOtherBronchitisPhysicianAddress6', nodeId: 'health-other-bronchitis-physician-address6' },
    { path: 'healthOtherBronchitisPhysicianName7', nodeId: 'health-other-bronchitis-physician-name7' },
    { path: 'healthOtherBronchitisPhysicianAddress7', nodeId: 'health-other-bronchitis-physician-address7' },
    { path: 'healthOtherBronchitisPhysicianName8', nodeId: 'health-other-bronchitis-physician-name8' },
    { path: 'healthOtherBronchitisPhysicianAddress8', nodeId: 'health-other-bronchitis-physician-address8' },
    { path: 'healthOtherBronchitisPhysicianName9', nodeId: 'health-other-bronchitis-physician-name9' },
    { path: 'healthOtherBronchitisPhysicianAddress9', nodeId: 'health-other-bronchitis-physician-address9' },
    { path: 'healthOtherBronchitisPhysicianName10', nodeId: 'health-other-bronchitis-physician-name10' },
    { path: 'healthOtherBronchitisPhysicianAddress10', nodeId: 'health-other-bronchitis-physician-address10' },
    {
      path: 'hasHealthOtherBronchitisInsuredHospitalized',
      nodeId: NodeIds.hasHealthOtherBronchitisInsuredHospitalized,
    },
    {
      path: 'healthOtherBronchitisInsuredHospitalizedDate',
      nodeId: NodeIds.healthOtherBronchitisInsuredHospitalizedDate,
    },
    { path: 'healthOtherBronchitisInsuredHospitalName', nodeId: NodeIds.healthOtherBronchitisInsuredHospitalName },
    {
      path: 'healthOtherBronchitisInsuredHospitalAddress',
      nodeId: NodeIds.healthOtherBronchitisInsuredHospitalAddress,
    },
    {
      path: 'hasHealthOtherBronchitisInsuredEmergencyVisit',
      nodeId: NodeIds.hasHealthOtherBronchitisInsuredEmergencyVisit,
    },
    {
      path: 'healthOtherBronchitisInsuredEmergencyVisitDate',
      nodeId: NodeIds.healthOtherBronchitisInsuredEmergencyVisitDate,
    },
    { path: 'healthOtherBronchitisInsuredEmergencyName', nodeId: NodeIds.healthOtherBronchitisInsuredEmergencyName },
    {
      path: 'healthOtherBronchitisInsuredEmergencyAddress',
      nodeId: NodeIds.healthOtherBronchitisInsuredEmergencyAddress,
    },
    {
      path: 'healthOtherBronchitisInsuredNumberOfMedications',
      nodeId: NodeIds.healthOtherBronchitisInsuredNumberOfMedications,
    },
    {
      path: 'healthOtherBronchitisNumberOfMedicationsDetails',
      nodeId: NodeIds.healthOtherBronchitisNumberOfMedicationsDetails,
    },
    {
      path: 'isHealthOtherBronchitisInsuredShortOfBreath',
      nodeId: NodeIds.isHealthOtherBronchitisInsuredShortOfBreath,
    },
    { path: 'healthOtherBronchitisLimitations', nodeId: NodeIds.healthOtherBronchitisLimitations },
    { path: 'healthOtherBronchitisLimitationsDetails', nodeId: NodeIds.healthOtherBronchitisLimitationsDetails },
    { path: 'isHealthOtherBronchitisInsuredTobaccoUser', nodeId: NodeIds.isHealthOtherBronchitisInsuredTobaccoUser },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const secondOwnerIdentityVerification: AnswerPathTreeNode = {
  path: 'secondOwnerIdentityVerification',
  children: [
    { path: 'identityVerification', nodeId: NodeIds.secondOwnerIdentityVerification },
    { path: 'documentType', nodeId: NodeIds.secondOwnerIdentityVerificationDocumentType },
    { path: 'documentTypeOther', nodeId: NodeIds.secondOwnerIdentityVerificationDocumentTypeOther },
    { path: 'documentLastName', nodeId: NodeIds.secondOwnerIdentityVerificationDocumentLastName },
    { path: 'documentFirstName', nodeId: NodeIds.secondOwnerIdentityVerificationDocumentFirstName },
    { path: 'documentNumber', nodeId: NodeIds.secondOwnerIdentityVerificationDocumentNumber },
    { path: 'documentJurisdiction', nodeId: NodeIds.secondOwnerIdentityVerificationDocumentJurisdiction },
    { path: 'documentExpiryDate', nodeId: NodeIds.secondOwnerIdentityVerificationDocumentExpiryDate },
    { path: 'dualProcessReason', nodeId: NodeIds.secondOwnerIdentityVerificationDualProcessReason },
    { path: 'dualProcessReasonOther', nodeId: NodeIds.secondOwnerIdentityVerificationDualProcessReasonOther },
    {
      path: 'dualProcessNameAddressDocumentType',
      nodeId: NodeIds.secondOwnerIdentityVerificationDualProcessNameAddressDocumentType,
    },
    {
      path: 'dualProcessNameAddressDocumentIssuingAuthority',
      nodeId: NodeIds.secondOwnerIdentityVerificationDualProcessNameAddressDocumentIssuingAuthority,
    },
    {
      path: 'dualProcessNameAddressDocumentIssuingJurisdiction',
      nodeId: NodeIds.secondOwnerIdentityVerificationDualProcessNameAddressDocumentIssuingJurisdiction,
    },
    {
      path: 'dualProcessNameAddressDocumentReferenceNumber',
      nodeId: NodeIds.secondOwnerIdentityVerificationDualProcessNameAddressDocumentReferenceNumber,
    },
    { path: 'dualProcessNameAddressDate', nodeId: NodeIds.secondOwnerIdentityVerificationDualProcessNameAddressDate },
    {
      path: 'dualProcessNameBirthDateDocumentType',
      nodeId: NodeIds.secondOwnerIdentityVerificationDualProcessNameBirthDateDocumentType,
    },
    {
      path: 'dualProcessNameBirthDateIssuingAuthority',
      nodeId: NodeIds.secondOwnerIdentityVerificationDualProcessNameBirthDateIssuingAuthority,
    },
    {
      path: 'dualProcessNameBirthDateIssuingJurisdiction',
      nodeId: NodeIds.secondOwnerIdentityVerificationDualProcessNameBirthDateIssuingJurisdiction,
    },
    {
      path: 'dualProcessNameBirthDateReferenceNumber',
      nodeId: NodeIds.secondOwnerIdentityVerificationDualProcessNameBirthDateReferenceNumber,
    },
    {
      path: 'dualProcessNameBirthDateDate',
      nodeId: NodeIds.secondOwnerIdentityVerificationDualProcessNameBirthDateDate,
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const advisor: AnswerPathTreeNode = {
  path: 'advisor',
  children: [
    { path: 'code', nodeId: NodeIds.advisorCode },
    { path: 'network', nodeId: NodeIds.advisorNetwork },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const criminalHistory: AnswerPathTreeNode = {
  path: 'criminal history',
  children: [
    { path: 'arrested', nodeId: NodeIds.insuredArrested },
    { path: 'arrestedState', nodeId: NodeIds.insuredArrestedState },
    { path: 'arrestedDate', nodeId: NodeIds.insuredArrestedDate },
    { path: 'arrestedDetails', nodeId: NodeIds.insuredArrestedDetails },
    { path: 'awaitingTrial', nodeId: NodeIds.insuredAwaitingTrial },
    { path: 'convicted', nodeId: NodeIds.insuredConvicted },
    { path: 'convictedDate', nodeId: NodeIds.insuredConvictedDate },
    { path: 'convictedSentence', nodeId: NodeIds.insuredConvictedSentence },
    { path: 'parole', nodeId: NodeIds.insuredParole },
    { path: 'dateCompletedProbation', nodeId: NodeIds.insuredDateCompletedProbation },
    { path: 'drugAlcohol', nodeId: NodeIds.arrestedDrugAlcohol },
    { path: 'drugAlcoholDetails', nodeId: NodeIds.arrestedDrugAlcoholDetails },
    { path: 'criminalHistoryDetails', nodeId: NodeIds.insuredCriminalHistoryDetails },
    {
      path: 'lifestyleInsuredCriminalHistory',
      children: [
        { path: 'hasCriminalHistory', nodeId: NodeIds.lifestyleInsuredHasCriminalHistory },
        { path: 'hasCriminalHistory1', nodeId: NodeIds.lifestyleInsuredHasCriminalHistory1 },
        { path: 'choices', nodeId: NodeIds.lifestyleInsuredCriminalHistoryChoices },
      ],
    },
  ],
};

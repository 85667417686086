import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const backNeck: AnswerPathTreeNode = {
  path: 'backNeck',
  children: [
    { path: 'hasCondition', nodeId: NodeIds.healthInsuredBackNeckHasCondition },
    { path: 'conditions', nodeId: NodeIds.healthInsuredBackNeckConditions },
    { path: 'conditionsOther', nodeId: NodeIds.healthInsuredBackNeckConditionsOther },
  ],
};

import { SelectOption } from '@breathelife/questionnaire-engine';

export const provinceOptions: SelectOption[] = [
  { id: 'AB', text: { en: 'Alberta', fr: 'Alberta' } },
  { id: 'BC', text: { en: 'British Columbia', fr: 'Colombie-Britannique' } },
  { id: 'MB', text: { en: 'Manitoba', fr: 'Manitoba' } },
  { id: 'NB', text: { en: 'New Brunswick', fr: 'Nouveau-Brunswick' } },
  { id: 'NL', text: { en: 'Newfoundland and Labrador', fr: 'Terre-Neuve-et-Labrador' } },
  { id: 'NS', text: { en: 'Nova Scotia', fr: 'Nouvelle-Écosse' } },
  { id: 'NU', text: { en: 'Nunavut', fr: 'Nunavut' } },
  { id: 'NT', text: { en: 'Northwest Territories', fr: 'Territoires du Nord-Ouest' } },
  { id: 'ON', text: { en: 'Ontario', fr: 'Ontario' } },
  { id: 'PE', text: { en: 'Prince Edward Island', fr: 'Île-du-Prince-Édouard' } },
  { id: 'QC', text: { en: 'Quebec', fr: 'Québec' } },
  { id: 'SK', text: { en: 'Saskatchewan', fr: 'Saskatchewan' } },
  { id: 'YT', text: { en: 'Yukon', fr: 'Yukon' } },
];

export const usStates = [
  { short: 'AL', name: 'Alabama' },
  { short: 'AK', name: 'Alaska' },
  { short: 'AZ', name: 'Arizona' },
  { short: 'AR', name: 'Arkansas' },
  { short: 'CA', name: 'California' },
  { short: 'CO', name: 'Colorado' },
  { short: 'CT', name: 'Connecticut' },
  { short: 'DC', name: 'District of Columbia', alt: ['Washington DC', 'Washington D.C.'] },
  { short: 'DE', name: 'Delaware' },
  { short: 'FL', name: 'Florida' },
  { short: 'GA', name: 'Georgia' },
  { short: 'HI', name: 'Hawaii' },
  { short: 'ID', name: 'Idaho' },
  { short: 'IL', name: 'Illinois' },
  { short: 'IN', name: 'Indiana' },
  { short: 'IA', name: 'Iowa' },
  { short: 'KS', name: 'Kansas' },
  { short: 'KY', name: 'Kentucky' },
  { short: 'LA', name: 'Louisiana' },
  { short: 'ME', name: 'Maine' },
  { short: 'MD', name: 'Maryland' },
  { short: 'MA', name: 'Massachusetts' },
  { short: 'MI', name: 'Michigan' },
  { short: 'MN', name: 'Minnesota' },
  { short: 'MS', name: 'Mississippi' },
  { short: 'MO', name: 'Missouri' },
  { short: 'MT', name: 'Montana' },
  { short: 'NE', name: 'Nebraska' },
  { short: 'NV', name: 'Nevada' },
  { short: 'NH', name: 'New Hampshire' },
  { short: 'NJ', name: 'New Jersey' },
  { short: 'NM', name: 'New Mexico' },
  { short: 'NY', name: 'New York' },
  { short: 'NC', name: 'North Carolina' },
  { short: 'ND', name: 'North Dakota' },
  { short: 'OH', name: 'Ohio' },
  { short: 'OK', name: 'Oklahoma' },
  { short: 'OR', name: 'Oregon' },
  { short: 'PA', name: 'Pennsylvania' },
  { short: 'RI', name: 'Rhode Island' },
  { short: 'SC', name: 'South Carolina' },
  { short: 'SD', name: 'South Dakota' },
  { short: 'TN', name: 'Tennessee' },
  { short: 'TX', name: 'Texas' },
  { short: 'UT', name: 'Utah' },
  { short: 'VT', name: 'Vermont' },
  { short: 'VA', name: 'Virginia' },
  { short: 'WA', name: 'Washington' },
  { short: 'WV', name: 'West Virginia' },
  { short: 'WI', name: 'Wisconsin' },
  { short: 'WY', name: 'Wyoming' },
  { short: 'AS', name: 'American Samoa' },
  { short: 'GU', name: 'Guam' },
  { short: 'MP', name: 'Northern Mariana Islands' },
  { short: 'PR', name: 'Puerto Rico' },
  { short: 'UM', name: 'United States Minor Outlying Islands' },
  { short: 'VI', name: 'Virgin Islands' },
];

export const usStateOptions = usStates.map(({ short }) => ({ id: short, text: { en: short, fr: short } }));

const countries = [
  { short: 'AF', en: 'Afghanistan', fr: 'Afghanistan' },
  { short: 'AX', en: '\u00c5land Islands', fr: '\u00eeles d\u2019\u00c5land' },
  { short: 'AL', en: 'Albania', fr: 'Albanie' },
  { short: 'DZ', en: 'Algeria', fr: 'Alg\u00e9rie' },
  { short: 'AS', en: 'American Samoa', fr: 'Samoa am\u00e9ricaines' },
  { short: 'AD', en: 'Andorra', fr: 'Andorre' },
  { short: 'AO', en: 'Angola', fr: 'Angola' },
  { short: 'AI', en: 'Anguilla', fr: 'Anguilla' },
  { short: 'AQ', en: 'Antarctica', fr: 'Antarctique' },
  { short: 'AG', en: 'Antigua and Barbuda', fr: 'Antigua-et-Barbuda' },
  { short: 'AR', en: 'Argentina', fr: 'Argentine' },
  { short: 'AM', en: 'Armenia', fr: 'Arm\u00e9nie' },
  { short: 'AW', en: 'Aruba', fr: 'Aruba' },
  { short: 'AU', en: 'Australia', fr: 'Australie' },
  { short: 'AT', en: 'Austria', fr: 'Autriche' },
  { short: 'AZ', en: 'Azerbaijan', fr: 'Azerba\u00efdjan' },
  { short: 'BS', en: 'Bahamas', fr: 'Bahamas' },
  { short: 'BH', en: 'Bahrain', fr: 'Bahre\u00efn' },
  { short: 'BD', en: 'Bangladesh', fr: 'Bangladesh' },
  { short: 'BB', en: 'Barbados', fr: 'Barbade' },
  { short: 'BY', en: 'Belarus', fr: 'B\u00e9larus' },
  { short: 'BE', en: 'Belgium', fr: 'Belgique' },
  { short: 'BZ', en: 'Belize', fr: 'Belize' },
  { short: 'BJ', en: 'Benin', fr: 'B\u00e9nin' },
  { short: 'BM', en: 'Bermuda', fr: 'Bermudes' },
  { short: 'BT', en: 'Bhutan', fr: 'Bhoutan' },
  { short: 'BO', en: 'Bolivia, Plurinational State of', fr: 'Bolivie' },
  { short: 'BQ', en: 'Bonaire, Sint Eustatius and Saba', fr: 'Pays-Bas carib\u00e9ens' },
  { short: 'BA', en: 'Bosnia and Herzegovina', fr: 'Bosnie-Herz\u00e9govine' },
  { short: 'BW', en: 'Botswana', fr: 'Botswana' },
  { short: 'BR', en: 'Brazil', fr: 'Br\u00e9sil' },
  {
    short: 'IO',
    en: 'British Indian Ocean Territory',
    fr: 'territoire britannique de l\u2019oc\u00e9an Indien',
  },
  { short: 'BN', en: 'Brunei Darussalam', fr: 'Brunei' },
  { short: 'BG', en: 'Bulgaria', fr: 'Bulgarie' },
  { short: 'BF', en: 'Burkina Faso', fr: 'Burkina Faso' },
  { short: 'BI', en: 'Burundi', fr: 'Burundi' },
  { short: 'KH', en: 'Cambodia', fr: 'Cambodge' },
  { short: 'CM', en: 'Cameroon', fr: 'Cameroun' },
  { short: 'CA', en: 'Canada', fr: 'Canada' },
  { short: 'CV', en: 'Cape Verde', fr: 'Cap-Vert' },
  { short: 'KY', en: 'Cayman Islands', fr: '\u00celes Ca\u00efmans' },
  { short: 'CF', en: 'Central African Republic', fr: 'R\u00e9publique centrafricaine' },
  { short: 'TD', en: 'Chad', fr: 'Tchad' },
  { short: 'CL', en: 'Chile', fr: 'Chili' },
  { short: 'CN', en: 'China', fr: 'Chine' },
  { short: 'CX', en: 'Christmas Island', fr: '\u00eele Christmas' },
  { short: 'CC', en: 'Cocos (Keeling) Islands', fr: '\u00eeles Cocos (Keeling)' },
  { short: 'CO', en: 'Colombia', fr: 'Colombie' },
  { short: 'KM', en: 'Comoros', fr: 'Comores' },
  { short: 'CG', en: 'Congo', fr: 'Congo-Brazzaville' },
  { short: 'CD', en: 'Congo, the Democratic Republic of the', fr: 'Congo-Kinshasa' },
  { short: 'CK', en: 'Cook Islands', fr: '\u00eeles Cook' },
  { short: 'CR', en: 'Costa Rica', fr: 'Costa Rica' },
  { short: 'CI', en: "C\u00f4te d'Ivoire", fr: 'C\u00f4te d\u2019Ivoire' },
  { short: 'HR', en: 'Croatia', fr: 'Croatie' },
  { short: 'CU', en: 'Cuba', fr: 'Cuba' },
  { short: 'CW', en: 'Cura\u00e7ao', fr: 'Cura\u00e7ao' },
  { short: 'CY', en: 'Cyprus', fr: 'Chypre' },
  { short: 'CZ', en: 'Czech Republic', fr: 'Tch\u00e9quie' },
  { short: 'DK', en: 'Denmark', fr: 'Danemark' },
  { short: 'DJ', en: 'Djibouti', fr: 'Djibouti' },
  { short: 'DM', en: 'Dominica', fr: 'Dominique' },
  { short: 'DO', en: 'Dominican Republic', fr: 'R\u00e9publique dominicaine' },
  { short: 'EC', en: 'Ecuador', fr: '\u00c9quateur' },
  { short: 'EG', en: 'Egypt', fr: '\u00c9gypte' },
  { short: 'SV', en: 'El Salvador', fr: 'Salvador' },
  { short: 'GQ', en: 'Equatorial Guinea', fr: 'Guin\u00e9e \u00e9quatoriale' },
  { short: 'ER', en: 'Eritrea', fr: '\u00c9rythr\u00e9e' },
  { short: 'EE', en: 'Estonia', fr: 'Estonie' },
  { short: 'ET', en: 'Ethiopia', fr: '\u00c9thiopie' },
  { short: 'FK', en: 'Falkland Islands (Malvinas)', fr: '\u00eeles Malouines' },
  { short: 'FO', en: 'Faroe Islands', fr: '\u00eeles F\u00e9ro\u00e9' },
  { short: 'FJ', en: 'Fiji', fr: 'Fshortji' },
  { short: 'FI', en: 'Finland', fr: 'Finlande' },
  { short: 'FR', en: 'France', fr: 'France' },
  { short: 'GF', en: 'French Guiana', fr: 'Guyane fran\u00e7aise' },
  { short: 'PF', en: 'French Polynesia', fr: 'Polyn\u00e9sie fran\u00e7aise' },
  { short: 'TF', en: 'French Southern Territories', fr: 'Terres australes fran\u00e7aises' },
  { short: 'GA', en: 'Gabon', fr: 'Gabon' },
  { short: 'GM', en: 'Gambia', fr: 'Gambie' },
  { short: 'GE', en: 'Georgia', fr: 'G\u00e9orgie' },
  { short: 'DE', en: 'Germany', fr: 'Allemagne' },
  { short: 'GH', en: 'Ghana', fr: 'Ghana' },
  { short: 'GI', en: 'Gibraltar', fr: 'Gibraltar' },
  { short: 'GR', en: 'Greece', fr: 'Gr\u00e8ce' },
  { short: 'GL', en: 'Greenland', fr: 'Groenland' },
  { short: 'GD', en: 'Grenada', fr: 'Grenade' },
  { short: 'GP', en: 'Guadeloupe', fr: 'Guadeloupe' },
  { short: 'GU', en: 'Guam', fr: 'Guam' },
  { short: 'GT', en: 'Guatemala', fr: 'Guatemala' },
  { short: 'GG', en: 'Guernsey', fr: 'Guernesey' },
  { short: 'GN', en: 'Guinea', fr: 'Guin\u00e9e' },
  { short: 'GW', en: 'Guinea-Bissau', fr: 'Guin\u00e9e-Bissau' },
  { short: 'GY', en: 'Guyana', fr: 'Guyana' },
  { short: 'HT', en: 'Haiti', fr: 'Ha\u00efti' },
  { short: 'VA', en: 'Holy See (Vatican City State)', fr: 'Cit\u00e9 du Vatican' },
  { short: 'HN', en: 'Honduras', fr: 'Honduras' },
  { short: 'HK', en: 'Hong Kong', fr: 'R.A.S. chinoise de Hong Kong' },
  { short: 'HU', en: 'Hungary', fr: 'Hongrie' },
  { short: 'IS', en: 'Iceland', fr: 'Islande' },
  { short: 'IN', en: 'India', fr: 'Inde' },
  { short: 'ID', en: 'Indonesia', fr: 'Indon\u00e9sie' },
  { short: 'IR', en: 'Iran', fr: 'Iran' },
  { short: 'IQ', en: 'Iraq', fr: 'Irak' },
  { short: 'IE', en: 'Ireland', fr: 'Irlande' },
  { short: 'IM', en: 'Isle of Man', fr: '\u00eele de Man' },
  { short: 'IL', en: 'Israel', fr: 'Isra\u00ebl' },
  { short: 'IT', en: 'Italy', fr: 'Italie' },
  { short: 'JM', en: 'Jamaica', fr: 'Jama\u00efque' },
  { short: 'JP', en: 'Japan', fr: 'Japon' },
  { short: 'JE', en: 'Jersey', fr: 'Jersey' },
  { short: 'JO', en: 'Jordan', fr: 'Jordanie' },
  { short: 'KZ', en: 'Kazakhstan', fr: 'Kazakhstan' },
  { short: 'KE', en: 'Kenya', fr: 'Kenya' },
  { short: 'KI', en: 'Kiribati', fr: 'Kiribati' },
  { short: 'KP', en: "Korea, Democratic People's Republic of", fr: 'Cor\u00e9e du Nord' },
  { short: 'KR', en: 'Korea, Republic of', fr: 'Cor\u00e9e du Sud' },
  { short: 'KW', en: 'Kuwait', fr: 'Kowe\u00eft' },
  { short: 'KG', en: 'Kyrgyzstan', fr: 'Kirghizistan' },
  { short: 'LA', en: 'Laos', fr: 'Lao' },
  { short: 'LV', en: 'Latvia', fr: 'Lettonie' },
  { short: 'LB', en: 'Lebanon', fr: 'Liban' },
  { short: 'LS', en: 'Lesotho', fr: 'Lesotho' },
  { short: 'LR', en: 'Liberia', fr: 'Lib\u00e9ria' },
  { short: 'LY', en: 'Libya', fr: 'Libye' },
  { short: 'LI', en: 'Liechtenstein', fr: 'Liechtenstein' },
  { short: 'LT', en: 'Lithuania', fr: 'Lituanie' },
  { short: 'LU', en: 'Luxembourg', fr: 'Luxembourg' },
  { short: 'MO', en: 'Macao', fr: 'R.A.S. chinoise de Macao' },
  { short: 'MK', en: 'Macedonia, the Former Yugoslav Republic of', fr: 'Mac\u00e9doine' },
  { short: 'MG', en: 'Madagascar', fr: 'Madagascar' },
  { short: 'MW', en: 'Malawi', fr: 'Malawi' },
  { short: 'MY', en: 'Malaysia', fr: 'Malaisie' },
  { short: 'MV', en: 'Maldives', fr: 'Maldives' },
  { short: 'ML', en: 'Mali', fr: 'Mali' },
  { short: 'MT', en: 'Malta', fr: 'Malte' },
  { short: 'MH', en: 'Marshall Islands', fr: '\u00celes Marshall' },
  { short: 'MQ', en: 'Martinique', fr: 'Martinique' },
  { short: 'MR', en: 'Mauritania', fr: 'Mauritanie' },
  { short: 'MU', en: 'Mauritius', fr: 'Maurice' },
  { short: 'YT', en: 'Mayotte', fr: 'Mayotte' },
  { short: 'MX', en: 'Mexico', fr: 'Mexique' },
  { short: 'FM', en: 'Micronesia, Federated States of', fr: 'Micron\u00e9sie' },
  { short: 'MD', en: 'Moldova, Republic of', fr: 'Moldavie' },
  { short: 'MC', en: 'Monaco', fr: 'Monaco' },
  { short: 'MN', en: 'Mongolia', fr: 'Mongolie' },
  { short: 'ME', en: 'Montenegro', fr: 'Mont\u00e9n\u00e9gro' },
  { short: 'MS', en: 'Montserrat', fr: 'Montserrat' },
  { short: 'MA', en: 'Morocco', fr: 'Maroc' },
  { short: 'MZ', en: 'Mozambique', fr: 'Mozambique' },
  { short: 'MM', en: 'Myanmar', fr: 'Myanmar' },
  { short: 'NA', en: 'Namibia', fr: 'Namibie' },
  { short: 'NR', en: 'Nauru', fr: 'Nauru' },
  { short: 'NP', en: 'Nepal', fr: 'N\u00e9pal' },
  { short: 'NL', en: 'Netherlands', fr: 'Pays-Bas' },
  { short: 'NC', en: 'New Caledonia', fr: 'Nouvelle-Cal\u00e9donie' },
  { short: 'NZ', en: 'New Zealand', fr: 'Nouvelle-Z\u00e9lande' },
  { short: 'NI', en: 'Nicaragua', fr: 'Nicaragua' },
  { short: 'NE', en: 'Niger', fr: 'Niger' },
  { short: 'NG', en: 'Nigeria', fr: 'Nig\u00e9ria' },
  { short: 'NU', en: 'Niue', fr: 'Niue' },
  { short: 'NF', en: 'Norfolk Island', fr: '\u00eele Norfolk' },
  { short: 'MP', en: 'Northern Mariana Islands', fr: 'Mariannes du Nord' },
  { short: 'NO', en: 'Norway', fr: 'Norv\u00e8ge' },
  { short: 'OM', en: 'Oman', fr: 'Oman' },
  { short: 'PK', en: 'Pakistan', fr: 'Pakistan' },
  { short: 'PW', en: 'Palau', fr: 'Palaos' },
  { short: 'PS', en: 'Palestine, State of', fr: 'Territoires palestiniens' },
  { short: 'PA', en: 'Panama', fr: 'Panama' },
  { short: 'PG', en: 'Papua New Guinea', fr: 'Papouasie-Nouvelle-Guin\u00e9e' },
  { short: 'PY', en: 'Paraguay', fr: 'Paraguay' },
  { short: 'PE', en: 'Peru', fr: 'P\u00e9rou' },
  { short: 'PH', en: 'Philippines', fr: 'Philippines' },
  { short: 'PN', en: 'Pitcairn', fr: '\u00eeles Pitcairn' },
  { short: 'PL', en: 'Poland', fr: 'Pologne' },
  { short: 'PT', en: 'Portugal', fr: 'Portugal' },
  { short: 'PR', en: 'Puerto Rico', fr: 'Porto Rico' },
  { short: 'QA', en: 'Qatar', fr: 'Qatar' },
  { short: 'RE', en: 'R\u00e9union', fr: 'la R\u00e9union' },
  { short: 'RO', en: 'Romania', fr: 'Roumanie' },
  { short: 'RU', en: 'Russian Federation', fr: 'Russie' },
  { short: 'RW', en: 'Rwanda', fr: 'Rwanda' },
  { short: 'BL', en: 'Saint Barth\u00e9lemy', fr: 'Saint-Barth\u00e9lemy' },
  { short: 'SH', en: 'Saint Helena, Ascension and Tristan da Cunha', fr: 'Sainte-H\u00e9l\u00e8ne' },
  { short: 'KN', en: 'Saint Kitts and Nevis', fr: 'Saint-Christophe-et-Ni\u00e9v\u00e8s' },
  { short: 'LC', en: 'Saint Lucia', fr: 'Sainte-Lucie' },
  { short: 'MF', en: 'Saint Martin (French part)', fr: 'Saint-Martin (France)' },
  { short: 'PM', en: 'Saint Pierre and Miquelon', fr: 'Saint-Pierre-et-Miquelon' },
  { short: 'VC', en: 'Saint Vincent and the Grenadines', fr: 'Saint-Vincent-et-les Grenadines' },
  { short: 'WS', en: 'Samoa', fr: 'Samoa' },
  { short: 'SM', en: 'San Marino', fr: 'Saint-Marin' },
  { short: 'ST', en: 'Sao Tome and Principe', fr: 'Sao Tom\u00e9-et-Principe' },
  { short: 'SA', en: 'Saudi Arabia', fr: 'Arabie saoudite' },
  { short: 'SN', en: 'Senegal', fr: 'S\u00e9n\u00e9gal' },
  { short: 'RS', en: 'Serbia', fr: 'Serbie' },
  { short: 'SC', en: 'Seychelles', fr: 'Seychelles' },
  { short: 'SL', en: 'Sierra Leone', fr: 'Sierra Leone' },
  { short: 'SG', en: 'Singapore', fr: 'Singapour' },
  { short: 'SX', en: 'Sint Maarten (Dutch part)', fr: 'Saint-Martin (Pays-Bas)' },
  { short: 'SK', en: 'Slovakia', fr: 'Slovaquie' },
  { short: 'SI', en: 'Slovenia', fr: 'Slov\u00e9nie' },
  { short: 'SB', en: 'Solomon Islands', fr: '\u00celes Salomon' },
  { short: 'SO', en: 'Somalia', fr: 'Somalie' },
  { short: 'ZA', en: 'South Africa', fr: 'Afrique du Sud' },
  {
    short: 'GS',
    en: 'South Georgia and the South Sandwich Islands',
    fr: 'G\u00e9orgie du Sud et \u00eeles Sandwich du Sud',
  },
  { short: 'SS', en: 'South Sudan', fr: 'Soudan du Sud' },
  { short: 'ES', en: 'Spain', fr: 'Espagne' },
  { short: 'LK', en: 'Sri Lanka', fr: 'Sri Lanka' },
  { short: 'SD', en: 'Sudan', fr: 'Soudan' },
  { short: 'SR', en: 'Suriname', fr: 'Suriname' },
  { short: 'SJ', en: 'Svalbard and Jan Mayen', fr: 'Svalbard et Jan Mayen' },
  { short: 'SZ', en: 'Swaziland', fr: 'Eswatini' },
  { short: 'SE', en: 'Sweden', fr: 'Su\u00e8de' },
  { short: 'CH', en: 'Switzerland', fr: 'Suisse' },
  { short: 'SY', en: 'Syrian Arab Republic', fr: 'Syrie' },
  { short: 'TW', en: 'Taiwan, Province of China', fr: 'Ta\u00efwan' },
  { short: 'TJ', en: 'Tajikistan', fr: 'Tadjikistan' },
  { short: 'TZ', en: 'Tanzania, United Republic of', fr: 'Tanzanie' },
  { short: 'TH', en: 'Thailand', fr: 'Tha\u00eflande' },
  { short: 'TL', en: 'Timor-Leste', fr: 'Timor-Leste' },
  { short: 'TG', en: 'Togo', fr: 'Togo' },
  { short: 'TK', en: 'Tokelau', fr: 'Tokelau' },
  { short: 'TO', en: 'Tonga', fr: 'Tonga' },
  { short: 'TT', en: 'Trinshortad and Tobago', fr: 'Trinit\u00e9-et-Tobago' },
  { short: 'TN', en: 'Tunisia', fr: 'Tunisie' },
  { short: 'TR', en: 'Turkey', fr: 'Turquie' },
  { short: 'TM', en: 'Turkmenistan', fr: 'Turkm\u00e9nistan' },
  { short: 'TC', en: 'Turks and Caicos Islands', fr: '\u00celes Turques-et-Ca\u00efques' },
  { short: 'TV', en: 'Tuvalu', fr: 'Tuvalu' },
  { short: 'UG', en: 'Uganda', fr: 'Ouganda' },
  { short: 'UA', en: 'Ukraine', fr: 'Ukraine' },
  { short: 'AE', en: 'United Arab Emirates', fr: '\u00c9mirats arabes unis' },
  { short: 'GB', en: 'United Kingdom', fr: 'Royaume-Uni' },
  { short: 'US', en: 'United States', fr: '\u00c9tats-Unis' },
  {
    short: 'UM',
    en: 'United States Minor Outlying Islands',
    fr: '\u00eeles mineures \u00e9loign\u00e9es des \u00c9tats-Unis',
  },
  { short: 'UY', en: 'Uruguay', fr: 'Uruguay' },
  { short: 'UZ', en: 'Uzbekistan', fr: 'Ouzb\u00e9kistan' },
  { short: 'VU', en: 'Vanuatu', fr: 'Vanuatu' },
  { short: 'VE', en: 'Venezuela, Bolivarian Republic of', fr: 'Venezuela' },
  { short: 'VN', en: 'Viet Nam', fr: 'Vietnam' },
  { short: 'VG', en: 'Virgin Islands, British', fr: '\u00eeles Vierges britanniques' },
  { short: 'VI', en: 'Virgin Islands, U.S.', fr: '\u00eeles Vierges am\u00e9ricaines' },
  { short: 'WF', en: 'Wallis and Futuna', fr: 'Wallis-et-Futuna' },
  { short: 'EH', en: 'Western Sahara', fr: 'Sahara occshortental' },
  { short: 'YE', en: 'Yemen', fr: 'Y\u00e9men' },
  { short: 'ZM', en: 'Zambia', fr: 'Zambie' },
  { short: 'ZW', en: 'Zimbabwe', fr: 'Zimbabwe' },
];

export const countryOptions: SelectOption[] = countries.map(({ short, en, fr }) => ({
  id: short,
  text: { en, fr },
}));

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const coverageDuration: AnswerPathTreeNode = {
  path: 'coverageDuration',
  children: [
    { path: 'type', nodeId: NodeIds.insuredCoverageDurationType },
    { path: 'years', nodeId: NodeIds.insuredCoverageDuration },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { arnoldsNeuralgia } from './arnoldsNeuralgia';
import { arthritis } from './arthritis';
import { backNeck } from './backNeck';
import { backNeckOther } from './backNeckOther';
import { bursitis } from './bursitis';
import { carpalTunnel } from './carpalTunnel';
import { discDegeneration } from './discDegeneration';
import { epicondylitis } from './epicondylitis';
import { fibromyalgia } from './fibromyalgia';
import { fracture } from './fracture';
import { herniatedDisc } from './herniatedDisc';
import { ligamentTearMeniscus } from './ligamentTearMeniscus';
import { lumbago } from './lumbago';
import { muscularDystrophy } from './muscularDystrophy';
import { musculoskeletalDetails } from './musculoskeletalDetails';
import { musculoskeletalFracture } from './musculoskeletalFracture';
import { musculoskeletalOsteoarthritis } from './musculoskeletalOsteoarthritis';
import { musculoskeletalOther } from './musculoskeletalOther';
import { musculoskeletalSprain } from './musculoskeletalSprain';
import { osteoarthritis } from './osteoarthritis';
import { osteoporosis } from './osteoporosis';
import { osteoporosisOsteopenia } from './osteoporosisOsteopenia';
import { replacementJoint } from './replacementJoint';
import { sciaticNerve } from './sciaticNerve';
import { scoliosisLordosis } from './scoliosisLordosis';
import { spondylitisAnkylosing } from './spondylitisAnkylosing';
import { sprain } from './sprain';
import { tendonitis } from './tendonitis';
import { torticollis } from './torticollis';
import { whiplash } from './whiplash';

export const musculoskeletal: AnswerPathTreeNode = {
  path: 'musculoskeletal',
  children: [
    backNeck,
    spondylitisAnkylosing,
    osteoarthritis,
    osteoporosis,
    whiplash,
    musculoskeletalSprain,
    torticollis,
    arnoldsNeuralgia,
    herniatedDisc,
    lumbago,
    bursitis,
    musculoskeletalFracture,
    musculoskeletalOsteoarthritis,
    osteoporosisOsteopenia,
    backNeckOther,
    arthritis,
    musculoskeletalDetails,
    scoliosisLordosis,
    sprain,
    sciaticNerve,
    tendonitis,
    epicondylitis,
    discDegeneration,
    carpalTunnel,
    ligamentTearMeniscus,
    muscularDystrophy,
    musculoskeletalOther,
    fracture,
    fibromyalgia,
    replacementJoint,
  ],
};

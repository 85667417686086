import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const payment: AnswerPathTreeNode = {
  path: 'payment',
  children: [
    {
      path: 'premiums',
      children: [
        { path: 'smokerPremium', nodeId: NodeIds.smokerPremium },
        { path: 'nonSmokerPremium', nodeId: NodeIds.nonSmokerPremium },
        { path: 'amount', nodeId: NodeIds.paymentPremiumAmount },
        { path: 'minimumPremium', nodeId: NodeIds.minimumPremium },
      ],
    },
    { path: 'factor', nodeId: NodeIds.paymentFactor },
    { path: 'hasDifferentPayer', nodeId: NodeIds.hasDifferentPayer },
    { path: 'frequency', nodeId: NodeIds.paymentFrequency },
    { path: 'contractInformationField', nodeId: NodeIds.contractInformationField },
    { path: 'paymentWithdrawalDate', nodeId: NodeIds.paymentWithdrawalDate },
    { path: 'firstPaymentOptions', nodeId: NodeIds.firstPaymentOptions },
    { path: 'paymentBankingInformationNow', nodeId: NodeIds.paymentBankingInformationNow },
    { path: 'paymentAnnualPremiumReceptionFull', nodeId: NodeIds.paymentAnnualPremiumReceptionFull },
    { path: 'paymentPartOfAnnualPremiumReceptionPart', nodeId: NodeIds.paymentPartOfAnnualPremiumReceptionPart },
    { path: 'paymentAnnualPremiumUponSettling', nodeId: NodeIds.paymentAnnualPremiumUponSettling },
    {
      path: 'account',
      children: [
        {
          path: 'owner',
          children: [
            { path: 'firstName', nodeId: NodeIds.paymentAccountOwnerFirstName },
            { path: 'lastName', nodeId: NodeIds.paymentAccountOwnerLastName },
            { path: 'signature', nodeId: NodeIds.paymentAccountOwnerSignature },
            { path: 'signatureDate', nodeId: NodeIds.paymentAccountOwnerSignatureDate },
          ],
        },
        {
          path: 'otherOwner',
          children: [
            { path: 'signature', nodeId: NodeIds.paymentAccountOtherOwnerSignature },
            { path: 'signatureDate', nodeId: NodeIds.paymentAccountOtherOwnerSignatureDate },
          ],
        },
        { path: 'type', nodeId: NodeIds.paymentAccountType },
        { path: 'number', nodeId: NodeIds.paymentAccountNumber },
      ],
    },
    { path: 'info', nodeId: NodeIds.paymentInfo },
    { path: 'financialInstitution', nodeId: NodeIds.paymentFinancialInstitution },
    { path: 'bankRoutingNumber', nodeId: NodeIds.paymentBankRoutingNumber },
    {
      path: 'payer',
      children: [
        { path: 'role', nodeId: NodeIds.payerRole },
        { path: 'type', nodeId: NodeIds.payerType },
        { path: 'isTheLegalGuardian', nodeId: NodeIds.payerIsTheLegalGuardian },
        { path: 'isTheInsured', nodeId: NodeIds.payerIsTheInsured },
        { path: 'isThePolicyOwner', nodeId: NodeIds.payerIsThePolicyOwner },
        { path: 'firstName', nodeId: NodeIds.payerFirstName },
        { path: 'middleName', nodeId: NodeIds.payerMiddleName },
        { path: 'lastName', nodeId: NodeIds.payerLastName },
        { path: 'gender', nodeId: NodeIds.payerGender },
        { path: 'dateOfBirth', nodeId: NodeIds.payerDateOfBirth },
        { path: 'email', nodeId: NodeIds.payerEmail },
        { path: 'telephoneNumber', nodeId: NodeIds.payerTelephoneNumber },
        { path: 'telephoneType', nodeId: NodeIds.payerTelephoneType },
        { path: 'alternateTelephoneNumber', nodeId: NodeIds.payerAlternateTelephoneNumber },
        { path: 'alternateTelephoneType', nodeId: NodeIds.payerAlternateTelephoneType },
        { path: 'telephoneNumberOne', nodeId: NodeIds.payerTelephoneNumberOne },
        { path: 'telephoneTypeOne', nodeId: NodeIds.payerTelephoneTypeOne },
        { path: 'telephoneNumberTwo', nodeId: NodeIds.payerTelephoneNumberTwo },
        { path: 'telephoneTypeTwo', nodeId: NodeIds.payerTelephoneTypeTwo },
        { path: 'telephoneNumberThree', nodeId: NodeIds.payerTelephoneNumberThree },
        { path: 'telephoneTypeThree', nodeId: NodeIds.payerTelephoneTypeThree },
        { path: 'title', nodeId: NodeIds.payerTitle },
        { path: 'payerTelephoneExtensionOne', nodeId: NodeIds.payerTelephoneExtensionOne },
        { path: 'payerTelephoneExtensionTwo', nodeId: NodeIds.payerTelephoneExtensionTwo },
        { path: 'payerTelephoneExtensionThree', nodeId: NodeIds.payerTelephoneExtensionThree },
        {
          path: 'address',
          children: [
            { path: 'addressAutocomplete', nodeId: NodeIds.payerAddressAutocomplete },
            { path: 'streetAddress', nodeId: NodeIds.payerStreetAddress },
            { path: 'apartment', nodeId: NodeIds.payerApartment },
            { path: 'city', nodeId: NodeIds.payerCity },
            { path: 'country', nodeId: NodeIds.payerCountry },
            { path: 'province', nodeId: NodeIds.payerProvince },
            { path: 'postalCode', nodeId: NodeIds.payerPostalCode },
          ],
        },
        { path: 'ssn', nodeId: NodeIds.payerSSN },
        { path: 'relationToInsured', nodeId: NodeIds.payerRelationToInsured },
        { path: 'relationToInsuredOther', nodeId: NodeIds.payerRelationToInsuredOther },
        { path: 'trustDate', nodeId: NodeIds.payerTrustDate },
        {
          path: 'trustOrEntity',
          children: [
            { path: 'name', nodeId: NodeIds.payerTrustOrEntityName },
            {
              path: 'address',
              children: [
                { path: 'street', nodeId: NodeIds.payerTrustOrEntityAddressStreet },
                { path: 'apartmentNumber', nodeId: NodeIds.payerTrustOrEntityAddressApartmentNumber },
                { path: 'city', nodeId: NodeIds.payerTrustOrEntityAddressCity },
                { path: 'provinceOrState', nodeId: NodeIds.payerTrustOrEntityAddressProvinceOrState },
                { path: 'postalCode', nodeId: NodeIds.payerTrustOrEntityAddressPostalCode },
              ],
            },
            { path: 'primaryPhoneNumber', nodeId: NodeIds.payerTrustOrEntityPrimaryPhoneNumber },
            { path: 'primaryPhoneType', nodeId: NodeIds.payerTrustOrEntityPrimaryPhoneType },
            { path: 'alternatePhoneNumber', nodeId: NodeIds.payerTrustOrEntityAlternatePhoneNumber },
            { path: 'alternatePhoneType', nodeId: NodeIds.payerTrustOrEntityAlternatePhoneType },
            { path: 'email', nodeId: NodeIds.payerTrustOrEntityEmail },
            { path: 'relationToInsured', nodeId: NodeIds.payerTrustOrEntityRelationToInsured },
            { path: 'otherRelationToInsured', nodeId: NodeIds.payerOtherTrustOrEntityRelationToInsured },
          ],
        },
        { path: 'TIN', nodeId: NodeIds.payerTIN },
        { path: 'trustee', nodeId: NodeIds.payerTrustee },
        { path: 'officer', nodeId: NodeIds.payerOfficer },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const lifestyleInsuredMarijuana: AnswerPathTreeNode = {
  path: 'lifestyleInsuredMarijuana',
  children: [
    { path: 'use', nodeId: NodeIds.lifestyleInsuredMarijuanaUse },
    { path: 'useForm', nodeId: NodeIds.lifestyleInsuredMarijuanaUseForm },
    {
      path: 'otherConsumption',
      children: [
        {
          path: 'consumptionHabits',
          children: [
            { path: 'quantity', nodeId: NodeIds.lifestyleInsuredMarijuanaOtherConsumptionHabitsQuantity },
            { path: 'frequency', nodeId: NodeIds.lifestyleInsuredMarijuanaOtherConsumptionHabitsFrequency },
            { path: 'usageDate', nodeId: NodeIds.lifestyleInsuredMarijuanaOtherConsumptionHabitsUsageDate },
            { path: 'usagetype', nodeId: NodeIds.lifestyleInsuredMarijuanaOtherConsumptionHabitsUsageType },
          ],
        },
        { path: 'medicalCondition', nodeId: NodeIds.lifestyleInsuredMarijuanaOtherConsumptionMedicalCondition },
        { path: 'medicalPrescribed', nodeId: NodeIds.lifestyleInsuredMarijuanaOtherMedicalPrescribed },
        {
          path: 'medicalPrescribingPhysician',
          nodeId: NodeIds.lifestyleInsuredMarijuanaOtherMedicalPrescribingPhysician,
        },
      ],
    },
    { path: 'otherPastUse', nodeId: NodeIds.lifestyleInsuredMarijuanaOtherPastUse },
    {
      path: 'otherPastConsumption',
      children: [
        {
          path: 'consumptionHabits',
          children: [
            { path: 'quantity', nodeId: NodeIds.lifestyleInsuredMarijuanaOtherPastConsumptionHabitsQuantity },
            { path: 'frequency', nodeId: NodeIds.lifestyleInsuredMarijuanaOtherPastConsumptionHabitsFrequency },
            {
              path: 'usageType',
              nodeId: NodeIds.lifestyleInsuredMarijuanaOtherPastConsumptionHabitsUsageType,
            },
          ],
        },
        { path: 'medicalCondition', nodeId: NodeIds.lifestyleInsuredMarijuanaOtherPastConsumptionMedicalCondition },
        {
          path: 'medicalPrescribingPhysician',
          nodeId: NodeIds.lifestyleInsuredMarijuanaOtherPastConsumptionMedicalPrescribingPhysician,
        },
        {
          path: 'prescribed',
          nodeId: NodeIds.lifestyleInsuredMarijuanaOtherPastConsumptionMedicalPrescribed,
        },
      ],
    },
    {
      path: 'advisedOrCease',
      children: [
        { path: 'consumptionDate', nodeId: NodeIds.lifestyleInsuredMarijuanaAdvisedOrCeaseConsumptionDate },
        {
          path: 'professionalContactInformation',
          nodeId: NodeIds.lifestyleInsuredMarijuanaAdvisedOrCeaseConsumptionProfessionalContactInformation,
        },
      ],
    },
    {
      path: 'jointConsumption',
      children: [
        {
          path: 'consumptionHabits',
          children: [
            { path: 'quantity', nodeId: NodeIds.lifestyleInsuredMarijuanaJointConsumptionHabitsQuantity },
            { path: 'frequency', nodeId: NodeIds.lifestyleInsuredMarijuanaJointConsumptionHabitsFrequency },
            { path: 'usageDate', nodeId: NodeIds.lifestyleInsuredMarijuanaJointConsumptionHabitsUsageDate },
            { path: 'usagetype', nodeId: NodeIds.lifestyleInsuredMarijuanaJointConsumptionHabitsUsageType },
          ],
        },
        { path: 'medicalCondition', nodeId: NodeIds.lifestyleInsuredMarijuanaJointConsumptionMedicalCondition },
        { path: 'medicalPrescribed', nodeId: NodeIds.lifestyleInsuredMarijuanaJointMedicalPrescribed },
        {
          path: 'medicalPrescribingPhysician',
          nodeId: NodeIds.lifestyleInsuredMarijuanaJointMedicalPrescribingPhysician,
        },
      ],
    },
    {
      path: 'jointPastConsumption',
      children: [
        { path: 'pastUse', nodeId: NodeIds.lifestyleInsuredMarijuanaJointPastUse },
        {
          path: 'consumptionHabits',
          children: [
            { path: 'quantity', nodeId: NodeIds.lifestyleInsuredMarijuanaJointPastConsumptionHabitsQuantity },
            { path: 'frequency', nodeId: NodeIds.lifestyleInsuredMarijuanaJointPastConsumptionHabitsFrequency },
            { path: 'usagetype', nodeId: NodeIds.lifestyleInsuredMarijuanaJointPastConsumptionHabitsUsageType },
          ],
        },
        { path: 'medicalCondition', nodeId: NodeIds.lifestyleInsuredMarijuanaJointPastConsumptionMedicalCondition },
        { path: 'medicalPrescribed', nodeId: NodeIds.lifestyleInsuredMarijuanaJointPastConsumptionMedicalPrescribed },
        {
          path: 'medicalPrescribingPhysician',
          nodeId: NodeIds.lifestyleInsuredMarijuanaJointPastConsumptionMedicalPrescribingPhysician,
        },
      ],
    },
    {
      path: 'jointChangeHabit',
      children: [
        { path: 'consumptionDate', nodeId: NodeIds.lifestyleInsuredMarijuanaJointChangeHabitConsumptionDate },
        { path: 'consumptionReason', nodeId: NodeIds.lifestyleInsuredMarijuanaJointChangeHabitConsumptionReason },
      ],
    },
    {
      path: 'edibleConsumption',
      children: [
        {
          path: 'consumptionHabits',
          children: [
            { path: 'quantity', nodeId: NodeIds.lifestyleInsuredMarijuanaEdibleConsumptionHabitsQuantity },
            { path: 'frequency', nodeId: NodeIds.lifestyleInsuredMarijuanaEdibleConsumptionHabitsFrequency },
            { path: 'usageDate', nodeId: NodeIds.lifestyleInsuredMarijuanaEdibleConsumptionHabitsUsageDate },
            { path: 'usagetype', nodeId: NodeIds.lifestyleInsuredMarijuanaEdibleConsumptionHabitsUsageType },
          ],
        },
        { path: 'medicalCondition', nodeId: NodeIds.lifestyleInsuredMarijuanaEdibleConsumptionMedicalCondition },
        { path: 'medicalPrescribed', nodeId: NodeIds.lifestyleInsuredMarijuanaEdibleMedicalPrescribed },
        {
          path: 'medicalPrescribingPhysician',
          nodeId: NodeIds.lifestyleInsuredMarijuanaEdibleMedicalPrescribingPhysician,
        },
      ],
    },
    {
      path: 'ediblePastConsumption',
      children: [
        { path: 'pastUse', nodeId: NodeIds.lifestyleInsuredMarijuanaEdiblePastUse },
        {
          path: 'consumptionHabits',
          children: [
            { path: 'quantity', nodeId: NodeIds.lifestyleInsuredMarijuanaEdiblePastConsumptionHabitsQuantity },
            { path: 'frequency', nodeId: NodeIds.lifestyleInsuredMarijuanaEdiblePastConsumptionHabitsFrequency },
            { path: 'usagetype', nodeId: NodeIds.lifestyleInsuredMarijuanaEdiblePastConsumptionHabitsUsageType },
          ],
        },
        { path: 'medicalCondition', nodeId: NodeIds.lifestyleInsuredMarijuanaEdiblePastConsumptionMedicalCondition },
        { path: 'medicalPrescribed', nodeId: NodeIds.lifestyleInsuredMarijuanaEdiblePastConsumptionMedicalPrescribed },
        {
          path: 'medicalPrescribingPhysician',
          nodeId: NodeIds.lifestyleInsuredMarijuanaEdiblePastConsumptionMedicalPrescribingPhysician,
        },
      ],
    },
    {
      path: 'edibleChangeHabit',
      children: [
        { path: 'consumptionDate', nodeId: NodeIds.lifestyleInsuredMarijuanaEdibleChangeHabitConsumptionDate },
        { path: 'consumptionReason', nodeId: NodeIds.lifestyleInsuredMarijuanaEdibleChangeHabitConsumptionReason },
      ],
    },
    {
      path: 'oilConsumption',
      children: [
        {
          path: 'consumptionHabits',
          children: [
            { path: 'quantity', nodeId: NodeIds.lifestyleInsuredMarijuanaOilConsumptionHabitsQuantity },
            { path: 'frequency', nodeId: NodeIds.lifestyleInsuredMarijuanaOilConsumptionHabitsFrequency },
            { path: 'usageDate', nodeId: NodeIds.lifestyleInsuredMarijuanaOilConsumptionHabitsUsageDate },
            { path: 'usagetype', nodeId: NodeIds.lifestyleInsuredMarijuanaOilConsumptionHabitsUsageType },
          ],
        },
        { path: 'medicalCondition', nodeId: NodeIds.lifestyleInsuredMarijuanaOilConsumptionMedicalCondition },
        { path: 'medicalPrescribed', nodeId: NodeIds.lifestyleInsuredMarijuanaOilMedicalPrescribed },
        {
          path: 'medicalPrescribingPhysician',
          nodeId: NodeIds.lifestyleInsuredMarijuanaOilMedicalPrescribingPhysician,
        },
      ],
    },
    {
      path: 'oilPastConsumption',
      children: [
        { path: 'pastUse', nodeId: NodeIds.lifestyleInsuredMarijuanaOilPastUse },
        {
          path: 'consumptionHabits',
          children: [
            { path: 'quantity', nodeId: NodeIds.lifestyleInsuredMarijuanaOilPastConsumptionHabitsQuantity },
            { path: 'frequency', nodeId: NodeIds.lifestyleInsuredMarijuanaOilPastConsumptionHabitsFrequency },
            { path: 'usagetype', nodeId: NodeIds.lifestyleInsuredMarijuanaOilPastConsumptionHabitsUsageType },
          ],
        },
        { path: 'medicalCondition', nodeId: NodeIds.lifestyleInsuredMarijuanaOilPastConsumptionMedicalCondition },
        { path: 'medicalPrescribed', nodeId: NodeIds.lifestyleInsuredMarijuanaOilPastConsumptionMedicalPrescribed },
        {
          path: 'medicalPrescribingPhysician',
          nodeId: NodeIds.lifestyleInsuredMarijuanaOilPastConsumptionMedicalPrescribingPhysician,
        },
      ],
    },
    {
      path: 'oilChangeHabit',
      children: [
        { path: 'consumptionDate', nodeId: NodeIds.lifestyleInsuredMarijuanaOilChangeHabitConsumptionDate },
        { path: 'consumptionReason', nodeId: NodeIds.lifestyleInsuredMarijuanaOilChangeHabitConsumptionReason },
      ],
    },
    {
      path: 'otherChangeHabitConsumptionDate',
      nodeId: NodeIds.lifestyleInsuredMarijuanaOtherChangeHabitConsumptionDate,
    },
    {
      path: 'otherChangeHabitConsumptionReason',
      nodeId: NodeIds.lifestyleInsuredMarijuanaOtherChangeHabitConsumptionReason,
    },
    {
      path: 'hasBeenAdvisedReduceOrCeaseConsumption',
      nodeId: NodeIds.lifestyleInsuredMarijuanaHasBeenAdvisedReduceOrCeaseConsumption,
    },
    {
      path: 'supportGroupDetails',
      children: [
        { path: 'meetings', nodeId: NodeIds.lifestyleInsuredMarijuanaSupportGroupMeetings },
        { path: 'stillAttending', nodeId: NodeIds.lifestyleInsuredMarijuanaSupportGroupStillAttending },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredMarijuanaSupportGroupStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredMarijuanaSupportGroupEndDate },
      ],
    },
    {
      path: 'therapyOrTreatmentDetails',
      children: [
        { path: 'hadTherapy', nodeId: NodeIds.lifestyleInsuredMarijuanaHadTherapy },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredMarijuanaTherapyOrTreatmentStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredMarijuanaTherapyOrTreatmentEndDate },
        { path: 'type', nodeId: NodeIds.lifestyleInsuredMarijuanaTherapyOrTreatmentType },
        {
          path: 'professionalContactInformation',
          nodeId: NodeIds.lifestyleInsuredMarijuanaTherapyOrTreatmentProfessionalContactInformation,
        },
        {
          path: 'isOnlyPeriod',
          nodeId: NodeIds.lifestyleInsuredMarijuanaTherapyOrTreatmentIsOnlyPeriod,
        },
        {
          path: 'additionalPeriodDetails',
          nodeId: NodeIds.lifestyleInsuredMarijuanaTherapyOrTreatmentAdditionalPeriodDetails,
        },
      ],
    },
    {
      path: 'pastUse',
      children: [
        { path: 'type', nodeId: NodeIds.lifestyleInsuredMarijuanaPastUseType },
        { path: 'typeOther', nodeId: NodeIds.lifestyleInsuredMarijuanaPastUseTypeOther },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const signature: AnswerPathTreeNode = {
  path: 'signature',
  children: [
    { path: 'insured', nodeId: NodeIds.insuredSignature },
    { path: 'owner', nodeId: NodeIds.ownerSignature },
    { path: 'legalGuardian', nodeId: NodeIds.legalGuardianSignature },
    { path: 'payer', nodeId: NodeIds.payerSignature },
    { path: 'ownerOrganization', nodeId: NodeIds.ownerOrganizationSignature },
    { path: 'primaryBeneficiary', nodeId: NodeIds.primaryBeneficiarySignature },
    { path: 'contingentBeneficiary', nodeId: NodeIds.contingentBeneficiarySignature },
    { path: 'agent', nodeId: NodeIds.agentSignature },
    { path: 'translator', nodeId: NodeIds.translatorSignature },
    { path: 'signingAuthority', nodeId: NodeIds.signingAuthoritySignature },
    { path: 'trustee', nodeId: NodeIds.trusteeSignature },
    { path: 'soleProprietor', nodeId: NodeIds.soleProprietorSignature },
    { path: 'officer', nodeId: NodeIds.officerSignature },
    { path: 'insuredSignatureCity', nodeId: NodeIds.insuredSignatureCity },
    { path: 'insuredSignatureState', nodeId: NodeIds.insuredSignatureState },
    { path: 'insuredSignatureDate', nodeId: NodeIds.insuredSignatureDate },
  ],
};

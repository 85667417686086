import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const healthOwner: AnswerPathTreeNode = {
  path: 'healthOwner',
  children: [
    {
      path: 'cardiovascularSystem',
      children: [
        { path: 'hasCondition', nodeId: NodeIds.healthOwnerCirculatorySystemHasCondition },
        { path: 'circulatorySystemChoices', nodeId: NodeIds.healthOwnerCirculatorySystemChoices },
        {
          path: 'highBloodPressure',
          children: [
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerHighBloodPressureMedicationWasPrescribed },
            { path: 'medicationDetails', nodeId: NodeIds.healthOwnerHighBloodPressureMedicationDetails },
            { path: 'noMedicationReason', nodeId: NodeIds.healthOwnerHighBloodPressureNoMedicationReason },
            { path: 'diagnosisDate', nodeId: NodeIds.healthOwnerHighBloodPressureDiagnosisDate },
            { path: 'isControlled', nodeId: NodeIds.healthOwnerHighBloodPressureIsControlled },
            { path: 'abnormalBloodPressureDetails', nodeId: NodeIds.healthOwnerAbnormalBloodPressureDetails },
          ],
        },
        {
          path: 'highCholesterol',
          children: [
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerHighCholesterolMedicationWasPrescribed },
            { path: 'medicationDetails', nodeId: NodeIds.healthOwnerHighCholesterolMedicationDetails },
            { path: 'noMedicationReason', nodeId: NodeIds.healthOwnerHighCholesterolNoMedicationReason },
            { path: 'diagnosisDate', nodeId: NodeIds.healthOwnerHighCholesterolDiagnosisDate },
            { path: 'isControlled', nodeId: NodeIds.healthOwnerHighCholesterolIsControlled },
            {
              path: 'cholesterolDetails',
              nodeId: NodeIds.healthOwnerCirculatorySystemCholesterolDetails,
            },
          ],
        },
        { path: 'aneurysmDetails', nodeId: NodeIds.healthOwnerAneurysmDetails },
        { path: 'heartAttackDetails', nodeId: NodeIds.healthOwnerHeartAttackDetails },
        { path: 'anginaDetails', nodeId: NodeIds.healthOwnerAnginaDetails },
        { path: 'tiaDetails', nodeId: NodeIds.healthOwnerTiaDetails },
        { path: 'cvaDetails', nodeId: NodeIds.healthOwnerCvaDetails },
        { path: 'heartMurmurDetails', nodeId: NodeIds.healthOwnerHeartMurmurDetails },
        { path: 'chestPainDetails', nodeId: NodeIds.healthOwnerChestPainDetails },
        { path: 'palpitationsDetails', nodeId: NodeIds.healthOwnerPalpitationsDetails },
        { path: 'anyOtherHeartDisorderDetails', nodeId: NodeIds.healthOwnerAnyOtherHeartDisorderDetails },
      ],
    },
    {
      path: 'respiratorySystem',
      children: [
        { path: 'hasCondition', nodeId: NodeIds.healthOwnerRespiratoryHasCondition },
        { path: 'respiratoryChoices', nodeId: NodeIds.healthOwnerRespiratoryChoices },
        {
          path: 'obstructiveSleepApnea',
          children: [
            { path: 'diagnosisDate', nodeId: NodeIds.healthOwnerObstructiveSleepApneaDiagnosisDate },
            { path: 'cpapTreatment', nodeId: NodeIds.healthOwnerCpapTreatment },
            { path: 'cpapTreatmentUsageHours', nodeId: NodeIds.healthOwnerCpapTreatmentUsageHours },
            { path: 'cpapTreatmentUsageWeek', nodeId: NodeIds.healthOwnerCpapTreatmentUsageWeek },
            { path: 'cpapTreatmentIsControlled', nodeId: NodeIds.healthOwnerCpapTreatmentIsControlled },
            { path: 'noCpapTreatmentReason', nodeId: NodeIds.healthOwnerNoCpapTreatmentReason },
            {
              path: 'noCpapTreatmentReasonOtherTreatment',
              nodeId: NodeIds.healthOwnerNoCpapTreatmentReasonOtherTreatment,
            },
            { path: 'noCpapTreatmentReasonOther', nodeId: NodeIds.healthOwnerNoCpapTreatmentReasonOther },
            { path: 'symptomsSeverity', nodeId: NodeIds.healthOwnerSleepApneaSymptomsSeverity },
          ],
        },
        { path: 'asthmaDetails', nodeId: NodeIds.healthOwnerAsthmaDetails },
        { path: 'chronicBronchitisDetails', nodeId: NodeIds.healthOwnerChronicBronchitisDetails },
        { path: 'emphysemaDetails', nodeId: NodeIds.healthOwnerEmphysemaDetails },
        { path: 'copdDetails', nodeId: NodeIds.healthOwnerCopdDetails },
        { path: 'cysticFibrosisDetails', nodeId: NodeIds.healthOwnerCysticFibrosisDetails },
        { path: 'sarcoidosisDetails', nodeId: NodeIds.healthOwnerSarcoidosisDetails },
        { path: 'coughingUpBloodDetails', nodeId: NodeIds.healthOwnerCoughingUpBloodDetails },
        { path: 'shortnessOfBreathDetails', nodeId: NodeIds.healthOwnerShortnessOfBreathDetails },
        { path: 'anyOtherRespiratoryDisorderDetails', nodeId: NodeIds.healthOwnerAnyOtherRespiratoryDisorderDetails },
      ],
    },
    {
      path: 'digestiveSystem',
      children: [
        { path: 'hasCondition', nodeId: NodeIds.healthOwnerDigestiveSystemHasCondition },
        { path: 'digestiveSystemChoices', nodeId: NodeIds.healthOwnerDigestiveSystemChoices },
        {
          path: 'gerd',
          children: [
            { path: 'isControlled', nodeId: NodeIds.healthOwnerGerdIsControlled },
            { path: 'consulted', nodeId: NodeIds.healthOwnerGerdConsulted },
            { path: 'details', nodeId: NodeIds.healthOwnerGerdDetails },
            { path: 'complications', nodeId: NodeIds.healthOwnerGerdComplications },
            { path: 'complicationsDetails', nodeId: NodeIds.healthOwnerGerdComplicationsDetails },
          ],
        },
        {
          path: 'gallstones',
          children: [
            { path: 'diagnosisDate', nodeId: NodeIds.healthOwnerGallstonesDiagnosisDate },
            { path: 'hadOperation', nodeId: NodeIds.healthOwnerGallstonesHadOperation },
            { path: 'operationDate', nodeId: NodeIds.healthOwnerGallstonesOperationDate },
            { path: 'pastSurgeryRecovery', nodeId: NodeIds.healthOwnerGallstonesPastSurgeryRecovery },
            { path: 'pendingSurgery', nodeId: NodeIds.healthOwnerGallstonesPendingSurgery },
            { path: 'pendingSurgeryDate', nodeId: NodeIds.healthOwnerGallstonesPendingSurgeryDate },
            { path: 'pendingSurgeryUnknownDate', nodeId: NodeIds.healthOwnerGallstonesPendingSurgeryUnknownDate },
          ],
        },
        { path: 'crohnsDiseaseDetails', nodeId: NodeIds.healthOwnerCrohnsDiseaseDetails },
        { path: 'ulcerativeColitisDetails', nodeId: NodeIds.healthOwnerUlcerativeColitisDetails },
        { path: 'celiacDiseaseDetails', nodeId: NodeIds.healthOwnerCeliacDiseaseDetails },
        { path: 'polypsDetails', nodeId: NodeIds.healthOwnerPolypsDetails },
        { path: 'cancerPolypsDetails', nodeId: NodeIds.healthOwnerCancerPolypsDetails },
        { path: 'hepatitisDetails', nodeId: NodeIds.healthOwnerHepatitisDetails },
        { path: 'cirrhosisDetails', nodeId: NodeIds.healthOwnerCirrhosisDetails },
        { path: 'pancreatitisDetails', nodeId: NodeIds.healthOwnerPancreatitisDetails },
        { path: 'ulcersDetails', nodeId: NodeIds.healthOwnerUlcersDetails },
        { path: 'gallbladderDisorderDetails', nodeId: NodeIds.healthOwnerGallbladderDisorderDetails },
        { path: 'bleedingDetails', nodeId: NodeIds.healthOwnerBleedingDetails },
        {
          path: 'anyOtherDisorderOfTheDigestiveSystemDetails',
          nodeId: NodeIds.healthOwnerAnyOtherDisorderOfTheDigestiveSystemDetails,
        },
      ],
    },
    {
      path: 'genitourinarySystem',
      children: [
        { path: 'hasCondition', nodeId: NodeIds.healthOwnerGenitourinaryHasCondition },
        { path: 'genitourinaryChoices', nodeId: NodeIds.healthOwnerGenitourinaryChoices },
        { path: 'otherDetails', nodeId: NodeIds.healthOwnerGenitourinaryOtherDetails },
        {
          path: 'kidneyStone',
          children: [
            { path: 'lastEpisode', nodeId: NodeIds.healthOwnerKidneyStoneLastEpisode },
            { path: 'episodesCount', nodeId: NodeIds.healthOwnerKidneyStoneEpisodesCount },
            { path: 'hasRecovered', nodeId: NodeIds.healthOwnerKidneyStoneHasRecovered },
          ],
        },
        { path: 'urineAbnormalitiesDetails', nodeId: NodeIds.healthOwnerUrineAbnormalitiesDetails },
        { path: 'disordersOfTheKidneyDetails', nodeId: NodeIds.healthOwnerDisordersOfTheKidneyDetails },
        { path: 'urinaryTractDetails', nodeId: NodeIds.healthOwnerUrinaryTractDetails },
        { path: 'bladderDetails', nodeId: NodeIds.healthOwnerBladderDetails },
        { path: 'prostateDetails', nodeId: NodeIds.healthOwnerProstateDetails },
        { path: 'genitalOrgansTestsDetails', nodeId: NodeIds.healthOwnerGenitalOrgansTestsDetails },
      ],
    },
    {
      path: 'endocrineSystem',
      children: [
        { path: 'hasCondition', nodeId: NodeIds.healthOwnerEndocrineSystemHasCondition },
        { path: 'endocrineChoices', nodeId: NodeIds.healthOwnerEndocrineChoices },
        {
          path: 'diabetes',
          children: [
            { path: 'type', nodeId: NodeIds.healthOwnerDiabetesType },
            { path: 'details', nodeId: NodeIds.healthOwnerDiabetesDetails },
          ],
        },
        {
          path: 'thyroidDisorder',
          children: [
            { path: 'isHypothyroidism', nodeId: NodeIds.healthOwnerThyroidDisorderIsHypothyroidism },
            { path: 'diagnosisDate', nodeId: NodeIds.healthOwnerThyroidDisorderDiagnosisDate },
            { path: 'hadSurgery', nodeId: NodeIds.healthOwnerThyroidDisorderHadSurgery },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerThyroidDisorderMedicationWasPrescribed },
            { path: 'synthroid', nodeId: NodeIds.healthOwnerThyroidDisorderSynthroid },
            { path: 'isControlled', nodeId: NodeIds.healthOwnerThyroidDisorderIsControlled },
            { path: 'recoveredDetails', nodeId: NodeIds.healthOwnerThyroidDisorderRecoveredDetails },
          ],
        },
        { path: 'abnormalBloodSugarDetails', nodeId: NodeIds.healthOwnerAbnormalBloodSugarDetails },
        { path: 'pituitaryGlandDisorderDetails', nodeId: NodeIds.healthOwnerPituitaryGlandDisorderDetails },
        { path: 'adrenalGlandDisorderDetails', nodeId: NodeIds.healthOwnerAdrenalGlandDisorderDetails },
        {
          path: 'anyOtherGlandularOrHormonalDisorderDetails',
          nodeId: NodeIds.healthOwnerAnyOtherGlandularOrHormonalDisorderDetails,
        },
      ],
    },
    {
      path: 'lumbago',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerLumbagoIsSingleEpisode },
        { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerLumbagoPreviousEpisodesCount },
        {
          path: 'painLocation',
          children: [
            { path: 'location', nodeId: NodeIds.healthOwnerLumbagoPainLocation },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerLumbagoDateSymptoms },
          ],
        },
        {
          path: 'painCause',
          children: [
            { path: 'cause', nodeId: NodeIds.healthOwnerLumbagoPainCause },
            { path: 'details', nodeId: NodeIds.healthOwnerLumbagoPainCauseDetails },
          ],
        },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerLumbagoHasConsultedDoctors },
        { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerLumbagoHasTakenTestsOrExams },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerLumbagoMedicationWasPrescribed },
        {
          path: 'operation',
          children: [
            { path: 'hadOperation', nodeId: NodeIds.healthOwnerLumbagoHadOperation },
            {
              path: 'performedOrPlanned',
              nodeId: NodeIds.healthOwnerLumbagoOperationPerformedOrPlanned,
            },
            {
              path: 'typeOfOperationPerformed',
              nodeId: NodeIds.healthOwnerLumbagoTypeOfOperationPerformed,
            },
            {
              path: 'performedDate',
              nodeId: NodeIds.healthOwnerLumbagoOperationPerformedDate,
            },
            {
              path: 'typeOfOperationPlanned',
              nodeId: NodeIds.healthOwnerLumbagoTypeOfOperationPlanned,
            },
            { path: 'plannedDate', nodeId: NodeIds.healthOwnerLumbagoOperationPlannedDate },
            { path: 'unknownDate', nodeId: NodeIds.healthOwnerLumbagoOperationUnknownDate },
          ],
        },
        {
          path: 'absence',
          children: [
            { path: 'hadAbsence', nodeId: NodeIds.healthOwnerLumbagoHadAbsence },
            { path: 'startDate', nodeId: NodeIds.healthOwnerLumbagoAbsenceStartDate },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerLumbagoAbsenceFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthOwnerLumbagoAbsenceFrequency },
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthOwnerLumbagoAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthOwnerLumbagoAbsenceRecoveryDate },
                { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerLumbagoAbsenceNotRecoveredDetails },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthOwnerLumbagoHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            { path: 'profession', nodeId: NodeIds.healthOwnerLumbagoHasConsultedDoctorsProfession },
            {
              path: 'professionDetails',
              nodeId: NodeIds.healthOwnerLumbagoHasConsultedDoctorsProfessionDetails,
            },
            { path: 'name', nodeId: NodeIds.healthOwnerLumbagoHasConsultedDoctorsName },
            { path: 'address', nodeId: NodeIds.healthOwnerLumbagoHasConsultedDoctorsAddress },
            { path: 'firstDate', nodeId: NodeIds.healthOwnerLumbagoHasConsultedDoctorsFirstDate },
            { path: 'lastDate', nodeId: NodeIds.healthOwnerLumbagoHasConsultedDoctorsLastDate },
            { path: 'result', nodeId: NodeIds.healthOwnerLumbagoHasConsultedDoctorsResult },
            { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerLumbagoHasConsultedDoctorsFrequencyNumber },
            { path: 'frequency', nodeId: NodeIds.healthOwnerLumbagoHasConsultedDoctorsFrequency },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthOwnerLumbagoHasConsultedDoctorFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthOwnerLumbagoHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthOwnerLumbagoHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            { path: 'type', nodeId: NodeIds.healthOwnerLumbagoHasTakenTestsOrExamsType },
            { path: 'date', nodeId: NodeIds.healthOwnerLumbagoHasTakenTestsOrExamsDate },
            { path: 'result', nodeId: NodeIds.healthOwnerLumbagoHasTakenTestsOrExamsResult },
            {
              path: 'abnormalResult',
              nodeId: NodeIds.healthOwnerLumbagoHasTakenTestsOrExamsDetailsAbnormalResult,
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthOwnerLumbagoMedicationDetails,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.healthOwnerLumbagoMedicationName },
            { path: 'dosage', nodeId: NodeIds.healthOwnerLumbagoMedicationDosage },
            { path: 'startDate', nodeId: NodeIds.healthOwnerLumbagoMedicationStartDate },
            { path: 'endDate', nodeId: NodeIds.healthOwnerLumbagoMedicationEndDate },
          ],
        },
      ],
    },
    {
      path: 'sciaticNerve',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerSciaticNerveIsSingleEpisode },
        { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerSciaticNervePreviousEpisodesCount },
        {
          path: 'painLocation',
          children: [
            { path: 'location', nodeId: NodeIds.healthOwnerSciaticNervePainLocation },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerSciaticNerveDateSymptoms },
          ],
        },
        {
          path: 'painCause',
          children: [
            { path: 'cause', nodeId: NodeIds.healthOwnerSciaticNervePainCause },
            { path: 'details', nodeId: NodeIds.healthOwnerSciaticNervePainCauseDetails },
          ],
        },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerSciaticNerveHasConsultedDoctors },
        { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerSciaticNerveHasTakenTestsOrExams },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerSciaticNerveMedicationWasPrescribed },
        {
          path: 'operation',
          children: [
            { path: 'hadOperation', nodeId: NodeIds.healthOwnerSciaticNerveHadOperation },
            {
              path: 'performedOrPlanned',
              nodeId: NodeIds.healthOwnerSciaticNerveOperationPerformedOrPlanned,
            },
            {
              path: 'typeOfOperationPerformed',
              nodeId: NodeIds.healthOwnerSciaticNerveTypeOfOperationPerformed,
            },
            {
              path: 'performedDate',
              nodeId: NodeIds.healthOwnerSciaticNerveOperationPerformedDate,
            },
            {
              path: 'typeOfOperationPlanned',
              nodeId: NodeIds.healthOwnerSciaticNerveTypeOfOperationPlanned,
            },
            { path: 'plannedDate', nodeId: NodeIds.healthOwnerSciaticNerveOperationPlannedDate },
            { path: 'unknownDate', nodeId: NodeIds.healthOwnerSciaticNerveOperationUnknownDate },
          ],
        },
        {
          path: 'absence',
          children: [
            { path: 'hadAbsence', nodeId: NodeIds.healthOwnerSciaticNerveHadAbsence },
            { path: 'startDate', nodeId: NodeIds.healthOwnerSciaticNerveAbsenceStartDate },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerSciaticNerveAbsenceFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthOwnerSciaticNerveAbsenceFrequency },
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthOwnerSciaticNerveAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthOwnerSciaticNerveAbsenceRecoveryDate },
                { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerSciaticNerveAbsenceNotRecoveredDetails },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthOwnerSciaticNerveHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            { path: 'profession', nodeId: NodeIds.healthOwnerSciaticNerveHasConsultedDoctorsProfession },
            {
              path: 'professionDetails',
              nodeId: NodeIds.healthOwnerSciaticNerveHasConsultedDoctorsProfessionDetails,
            },
            { path: 'name', nodeId: NodeIds.healthOwnerSciaticNerveHasConsultedDoctorsName },
            { path: 'address', nodeId: NodeIds.healthOwnerSciaticNerveHasConsultedDoctorsAddress },
            { path: 'firstDate', nodeId: NodeIds.healthOwnerSciaticNerveHasConsultedDoctorsFirstDate },
            { path: 'lastDate', nodeId: NodeIds.healthOwnerSciaticNerveHasConsultedDoctorsLastDate },
            { path: 'result', nodeId: NodeIds.healthOwnerSciaticNerveHasConsultedDoctorsResult },
            { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerSciaticNerveHasConsultedDoctorsFrequencyNumber },
            { path: 'frequency', nodeId: NodeIds.healthOwnerSciaticNerveHasConsultedDoctorsFrequency },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthOwnerSciaticNerveHasConsultedDoctorFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthOwnerSciaticNerveHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthOwnerSciaticNerveHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            { path: 'type', nodeId: NodeIds.healthOwnerSciaticNerveHasTakenTestsOrExamsType },
            { path: 'date', nodeId: NodeIds.healthOwnerSciaticNerveHasTakenTestsOrExamsDate },
            { path: 'result', nodeId: NodeIds.healthOwnerSciaticNerveHasTakenTestsOrExamsResult },
            {
              path: 'abnormalResult',
              nodeId: NodeIds.healthOwnerSciaticNerveHasTakenTestsOrExamsDetailsAbnormalResult,
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthOwnerSciaticNerveMedicationDetails,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.healthOwnerSciaticNerveMedicationName },
            { path: 'dosage', nodeId: NodeIds.healthOwnerSciaticNerveMedicationDosage },
            { path: 'startDate', nodeId: NodeIds.healthOwnerSciaticNerveMedicationStartDate },
            { path: 'endDate', nodeId: NodeIds.healthOwnerSciaticNerveMedicationEndDate },
          ],
        },
      ],
    },
    {
      path: 'discDegeneration',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerDiscDegenerationIsSingleEpisode },
        { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerDiscDegenerationPreviousEpisodesCount },
        {
          path: 'painLocation',
          children: [
            { path: 'location', nodeId: NodeIds.healthOwnerDiscDegenerationPainLocation },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerDiscDegenerationDateSymptoms },
          ],
        },
        {
          path: 'painCause',
          children: [
            { path: 'cause', nodeId: NodeIds.healthOwnerDiscDegenerationPainCause },
            { path: 'details', nodeId: NodeIds.healthOwnerDiscDegenerationPainCauseDetails },
          ],
        },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerDiscDegenerationHasConsultedDoctors },
        { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerDiscDegenerationHasTakenTestsOrExams },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerDiscDegenerationMedicationWasPrescribed },
        {
          path: 'operation',
          children: [
            { path: 'hadOperation', nodeId: NodeIds.healthOwnerDiscDegenerationHadOperation },
            {
              path: 'performedOrPlanned',
              nodeId: NodeIds.healthOwnerDiscDegenerationOperationPerformedOrPlanned,
            },
            {
              path: 'typeOfOperationPerformed',
              nodeId: NodeIds.healthOwnerDiscDegenerationTypeOfOperationPerformed,
            },
            {
              path: 'performedDate',
              nodeId: NodeIds.healthOwnerDiscDegenerationOperationPerformedDate,
            },
            {
              path: 'typeOfOperationPlanned',
              nodeId: NodeIds.healthOwnerDiscDegenerationTypeOfOperationPlanned,
            },
            { path: 'plannedDate', nodeId: NodeIds.healthOwnerDiscDegenerationOperationPlannedDate },
            { path: 'unknownDate', nodeId: NodeIds.healthOwnerDiscDegenerationOperationUnknownDate },
          ],
        },
        {
          path: 'absence',
          children: [
            { path: 'hadAbsence', nodeId: NodeIds.healthOwnerDiscDegenerationHadAbsence },
            { path: 'startDate', nodeId: NodeIds.healthOwnerDiscDegenerationAbsenceStartDate },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerDiscDegenerationAbsenceFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthOwnerDiscDegenerationAbsenceFrequency },
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthOwnerDiscDegenerationAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthOwnerDiscDegenerationAbsenceRecoveryDate },
                { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerDiscDegenerationAbsenceNotRecoveredDetails },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthOwnerDiscDegenerationHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            { path: 'profession', nodeId: NodeIds.healthOwnerDiscDegenerationHasConsultedDoctorsProfession },
            {
              path: 'professionDetails',
              nodeId: NodeIds.healthOwnerDiscDegenerationHasConsultedDoctorsProfessionDetails,
            },
            { path: 'name', nodeId: NodeIds.healthOwnerDiscDegenerationHasConsultedDoctorsName },
            { path: 'address', nodeId: NodeIds.healthOwnerDiscDegenerationHasConsultedDoctorsAddress },
            { path: 'firstDate', nodeId: NodeIds.healthOwnerDiscDegenerationHasConsultedDoctorsFirstDate },
            { path: 'lastDate', nodeId: NodeIds.healthOwnerDiscDegenerationHasConsultedDoctorsLasttDate },
            { path: 'result', nodeId: NodeIds.healthOwnerDiscDegenerationHasConsultedDoctorsResult },
            { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerDiscDegenerationHasConsultedDoctorsFrequencyNumber },
            { path: 'frequency', nodeId: NodeIds.healthOwnerDiscDegenerationHasConsultedDoctorsFrequency },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthOwnerDiscDegenerationHasConsultedDoctorFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthOwnerDiscDegenerationHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthOwnerDiscDegenerationHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            { path: 'type', nodeId: NodeIds.healthOwnerDiscDegenerationHasTakenTestsOrExamsType },
            { path: 'date', nodeId: NodeIds.healthOwnerDiscDegenerationHasTakenTestsOrExamsDate },
            { path: 'result', nodeId: NodeIds.healthOwnerDiscDegenerationHasTakenTestsOrExamsResult },
            {
              path: 'abnormalResult',
              nodeId: NodeIds.healthOwnerDiscDegenerationHasTakenTestsOrExamsDetailsAbnormalResult,
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthOwnerDiscDegenerationMedicationDetails,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.healthOwnerDiscDegenerationMedicationName },
            { path: 'dosage', nodeId: NodeIds.healthOwnerDiscDegenerationMedicationDosage },
            { path: 'startDate', nodeId: NodeIds.healthOwnerDiscDegenerationMedicationStartDate },
            { path: 'endDate', nodeId: NodeIds.healthOwnerDiscDegenerationMedicationEndDate },
          ],
        },
      ],
    },
    {
      path: 'fracture',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerFractureIsSingleEpisode },
        { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerFracturePreviousEpisodesCount },
        {
          path: 'painLocation',
          children: [
            { path: 'location', nodeId: NodeIds.healthOwnerFracturePainLocation },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerFractureDateSymptoms },
          ],
        },
        {
          path: 'painCause',
          children: [
            { path: 'cause', nodeId: NodeIds.healthOwnerFracturePainCause },
            { path: 'details', nodeId: NodeIds.healthOwnerFracturePainCauseDetails },
          ],
        },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerFractureHasConsultedDoctors },
        { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerFractureHasTakenTestsOrExams },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerFractureMedicationWasPrescribed },
        {
          path: 'operation',
          children: [
            { path: 'hadOperation', nodeId: NodeIds.healthOwnerFractureHadOperation },
            {
              path: 'performedOrPlanned',
              nodeId: NodeIds.healthOwnerFractureOperationPerformedOrPlanned,
            },
            {
              path: 'typeOfOperationPerformed',
              nodeId: NodeIds.healthOwnerFractureTypeOfOperationPerformed,
            },
            {
              path: 'performedDate',
              nodeId: NodeIds.healthOwnerFractureOperationPerformedDate,
            },
            {
              path: 'typeOfOperationPlanned',
              nodeId: NodeIds.healthOwnerFractureTypeOfOperationPlanned,
            },
            { path: 'plannedDate', nodeId: NodeIds.healthOwnerFractureOperationPlannedDate },
            { path: 'unknownDate', nodeId: NodeIds.healthOwnerFractureOperationUnknownDate },
          ],
        },
        {
          path: 'absence',
          children: [
            { path: 'hadAbsence', nodeId: NodeIds.healthOwnerFractureHadAbsence },
            { path: 'startDate', nodeId: NodeIds.healthOwnerFractureAbsenceStartDate },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerFractureAbsenceFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthOwnerFractureAbsenceFrequency },
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthOwnerFractureAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthOwnerFractureAbsenceRecoveryDate },
                { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerFractureAbsenceNotRecoveredDetails },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthOwnerFractureHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            { path: 'profession', nodeId: NodeIds.healthOwnerFractureHasConsultedDoctorsProfession },
            { path: 'professionDetails', nodeId: NodeIds.healthOwnerFractureHasConsultedDoctorsProfessionDetails },
            { path: 'name', nodeId: NodeIds.healthOwnerFractureHasConsultedDoctorsName },
            { path: 'address', nodeId: NodeIds.healthOwnerFractureHasConsultedDoctorsAddress },
            { path: 'firstDate', nodeId: NodeIds.healthOwnerFractureHasConsultedDoctorsFirstDate },
            { path: 'lastDate', nodeId: NodeIds.healthOwnerFractureHasConsultedDoctorsLastDate },
            { path: 'result', nodeId: NodeIds.healthOwnerFractureHasConsultedDoctorsResult },
            { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerFractureHasConsultedDoctorsFrequencyNumber },
            { path: 'frequency', nodeId: NodeIds.healthOwnerFractureHasConsultedDoctorsFrequency },
            { path: 'furtherConsultation', nodeId: NodeIds.healthOwnerFractureHasConsultedDoctorFurtherConsultation },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthOwnerFractureHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthOwnerFractureHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            { path: 'type', nodeId: NodeIds.healthOwnerFractureHasTakenTestsOrExamsType },
            { path: 'date', nodeId: NodeIds.healthOwnerFractureHasTakenTestsOrExamsDate },
            { path: 'result', nodeId: NodeIds.healthOwnerFractureHasTakenTestsOrExamsResult },
            { path: 'abnormalResult', nodeId: NodeIds.healthOwnerFractureHasTakenTestsOrExamsDetailsAbnormalResult },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthOwnerFractureMedicationDetails,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.healthOwnerFractureMedicationName },
            { path: 'dosage', nodeId: NodeIds.healthOwnerFractureMedicationDosage },
            { path: 'startDate', nodeId: NodeIds.healthOwnerFractureMedicationStartDate },
            { path: 'endDate', nodeId: NodeIds.healthOwnerFractureMedicationEndDate },
          ],
        },
      ],
    },
    {
      path: 'herniatedDisc',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerHerniatedDiscIsSingleEpisode },
        { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerHerniatedDiscPreviousEpisodesCount },
        {
          path: 'painLocation',
          children: [
            { path: 'location', nodeId: NodeIds.healthOwnerHerniatedDiscPainLocation },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerHerniatedDiscDateSymptoms },
          ],
        },
        {
          path: 'painCause',
          children: [
            { path: 'cause', nodeId: NodeIds.healthOwnerHerniatedDiscPainCause },
            { path: 'details', nodeId: NodeIds.healthOwnerHerniatedDiscPainCauseDetails },
          ],
        },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerHerniatedDiscHasConsultedDoctors },
        { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerHerniatedDiscHasTakenTestsOrExams },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerHerniatedDiscMedicationWasPrescribed },
        {
          path: 'operation',
          children: [
            { path: 'hadOperation', nodeId: NodeIds.healthOwnerHerniatedDiscHadOperation },
            {
              path: 'performedOrPlanned',
              nodeId: NodeIds.healthOwnerHerniatedDiscOperationPerformedOrPlanned,
            },
            {
              path: 'typeOfOperationPerformed',
              nodeId: NodeIds.healthOwnerHerniatedDiscTypeOfOperationPerformed,
            },
            {
              path: 'performedDate',
              nodeId: NodeIds.healthOwnerHerniatedDiscOperationPerformedDate,
            },
            {
              path: 'typeOfOperationPlanned',
              nodeId: NodeIds.healthOwnerHerniatedDiscTypeOfOperationPlanned,
            },
            { path: 'plannedDate', nodeId: NodeIds.healthOwnerHerniatedDiscOperationPlannedDate },
            { path: 'unknownDate', nodeId: NodeIds.healthOwnerHerniatedDiscOperationUnknownDate },
          ],
        },
        {
          path: 'absence',
          children: [
            { path: 'hadAbsence', nodeId: NodeIds.healthOwnerHerniatedDiscHadAbsence },
            { path: 'startDate', nodeId: NodeIds.healthOwnerHerniatedDiscAbsenceStartDate },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerHerniatedDiscAbsenceFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthOwnerHerniatedDiscAbsenceFrequency },
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthOwnerHerniatedDiscAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthOwnerHerniatedDiscAbsenceRecoveryDate },
                { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerHerniatedDiscAbsenceNotRecoveredDetails },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthOwnerHerniatedDiscHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            { path: 'profession', nodeId: NodeIds.healthOwnerHerniatedDiscHasConsultedDoctorsProfession },
            { path: 'professionDetails', nodeId: NodeIds.healthOwnerHerniatedDiscHasConsultedDoctorsProfessionDetails },
            { path: 'name', nodeId: NodeIds.healthOwnerHerniatedDiscHasConsultedDoctorsName },
            { path: 'address', nodeId: NodeIds.healthOwnerHerniatedDiscHasConsultedDoctorsAddress },
            { path: 'firstDate', nodeId: NodeIds.healthOwnerHerniatedDiscHasConsultedDoctorsFirstDate },
            { path: 'lastDate', nodeId: NodeIds.healthOwnerHerniatedDiscHasConsultedDoctorsLastDate },
            { path: 'result', nodeId: NodeIds.healthOwnerHerniatedDiscHasConsultedDoctorsResult },
            { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerHerniatedDiscHasConsultedDoctorsFrequencyNumber },
            { path: 'frequency', nodeId: NodeIds.healthOwnerHerniatedDiscHasConsultedDoctorsFrequency },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthOwnerHerniatedDiscHasConsultedDoctorFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthOwnerHerniatedDiscHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthOwnerHerniatedDiscHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            { path: 'type', nodeId: NodeIds.healthOwnerHerniatedDiscHasTakenTestsOrExamsType },
            { path: 'date', nodeId: NodeIds.healthOwnerHerniatedDiscHasTakenTestsOrExamsDate },
            { path: 'result', nodeId: NodeIds.healthOwnerHerniatedDiscHasTakenTestsOrExamsResult },
            {
              path: 'abnormalResult',
              nodeId: NodeIds.healthOwnerHerniatedDiscHasTakenTestsOrExamsDetailsAbnormalResult,
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthOwnerHerniatedDiscMedicationDetails,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.healthOwnerHerniatedDiscMedicationName },
            { path: 'dosage', nodeId: NodeIds.healthOwnerHerniatedDiscMedicationDosage },
            { path: 'startDate', nodeId: NodeIds.healthOwnerHerniatedDiscMedicationStartDate },
            { path: 'endDate', nodeId: NodeIds.healthOwnerHerniatedDiscMedicationEndDate },
          ],
        },
      ],
    },
    {
      path: 'scoliosisLordosis',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerScoliosisLordosisIsSingleEpisode },
        { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerScoliosisLordosisPreviousEpisodesCount },
        {
          path: 'painLocation',
          children: [
            { path: 'location', nodeId: NodeIds.healthOwnerScoliosisLordosisPainLocation },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerScoliosisLordosisDateSymptoms },
          ],
        },
        {
          path: 'painCause',
          children: [
            { path: 'cause', nodeId: NodeIds.healthOwnerScoliosisLordosisPainCause },
            { path: 'details', nodeId: NodeIds.healthOwnerScoliosisLordosisPainCauseDetails },
          ],
        },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerScoliosisLordosisHasConsultedDoctors },
        { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerScoliosisLordosisHasTakenTestsOrExams },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerScoliosisLordosisMedicationWasPrescribed },
        {
          path: 'operation',
          children: [
            { path: 'hadOperation', nodeId: NodeIds.healthOwnerScoliosisLordosisHadOperation },
            {
              path: 'performedOrPlanned',
              nodeId: NodeIds.healthOwnerScoliosisLordosisOperationPerformedOrPlanned,
            },
            {
              path: 'typeOfOperationPerformed',
              nodeId: NodeIds.healthOwnerScoliosisLordosisTypeOfOperationPerformed,
            },
            {
              path: 'performedDate',
              nodeId: NodeIds.healthOwnerScoliosisLordosisOperationPerformedDate,
            },
            {
              path: 'typeOfOperationPlanned',
              nodeId: NodeIds.healthOwnerScoliosisLordosisTypeOfOperationPlanned,
            },
            { path: 'plannedDate', nodeId: NodeIds.healthOwnerScoliosisLordosisOperationPlannedDate },
            { path: 'unknownDate', nodeId: NodeIds.healthOwnerScoliosisLordosisOperationUnknownDate },
          ],
        },
        {
          path: 'absence',
          children: [
            { path: 'hadAbsence', nodeId: NodeIds.healthOwnerScoliosisLordosisHadAbsence },
            { path: 'startDate', nodeId: NodeIds.healthOwnerScoliosisLordosisAbsenceStartDate },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerScoliosisLordosisAbsenceFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthOwnerScoliosisLordosisAbsenceFrequency },
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthOwnerScoliosisLordosisAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthOwnerScoliosisLordosisAbsenceRecoveryDate },
                { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerScoliosisLordosisAbsenceNotRecoveredDetails },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthOwnerScoliosisLordosisHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            { path: 'profession', nodeId: NodeIds.healthOwnerScoliosisLordosisHasConsultedDoctorsProfession },
            {
              path: 'professionDetails',
              nodeId: NodeIds.healthOwnerScoliosisLordosisHasConsultedDoctorsProfessionDetails,
            },
            { path: 'name', nodeId: NodeIds.healthOwnerScoliosisLordosisHasConsultedDoctorsName },
            { path: 'address', nodeId: NodeIds.healthOwnerScoliosisLordosisHasConsultedDoctorsAddress },
            { path: 'firstDate', nodeId: NodeIds.healthOwnerScoliosisLordosisHasConsultedDoctorsFirstDate },
            { path: 'lastDate', nodeId: NodeIds.healthOwnerScoliosisLordosisHasConsultedDoctorsLastDate },
            { path: 'result', nodeId: NodeIds.healthOwnerScoliosisLordosisHasConsultedDoctorsResult },
            { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerScoliosisLordosisHasConsultedDoctorsFrequencyNumber },
            { path: 'frequency', nodeId: NodeIds.healthOwnerScoliosisLordosisHasConsultedDoctorsFrequency },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthOwnerScoliosisLordosisHasConsultedDoctorFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthOwnerScoliosisLordosisHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthOwnerScoliosisLordosisHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            { path: 'type', nodeId: NodeIds.healthOwnerScoliosisLordosisHasTakenTestsOrExamsType },
            { path: 'date', nodeId: NodeIds.healthOwnerScoliosisLordosisHasTakenTestsOrExamsDate },
            { path: 'result', nodeId: NodeIds.healthOwnerScoliosisLordosisHasTakenTestsOrExamsResult },
            {
              path: 'abnormalResult',
              nodeId: NodeIds.healthOwnerScoliosisLordosisHasTakenTestsOrExamsDetailsAbnormalResult,
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthOwnerScoliosisLordosisMedicationDetails,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.healthOwnerScoliosisLordosisMedicationName },
            { path: 'dosage', nodeId: NodeIds.healthOwnerScoliosisLordosisMedicationDosage },
            { path: 'startDate', nodeId: NodeIds.healthOwnerScoliosisLordosisMedicationStartDate },
            { path: 'endDate', nodeId: NodeIds.healthOwnerScoliosisLordosisMedicationEndDate },
          ],
        },
      ],
    },
    {
      path: 'otherBackNeckDisorder',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerBackNeckOtherIsSingleEpisode },
        { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerBackNeckOtherPreviousEpisodesCount },
        {
          path: 'painLocation',
          children: [
            { path: 'location', nodeId: NodeIds.healthOwnerBackNeckOtherPainLocation },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerBackNeckOtherDateSymptoms },
          ],
        },
        {
          path: 'painCause',
          children: [
            { path: 'cause', nodeId: NodeIds.healthOwnerBackNeckOtherPainCause },
            { path: 'details', nodeId: NodeIds.healthOwnerBackNeckOtherPainCauseDetails },
          ],
        },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerBackNeckOtherHasConsultedDoctors },
        { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerBackNeckOtherHasTakenTestsOrExams },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerBackNeckOtherMedicationWasPrescribed },
        {
          path: 'operation',
          children: [
            { path: 'hadOperation', nodeId: NodeIds.healthOwnerBackNeckOtherHadOperation },
            {
              path: 'performedOrPlanned',
              nodeId: NodeIds.healthOwnerBackNeckOtherOperationPerformedOrPlanned,
            },
            {
              path: 'typeOfOperationPerformed',
              nodeId: NodeIds.healthOwnerBackNeckOtherTypeOfOperationPerformed,
            },
            {
              path: 'performedDate',
              nodeId: NodeIds.healthOwnerBackNeckOtherOperationPerformedDate,
            },
            {
              path: 'typeOfOperationPlanned',
              nodeId: NodeIds.healthOwnerBackNeckOtherTypeOfOperationPlanned,
            },
            { path: 'plannedDate', nodeId: NodeIds.healthOwnerBackNeckOtherOperationPlannedDate },
            { path: 'unknownDate', nodeId: NodeIds.healthOwnerBackNeckOtherOperationUnknownDate },
          ],
        },
        {
          path: 'absence',
          children: [
            { path: 'hadAbsence', nodeId: NodeIds.healthOwnerBackNeckOtherHadAbsence },
            { path: 'startDate', nodeId: NodeIds.healthOwnerBackNeckOtherAbsenceStartDate },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerBackNeckOtherAbsenceFrequencyNumber,
            },
            { path: 'frequency', nodeId: NodeIds.healthOwnerBackNeckOtherAbsenceFrequency },
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthOwnerBackNeckOtherAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthOwnerBackNeckOtherAbsenceRecoveryDate },
                { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerBackNeckOtherAbsenceNotRecoveredDetails },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthOwnerBackNeckOtherHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            {
              path: 'profession',
              nodeId: NodeIds.healthOwnerBackNeckOtherHasConsultedDoctorsProfession,
            },
            {
              path: 'professionDetails',
              nodeId: NodeIds.healthOwnerBackNeckOtherHasConsultedDoctorsProfessionDetails,
            },
            {
              path: 'name',
              nodeId: NodeIds.healthOwnerBackNeckOtherHasConsultedDoctorsName,
            },
            {
              path: 'address',
              nodeId: NodeIds.healthOwnerBackNeckOtherHasConsultedDoctorsAddress,
            },
            {
              path: 'firstDate',
              nodeId: NodeIds.healthOwnerBackNeckOtherHasConsultedDoctorsFirstDate,
            },
            {
              path: 'lastDate',
              nodeId: NodeIds.healthOwnerBackNeckOtherHasConsultedDoctorsLastDate,
            },
            {
              path: 'result',
              nodeId: NodeIds.healthOwnerBackNeckOtherHasConsultedDoctorsResult,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerBackNeckOtherHasConsultedDoctorsFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthOwnerBackNeckOtherHasConsultedDoctorsFrequency,
            },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthOwnerBackNeckOtherHasConsultedDoctorFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthOwnerBackNeckOtherHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthOwnerBackNeckOtherHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            {
              path: 'type',
              nodeId: NodeIds.healthOwnerBackNeckOtherHasTakenTestsOrExamsType,
            },
            {
              path: 'date',
              nodeId: NodeIds.healthOwnerBackNeckOtherHasTakenTestsOrExamsDate,
            },
            {
              path: 'result',
              nodeId: NodeIds.healthOwnerBackNeckOtherHasTakenTestsOrExamsResult,
            },
            {
              path: 'abnormalResult',
              nodeId: NodeIds.healthOwnerBackNeckOtherHasTakenTestsOrExamsDetailsAbnormalResult,
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthOwnerBackNeckOtherMedicationDetails,
          isCollection: true,
          children: [
            {
              path: 'name',
              nodeId: NodeIds.healthOwnerBackNeckOtherMedicationName,
            },
            {
              path: 'dosage',
              nodeId: NodeIds.healthOwnerBackNeckOtherMedicationDosage,
            },
            {
              path: 'startDate',
              nodeId: NodeIds.healthOwnerBackNeckOtherMedicationStartDate,
            },
            {
              path: 'endDate',
              nodeId: NodeIds.healthOwnerBackNeckOtherMedicationEndDate,
            },
          ],
        },
      ],
    },
    {
      path: 'musculoskeletal',
      children: [
        { path: 'hasCondition', nodeId: NodeIds.healthOwnerMusculoskeletalHasCondition },
        { path: 'conditions', nodeId: NodeIds.healthOwnerMusculoskeletalConditions },
        { path: 'conditionsOther', nodeId: NodeIds.healthOwnerMusculoskeletalConditionsOther },
        {
          path: 'backNeck',
          children: [
            { path: 'hasCondition', nodeId: NodeIds.healthOwnerBackNeckHasCondition },
            { path: 'conditions', nodeId: NodeIds.healthOwnerBackNeckConditions },
            { path: 'conditionsOther', nodeId: NodeIds.healthOwnerBackNeckConditionsOther },
          ],
        },
        {
          path: 'osteoarthritis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerOsteoarthritisIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerOsteoarthritisPreviousEpisodesCount },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerOsteoarthritisPainLocation },
                { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerOsteoarthritisDateSymptoms },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerOsteoarthritisPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerOsteoarthritisPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerOsteoarthritisHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerOsteoarthritisHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerOsteoarthritisMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerOsteoarthritisHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthOwnerOsteoarthritisOperationPerformedOrPlanned,
                },
                { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthOwnerOsteoarthritisTypeOfOperationPerformed },
                { path: 'performedDate', nodeId: NodeIds.healthOwnerOsteoarthritisOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthOwnerOsteoarthritisTypeOfOperationPlanned },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerOsteoarthritisOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerOsteoarthritisOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerOsteoarthritisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerOsteoarthritisAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerOsteoarthritisAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerOsteoarthritisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerOsteoarthritisAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerOsteoarthritisAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthOwnerOsteoarthritisAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerOsteoarthritisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthOwnerOsteoarthritisHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerOsteoarthritisHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerOsteoarthritisHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthOwnerOsteoarthritisHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthOwnerOsteoarthritisHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthOwnerOsteoarthritisHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerOsteoarthritisHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerOsteoarthritisHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerOsteoarthritisHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerOsteoarthritisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerOsteoarthritisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerOsteoarthritisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthOwnerOsteoarthritisHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthOwnerOsteoarthritisHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerOsteoarthritisHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerOsteoarthritisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerOsteoarthritisMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerOsteoarthritisMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthOwnerOsteoarthritisMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthOwnerOsteoarthritisMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthOwnerOsteoarthritisMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'spondylitisAnkylosing',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingPreviousEpisodesCount },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingPainLocation },
                { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingDateSymptoms },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasTakenTestsOrExams },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthOwnerSpondylitisAnkylosingMedicationWasPrescribed,
            },
            {
              path: 'operation',
              children: [
                {
                  path: 'hadOperation',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHadOperation,
                },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingTypeOfOperationPerformed,
                },
                {
                  path: 'performedDate',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingOperationPerformedDate,
                },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerSpondylitisAnkylosingAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthOwnerSpondylitisAnkylosingAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerSpondylitisAnkylosingMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthOwnerSpondylitisAnkylosingMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'osteoporosis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerOsteoporosisIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerOsteoporosisPreviousEpisodesCount },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerOsteoporosisPainLocation },
                { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerOsteoporosisDateSymptoms },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerOsteoporosisPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerOsteoporosisPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerOsteoporosisHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerOsteoporosisHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerOsteoporosisMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerOsteoporosisHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthOwnerOsteoporosisOperationPerformedOrPlanned,
                },
                { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthOwnerOsteoporosisTypeOfOperationPerformed },
                { path: 'operationPerformedDate', nodeId: NodeIds.healthOwnerOsteoporosisOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthOwnerOsteoporosisTypeOfOperationPlanned },
                { path: 'operationPlannedDate', nodeId: NodeIds.healthOwnerOsteoporosisOperationPlannedDate },
                { path: 'operationUnknownDate', nodeId: NodeIds.healthOwnerOsteoporosisOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerOsteoporosisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerOsteoporosisAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerOsteoporosisAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerOsteoporosisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerOsteoporosisAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerOsteoporosisAbsenceRecoveryDate },
                    { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerOsteoporosisAbsenceNotRecoveredDetails },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerOsteoporosisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthOwnerOsteoporosisHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerOsteoporosisHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerOsteoporosisHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthOwnerOsteoporosisHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthOwnerOsteoporosisHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthOwnerOsteoporosisHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerOsteoporosisHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerOsteoporosisHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerOsteoporosisHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerOsteoporosisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerOsteoporosisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerOsteoporosisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthOwnerOsteoporosisHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthOwnerOsteoporosisHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerOsteoporosisHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerOsteoporosisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerOsteoporosisMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerOsteoporosisMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthOwnerOsteoporosisMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthOwnerOsteoporosisMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthOwnerOsteoporosisMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'whiplash',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerWhiplashIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerWhiplashPreviousEpisodesCount },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerWhiplashPainLocation },
                { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerWhiplashDateSymptoms },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerWhiplashPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerWhiplashPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerWhiplashHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerWhiplashHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerWhiplashMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerWhiplashHadOperation },
                { path: 'performedOrPlanned', nodeId: NodeIds.healthOwnerWhiplashOperationPerformedOrPlanned },
                { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthOwnerWhiplashTypeOfOperationPerformed },
                { path: 'operationPerformedDate', nodeId: NodeIds.healthOwnerWhiplashOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthOwnerWhiplashTypeOfOperationPlanned },
                { path: 'operationPlannedDate', nodeId: NodeIds.healthOwnerWhiplashOperationPlannedDate },
                { path: 'operationUnknownDate', nodeId: NodeIds.healthOwnerWhiplashOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerWhiplashHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerWhiplashAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerWhiplashAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerWhiplashAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerWhiplashAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerWhiplashAbsenceRecoveryDate },
                    { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerWhiplashAbsenceNotRecoveredDetails },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerWhiplashHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthOwnerWhiplashHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerWhiplashHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerWhiplashHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthOwnerWhiplashHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthOwnerWhiplashHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthOwnerWhiplashHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerWhiplashHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerWhiplashHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerWhiplashHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerWhiplashHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerWhiplashHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerWhiplashHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthOwnerWhiplashHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthOwnerWhiplashHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerWhiplashHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerWhiplashHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerWhiplashMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerWhiplashMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthOwnerWhiplashMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthOwnerWhiplashMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthOwnerWhiplashMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'torticollis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerTorticollisIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerTorticollisPreviousEpisodesCount },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerTorticollisPainLocation },
                { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerTorticollisDateSymptoms },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerTorticollisPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerTorticollisPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerTorticollisHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerTorticollisHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerTorticollisMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerTorticollisHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthOwnerTorticollisOperationPerformedOrPlanned,
                },
                { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthOwnerTorticollisTypeOfOperationPerformed },
                { path: 'operationPerformedDate', nodeId: NodeIds.healthOwnerTorticollisOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthOwnerTorticollisTypeOfOperationPlanned },
                { path: 'operationPlannedDate', nodeId: NodeIds.healthOwnerTorticollisOperationPlannedDate },
                { path: 'operationUnknownDate', nodeId: NodeIds.healthOwnerTorticollisOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerTorticollisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerTorticollisAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerTorticollisAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerTorticollisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerTorticollisAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerTorticollisAbsenceRecoveryDate },
                    { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerTorticollisAbsenceNotRecoveredDetails },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerTorticollisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerTorticollisHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerTorticollisHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerTorticollisHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerTorticollisHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerTorticollisHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerTorticollisHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerTorticollisHasConsultedDoctorsResult },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerTorticollisHasConsultedDoctorsFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerTorticollisHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerTorticollisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerTorticollisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerTorticollisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthOwnerTorticollisHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthOwnerTorticollisHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthOwnerTorticollisHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerTorticollisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerTorticollisMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerTorticollisMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerTorticollisMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerTorticollisMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerTorticollisMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'neuralgia',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaPreviousEpisodesCount },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaPainLocation },
                { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaDateSymptoms },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthOwnerArnoldsNeuralgiaOperationPerformedOrPlanned,
                },
                {
                  path: 'performed',
                  nodeId: NodeIds.healthOwnerArnoldsNeuralgiaTypeOfOperationPerformed,
                },
                {
                  path: 'performedDate',
                  nodeId: NodeIds.healthOwnerArnoldsNeuralgiaOperationPerformedDate,
                },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthOwnerArnoldsNeuralgiaTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                {
                  path: 'hadAbsence',
                  nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHadAbsence,
                },
                { path: 'startDate', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaAbsenceStartDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerArnoldsNeuralgiaAbsenceFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthOwnerArnoldsNeuralgiaAbsenceNotRecoveredDetails,
                    },
                  ],
                },
                {
                  path: 'hasConsultedDoctorsDetails',
                  nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasConsultedDoctorsDetails,
                  isCollection: true,
                  children: [
                    { path: 'profession', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasConsultedDoctorsProfession },
                    {
                      path: 'professionDetails',
                      nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasConsultedDoctorsProfessionDetails,
                    },
                    { path: 'name', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasConsultedDoctorsName },
                    { path: 'address', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasConsultedDoctorsAddress },
                    { path: 'firstDate', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasConsultedDoctorsFirstDate },
                    { path: 'lastDate', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasConsultedDoctorsLastDate },
                    { path: 'result', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasConsultedDoctorsResult },
                    {
                      path: 'frequencyNumber',
                      nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasConsultedDoctorsFrequencyNumber,
                    },
                    { path: 'frequency', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasConsultedDoctorsFrequency },
                    {
                      path: 'furtherConsultation',
                      nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasConsultedDoctorFurtherConsultation,
                    },
                    {
                      path: 'nextConsultationDate',
                      nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasConsultedDoctorsNextConsultationDate,
                    },
                  ],
                },
                {
                  path: 'hasTakenTestsOrExamsDetails',
                  nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasTakenTestsOrExamsDetails,
                  isCollection: true,
                  children: [
                    { path: 'type', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasTakenTestsOrExamsType },
                    { path: 'date', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasTakenTestsOrExamsDate },
                    { path: 'result', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasTakenTestsOrExamsResult },
                    {
                      path: 'abnormalResult',
                      nodeId: NodeIds.healthOwnerArnoldsNeuralgiaHasTakenTestsOrExamsDetailsAbnormalResult,
                    },
                  ],
                },
                {
                  path: 'medicationDetails',
                  nodeId: NodeIds.healthOwnerArnoldsNeuralgiaMedicationDetails,
                  isCollection: true,
                  children: [
                    { path: 'name', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaMedicationName },
                    { path: 'dosage', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaMedicationDosage },
                    { path: 'startDate', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaMedicationStartDate },
                    { path: 'endDate', nodeId: NodeIds.healthOwnerArnoldsNeuralgiaMedicationEndDate },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'sprain',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerSprainIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerSprainPreviousEpisodesCount },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerSprainPainLocation },
                { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerSprainDateSymptoms },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerSprainPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerSprainPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerSprainHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerSprainHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerSprainMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerSprainHadOperation },
                { path: 'performedOrPlanned', nodeId: NodeIds.healthOwnerSprainOperationPerformedOrPlanned },
                { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthOwnerSprainTypeOfOperationPerformed },
                { path: 'operationPerformedDate', nodeId: NodeIds.healthOwnerSprainOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthOwnerSprainTypeOfOperationPlanned },
                { path: 'operationPlannedDate', nodeId: NodeIds.healthOwnerSprainOperationPlannedDate },
                { path: 'operationUnknownDate', nodeId: NodeIds.healthOwnerSprainOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                {
                  path: 'hadAbsence',
                  nodeId: NodeIds.healthOwnerSprainHadAbsence,
                },
                { path: 'startDate', nodeId: NodeIds.healthOwnerSprainAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerSprainAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerSprainAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerSprainAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerSprainAbsenceRecoveryDate },
                    { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerSprainAbsenceNotRecoveredDetails },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerSprainHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerSprainHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerSprainHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerSprainHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerSprainHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerSprainHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerSprainHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerSprainHasConsultedDoctorsResult },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerSprainHasConsultedDoctorsFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerSprainHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerSprainHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerSprainHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerSprainHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthOwnerSprainHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthOwnerSprainHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthOwnerSprainHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerSprainHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerSprainMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerSprainMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerSprainMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerSprainMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerSprainMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'arthritis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerArthritisIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerArthritisPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerArthritisDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerArthritisPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthOwnerArthritisPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthOwnerArthritisPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerArthritisPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerArthritisPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerArthritisHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerArthritisHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerArthritisMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerArthritisHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthOwnerArthritisOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthOwnerArthritisTypeOfOperationPerformed,
                },
                {
                  path: 'performedDate',
                  nodeId: NodeIds.healthOwnerArthritisOperationPerformedDate,
                },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthOwnerArthritisTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerArthritisOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerArthritisOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerArthritisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerArthritisAbsenceStartDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerArthritisAbsenceFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthOwnerArthritisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerArthritisAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerArthritisAbsenceRecoveryDate },
                    { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerArthritisAbsenceNotRecoveredDetails },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerArthritisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerArthritisHasConsultedDoctorsProfession },
                { path: 'professionDetails', nodeId: NodeIds.healthOwnerArthritisHasConsultedDoctorsProfessionDetails },
                { path: 'name', nodeId: NodeIds.healthOwnerArthritisHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerArthritisHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerArthritisHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerArthritisHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerArthritisHasConsultedDoctorsResult },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerArthritisHasConsultedDoctorsFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerArthritisHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerArthritisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerArthritisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerArthritisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthOwnerArthritisHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthOwnerArthritisHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthOwnerArthritisHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerArthritisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerArthritisMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerArthritisMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerArthritisMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerArthritisMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerArthritisMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'osteoarthritis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisIsSingleEpisode },
            {
              path: 'previousEpisodesCount',
              nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisPreviousEpisodesCount,
            },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisPainLocation },
                {
                  path: 'leftRight',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisPainLocationLeftRight,
                },
                { path: 'other', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisPainCauseDetails },
              ],
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctors,
            },
            {
              path: 'hasTakenTestsOrExams',
              nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExams,
            },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisMedicationWasPrescribed,
            },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisTypeOfOperationPerformed,
                },
                {
                  path: 'performedDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisOperationPerformedDate,
                },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisAbsenceStartDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisAbsenceFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    {
                      path: 'hasRecovered',
                      nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisAbsenceHasRecovered,
                    },
                    {
                      path: 'recoveryDate',
                      nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisAbsenceRecoveryDate,
                    },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsAddress },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsLastDate,
                },
                { path: 'result', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoarthritisMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'osteoporosisOsteopenia',
          children: [
            {
              path: 'isSingleEpisode',
              nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaIsSingleEpisode,
            },
            {
              path: 'previousEpisodesCount',
              nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaPreviousEpisodesCount,
            },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaPainLocation },
                {
                  path: 'leftRight',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaPainLocationLeftRight,
                },
                {
                  path: 'other',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaPainLocationOther,
                },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaPainCauseDetails },
              ],
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctors,
            },
            {
              path: 'hasTakenTestsOrExams',
              nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExams,
            },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationWasPrescribed,
            },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaTypeOfOperationPerformed,
                },
                {
                  path: 'performedDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaOperationPerformedDate,
                },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaTypeOfOperationPlanned,
                },
                {
                  path: 'plannedDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaOperationPlannedDate,
                },
                {
                  path: 'unknownDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaOperationUnknownDate,
                },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceStartDate },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    {
                      path: 'hasRecovered',
                      nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceHasRecovered,
                    },
                    {
                      path: 'recoveryDate',
                      nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceRecoveryDate,
                    },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId:
                    NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId:
                    NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationDosage },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationStartDate,
                },
                { path: 'endDate', nodeId: NodeIds.healthOwnerMusculoskeletalOsteoporosisOsteopeniaMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'fibromyalgia',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerFibromyalgiIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerFibromyalgiaPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerFibromyalgiaDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerFibromyalgiaPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthOwnerFibromyalgiaPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthOwnerFibromyalgiaPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerFibromyalgiaPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerFibromyalgiaPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerFibromyalgiaHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerFibromyalgiaHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerFibromyalgiaMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerFibromyalgiaHadOperation },
                { path: 'performedOrPlanned', nodeId: NodeIds.healthOwnerFibromyalgiaOperationPerformedOrPlanned },
                { path: 'typeOfOperationperformed', nodeId: NodeIds.healthOwnerFibromyalgiaTypeOfOperationPerformed },
                { path: 'performedDate', nodeId: NodeIds.healthOwnerFibromyalgiaOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthOwnerFibromyalgiaTypeOfOperationPlanned },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerFibromyalgiaOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerFibromyalgiaOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerFibromyalgiaHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerFibromyalgiaAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerFibromyalgiaAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerFibromyalgiaAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerFibromyalgiaAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerFibromyalgiaAbsenceRecoveryDate },
                    { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerFibromyalgiaAbsenceNotRecoveredDetails },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerFibromyalgiaHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthOwnerFibromyalgiaHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerFibromyalgiaHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerFibromyalgiaHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthOwnerFibromyalgiaHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthOwnerFibromyalgiaHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthOwnerFibromyalgiaHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerFibromyalgiaHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerFibromyalgiaHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerFibromyalgiaHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerFibromyalgiaHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerFibromyalgiaHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerFibromyalgiaHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthOwnerFibromyalgiaHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthOwnerFibromyalgiaHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerFibromyalgiaHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerFibromyalgiaHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerFibromyalgiaMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerFibromyalgiaMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthOwnerFibromyalgiaMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthOwnerFibromyalgiaMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthOwnerFibromyalgiaMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'replacementJoint',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerReplacementJointIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerReplacementJointPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerReplacementJointDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerReplacementJointPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthOwnerReplacementJointPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthOwnerReplacementJointPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerReplacementJointPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerReplacementJointPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerReplacementJointHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerReplacementJointHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerReplacementJointMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerReplacementJointHadOperation },
                { path: 'performedOrPlanned', nodeId: NodeIds.healthOwnerReplacementJointOperationPerformedOrPlanned },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthOwnerReplacementJointTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthOwnerReplacementJointOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthOwnerReplacementJointTypeOfOperationPlanned },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerReplacementJointOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerReplacementJointOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerReplacementJointHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerReplacementJointAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerReplacementJointAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerReplacementJointAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerReplacementJointAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerReplacementJointAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthOwnerReplacementJointAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerReplacementJointHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthOwnerReplacementJointHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerReplacementJointHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerReplacementJointHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthOwnerReplacementJointHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthOwnerReplacementJointHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthOwnerReplacementJointHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerReplacementJointHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerReplacementJointHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerReplacementJointHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerReplacementJointHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerReplacementJointHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerReplacementJointHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthOwnerReplacementJointHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthOwnerReplacementJointHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerReplacementJointHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerReplacementJointHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerReplacementJointMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerReplacementJointMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthOwnerReplacementJointMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthOwnerReplacementJointMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthOwnerReplacementJointMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'sprain',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerMusculoskeletalSprainIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerMusculoskeletalSprainPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerMusculoskeletalSprainDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerMusculoskeletalSprainPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthOwnerMusculoskeletalSprainPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthOwnerMusculoskeletalSprainPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerMusculoskeletalSprainPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerMusculoskeletalSprainPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasTakenTestsOrExams },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthOwnerMusculoskeletalSprainMedicationWasPrescribed,
            },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerMusculoskeletalSprainHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthOwnerMusculoskeletalSprainOperationPerformedDate },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerMusculoskeletalSprainOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerMusculoskeletalSprainOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerMusculoskeletalSprainHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerMusculoskeletalSprainAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerMusculoskeletalSprainAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerMusculoskeletalSprainAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerMusculoskeletalSprainAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerMusculoskeletalSprainAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthOwnerMusculoskeletalSprainAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerMusculoskeletalSprainMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalSprainMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'fracture',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerMusculoskeletalFractureIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerMusculoskeletalFracturePreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerMusculoskeletalFractureDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerMusculoskeletalFracturePainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthOwnerMusculoskeletalFracturePainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthOwnerMusculoskeletalFracturePainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerMusculoskeletalFracturePainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerMusculoskeletalFracturePainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasTakenTestsOrExams },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthOwnerMusculoskeletalFractureMedicationWasPrescribed,
            },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerMusculoskeletalFractureHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthOwnerMusculoskeletalFractureOperationPerformedDate },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerMusculoskeletalFractureOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerMusculoskeletalFractureOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerMusculoskeletalFractureHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerMusculoskeletalFractureAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerMusculoskeletalFractureAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerMusculoskeletalFractureAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerMusculoskeletalFractureAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerMusculoskeletalFractureAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthOwnerMusculoskeletalFractureAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerMusculoskeletalFractureMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalFractureMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'bursitis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerBursitisIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerBursitisPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerBursitisDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerBursitisPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthOwnerBursitisPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthOwnerBursitisPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerBursitisPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerBursitisPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerBursitisHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerBursitisHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerBursitisMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerBursitisHadOperation },
                { path: 'performedOrPlanned', nodeId: NodeIds.healthOwnerBursitisOperationPerformedOrPlanned },
                { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthOwnerBursitisTypeOfOperationPerformed },
                { path: 'performedDate', nodeId: NodeIds.healthOwnerBursitisOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthOwnerBursitisTypeOfOperationPlanned },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerBursitisOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerBursitisOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerBursitisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerBursitisAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerBursitisAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerBursitisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerBursitisAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerBursitisAbsenceRecoveryDate },
                    { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerBursitisAbsenceNotRecoveredDetails },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerBursitisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthOwnerBursitisHasConsultedDoctorsProfession,
                },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerBursitisHasConsultedDoctorsProfessionDetails,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerBursitisHasConsultedDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthOwnerBursitisHasConsultedDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthOwnerBursitisHasConsultedDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthOwnerBursitisHasConsultedDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerBursitisHasConsultedDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerBursitisHasConsultedDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerBursitisHasConsultedDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerBursitisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerBursitisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerBursitisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                {
                  path: 'type',
                  nodeId: NodeIds.healthOwnerBursitisHasTakenTestsOrExamsType,
                },
                {
                  path: 'date',
                  nodeId: NodeIds.healthOwnerBursitisHasTakenTestsOrExamsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerBursitisHasTakenTestsOrExamsResult,
                },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerBursitisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerBursitisMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerBursitisMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthOwnerBursitisMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthOwnerBursitisMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthOwnerBursitisMedicationEndDate,
                },
              ],
            },
          ],
        },
        {
          path: 'tendonitis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerTendonitisIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerTendonitisPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerTendonitisDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerTendonitisPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthOwnerTendonitisPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthOwnerTendonitisPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerTendonitisPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerTendonitisPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerTendonitisHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerTendonitisHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerTendonitisMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerTendonitisHadOperation },
                { path: 'performedOrPlanned', nodeId: NodeIds.healthOwnerTendonitisOperationPerformedOrPlanned },
                { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthOwnerTendonitisTypeOfOperationPerformed },
                { path: 'performedDate', nodeId: NodeIds.healthOwnerTendonitisOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthOwnerTendonitisTypeOfOperationPlanned },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerTendonitisOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerTendonitisOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerTendonitisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerTendonitisAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerTendonitisAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerTendonitisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerTendonitisAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerTendonitisAbsenceRecoveryDate },
                    { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerTendonitisAbsenceNotRecoveredDetails },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerTendonitisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerTendonitisHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerTendonitisHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerTendonitisHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerTendonitisHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerTendonitisHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerTendonitisHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerTendonitisHasConsultedDoctorsResult },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerTendonitisHasConsultedDoctorsFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerTendonitisHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerTendonitisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerTendonitisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerTendonitisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthOwnerTendonitisHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthOwnerTendonitisHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthOwnerTendonitisHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerTendonitisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerTendonitisMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerTendonitisMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerTendonitisMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerTendonitisMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerTendonitisMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'epicondylitis',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerEpicondylitisIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerEpicondylitisPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerEpicondylitisDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerEpicondylitisPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthOwnerEpicondylitisPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthOwnerEpicondylitisPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerEpicondylitisPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerEpicondylitisPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerEpicondylitisHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerEpicondylitisHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerEpicondylitisMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerEpicondylitisHadOperation },
                { path: 'performedOrPlanned', nodeId: NodeIds.healthOwnerEpicondylitisOperationPerformedOrPlanned },
                { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthOwnerEpicondylitisTypeOfOperationPerformed },
                { path: 'performedDate', nodeId: NodeIds.healthOwnerEpicondylitisOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthOwnerEpicondylitisTypeOfOperationPlanned },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerEpicondylitisOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerEpicondylitisOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerEpicondylitisHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerEpicondylitisAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerEpicondylitisAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerEpicondylitisAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerEpicondylitisAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerEpicondylitisAbsenceRecoveryDate },
                    { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerEpicondylitisAbsenceNotRecoveredDetails },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerEpicondylitisHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerEpicondylitisHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerEpicondylitisHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerEpicondylitisHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerEpicondylitisHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerEpicondylitisHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerEpicondylitisHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerEpicondylitisHasConsultedDoctorsResult },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerEpicondylitisHasConsultedDoctorsFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerEpicondylitisHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerEpicondylitisHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerEpicondylitisHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerEpicondylitisHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthOwnerEpicondylitisHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthOwnerEpicondylitisHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthOwnerEpicondylitisHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerEpicondylitisHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerEpicondylitisMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerEpicondylitisMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerEpicondylitisMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerEpicondylitisMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerEpicondylitisMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'ligamentTearMeniscus',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerLigamentTearMeniscusIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerLigamentTearMeniscusPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerLigamentTearMeniscusDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerLigamentTearMeniscusPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthOwnerLigamentTearMeniscusPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthOwnerLigamentTearMeniscusPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerLigamentTearMeniscusPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerLigamentTearMeniscusPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerLigamentTearMeniscusMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerLigamentTearMeniscusHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthOwnerLigamentTearMeniscusOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthOwnerLigamentTearMeniscusTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthOwnerLigamentTearMeniscusOperationPerformedDate },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthOwnerLigamentTearMeniscusTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerLigamentTearMeniscusOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerLigamentTearMeniscusOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerLigamentTearMeniscusHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerLigamentTearMeniscusAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerLigamentTearMeniscusAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerLigamentTearMeniscusAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerLigamentTearMeniscusAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerLigamentTearMeniscusAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthOwnerLigamentTearMeniscusAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasConsultedDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasConsultedDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerLigamentTearMeniscusHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerLigamentTearMeniscusMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerLigamentTearMeniscusMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerLigamentTearMeniscusMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerLigamentTearMeniscusMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerLigamentTearMeniscusMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'carpalTunnel',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerCarpalTunnelIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerCarpalTunnelPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerCarpalTunnelDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerCarpalTunnelPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthOwnerCarpalTunnelPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthOwnerCarpalTunnelPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerCarpalTunnelPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerCarpalTunnelPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerCarpalTunnelHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerCarpalTunnelHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerCarpalTunnelMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerCarpalTunnelHadOperation },
                { path: 'performedOrPlanned', nodeId: NodeIds.healthOwnerCarpalTunnelOperationPerformedOrPlanned },
                { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthOwnerCarpalTunnelTypeOfOperationPerformed },
                { path: 'performedDate', nodeId: NodeIds.healthOwnerCarpalTunnelOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthOwnerCarpalTunnelTypeOfOperationPlanned },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerCarpalTunnelOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerCarpalTunnelOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerCarpalTunnelHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerCarpalTunnelAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerCarpalTunnelAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerCarpalTunnelAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerCarpalTunnelAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerCarpalTunnelAbsenceRecoveryDate },
                    { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerCarpalTunnelAbsenceNotRecoveredDetails },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerCarpalTunnelHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerCarpalTunnelHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerCarpalTunnelHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerCarpalTunnelHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerCarpalTunnelHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerCarpalTunnelHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerCarpalTunnelHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerCarpalTunnelHasConsultedDoctorsResult },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerCarpalTunnelHasConsultedDoctorsFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerCarpalTunnelHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerCarpalTunnelHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerCarpalTunnelHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerCarpalTunnelHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthOwnerCarpalTunnelHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthOwnerCarpalTunnelHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthOwnerCarpalTunnelHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerCarpalTunnelHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerCarpalTunnelMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerCarpalTunnelMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerCarpalTunnelMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerCarpalTunnelMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerCarpalTunnelMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'muscularDystrophy',
          children: [
            { path: 'type', nodeId: NodeIds.healthOwnerMuscularDystrophyType },
            { path: 'diagnosisDate', nodeId: NodeIds.healthOwnerMuscularDystrophyDiagnosisDate },
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerMuscularDystrophyIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerMuscularDystrophyPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerMuscularDystrophyDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerMuscularDystrophyPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthOwnerMuscularDystrophyPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthOwnerMuscularDystrophyPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerMuscularDystrophyPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerMuscularDystrophyPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerMuscularDystrophyHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerMuscularDystrophyHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerMuscularDystrophyMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerMuscularDystrophyHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthOwnerMuscularDystrophyOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthOwnerMuscularDystrophyTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthOwnerMuscularDystrophyOperationPerformedDate },
                { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthOwnerMuscularDystrophyTypeOfOperationPlanned },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerMuscularDystrophyOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerMuscularDystrophyOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerMuscularDystrophyHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerMuscularDystrophyAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerMuscularDystrophyAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerMuscularDystrophyAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerMuscularDystrophyAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerMuscularDystrophyAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthOwnerMuscularDystrophyAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerMuscularDystrophyHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerMuscularDystrophyHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerMuscularDystrophyHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerMuscularDystrophyHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerMuscularDystrophyHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerMuscularDystrophyHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerMuscularDystrophyHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerMuscularDystrophyHasConsultedDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerMuscularDystrophyHasConsultedDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthOwnerMuscularDystrophyHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerMuscularDystrophyHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerMuscularDystrophyHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerMuscularDystrophyHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthOwnerMuscularDystrophyHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthOwnerMuscularDystrophyHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthOwnerMuscularDystrophyHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerMuscularDystrophyHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerMuscularDystrophyMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerMuscularDystrophyMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerMuscularDystrophyMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerMuscularDystrophyMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerMuscularDystrophyMedicationEndDate },
              ],
            },
          ],
        },
        {
          path: 'other',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerMusculoskeletalOtherIsSingleEpisode },
            { path: 'previousEpisodesCount', nodeId: NodeIds.healthOwnerMusculoskeletalOtherPreviousEpisodesCount },
            { path: 'dateSymptoms', nodeId: NodeIds.healthOwnerMusculoskeletalOtherDateSymptoms },
            {
              path: 'painLocation',
              children: [
                { path: 'location', nodeId: NodeIds.healthOwnerMusculoskeletalOtherPainLocation },
                { path: 'leftRight', nodeId: NodeIds.healthOwnerMusculoskeletalOtherPainLocationLeftRight },
                { path: 'other', nodeId: NodeIds.healthOwnerMusculoskeletalOtherPainLocationOther },
              ],
            },
            {
              path: 'painCause',
              children: [
                { path: 'cause', nodeId: NodeIds.healthOwnerMusculoskeletalOtherPainCause },
                { path: 'details', nodeId: NodeIds.healthOwnerMusculoskeletalOtherPainCauseDetails },
              ],
            },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasConsultedDoctors },
            { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasTakenTestsOrExams },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerMusculoskeletalOtherMedicationWasPrescribed },
            {
              path: 'operation',
              children: [
                { path: 'hadOperation', nodeId: NodeIds.healthOwnerMusculoskeletalOtherHadOperation },
                {
                  path: 'performedOrPlanned',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOtherOperationPerformedOrPlanned,
                },
                {
                  path: 'typeOfOperationPerformed',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOtherTypeOfOperationPerformed,
                },
                { path: 'performedDate', nodeId: NodeIds.healthOwnerMusculoskeletalOtherOperationPerformedDate },
                {
                  path: 'typeOfOperationPlanned',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOtherTypeOfOperationPlanned,
                },
                { path: 'plannedDate', nodeId: NodeIds.healthOwnerMusculoskeletalOtherOperationPlannedDate },
                { path: 'unknownDate', nodeId: NodeIds.healthOwnerMusculoskeletalOtherOperationUnknownDate },
              ],
            },
            {
              path: 'absence',
              children: [
                { path: 'hadAbsence', nodeId: NodeIds.healthOwnerMusculoskeletalOtherHadAbsence },
                { path: 'startDate', nodeId: NodeIds.healthOwnerMusculoskeletalOtherAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerMusculoskeletalOtherAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerMusculoskeletalOtherAbsenceFrequency },
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerMusculoskeletalOtherAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerMusculoskeletalOtherAbsenceRecoveryDate },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthOwnerMusculoskeletalOtherAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hasConsultedDoctorsDetails',
              nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasConsultedDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasConsultedDoctorsProfession },
                {
                  path: 'professionDetails',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasConsultedDoctorsProfessionDetails,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasConsultedDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasConsultedDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasConsultedDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasConsultedDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasConsultedDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasConsultedDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasConsultedDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasConsultedDoctorFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasConsultedDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'type', nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasTakenTestsOrExamsType },
                { path: 'date', nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasTakenTestsOrExamsDate },
                { path: 'result', nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasTakenTestsOrExamsResult },
                {
                  path: 'abnormalResult',
                  nodeId: NodeIds.healthOwnerMusculoskeletalOtherHasTakenTestsOrExamsDetailsAbnormalResult,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerMusculoskeletalOtherMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerMusculoskeletalOtherMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerMusculoskeletalOtherMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerMusculoskeletalOtherMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerMusculoskeletalOtherMedicationEndDate },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'neurologicalDisorders',
      children: [
        { path: 'epilepsyHasCondition', nodeId: NodeIds.healthOwnerEpilepsyHasCondition },
        { path: 'epilepsyDetails', nodeId: NodeIds.healthOwnerEpilepsyDetails },
        { path: 'neurologicalDisordersHasCondition', nodeId: NodeIds.healthOwnerNeurologicalDisordersHasCondition },
        { path: 'neurologicalDisordersConditions', nodeId: NodeIds.healthOwnerNeurologicalConditions },
        { path: 'cerebralPalsyDetails', nodeId: NodeIds.healthOwnerCerebralPalsyDetails },
        { path: 'paralysisDetails', nodeId: NodeIds.healthOwnerParalysisDetails },
        { path: 'concussionDetails', nodeId: NodeIds.healthOwnerConcussionDetails },
        { path: 'migrainesDetails', nodeId: NodeIds.healthOwnerMigrainesDetails },
        { path: 'blurredVisionOrOpticNeuritisDetails', nodeId: NodeIds.healthOwnerBlurredVisionOrOpticNeuritisDetails },
        { path: 'multipleSclerosisDetails', nodeId: NodeIds.healthOwnerMultipleSclerosisDetails },
        { path: 'alsDetails', nodeId: NodeIds.healthOwnerAlsDetails },
        { path: 'parkinsonsDetails', nodeId: NodeIds.healthOwnerParkinsonsDetails },
        { path: 'alzheimerDetails', nodeId: NodeIds.healthOwnerAlzheimerDetails },
        { path: 'lossOfConsciousnessDetails', nodeId: NodeIds.healthOwnerLossOfConsciousnessDetails },
        { path: 'numbnessDetails', nodeId: NodeIds.healthOwnerNumbnessDetails },
        { path: 'memoryLossDetails', nodeId: NodeIds.healthOwnerMemoryLossDetails },
        { path: 'degenerativeDiseaseDetails', nodeId: NodeIds.healthOwnerDegenerativeDiseaseDetails },
        { path: 'anyOtherCognitiveImpairmentDetails', nodeId: NodeIds.healthOwnerAnyOtherCognitiveImpairmentDetails },
      ],
    },
    {
      path: 'mentalHealth',
      children: [
        {
          path: 'insomnia',
          children: [
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthOwnerInsomniaMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthOwnerInsomniaHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthOwnerInsomniaHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'hadAbsence',
              nodeId: NodeIds.healthOwnerInsomniaHadAbsence,
            },
            {
              path: 'absence',
              children: [
                {
                  path: 'recovery',
                  children: [
                    {
                      path: 'hasRecovered',
                      nodeId: NodeIds.healthOwnerInsomniaAbsenceHasRecovered,
                    },
                    {
                      path: 'recoveryDate',
                      nodeId: NodeIds.healthOwnerInsomniaAbsenceRecoveryDate,
                    },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthOwnerInsomniaAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerInsomniaMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerInsomniaMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthOwnerInsomniaMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthOwnerInsomniaMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthOwnerInsomniaMedicationEndDate,
                },
                {
                  path: 'stillUses',
                  nodeId: NodeIds.healthOwnerInsomniaMedicationStillUses,
                },
                {
                  path: 'hasChanged',
                  nodeId: NodeIds.healthOwnerInsomniaMedicationHasChanged,
                },
                {
                  path: 'hasChangedDetails',
                  nodeId: NodeIds.healthOwnerInsomniaMedicationHasChangedDetails,
                },
              ],
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthOwnerInsomniaHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthOwnerInsomniaHasOrWillConsultDoctorsProfession,
                },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthOwnerInsomniaHasOrWillConsultDoctorsProfessionOther,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerInsomniaHasOrWillConsultDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthOwnerInsomniaHasOrWillConsultDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthOwnerInsomniaHasOrWillConsultDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthOwnerInsomniaHasOrWillConsultDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerInsomniaHasOrWillConsultDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerInsomniaHasOrWillConsultDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerInsomniaHasOrWillConsultDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerInsomniaHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerInsomniaHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'hadAbsenceDetails',
              nodeId: NodeIds.healthOwnerInsomniaHadAbsenceDetails,
              isCollection: true,
              children: [
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthOwnerInsomniaAbsenceStartDate,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerInsomniaAbsenceFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerInsomniaAbsenceFrequency,
                },
              ],
            },
          ],
        },
        {
          path: 'bipolarDisorder',
          children: [
            {
              path: 'isSingleEpisode',
              nodeId: NodeIds.healthOwnerBipolarDisorderIsSingleEpisode,
            },
            {
              path: 'diagnosticDate',
              nodeId: NodeIds.healthOwnerBipolarDisorderDiagnosticDate,
            },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthOwnerBipolarDisorderMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthOwnerBipolarDisorderHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthOwnerBipolarDisorderHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'went-to-emergency-department',
              nodeId: NodeIds.healthOwnerBipolarDisorderWentToEmergencyDepartment,
            },
            {
              path: 'was-hospitalized',
              nodeId: NodeIds.healthOwnerBipolarDisorderWasHospitalized,
            },
            {
              path: 'suicidalThoughtsAndAttempt',
              children: [
                {
                  path: 'hadSuicidalThoughtsAndAttempt',
                  nodeId: NodeIds.healthOwnerBipolarDisorderHadSuicidalThoughtsAndAttempt,
                },
                {
                  path: 'choices',
                  nodeId: NodeIds.healthOwnerBipolarDisorderSuicidalThoughtsAndAttemptChoices,
                },
                {
                  path: 'suicidalIdeation',
                  children: [
                    {
                      path: 'frequencyNumber',
                      nodeId: NodeIds.healthOwnerBipolarDisorderSuicidalThoughtsFrequencyNumber,
                    },
                    {
                      path: 'frequency',
                      nodeId: NodeIds.healthOwnerBipolarDisorderSuicidalThoughtsFrequency,
                    },
                  ],
                },
                {
                  path: 'attemptedSuicide',
                  children: [
                    {
                      path: 'attemptsNumber',
                      nodeId: NodeIds.healthOwnerBipolarDisorderSuicideAttemptsNumber,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hadAbsence',
              nodeId: NodeIds.healthOwnerBipolarDisorderHadAbsence,
            },
            {
              path: 'absence',
              children: [
                {
                  path: 'recovery',
                  children: [
                    {
                      path: 'hasRecovered',
                      nodeId: NodeIds.healthOwnerBipolarDisorderAbsenceHasRecovered,
                    },
                    {
                      path: 'recoveryDate',
                      nodeId: NodeIds.healthOwnerBipolarDisorderAbsenceRecoveryDate,
                    },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthOwnerBipolarDisorderAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'wasHospitalizedDetails',
              nodeId: NodeIds.healthOwnerBipolarDisorderWasHospitalizedDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthOwnerBipolarDisorderWasHospitalizedDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerBipolarDisorderWasHospitalizedFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerBipolarDisorderWasHospitalizedFrequency },
                { path: 'location', nodeId: NodeIds.healthOwnerBipolarDisorderWasHospitalizedLocation },
              ],
            },
            {
              path: 'suicidalThoughtsDetails',
              nodeId: NodeIds.healthOwnerBipolarDisorderSuicidalThoughtsDetails,
              isCollection: true,
              children: [{ path: 'date', nodeId: NodeIds.healthOwnerBipolarDisorderSuicidalThoughtsDate }],
            },
            {
              path: 'suicideAttemptsDetails',
              nodeId: NodeIds.healthOwnerBipolarDisorderSuicideAttemptsDetails,
              isCollection: true,
              children: [{ path: 'date', nodeId: NodeIds.healthOwnerBipolarDisorderSuicideAttemptsDate }],
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthOwnerBipolarDisorderHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerBipolarDisorderHasOrWillConsultDoctorsProfession },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthOwnerBipolarDisorderHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerBipolarDisorderHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerBipolarDisorderHasOrWillConsultDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerBipolarDisorderHasOrWillConsultDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerBipolarDisorderHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerBipolarDisorderHasOrWillConsultDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerBipolarDisorderHasOrWillConsultDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthOwnerBipolarDisorderHasOrWillConsultDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerBipolarDisorderHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerBipolarDisorderHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'wentToEmergencyDepartmentDetails',
              nodeId: NodeIds.healthOwnerBipolarDisorderWentToEmergencyDepartmentDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthOwnerBipolarDisorderWentToEmergencyDepartmentDate },
                { path: 'location', nodeId: NodeIds.healthOwnerBipolarDisorderWentToEmergencyDepartmentLocation },
              ],
            },
            {
              path: 'hadAbsenceDetails',
              nodeId: NodeIds.healthOwnerBipolarDisorderHadAbsenceDetails,
              isCollection: true,
              children: [
                { path: 'startDate', nodeId: NodeIds.healthOwnerBipolarDisorderAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerBipolarDisorderAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerBipolarDisorderAbsenceFrequency },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerBipolarDisorderMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerBipolarDisorderMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerBipolarDisorderMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerBipolarDisorderMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerBipolarDisorderMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthOwnerBipolarDisorderMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthOwnerBipolarDisorderMedicationHasChanged },
                { path: 'hasChangedDetails', nodeId: NodeIds.healthOwnerBipolarDisorderMedicationHasChangedDetails },
              ],
            },
          ],
        },
        {
          path: 'psychosis',
          children: [
            {
              path: 'isSingleEpisode',
              nodeId: NodeIds.healthOwnerPsychosisIsSingleEpisode,
            },
            {
              path: 'diagnosticDate',
              nodeId: NodeIds.healthOwnerPsychosisDiagnosticDate,
            },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthOwnerPsychosisMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthOwnerPsychosisHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthOwnerPsychosisHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'went-to-emergency-department',
              nodeId: NodeIds.healthOwnerPsychosisWentToEmergencyDepartment,
            },
            {
              path: 'was-hospitalized',
              nodeId: NodeIds.healthOwnerPsychosisWasHospitalized,
            },
            {
              path: 'suicidalThoughtsAndAttempt',
              children: [
                {
                  path: 'hadSuicidalThoughtsAndAttempt',
                  nodeId: NodeIds.healthOwnerPsychosisHadSuicidalThoughtsAndAttempt,
                },
                {
                  path: 'choices',
                  nodeId: NodeIds.healthOwnerPsychosisSuicidalThoughtsAndAttemptChoices,
                },
                {
                  path: 'suicidalIdeation',
                  children: [
                    {
                      path: 'frequencyNumber',
                      nodeId: NodeIds.healthOwnerPsychosisSuicidalThoughtsFrequencyNumber,
                    },
                    {
                      path: 'frequency',
                      nodeId: NodeIds.healthOwnerPsychosisSuicidalThoughtsFrequency,
                    },
                  ],
                },
                {
                  path: 'attemptedSuicide',
                  children: [
                    {
                      path: 'attemptsNumber',
                      nodeId: NodeIds.healthOwnerPsychosisSuicideAttemptsNumber,
                    },
                  ],
                },
              ],
            },
            {
              path: 'hadAbsence',
              nodeId: NodeIds.healthOwnerPsychosisHadAbsence,
            },
            {
              path: 'absence',
              children: [
                {
                  path: 'recovery',
                  children: [
                    {
                      path: 'hasRecovered',
                      nodeId: NodeIds.healthOwnerPsychosisAbsenceHasRecovered,
                    },
                    {
                      path: 'recoveryDate',
                      nodeId: NodeIds.healthOwnerPsychosisAbsenceRecoveryDate,
                    },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthOwnerPsychosisAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'suicideAttemptsDetails',
              nodeId: NodeIds.healthOwnerPsychosisSuicideAttemptsDetails,
              isCollection: true,
              children: [{ path: 'date', nodeId: NodeIds.healthOwnerPsychosisSuicideAttemptsDate }],
            },
            {
              path: 'suicidalThoughtsDetails',
              nodeId: NodeIds.healthOwnerPsychosisSuicidalThoughtsDetails,
              isCollection: true,
              children: [{ path: 'date', nodeId: NodeIds.healthOwnerPsychosisSuicidalThoughtsDate }],
            },
            {
              path: 'hadAbsenceDetails',
              nodeId: NodeIds.healthOwnerPsychosisHadAbsenceDetails,
              isCollection: true,
              children: [
                { path: 'startDate', nodeId: NodeIds.healthOwnerPsychosisAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerPsychosisAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerPsychosisAbsenceFrequency },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerPsychosisMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerPsychosisMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerPsychosisMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerPsychosisMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerPsychosisMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthOwnerPsychosisMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthOwnerPsychosisMedicationHasChanged },
                { path: 'hasChangedDetails', nodeId: NodeIds.healthOwnerPsychosisMedicationHasChangedDetails },
              ],
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthOwnerPsychosisHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerPsychosisHasOrWillConsultDoctorsProfession },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthOwnerPsychosisHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerPsychosisHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerPsychosisHasOrWillConsultDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerPsychosisHasOrWillConsultDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerPsychosisHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerPsychosisHasOrWillConsultDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerPsychosisHasOrWillConsultDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthOwnerPsychosisHasOrWillConsultDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerPsychosisHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerPsychosisHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'wentToEmergencyDepartmentDetails',
              nodeId: NodeIds.healthOwnerPsychosisWentToEmergencyDepartmentDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthOwnerPsychosisWentToEmergencyDepartmentDate },
                { path: 'location', nodeId: NodeIds.healthOwnerPsychosisWentToEmergencyDepartmentLocation },
              ],
            },
            {
              path: 'wasHospitalizedDetails',
              nodeId: NodeIds.healthOwnerPsychosisWasHospitalizedDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthOwnerPsychosisWasHospitalizedDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerPsychosisWasHospitalizedFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerPsychosisWasHospitalizedFrequency },
                { path: 'location', nodeId: NodeIds.healthOwnerPsychosisWasHospitalizedLocation },
              ],
            },
          ],
        },
        {
          path: 'suicideAttempt',
          children: [
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthOwnerSuicideAttemptMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthOwnerSuicideAttemptHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthOwnerSuicideAttemptHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'was-hospitalized',
              nodeId: NodeIds.healthOwnerSuicideAttemptWasHospitalized,
            },
            { path: 'date', nodeId: NodeIds.healthOwnerSuicideAttemptDate },
            { path: 'reason', nodeId: NodeIds.healthOwnerSuicideAttemptReason },
            {
              path: 'moreThanOne',
              nodeId: NodeIds.healthOwnerSuicideAttemptHasMoreThanOneAttempt,
            },
            {
              path: 'howManyAttempts',
              nodeId: NodeIds.healthOwnerSuicideAttemptHowManyAttempts,
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerSuicideAttemptMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerSuicideAttemptMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerSuicideAttemptMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerSuicideAttemptMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerSuicideAttemptMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthOwnerSuicideAttemptMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthOwnerSuicideAttemptMedicationHasChanged },
                { path: 'hasChangedDetails', nodeId: NodeIds.healthOwnerSuicideAttemptMedicationHasChangedDetails },
              ],
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthOwnerSuicideAttemptHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerSuicideAttemptHasOrWillConsultDoctorsProfession },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthOwnerSuicideAttemptHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerSuicideAttemptHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerSuicideAttemptHasOrWillConsultDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerSuicideAttemptHasOrWillConsultDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerSuicideAttemptHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerSuicideAttemptHasOrWillConsultDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerSuicideAttemptHasOrWillConsultDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthOwnerSuicideAttemptHasOrWillConsultDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerSuicideAttemptHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerSuicideAttemptHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'wasHospitalizedDetails',
              nodeId: NodeIds.healthOwnerSuicideAttemptWasHospitalizedDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthOwnerSuicideAttemptWasHospitalizedDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerSuicideAttemptWasHospitalizedFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerSuicideAttemptWasHospitalizedFrequency },
                { path: 'location', nodeId: NodeIds.healthOwnerSuicideAttemptWasHospitalizedLocation },
              ],
            },
            {
              path: 'multipleAttemptsDetails',
              nodeId: NodeIds.healthOwnerSuicideAttemptMultipleAttemptsDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthOwnerSuicideAttemptMultipleAttemptsDate },
                { path: 'reason', nodeId: NodeIds.healthOwnerSuicideAttemptMultipleAttemptsReason },
              ],
            },
          ],
        },
        {
          path: 'eatingDisorder',
          children: [
            {
              path: 'diagnosticDate',
              nodeId: NodeIds.healthOwnerEatingDisorderDiagnosticDate,
            },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthOwnerEatingDisorderMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthOwnerEatingDisorderHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthOwnerEatingDisorderHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'was-hospitalized',
              nodeId: NodeIds.healthOwnerEatingDisorderWasHospitalized,
            },
            {
              path: 'isControlled',
              nodeId: NodeIds.healthOwnerEatingDisorderIsControlled,
            },
            { path: 'notControlledDetails', nodeId: NodeIds.healthOwnerEatingDisorderNotControlledDetails },
            {
              path: 'hasStableWeightDate',
              nodeId: NodeIds.healthOwnerEatingDisorderHasStableWeightDate,
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthOwnerEatingDisorderHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerEatingDisorderHasOrWillConsultDoctorsProfession },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthOwnerEatingDisorderHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerEatingDisorderHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerEatingDisorderHasOrWillConsultDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerEatingDisorderHasOrWillConsultDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerEatingDisorderHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerEatingDisorderHasOrWillConsultDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerEatingDisorderHasOrWillConsultDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthOwnerEatingDisorderHasOrWillConsultDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerEatingDisorderHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerEatingDisorderHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'wasHospitalizedDetails',
              nodeId: NodeIds.healthOwnerEatingDisorderWasHospitalizedDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthOwnerEatingDisorderWasHospitalizedDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerEatingDisorderWasHospitalizedFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerEatingDisorderWasHospitalizedFrequency },
                { path: 'location', nodeId: NodeIds.healthOwnerEatingDisorderWasHospitalizedLocation },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerEatingDisorderMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerEatingDisorderMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerEatingDisorderMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerEatingDisorderMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerEatingDisorderMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthOwnerEatingDisorderMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthOwnerEatingDisorderMedicationHasChanged },
                { path: 'hasChangedDetails', nodeId: NodeIds.healthOwnerEatingDisorderMedicationHasChangedDetails },
              ],
            },
          ],
        },
        {
          path: 'attentionDeficitHyperactivityDisorder',
          children: [
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthOwnerAddAdhdMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthOwnerAddAdhdHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthOwnerAddAdhdHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'isControlled',
              nodeId: NodeIds.healthOwnerAddAdhdIsControlled,
            },
            {
              path: 'hasStableWeightDate',
              nodeId: NodeIds.healthOwnerAddAdhdHasStableWeightDate,
            },
            { path: 'notControlledDetails', nodeId: NodeIds.healthOwnerAddAdhdNotControlledDetails },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerAddAdhdMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerAddAdhdMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerAddAdhdMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerAddAdhdMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerAddAdhdMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthOwnerAddAdhdMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthOwnerAddAdhdMedicationHasChanged },
                { path: 'hasChangedDetails', nodeId: NodeIds.healthOwnerAddAdhdMedicationHasChangedDetails },
              ],
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthOwnerAddAdhdHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerAddAdhdHasOrWillConsultDoctorsProfession },
                { path: 'professionOther', nodeId: NodeIds.healthOwnerAddAdhdHasOrWillConsultDoctorsProfessionOther },
                { path: 'name', nodeId: NodeIds.healthOwnerAddAdhdHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerAddAdhdHasOrWillConsultDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerAddAdhdHasOrWillConsultDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerAddAdhdHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerAddAdhdHasOrWillConsultDoctorsResult },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerAddAdhdHasOrWillConsultDoctorsFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerAddAdhdHasOrWillConsultDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerAddAdhdHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerAddAdhdHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
          ],
        },
        {
          path: 'autismSpectrumDisorder',
          children: [
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerAutismMedicationWasPrescribed },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthOwnerAutismHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthOwnerAutismHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'performsDailyActivitiesWithoutHelp',
              nodeId: NodeIds.healthOwnerAutismPerformsDailyActivitiesWithoutHelp,
            },
            {
              path: 'performsDailyActivitiesWithoutHelpDetails',
              nodeId: NodeIds.healthOwnerAutismPerformsDailyActivitiesWithoutHelpDetails,
            },
            {
              path: 'needsAccommodation',
              nodeId: NodeIds.healthOwnerAutismNeedsAccommodation,
            },
            {
              path: 'accommodationDetails',
              nodeId: NodeIds.healthOwnerAutismAccommodationDetails,
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthOwnerAutismHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerAutismHasOrWillConsultDoctorsProfession },
                { path: 'professionOther', nodeId: NodeIds.healthOwnerAutismHasOrWillConsultDoctorsProfessionOther },
                { path: 'name', nodeId: NodeIds.healthOwnerAutismHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerAutismHasOrWillConsultDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerAutismHasOrWillConsultDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerAutismHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerAutismHasOrWillConsultDoctorsResult },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerAutismHasOrWillConsultDoctorsFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerAutismHasOrWillConsultDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerAutismHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerAutismHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerAutismMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerAutismMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerAutismMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerAutismMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerAutismMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthOwnerAutismMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthOwnerAutismMedicationHasChanged },
                { path: 'hasChangedDetails', nodeId: NodeIds.healthOwnerAutismMedicationHasChangedDetails },
              ],
            },
          ],
        },
        {
          path: 'intellectualImpairment',
          children: [
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthOwnerIntellectualImpairmentMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthOwnerIntellectualImpairmentHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthOwnerIntellectualImpairmentHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'performsDailyActivitiesWithoutHelp',
              nodeId: NodeIds.healthOwnerIntellectualImpairmentPerformsDailyActivitiesWithoutHelp,
            },
            {
              path: 'performsDailyActivitiesWithoutHelpDetails',
              nodeId: NodeIds.healthOwnerIntellectualImpairmentPerformsDailyActivitiesWithoutHelpDetails,
            },
            {
              path: 'needsAccommodation',
              nodeId: NodeIds.healthOwnerIntellectualImpairmentNeedsAccommodation,
            },
            {
              path: 'accommodationDetails',
              nodeId: NodeIds.healthOwnerIntellectualImpairmentAccommodationDetails,
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsProfession,
                },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsAddress },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsFirstDate,
                },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerIntellectualImpairmentHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerIntellectualImpairmentMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerIntellectualImpairmentMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerIntellectualImpairmentMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerIntellectualImpairmentMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerIntellectualImpairmentMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthOwnerIntellectualImpairmentMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthOwnerIntellectualImpairmentMedicationHasChanged },
                {
                  path: 'hasChangedDetails',
                  nodeId: NodeIds.healthOwnerIntellectualImpairmentMedicationHasChangedDetails,
                },
              ],
            },
          ],
        },
        {
          path: 'downSyndrome',
          children: [
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthOwnerDownSyndromeMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthOwnerDownSyndromeHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthOwnerDownSyndromeHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'performsDailyActivitiesWithoutHelp',
              nodeId: NodeIds.healthOwnerDownSyndromePerformsDailyActivitiesWithoutHelp,
            },
            {
              path: 'performsDailyActivitiesWithoutHelpDetails',
              nodeId: NodeIds.healthOwnerDownSyndromePerformsDailyActivitiesWithoutHelpDetails,
            },
            {
              path: 'needsAccommodation',
              nodeId: NodeIds.healthOwnerDownSyndromeNeedsAccommodation,
            },
            {
              path: 'accommodationDetails',
              nodeId: NodeIds.healthOwnerDownSyndromeAccommodationDetails,
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthOwnerDownSyndromeHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerDownSyndromeHasOrWillConsultDoctorsProfession },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthOwnerDownSyndromeHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerDownSyndromeHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerDownSyndromeHasOrWillConsultDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerDownSyndromeHasOrWillConsultDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerDownSyndromeHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerDownSyndromeHasOrWillConsultDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerDownSyndromeHasOrWillConsultDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthOwnerDownSyndromeHasOrWillConsultDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerDownSyndromeHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerDownSyndromeHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerDownSyndromeMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerDownSyndromeMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerDownSyndromeMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerDownSyndromeMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerDownSyndromeMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthOwnerDownSyndromeMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthOwnerDownSyndromeMedicationHasChanged },
                { path: 'hasChangedDetails', nodeId: NodeIds.healthOwnerDownSyndromeMedicationHasChangedDetails },
              ],
            },
          ],
        },
        {
          path: 'otherMentalHealth',
          children: [
            {
              path: 'disorderType',
              nodeId: NodeIds.healthOwnerMentalHealthOtherDisorderType,
            },
            { path: 'date', nodeId: NodeIds.healthOwnerMentalHealthOtherDate },
            {
              path: 'hasDiagnostic',
              nodeId: NodeIds.healthOwnerMentalHealthOtherHasDiagnostic,
            },
            {
              path: 'diagnosticResult',
              nodeId: NodeIds.healthOwnerMentalHealthOtherDiagnosticResult,
            },
            {
              path: 'diagnosticDate',
              nodeId: NodeIds.healthOwnerMentalHealthOtherDiagnosticDate,
            },
            {
              path: 'anyOtherTests',
              nodeId: NodeIds.healthOwnerMentalHealthOtherHasTakenTestsOrExams,
            },
            {
              path: 'medicationWasPrescribed',
              nodeId: NodeIds.healthOwnerMentalHealthOtherMedicationWasPrescribed,
            },
            {
              path: 'hasConsultedDoctors',
              nodeId: NodeIds.healthOwnerMentalHealthOtherHasConsultedDoctors,
            },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthOwnerMentalHealthOtherHasConsultedOtherDoctorsOrTherapists,
            },
            {
              path: 'went-to-emergency-department',
              nodeId: NodeIds.healthOwnerMentalHealthOtherWentToEmergencyDepartment,
            },
            {
              path: 'was-hospitalized',
              nodeId: NodeIds.healthOwnerMentalHealthOtherWasHospitalized,
            },
            {
              path: 'hadAbsence',
              nodeId: NodeIds.healthOwnerMentalHealthOtherHadAbsence,
            },
            {
              path: 'absence',
              children: [
                {
                  path: 'recovery',
                  children: [
                    {
                      path: 'hasRecovered',
                      nodeId: NodeIds.healthOwnerMentalHealthOtherAbsenceHasRecovered,
                    },
                    {
                      path: 'recoveryDate',
                      nodeId: NodeIds.healthOwnerMentalHealthOtherAbsenceRecoveryDate,
                    },
                    {
                      path: 'notRecoveredDetails',
                      nodeId: NodeIds.healthOwnerMentalHealthOtherAbsenceNotRecoveredDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerMentalHealthOtherMedicationDetails,
              isCollection: true,
              children: [
                { path: 'name', nodeId: NodeIds.healthOwnerMentalHealthOtherMedicationName },
                { path: 'dosage', nodeId: NodeIds.healthOwnerMentalHealthOtherMedicationDosage },
                { path: 'startDate', nodeId: NodeIds.healthOwnerMentalHealthOtherMedicationStartDate },
                { path: 'endDate', nodeId: NodeIds.healthOwnerMentalHealthOtherMedicationEndDate },
                { path: 'stillUses', nodeId: NodeIds.healthOwnerMentalHealthOtherMedicationStillUses },
                { path: 'hasChanged', nodeId: NodeIds.healthOwnerMentalHealthOtherMedicationHasChanged },
                { path: 'hasChangedDetails', nodeId: NodeIds.healthOwnerMentalHealthOtherMedicationHasChangedDetails },
              ],
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthOwnerMentalHealthOtherHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                { path: 'profession', nodeId: NodeIds.healthOwnerMentalHealthOtherHasOrWillConsultDoctorsProfession },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthOwnerMentalHealthOtherHasOrWillConsultDoctorsProfessionOther,
                },
                { path: 'name', nodeId: NodeIds.healthOwnerMentalHealthOtherHasOrWillConsultDoctorsName },
                { path: 'address', nodeId: NodeIds.healthOwnerMentalHealthOtherHasOrWillConsultDoctorsAddress },
                { path: 'firstDate', nodeId: NodeIds.healthOwnerMentalHealthOtherHasOrWillConsultDoctorsFirstDate },
                { path: 'lastDate', nodeId: NodeIds.healthOwnerMentalHealthOtherHasOrWillConsultDoctorsLastDate },
                { path: 'result', nodeId: NodeIds.healthOwnerMentalHealthOtherHasOrWillConsultDoctorsResult },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerMentalHealthOtherHasOrWillConsultDoctorsFrequencyNumber,
                },
                { path: 'frequency', nodeId: NodeIds.healthOwnerMentalHealthOtherHasOrWillConsultDoctorsFrequency },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerMentalHealthOtherHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerMentalHealthOtherHasOrWillConsultDoctorsNextConsultationDate,
                },
              ],
            },

            {
              path: 'wentToEmergencyDepartmentDetails',
              nodeId: NodeIds.healthOwnerMentalHealthOtherWentToEmergencyDepartmentDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthOwnerMentalHealthOtherWentToEmergencyDepartmentDate },
                { path: 'location', nodeId: NodeIds.healthOwnerMentalHealthOtherWentToEmergencyDepartmentLocation },
              ],
            },
            {
              path: 'wasHospitalizedDetails',
              nodeId: NodeIds.healthOwnerMentalHealthOtherWasHospitalizedDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.healthOwnerMentalHealthOtherWasHospitalizedDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerMentalHealthOtherWasHospitalizedFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerMentalHealthOtherWasHospitalizedFrequency },
                { path: 'location', nodeId: NodeIds.healthOwnerMentalHealthOtherWasHospitalizedLocation },
              ],
            },
            {
              path: 'hadAbsenceDetails',
              nodeId: NodeIds.healthOwnerMentalHealthOtherhadAbsenceDetails,
              isCollection: true,
              children: [
                { path: 'startDate', nodeId: NodeIds.healthOwnerMentalHealthOtherAbsenceStartDate },
                { path: 'frequencyNumber', nodeId: NodeIds.healthOwnerMentalHealthOtherAbsenceFrequencyNumber },
                { path: 'frequency', nodeId: NodeIds.healthOwnerMentalHealthOtherAbsenceFrequency },
              ],
            },
            {
              path: 'hasTakenTestsOrExamsDetails',
              nodeId: NodeIds.healthOwnerMentalHealthOtherHasTakenTestsOrExamsDetails,
              isCollection: true,
              children: [
                { path: 'details', nodeId: NodeIds.healthOwnerMentalHealthOtherTestsOrExamsDetails },
                {
                  path: 'detailsDate',
                  nodeId: NodeIds.healthOwnerMentalHealthOtherOtherTestsOrExamsDetailsDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerMentalHealthOtherOtherTestsOrExamsDetailsResult,
                },
              ],
            },
          ],
        },
        {
          path: 'depression',
          children: [
            { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerDepressionIsSingleEpisode },
            { path: 'diagnosticDate', nodeId: NodeIds.healthOwnerDepressionDiagnosticDate },
            { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerDepressionMedicationWasPrescribed },
            { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerDepressionHasConsultedDoctors },
            {
              path: 'hasConsultedOtherDoctorOrTherapist',
              nodeId: NodeIds.healthOwnerDepressionHasConsultedOtherDoctorsOrTherapists,
            },
            { path: 'went-to-emergency-department', nodeId: NodeIds.healthOwnerDepressionWentToEmergencyDepartment },
            { path: 'was-hospitalized', nodeId: NodeIds.healthOwnerDepressionWasHospitalized },
            {
              path: 'suicidalThoughtsAndAttempt',
              children: [
                {
                  path: 'hadSuicidalThoughtsAndAttempt',
                  nodeId: NodeIds.healthOwnerDepressionHadSuicidalThoughtsAndAttempt,
                },
                {
                  path: 'choices',
                  nodeId: NodeIds.healthOwnerDepressionSuicidalThoughtsAndAttemptChoices,
                },
                {
                  path: 'suicidalIdeation',
                  children: [
                    {
                      path: 'frequencyNumber',
                      nodeId: NodeIds.healthOwnerDepressionSuicidalThoughtsFrequencyNumber,
                    },
                    {
                      path: 'frequency',
                      nodeId: NodeIds.healthOwnerDepressionSuicidalThoughtsFrequency,
                    },
                  ],
                },
                {
                  path: 'attemptedSuicide',
                  children: [
                    {
                      path: 'attemptsNumber',
                      nodeId: NodeIds.healthOwnerDepressionSuicideAttemptsNumber,
                    },
                  ],
                },
              ],
            },
            { path: 'hadAbsence', nodeId: NodeIds.healthOwnerDepressionHadAbsence },
            {
              path: 'absence',
              children: [
                {
                  path: 'recovery',
                  children: [
                    { path: 'hasRecovered', nodeId: NodeIds.healthOwnerDepressionAbsenceHasRecovered },
                    { path: 'recoveryDate', nodeId: NodeIds.healthOwnerDepressionAbsenceRecoveryDate },
                    { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerDepressionAbsenceNotRecoveredDetails },
                  ],
                },
              ],
            },
            {
              path: 'medicationDetails',
              nodeId: NodeIds.healthOwnerDepressionMedicationDetails,
              isCollection: true,
              children: [
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerDepressionMedicationName,
                },
                {
                  path: 'dosage',
                  nodeId: NodeIds.healthOwnerDepressionMedicationDosage,
                },
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthOwnerDepressionMedicationStartDate,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthOwnerDepressionMedicationEndDate,
                },
                {
                  path: 'stillUses',
                  nodeId: NodeIds.healthOwnerDepressionMedicationStillUses,
                },
              ],
            },
            {
              path: 'hasOrWillConsultDoctorsDetails',
              nodeId: NodeIds.healthOwnerDepressionHasOrWillConsultDoctorsDetails,
              isCollection: true,
              children: [
                {
                  path: 'profession',
                  nodeId: NodeIds.healthOwnerDepressionHasOrWillConsultDoctorsProfession,
                },
                {
                  path: 'professionOther',
                  nodeId: NodeIds.healthOwnerDepressionHasOrWillConsultDoctorsProfessionOther,
                },
                {
                  path: 'name',
                  nodeId: NodeIds.healthOwnerDepressionHasOrWillConsultDoctorsName,
                },
                {
                  path: 'address',
                  nodeId: NodeIds.healthOwnerDepressionHasOrWillConsultDoctorsAddress,
                },
                {
                  path: 'firstDate',
                  nodeId: NodeIds.healthOwnerDepressionHasOrWillConsultDoctorsFirstDate,
                },
                {
                  path: 'lastDate',
                  nodeId: NodeIds.healthOwnerDepressionHasOrWillConsultDoctorsLastDate,
                },
                {
                  path: 'result',
                  nodeId: NodeIds.healthOwnerDepressionHasOrWillConsultDoctorsResult,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerDepressionHasOrWillConsultDoctorsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerDepressionHasOrWillConsultDoctorsFrequency,
                },
                {
                  path: 'furtherConsultation',
                  nodeId: NodeIds.healthOwnerDepressionHasOrWillConsultDoctorsFurtherConsultation,
                },
                {
                  path: 'nextConsultationDate',
                  nodeId: NodeIds.healthOwnerDepressionHasOrWillConsultDoctorsNextConsultationDate,
                },
                {
                  path: 'nextConsultationDateUnknown',
                  nodeId: NodeIds.healthOwnerDepressionHasOrWillConsultDoctorsNextConsultationDateUnknown,
                },
              ],
            },
            {
              path: 'wentToEmergencyDepartmentDetails',
              nodeId: NodeIds.healthOwnerDepressionWentToEmergencyDepartmentDetails,
              isCollection: true,
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.healthOwnerDepressionWentToEmergencyDepartmentDate,
                },
                {
                  path: 'location',
                  nodeId: NodeIds.healthOwnerDepressionWentToEmergencyDepartmentLocation,
                },
              ],
            },
            {
              path: 'wasHospitalizedDetails',
              nodeId: NodeIds.healthOwnerDepressionWasHospitalizedDetails,
              isCollection: true,
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.healthOwnerDepressionWasHospitalizedDate,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerDepressionWasHospitalizedFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerDepressionWasHospitalizedFrequency,
                },
                {
                  path: 'location',
                  nodeId: NodeIds.healthOwnerDepressionWasHospitalizedLocation,
                },
                {
                  path: 'endDate',
                  nodeId: NodeIds.healthOwnerDepressionWasHospitalizedEndDate,
                },
              ],
            },
            {
              path: 'suicidalThoughtsDetails',
              nodeId: NodeIds.healthOwnerDepressionSuicidalThoughtsDetails,
              isCollection: true,
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.healthOwnerDepressionSuicidalThoughtsDate,
                },
              ],
            },
            {
              path: 'suicideAttemptsDetails',
              nodeId: NodeIds.healthOwnerDepressionSuicideAttemptsDetails,
              isCollection: true,
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.healthOwnerDepressionSuicideAttemptsDate,
                },
              ],
            },
            {
              path: 'hadAbsenceDetails',
              nodeId: NodeIds.healthOwnerDepressionHadAbsenceDetails,
              isCollection: true,
              children: [
                {
                  path: 'startDate',
                  nodeId: NodeIds.healthOwnerDepressionAbsenceStartDate,
                },
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerDepressionAbsenceFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerDepressionAbsenceFrequency,
                },
              ],
            },
          ],
        },
        {
          path: 'condition',
          children: [
            { path: 'hasCondition', nodeId: NodeIds.healthOwnerMentalHealthHasCondition },
            { path: 'applicableConditions', nodeId: NodeIds.healthOwnerMentalHealthApplicableConditions },
          ],
        },
      ],
    },
    {
      path: 'anxiety',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerAnxietyIsSingleEpisode },
        { path: 'diagnosticDate', nodeId: NodeIds.healthOwnerAnxietyDiagnosticDate },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerAnxietyMedicationWasPrescribed },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerAnxietyHasConsultedDoctors },
        {
          path: 'hasConsultedOtherDoctorOrTherapist',
          nodeId: NodeIds.healthOwnerAnxietyHasConsultedOtherDoctorsOrTherapists,
        },
        { path: 'went-to-emergency-department', nodeId: NodeIds.healthOwnerAnxietyWentToEmergencyDepartment },
        { path: 'was-hospitalized', nodeId: NodeIds.healthOwnerAnxietyWasHospitalized },
        {
          path: 'suicidalThoughtsAndAttempt',
          children: [
            {
              path: 'hadSuicidalThoughtsAndAttempt',
              nodeId: NodeIds.healthOwnerAnxietyHadSuicidalThoughtsAndAttempt,
            },
            {
              path: 'choices',
              nodeId: NodeIds.healthOwnerAnxietySuicidalThoughtsAndAttemptChoices,
            },
            {
              path: 'suicidalIdeation',
              children: [
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerAnxietySuicidalThoughtsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerAnxietySuicidalThoughtsFrequency,
                },
              ],
            },
            {
              path: 'attemptedSuicide',
              children: [
                {
                  path: 'attemptsNumber',
                  nodeId: NodeIds.healthOwnerAnxietySuicideAttemptsNumber,
                },
              ],
            },
          ],
        },
        { path: 'hadAbsence', nodeId: NodeIds.healthOwnerAnxietyHadAbsence },
        {
          path: 'absence',
          children: [
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthOwnerAnxietyAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthOwnerAnxietyAbsenceRecoveryDate },
                { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerAnxietyAbsenceNotRecoveredDetails },
              ],
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthOwnerAnxietyMedicationDetails,
          isCollection: true,
          children: [
            {
              path: 'name',
              nodeId: NodeIds.healthOwnerAnxietyMedicationName,
            },
            {
              path: 'dosage',
              nodeId: NodeIds.healthOwnerAnxietyMedicationDosage,
            },
            {
              path: 'startDate',
              nodeId: NodeIds.healthOwnerAnxietyMedicationStartDate,
            },
            {
              path: 'endDate',
              nodeId: NodeIds.healthOwnerAnxietyMedicationEndDate,
            },
            {
              path: 'stillUses',
              nodeId: NodeIds.healthOwnerAnxietyMedicationStillUses,
            },
          ],
        },
        {
          path: 'hasOrWillConsultDoctorsDetails',
          nodeId: NodeIds.healthOwnerAnxietyHasOrWillConsultDoctorsDetails,
          isCollection: true,
          children: [
            {
              path: 'profession',
              nodeId: NodeIds.healthOwnerAnxietyHasOrWillConsultDoctorsProfession,
            },
            {
              path: 'professionOther',
              nodeId: NodeIds.healthOwnerAnxietyHasOrWillConsultDoctorsProfessionOther,
            },
            {
              path: 'name',
              nodeId: NodeIds.healthOwnerAnxietyHasOrWillConsultDoctorsName,
            },
            {
              path: 'address',
              nodeId: NodeIds.healthOwnerAnxietyHasOrWillConsultDoctorsAddress,
            },
            {
              path: 'firstDate',
              nodeId: NodeIds.healthOwnerAnxietyHasOrWillConsultDoctorsFirstDate,
            },
            {
              path: 'lastDate',
              nodeId: NodeIds.healthOwnerAnxietyHasOrWillConsultDoctorsLastDate,
            },
            {
              path: 'result',
              nodeId: NodeIds.healthOwnerAnxietyHasOrWillConsultDoctorsResult,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerAnxietyHasOrWillConsultDoctorsFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthOwnerAnxietyHasOrWillConsultDoctorsFrequency,
            },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthOwnerAnxietyHasOrWillConsultDoctorsFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthOwnerAnxietyHasOrWillConsultDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'wentToEmergencyDepartmentDetails',
          nodeId: NodeIds.healthOwnerAnxietyWentToEmergencyDepartmentDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthOwnerAnxietyWentToEmergencyDepartmentDate,
            },
            {
              path: 'location',
              nodeId: NodeIds.healthOwnerAnxietyWentToEmergencyDepartmentLocation,
            },
          ],
        },
        {
          path: 'wasHospitalizedDetails',
          nodeId: NodeIds.healthOwnerAnxietyWasHospitalizedDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthOwnerAnxietyWasHospitalizedDate,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerAnxietyWasHospitalizedFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthOwnerAnxietyWasHospitalizedFrequency,
            },
            {
              path: 'location',
              nodeId: NodeIds.healthOwnerAnxietyWasHospitalizedLocation,
            },
          ],
        },
        {
          path: 'suicidalThoughtsDetails',
          nodeId: NodeIds.healthOwnerAnxietySuicidalThoughtsDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthOwnerAnxietySuicidalThoughtsDate,
            },
          ],
        },
        {
          path: 'suicideAttemptsDetails',
          nodeId: NodeIds.healthOwnerAnxietySuicideAttemptsDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthOwnerAnxietySuicideAttemptsDate,
            },
          ],
        },
        {
          path: 'hadAbsenceDetails',
          nodeId: NodeIds.healthOwnerAnxietyHadAbsenceDetails,
          isCollection: true,
          children: [
            {
              path: 'startDate',
              nodeId: NodeIds.healthOwnerAnxietyAbsenceStartDate,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerAnxietyAbsenceFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthOwnerAnxietyAbsenceFrequency,
            },
          ],
        },
      ],
    },
    {
      path: 'panicAttacks',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerPanicAttacksIsSingleEpisode },
        { path: 'diagnosticDate', nodeId: NodeIds.healthOwnerPanicAttacksDiagnosticDate },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerPanicAttacksMedicationWasPrescribed },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerPanicAttacksHasConsultedDoctors },
        {
          path: 'hasConsultedOtherDoctorOrTherapist',
          nodeId: NodeIds.healthOwnerPanicAttacksHasConsultedOtherDoctorsOrTherapists,
        },
        { path: 'went-to-emergency-department', nodeId: NodeIds.healthOwnerPanicAttacksWentToEmergencyDepartment },
        { path: 'was-hospitalized', nodeId: NodeIds.healthOwnerPanicAttacksWasHospitalized },
        {
          path: 'suicidalThoughtsAndAttempt',
          children: [
            {
              path: 'hadSuicidalThoughtsAndAttempt',
              nodeId: NodeIds.healthOwnerPanicAttacksHadSuicidalThoughtsAndAttempt,
            },
            {
              path: 'choices',
              nodeId: NodeIds.healthOwnerPanicAttacksSuicidalThoughtsAndAttemptChoices,
            },
            {
              path: 'suicidalIdeation',
              children: [
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerPanicAttacksSuicidalThoughtsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerPanicAttacksSuicidalThoughtsFrequency,
                },
              ],
            },
            {
              path: 'attemptedSuicide',
              children: [
                {
                  path: 'attemptsNumber',
                  nodeId: NodeIds.healthOwnerPanicAttacksSuicideAttemptsNumber,
                },
              ],
            },
          ],
        },
        { path: 'hadAbsence', nodeId: NodeIds.healthOwnerPanicAttacksHadAbsence },
        {
          path: 'absence',
          children: [
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthOwnerPanicAttacksAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthOwnerPanicAttacksAbsenceRecoveryDate },
                { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerPanicAttacksAbsenceNotRecoveredDetails },
              ],
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthOwnerPanicAttacksMedicationDetails,
          isCollection: true,
          children: [
            {
              path: 'name',
              nodeId: NodeIds.healthOwnerPanicAttacksMedicationName,
            },
            {
              path: 'dosage',
              nodeId: NodeIds.healthOwnerPanicAttacksMedicationDosage,
            },
            {
              path: 'startDate',
              nodeId: NodeIds.healthOwnerPanicAttacksMedicationStartDate,
            },
            {
              path: 'endDate',
              nodeId: NodeIds.healthOwnerPanicAttacksMedicationEndDate,
            },
            {
              path: 'stillUses',
              nodeId: NodeIds.healthOwnerPanicAttacksMedicationStillUses,
            },
          ],
        },
        {
          path: 'hasOrWillConsultDoctorsDetails',
          nodeId: NodeIds.healthOwnerPanicAttacksHasOrWillConsultDoctorsDetails,
          isCollection: true,
          children: [
            {
              path: 'profession',
              nodeId: NodeIds.healthOwnerPanicAttacksHasOrWillConsultDoctorsProfession,
            },
            {
              path: 'professionOther',
              nodeId: NodeIds.healthOwnerPanicAttacksHasOrWillConsultDoctorsProfessionOther,
            },
            {
              path: 'name',
              nodeId: NodeIds.healthOwnerPanicAttacksHasOrWillConsultDoctorsName,
            },
            {
              path: 'address',
              nodeId: NodeIds.healthOwnerPanicAttacksHasOrWillConsultDoctorsAddress,
            },
            {
              path: 'firstDate',
              nodeId: NodeIds.healthOwnerPanicAttacksHasOrWillConsultDoctorsFirstDate,
            },
            {
              path: 'lastDate',
              nodeId: NodeIds.healthOwnerPanicAttacksHasOrWillConsultDoctorsLastDate,
            },
            {
              path: 'result',
              nodeId: NodeIds.healthOwnerPanicAttacksHasOrWillConsultDoctorsResult,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerPanicAttacksHasOrWillConsultDoctorsFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthOwnerPanicAttacksHasOrWillConsultDoctorsFrequency,
            },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthOwnerPanicAttacksHasOrWillConsultDoctorsFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthOwnerPanicAttacksHasOrWillConsultDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'wentToEmergencyDepartmentDetails',
          nodeId: NodeIds.healthOwnerPanicAttacksWentToEmergencyDepartmentDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthOwnerPanicAttacksWentToEmergencyDepartmentDate,
            },
            {
              path: 'location',
              nodeId: NodeIds.healthOwnerPanicAttacksWentToEmergencyDepartmentLocation,
            },
          ],
        },
        {
          path: 'wasHospitalizedDetails',
          nodeId: NodeIds.healthOwnerPanicAttacksWasHospitalizedDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthOwnerPanicAttacksWasHospitalizedDate,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerPanicAttacksWasHospitalizedFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthOwnerPanicAttacksWasHospitalizedFrequency,
            },
            {
              path: 'location',
              nodeId: NodeIds.healthOwnerPanicAttacksWasHospitalizedLocation,
            },
          ],
        },
        {
          path: 'suicidalThoughtsDetails',
          nodeId: NodeIds.healthOwnerPanicAttacksSuicidalThoughtsDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthOwnerPanicAttacksSuicidalThoughtsDate,
            },
          ],
        },
        {
          path: 'suicideAttemptsDetails',
          nodeId: NodeIds.healthOwnerPanicAttacksSuicideAttemptsDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthOwnerPanicAttacksSuicideAttemptsDate,
            },
          ],
        },
        {
          path: 'hadAbsenceDetails',
          nodeId: NodeIds.healthOwnerPanicAttacksHadAbsenceDetails,
          isCollection: true,
          children: [
            {
              path: 'startDate',
              nodeId: NodeIds.healthOwnerPanicAttacksAbsenceStartDate,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerPanicAttacksAbsenceFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthOwnerPanicAttacksAbsenceFrequency,
            },
          ],
        },
      ],
    },
    {
      path: 'burnout',
      children: [
        { path: 'isSingleEpisode', nodeId: NodeIds.healthOwnerBurnoutIsSingleEpisode },
        { path: 'diagnosticDate', nodeId: NodeIds.healthOwnerBurnoutDiagnosticDate },
        { path: 'medicationWasPrescribed', nodeId: NodeIds.healthOwnerBurnoutMedicationWasPrescribed },
        { path: 'hasConsultedDoctors', nodeId: NodeIds.healthOwnerBurnoutHasConsultedDoctors },
        {
          path: 'hasConsultedOtherDoctorOrTherapist',
          nodeId: NodeIds.healthOwnerBurnoutHasConsultedOtherDoctorsOrTherapists,
        },
        { path: 'went-to-emergency-department', nodeId: NodeIds.healthOwnerBurnoutWentToEmergencyDepartment },
        { path: 'was-hospitalized', nodeId: NodeIds.healthOwnerBurnoutWasHospitalized },
        {
          path: 'suicidalThoughtsAndAttempt',
          children: [
            {
              path: 'hadSuicidalThoughtsAndAttempt',
              nodeId: NodeIds.healthOwnerBurnoutHadSuicidalThoughtsAndAttempt,
            },
            {
              path: 'choices',
              nodeId: NodeIds.healthOwnerBurnoutSuicidalThoughtsAndAttemptChoices,
            },
            {
              path: 'suicidalIdeation',
              children: [
                {
                  path: 'frequencyNumber',
                  nodeId: NodeIds.healthOwnerBurnoutSuicidalThoughtsFrequencyNumber,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.healthOwnerBurnoutSuicidalThoughtsFrequency,
                },
              ],
            },
            {
              path: 'attemptedSuicide',
              children: [
                {
                  path: 'attemptsNumber',
                  nodeId: NodeIds.healthOwnerBurnoutSuicideAttemptsNumber,
                },
              ],
            },
          ],
        },
        { path: 'hadAbsence', nodeId: NodeIds.healthOwnerBurnoutHadAbsence },
        {
          path: 'absence',
          children: [
            {
              path: 'recovery',
              children: [
                { path: 'hasRecovered', nodeId: NodeIds.healthOwnerBurnoutAbsenceHasRecovered },
                { path: 'recoveryDate', nodeId: NodeIds.healthOwnerBurnoutAbsenceRecoveryDate },
                { path: 'notRecoveredDetails', nodeId: NodeIds.healthOwnerBurnoutAbsenceNotRecoveredDetails },
              ],
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthOwnerBurnoutMedicationDetails,
          isCollection: true,
          children: [
            {
              path: 'name',
              nodeId: NodeIds.healthOwnerBurnoutMedicationName,
            },
            {
              path: 'dosage',
              nodeId: NodeIds.healthOwnerBurnoutMedicationDosage,
            },
            {
              path: 'startDate',
              nodeId: NodeIds.healthOwnerBurnoutMedicationStartDate,
            },
            {
              path: 'endDate',
              nodeId: NodeIds.healthOwnerBurnoutMedicationEndDate,
            },
            {
              path: 'stillUses',
              nodeId: NodeIds.healthOwnerBurnoutMedicationStillUses,
            },
          ],
        },
        {
          path: 'hasOrWillConsultDoctorsDetails',
          nodeId: NodeIds.healthOwnerBurnoutHasOrWillConsultDoctorsDetails,
          isCollection: true,
          children: [
            {
              path: 'profession',
              nodeId: NodeIds.healthOwnerBurnoutHasOrWillConsultDoctorsProfession,
            },
            {
              path: 'professionOther',
              nodeId: NodeIds.healthOwnerBurnoutHasOrWillConsultDoctorsProfessionOther,
            },
            {
              path: 'name',
              nodeId: NodeIds.healthOwnerBurnoutHasOrWillConsultDoctorsName,
            },
            {
              path: 'address',
              nodeId: NodeIds.healthOwnerBurnoutHasOrWillConsultDoctorsAddress,
            },
            {
              path: 'firstDate',
              nodeId: NodeIds.healthOwnerBurnoutHasOrWillConsultDoctorsFirstDate,
            },
            {
              path: 'lastDate',
              nodeId: NodeIds.healthOwnerBurnoutHasOrWillConsultDoctorsLastDate,
            },
            {
              path: 'result',
              nodeId: NodeIds.healthOwnerBurnoutHasOrWillConsultDoctorsResult,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerBurnoutHasOrWillConsultDoctorsFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthOwnerBurnoutHasOrWillConsultDoctorsFrequency,
            },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthOwnerBurnoutHasOrWillConsultDoctorsFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthOwnerBurnoutHasOrWillConsultDoctorsNextConsultationDate,
            },
            {
              path: 'nextConsultationDateUnknown',
              nodeId: NodeIds.healthOwnerBurnoutHasOrWillConsultDoctorsNextConsultationDateUnknown,
            },
          ],
        },
        {
          path: 'wentToEmergencyDepartmentDetails',
          nodeId: NodeIds.healthOwnerBurnoutWentToEmergencyDepartmentDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthOwnerBurnoutWentToEmergencyDepartmentDate,
            },
            {
              path: 'location',
              nodeId: NodeIds.healthOwnerBurnoutWentToEmergencyDepartmentLocation,
            },
          ],
        },
        {
          path: 'wasHospitalizedDetails',
          nodeId: NodeIds.healthOwnerBurnoutWasHospitalizedDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthOwnerBurnoutWasHospitalizedDate,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerBurnoutWasHospitalizedFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthOwnerBurnoutWasHospitalizedFrequency,
            },
            {
              path: 'location',
              nodeId: NodeIds.healthOwnerBurnoutWasHospitalizedLocation,
            },
            {
              path: 'endDate',
              nodeId: NodeIds.healthOwnerBurnoutWasHospitalizedEndDate,
            },
          ],
        },
        {
          path: 'suicidalThoughtsDetails',
          nodeId: NodeIds.healthOwnerBurnoutSuicidalThoughtsDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthOwnerBurnoutSuicidalThoughtsDate,
            },
          ],
        },
        {
          path: 'suicideAttemptsDetails',
          nodeId: NodeIds.healthOwnerBurnoutSuicideAttemptsDetails,
          isCollection: true,
          children: [
            {
              path: 'date',
              nodeId: NodeIds.healthOwnerBurnoutSuicideAttemptsDate,
            },
          ],
        },
        {
          path: 'hadAbsenceDetails',
          nodeId: NodeIds.healthOwnerBurnoutHadAbsenceDetails,
          isCollection: true,
          children: [
            {
              path: 'startDate',
              nodeId: NodeIds.healthOwnerBurnoutAbsenceStartDate,
            },
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthOwnerBurnoutAbsenceFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthOwnerBurnoutAbsenceFrequency,
            },
          ],
        },
      ],
    },
    {
      path: 'autoImmuneSystem',
      children: [
        { path: 'hasCondition', nodeId: NodeIds.healthOwnerAutoImmuneSystemHasCondition },
        { path: 'autoImmuneSystemChoices', nodeId: NodeIds.healthOwnerAutoImmuneSystemChoices },
        { path: 'aidsDetails', nodeId: NodeIds.healthOwnerAidsDetails },
        { path: 'hivDetails', nodeId: NodeIds.healthOwnerHivDetails },
        { path: 'lupusDetails', nodeId: NodeIds.healthOwnerLupusDetails },
        { path: 'sclerodermaDetails', nodeId: NodeIds.healthOwnerSclerodermaDetails },
        {
          path: 'otherAutoImmuneSystemDisorderDetails',
          nodeId: NodeIds.healthOwnerOtherAutoImmuneSystemDisorderDetails,
        },
      ],
    },
    {
      path: 'cancerOrTumor',
      children: [
        {
          path: 'tumor',
          children: [
            { path: 'bodyPart', nodeId: NodeIds.healthOwnerTumorBodyPart },
            { path: 'severity', nodeId: NodeIds.healthOwnerTumorSeverity },
          ],
        },
        { path: 'leukemiaDetails', nodeId: NodeIds.healthOwnerLeukemiaDetails },
        { path: 'cancerDetails', nodeId: NodeIds.healthOwnerCancerDetails },
        { path: 'noduleDetails', nodeId: NodeIds.healthOwnerNoduleDetails },
        { path: 'lymphNodeDisorderDetails', nodeId: NodeIds.healthOwnerLymphNodeDisorderDetails },
        { path: 'cancerOtherDetails', nodeId: NodeIds.healthOwnerCancerOtherDetails },
        {
          path: 'cancer',
          children: [
            { path: 'hasCondition', nodeId: NodeIds.healthOwnerCancerHasCondition },
            { path: 'applicableConditions', nodeId: NodeIds.healthOwnerCancerApplicableConditions },
          ],
        },
        {
          path: 'breastDisorder',
          children: [
            { path: 'hasCondition', nodeId: NodeIds.healthOwnerBreastDisorderHasCondition },
            { path: 'condition', nodeId: NodeIds.healthOwnerBreastDisorderApplicableConditions },
            { path: 'lumpDetails', nodeId: NodeIds.healthOwnerBreastDisorderLumpDetails },
            { path: 'bumpDetails', nodeId: NodeIds.healthOwnerBreastDisorderBumpDetails },
            { path: 'cystDetails', nodeId: NodeIds.healthOwnerBreastDisorderCystDetails },
            { path: 'otherDetails', nodeId: NodeIds.healthOwnerBreastDisorderOtherDetails },
          ],
        },
        { path: 'breastCystDetails', nodeId: NodeIds.healthOwnerBreastCystDetails },
        { path: 'ovarianCystDetails', nodeId: NodeIds.healthOwnerOvarianCystDetails },
      ],
    },
    {
      path: 'eyeEarNoseOrThroatDisorder',
      children: [
        { path: 'partialOrTotalBlindnessDetails', nodeId: NodeIds.healthOwnerPartialOrTotalBlindnessDetails },
        { path: 'macularDegenerationDetails', nodeId: NodeIds.healthOwnerMacularDegenerationDetails },
        { path: 'glaucomaDetails', nodeId: NodeIds.healthOwnerGlaucomaDetails },
        { path: 'partialOrTotalDeafnessDetails', nodeId: NodeIds.healthOwnerPartialOrTotalDeafnessDetails },
        { path: 'tinnitusDetails', nodeId: NodeIds.healthOwnerTinnitusDetails },
        { path: 'meniereDiseaseDetails', nodeId: NodeIds.healthOwnerMeniereDiseaseDetails },
        { path: 'labyrinthitisDetails', nodeId: NodeIds.healthOwnerLabyrinthitisDetails },
        {
          path: 'otherEyeEarNoseOrThroatDisorderDetails',
          nodeId: NodeIds.healthOwnerOtherEyeEarNoseOrThroatDisorderDetails,
        },
        { path: 'hasCondition', nodeId: NodeIds.healthOwnerEyeEarNoseThroatDisorderHasCondition },
        { path: 'applicableConditions', nodeId: NodeIds.healthOwnerEyeEarNoseThroatDisorderApplicableConditions },
        { path: 'otherDisorderDetails', nodeId: NodeIds.healthOwnerEyeEarNoseThroatOtherDisorderDetails },
      ],
    },
    {
      path: 'physiciansTestsAndMedicalConsultations',
      children: [
        { path: 'last5YearsTestsDetails', nodeId: NodeIds.healthOwnerLast5YearsTestsDetails },
        { path: 'last5YearsTestsAnyOtherDetails', nodeId: NodeIds.healthOwnerLast5YearsTestsAnyOtherDetails },
        { path: 'otherProfessionals', nodeId: NodeIds.healthOwnerOtherProfessionals },
        { path: 'last5YearsHospitalizedDetails', nodeId: NodeIds.healthOwnerLast5YearsHospitalizedDetails },
        {
          path: 'moreMedicalAction',
          children: [
            { path: 'pending', nodeId: NodeIds.healthOwnerMoreMedicalActionPending },
            { path: 'choices', nodeId: NodeIds.healthOwnerMoreMedicalActionChoices },
            { path: 'treatmentsDetails', nodeId: NodeIds.healthOwnerMoreMedicalActionTreatmentsDetails },
            { path: 'surgeryDetails', nodeId: NodeIds.healthOwnerMoreMedicalActionSurgeryDetails },
            { path: 'awaitingResultsDetails', nodeId: NodeIds.healthOwnerMoreMedicalActionAwaitingResultsDetails },
          ],
        },
        { path: 'diagnosticExamsOrTestsDetails', nodeId: NodeIds.healthOwnerDiagnosticExamsOrTestsDetails },
      ],
    },
    {
      path: 'familyHistory',
      children: [
        { path: 'hasFamilyHistory2', nodeId: NodeIds.healthOwnerHasFamilyHistory2 },
        { path: 'familyMedicalHistory2', nodeId: NodeIds.healthOwnerFamilyMedicalHistory2 },
        { path: 'hasFamilyMedicalHistory', nodeId: NodeIds.healthOwnerHasFamilyMedicalHistory },
        { path: 'familyMedicalHistoryDetails', nodeId: NodeIds.healthOwnerHasFamilyMedicalHistoryDetails },
      ],
    },
    {
      path: 'familyDoctorOrClinic',
      children: [
        { path: 'hasFamilyDoctorOrClinic', nodeId: NodeIds.healthOwnerHasFamilyDoctorOrClinic },
        { path: 'name', nodeId: NodeIds.healthOwnerFamilyDoctorOrClinicName },
        {
          path: 'address',
          children: [
            { path: 'street', nodeId: NodeIds.healthOwnerFamilyDoctorOrClinicAddressStreet },
            { path: 'apartment', nodeId: NodeIds.healthOwnerFamilyDoctorOrClinicAddressApartment },
            { path: 'city', nodeId: NodeIds.healthOwnerFamilyDoctorOrClinicAddressCity },
            { path: 'country', nodeId: NodeIds.healthOwnerFamilyDoctorOrClinicAddressCountry },
            { path: 'province', nodeId: NodeIds.healthOwnerFamilyDoctorOrClinicAddressProvince },
            { path: 'postalCode', nodeId: NodeIds.healthOwnerFamilyDoctorOrClinicAddressPostalCode },
          ],
        },
      ],
    },
    {
      path: 'hadConsultationTreatmentForBloodOrSkinDisease',
      children: [
        { path: 'disease', nodeId: NodeIds.healthOwnerHadConsultationTreatmentForBloodOrSkinDisease },
        { path: 'details', nodeId: NodeIds.healthOwnerHadConsultationTreatmentForBloodOrSkinDiseaseDetails },
        { path: 'forBloodDiseaseDetails', nodeId: NodeIds.healthOwnerHadConsultationTreatmentForBloodDiseaseDetails },
        { path: 'forSkinDiseaseDetails', nodeId: NodeIds.healthOwnerHadConsultationTreatmentForSkinDiseaseDetails },
      ],
    },
    {
      path: 'hadConsultationTreatmentForOtherPhysicalOrMentalDisorder',
      children: [
        { path: 'disorder', nodeId: NodeIds.healthOwnerHadConsultationTreatmentForOtherPhysicalOrMentalDisorder },
        {
          path: 'details',
          nodeId: NodeIds.healthOwnerHadConsultationTreatmentForOtherPhysicalOrMentalDisorderDetails,
        },

        {
          path: 'forOtherPysicalDisorderDetails',
          nodeId: NodeIds.healthOwnerHadConsultationTreatmentForOtherPhysicalDisorderDetails,
        },
        {
          path: 'forOtherPysicalMentalDetails',
          nodeId: NodeIds.healthOwnerHadConsultationTreatmentForOtherMentalDisorderDetails,
        },
      ],
    },
    {
      path: 'pregnancyAndChildBirth',
      children: [
        { path: 'isPregnant', nodeId: NodeIds.healthOwnerIsPregnant },
        { path: 'dueDate', nodeId: NodeIds.healthOwnerPregnancyDueDate },
        { path: 'hadComplications', nodeId: NodeIds.healthOwnerHadPregnancyOrChildbirthComplications },
        { path: 'hadComplicationsDetails', nodeId: NodeIds.healthOwnerHadPregnancyOrChildbirthComplicationsDetails },
      ],
    },
    {
      path: 'medicalTestsAndConsultations',
      children: [
        {
          path: 'hadOtherMedicalTests',
          children: [
            { path: 'last5Years', nodeId: NodeIds.healthOwnerHadOtherMedicalTestsLast5Years },
            { path: 'last5YearsTests', nodeId: NodeIds.healthOwnerHadOtherMedicalTestsLast5YearsTests },
            {
              path: 'last5YearsTestsDetails',
              children: [
                { path: 'blood', nodeId: NodeIds.healthOwnerHadOtherMedicalTestsLast5YearsBloodTestDetails },
                {
                  path: 'restingElectrocardiogram',
                  nodeId: NodeIds.healthOwnerHadOtherMedicalTestsLast5YearsRestingElectrocardiogramDetails,
                },
                {
                  path: 'stressElectrocardiogram',
                  nodeId: NodeIds.healthOwnerHadOtherMedicalTestsLast5YearsStressElectrocardiogramDetails,
                },
                {
                  path: 'echocardiogram',
                  nodeId: NodeIds.healthOwnerHadOtherMedicalTestsLast5YearsEchocardiogramDetails,
                },
                { path: 'colonoscopy', nodeId: NodeIds.healthOwnerHadOtherMedicalTestsLast5YearsColonoscopyDetails },
                { path: 'xRay', nodeId: NodeIds.healthOwnerHadOtherMedicalTestsLast5YearsXRayDetails },
                { path: 'mammography', nodeId: NodeIds.healthOwnerHadOtherMedicalTestsLast5YearsMammographyDetails },
                { path: 'ultrasound', nodeId: NodeIds.healthOwnerHadOtherMedicalTestsLast5YearsUltrasoundDetails },
                { path: 'ctScan', nodeId: NodeIds.healthOwnerHadOtherMedicalTestsLast5YearsCtScanDetails },
                { path: 'mri', nodeId: NodeIds.healthOwnerHadOtherMedicalTestsLast5YearsMriDetails },
              ],
            },
          ],
        },
        {
          path: 'abnormalProblemsLast5Years',
          children: [
            { path: 'hasConsulted', nodeId: NodeIds.healthOwnerHasConsultedForAbnormalProblemsLast5Years },
            { path: 'details', nodeId: NodeIds.healthOwnerAbnormalProblemsLast5YearsDetails },
          ],
        },
        {
          path: 'hospitalizedInLast5Years',
          children: [
            { path: 'wasHospitalized', nodeId: NodeIds.healthOwnerWasHospitalizedInLast5Years },
            { path: 'details', nodeId: NodeIds.healthOwnerHospitalizedInLast5YearsDetails },
          ],
        },
        {
          path: 'medicalTestsAbnormalTestPhysician',
          children: [
            {
              path: 'hasNotYetConsulted',
              nodeId: NodeIds.healthOwnerMedicalTestsAbnormalTestPhysicianHasNotYetConsulted,
            },
            {
              path: 'hasNotYetConsultedDetails',
              nodeId: NodeIds.healthOwnerMedicalTestsAbnormalTestPhysicianHasNotYetConsultedDetails,
            },
          ],
        },
        { path: 'otherProfessionalsDetails', nodeId: NodeIds.healthOwnerOtherProfessionalsDetails },
      ],
    },
    {
      path: 'disability',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthOwnerDisabilityHadAbsence },
        { path: 'absenceDetails', nodeId: NodeIds.healthOwnerDisabilityAbsenceDetails },
      ],
    },
    {
      path: 'medication',
      children: [
        { path: 'hasOrHadMedication', nodeId: NodeIds.healthOwnerHasOrHadMedication },
        { path: 'hasOrHadMedicationDetails', nodeId: NodeIds.healthOwnerHasOrHadMedicationDetails },
      ],
    },
    {
      path: 'heightAndWeight',
      children: [
        { path: 'height', nodeId: NodeIds.healthOwnerHeight },
        { path: 'weight', nodeId: NodeIds.healthOwnerWeight },
        { path: 'hadUnexplainedWeightLoss', nodeId: NodeIds.healthOwnerHadUnexplainedWeightLoss },
        { path: 'weightLossIsIntentional', nodeId: NodeIds.healthOwnerWeightLossIsIntentional },
        { path: 'weightLoss', nodeId: NodeIds.healthOwnerWeightLoss },
        { path: 'unexplainedWeightLossDetails', nodeId: NodeIds.healthOwnerUnexplainedWeightLossDetails },
      ],
    },
    {
      path: 'phoneInterview',
      children: [
        { path: 'isPrescreening', nodeId: NodeIds.healthOwnerPhoneInterviewIsPrescreening },
        { path: 'day', nodeId: NodeIds.healthOwnerPhoneInterviewDay },
        { path: 'time', nodeId: NodeIds.healthOwnerPhoneInterviewTime },
        { path: 'number', nodeId: NodeIds.healthOwnerPhoneInterviewNumber },
        { path: 'day2', nodeId: NodeIds.healthOwnerPhoneInterviewDay2 },
        { path: 'time2', nodeId: NodeIds.healthOwnerPhoneInterviewTime2 },
        { path: 'number2', nodeId: NodeIds.healthOwnerPhoneInterviewNumber2 },
      ],
    },
  ],
};

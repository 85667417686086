import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const pastChild: AnswerPathTreeNode = {
  path: 'pastChild',
  children: [
    {
      path: 'riderApplicationInfo',
      children: [
        { path: 'riderApplication', nodeId: NodeIds.pastChildRiderApplication },
        { path: 'criticalIllnessRiderApplication', nodeId: NodeIds.pastChildCriticalIllnessRiderApplication },
      ],
    },
    {
      path: 'riderApplication',
      nodeId: NodeIds.pastChildRiderApplicationInfo,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.pastChildRiderApplicationName },
        { path: 'firstName', nodeId: NodeIds.pastChildRiderApplicationFirstName },
        { path: 'lastName', nodeId: NodeIds.pastChildRiderApplicationLastName },
        { path: 'choices', nodeId: NodeIds.pastChildRiderApplicationChoices },
        { path: 'date', nodeId: NodeIds.pastChildRiderApplicationDate },
        { path: 'reason', nodeId: NodeIds.pastChildRiderApplicationReason },
      ],
    },
    {
      path: 'criticalIllnessRiderApplication',
      nodeId: NodeIds.pastChildCriticalIllnessRiderApplicationInfo,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.pastChildCriticalIllnessRiderApplicationName },
        { path: 'firstName', nodeId: NodeIds.pastChildCriticalIllnessRiderApplicationFirstName },
        { path: 'lastName', nodeId: NodeIds.pastChildCriticalIllnessRiderApplicationLastName },
        { path: 'choices', nodeId: NodeIds.pastChildCriticalIllnessRiderApplicationChoices },
        { path: 'date', nodeId: NodeIds.pastChildCriticalIllnessRiderApplicationDate },
        { path: 'reason', nodeId: NodeIds.pastChildCriticalIllnessRiderApplicationReason },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const suicideAttempts: AnswerPathTreeNode = {
  path: 'suicideAttempts',
  children: [
    { path: 'hasMoreThanOneAttempt', nodeId: NodeIds.healthInsuredSuicideAttemptHasMoreThanOneAttempt },
    { path: 'howManyAttempts', nodeId: NodeIds.healthInsuredSuicideAttemptHowManyAttempts },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredSuicideAttemptMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredSuicideAttemptMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredSuicideAttemptMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredSuicideAttemptMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredSuicideAttemptMedicationEndDate },
        { path: 'stillUses', nodeId: NodeIds.healthInsuredSuicideAttemptMedicationStillUses },
        { path: 'hasChanged', nodeId: NodeIds.healthInsuredSuicideAttemptMedicationHasChanged },
        { path: 'hasChangedDetails', nodeId: NodeIds.healthInsuredSuicideAttemptMedicationHasChangedDetails },
      ],
    },
    {
      path: 'hasOrWillConsultDoctorsDetails',
      nodeId: NodeIds.healthInsuredSuicideAttemptHasOrWillConsultDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredSuicideAttemptHasOrWillConsultDoctorsProfession },
        {
          path: 'professionOther',
          nodeId: NodeIds.healthInsuredSuicideAttemptHasOrWillConsultDoctorsProfessionOther,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredSuicideAttemptHasOrWillConsultDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredSuicideAttemptHasOrWillConsultDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredSuicideAttemptHasOrWillConsultDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredSuicideAttemptHasOrWillConsultDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredSuicideAttemptHasOrWillConsultDoctorsResult },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredSuicideAttemptHasOrWillConsultDoctorsFrequencyNumber,
        },
        { path: 'frequency', nodeId: NodeIds.healthInsuredSuicideAttemptHasOrWillConsultDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredSuicideAttemptHasOrWillConsultDoctorsFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredSuicideAttemptHasOrWillConsultDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'wasHospitalizedDetails',
      nodeId: NodeIds.healthInsuredSuicideAttemptWasHospitalizedDetails,
      isCollection: true,
      children: [
        { path: 'date', nodeId: NodeIds.healthInsuredSuicideAttemptWasHospitalizedDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredSuicideAttemptWasHospitalizedFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredSuicideAttemptWasHospitalizedFrequency },
        { path: 'location', nodeId: NodeIds.healthInsuredSuicideAttemptWasHospitalizedLocation },
      ],
    },
    {
      path: 'multipleAttemptsDetails',
      nodeId: NodeIds.healthInsuredSuicideAttemptMultipleAttemptsDetails,
      isCollection: true,
      children: [
        { path: 'date', nodeId: NodeIds.healthInsuredSuicideAttemptMultipleAttemptsDate },
        { path: 'reason', nodeId: NodeIds.healthInsuredSuicideAttemptMultipleAttemptsReason },
      ],
    },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredSuicideAttemptMedicationWasPrescribed },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredSuicideAttemptHasConsultedDoctors },
    {
      path: 'hasOrWillConsultOtherDoctorsOrTherapists',
      nodeId: NodeIds.healthInsuredSuicideAttemptHasOrWillConsultOtherDoctorsOrTherapists,
    },
    { path: 'wasHospitalized', nodeId: NodeIds.healthInsuredSuicideAttemptWasHospitalized },
    { path: 'date', nodeId: NodeIds.healthInsuredSuicideAttemptDate },
    { path: 'reason', nodeId: NodeIds.healthInsuredSuicideAttemptReason },
  ],
};

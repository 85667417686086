import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const sciaticNerve: AnswerPathTreeNode = {
  path: 'sciaticNerve',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredSciaticNerveIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredSciaticNervePreviousEpisodesCount },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredSciaticNervePainLocation },
        { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredSciaticNerveDateSymptoms },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredSciaticNervePainCause },
        { path: 'details', nodeId: NodeIds.healthInsuredSciaticNervePainCauseDetails },
      ],
    },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredSciaticNerveHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredSciaticNerveHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredSciaticNerveMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredSciaticNerveHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredSciaticNerveOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredSciaticNerveTypeOfOperationPerformed,
        },
        { path: 'performedDate', nodeId: NodeIds.healthInsuredSciaticNerveOperationPerformedDate },
        { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthInsuredSciaticNerveTypeOfOperationPlanned },
        { path: 'plannedDate', nodeId: NodeIds.healthInsuredSciaticNerveOperationPlannedDate },
        { path: 'unknownDate', nodeId: NodeIds.healthInsuredSciaticNerveOperationUnknownDate },
        {
          path: 'absence',
          children: [
            { path: 'hadAbsence', nodeId: NodeIds.healthInsuredSciaticNerveHadAbsence },
            { path: 'startDate', nodeId: NodeIds.healthInsuredSciaticNerveAbsenceStartDate },
            { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredSciaticNerveAbsenceFrequencyNumber },
            { path: 'frequency', nodeId: NodeIds.healthInsuredSciaticNerveAbsenceFrequency },
            {
              path: 'recovery',
              children: [
                { path: 'recoveryDate', nodeId: NodeIds.healthInsuredSciaticNerveAbsenceRecoveryDate },
                { path: 'hasRecovered', nodeId: NodeIds.healthInsuredSciaticNerveAbsenceHasRecovered },
                { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredSciaticNerveAbsenceNotRecoveredDetails },
              ],
            },
          ],
        },
        {
          path: 'hasConsultedDoctorsDetails',
          nodeId: NodeIds.healthInsuredSciaticNerveHasConsultedDoctorsDetails,
          isCollection: true,
          children: [
            { path: 'profession', nodeId: NodeIds.healthInsuredSciaticNerveHasConsultedDoctorsProfession },
            {
              path: 'professionDetails',
              nodeId: NodeIds.healthInsuredSciaticNerveHasConsultedDoctorsProfessionDetails,
            },
            { path: 'name', nodeId: NodeIds.healthInsuredSciaticNerveHasConsultedDoctorsName },
            { path: 'address', nodeId: NodeIds.healthInsuredSciaticNerveHasConsultedDoctorsAddress },
            { path: 'firstDate', nodeId: NodeIds.healthInsuredSciaticNerveHasConsultedDoctorsFirstDate },
            { path: 'lastDate', nodeId: NodeIds.healthInsuredSciaticNerveHasConsultedDoctorsLastDate },
            { path: 'result', nodeId: NodeIds.healthInsuredSciaticNerveHasConsultedDoctorsResult },
            { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredSciaticNerveHasConsultedDoctorsFrequencyNumber },
            { path: 'frequency', nodeId: NodeIds.healthInsuredSciaticNerveHasConsultedDoctorsFrequency },
            {
              path: 'furtherConsultation',
              nodeId: NodeIds.healthInsuredSciaticNerveHasConsultedDoctorFurtherConsultation,
            },
            {
              path: 'nextConsultationDate',
              nodeId: NodeIds.healthInsuredSciaticNerveHasConsultedDoctorsNextConsultationDate,
            },
          ],
        },
        {
          path: 'hasTakenTestsOrExamsDetails',
          nodeId: NodeIds.healthInsuredSciaticNerveHasTakenTestsOrExamsDetails,
          isCollection: true,
          children: [
            { path: 'type', nodeId: NodeIds.healthInsuredSciaticNerveHasTakenTestsOrExamsType },
            { path: 'date', nodeId: NodeIds.healthInsuredSciaticNerveHasTakenTestsOrExamsDate },
            { path: 'result', nodeId: NodeIds.healthInsuredSciaticNerveHasTakenTestsOrExamsResult },
            {
              path: 'abnormalResult',
              nodeId: NodeIds.healthInsuredSciaticNerveHasTakenTestsOrExamsDetailsAbnormalResult,
            },
          ],
        },
        {
          path: 'medicationDetails',
          nodeId: NodeIds.healthInsuredSciaticNerveMedicationDetails,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.healthInsuredSciaticNerveMedicationName },
            { path: 'dosage', nodeId: NodeIds.healthInsuredSciaticNerveMedicationDosage },
            { path: 'startDate', nodeId: NodeIds.healthInsuredSciaticNerveMedicationStartDate },
            { path: 'endDate', nodeId: NodeIds.healthInsuredSciaticNerveMedicationEndDate },
          ],
        },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const otherPhysicalMentalHealth: AnswerPathTreeNode = {
  path: 'otherPhysicalMentalHealth',
  children: [
    { path: 'disorder', nodeId: NodeIds.healthInsuredHadConsultationTreatmentForOtherPhysicalMentalDisorder },
    {
      path: 'disorderDetails',
      nodeId: NodeIds.healthInsuredHadConsultationTreatmentForOtherPhysicalMentalDisorderDetails,
    },
    {
      path: 'mentalDisorder',
      children: [
        {
          path: 'hadConsultationOrTreatment',
          nodeId: NodeIds.healthInsuredHadConsultationTreatmentForOtherMentalDisorderDetails,
        },
      ],
    },
    {
      path: 'physicalDisorder',
      children: [
        {
          path: 'hadConsultationOrTreatment',
          nodeId: NodeIds.healthInsuredHadConsultationTreatmentForOtherPhysicalDisorderDetails,
        },
      ],
    },
  ],
};

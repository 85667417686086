import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const contactInformation: AnswerPathTreeNode = {
  path: 'contactInformation',
  children: [
    { path: 'email', nodeId: NodeIds.insuredEmail },
    { path: 'phoneNumber', nodeId: NodeIds.insuredPhoneNumber },
    { path: 'phoneType', nodeId: NodeIds.insuredPhoneType },
    { path: 'phoneExtension', nodeId: NodeIds.insuredPhoneExtension },
    { path: 'alternatePhoneNumber', nodeId: NodeIds.insuredAlternatePhoneNumber },
    { path: 'alternatePhoneType', nodeId: NodeIds.insuredAlternatePhoneType },
    { path: 'alternatePhoneExtension', nodeId: NodeIds.insuredAlternatePhoneExtension },
    { path: 'alternatePhoneNumberTwo', nodeId: NodeIds.insuredAlternatePhoneNumberTwo },
    { path: 'alternatePhoneTypeTwo', nodeId: NodeIds.insuredAlternatePhoneTypeTwo },
    { path: 'alternatePhoneExtensionTwo', nodeId: NodeIds.insuredAlternatePhoneExtensionTwo },
    { path: 'timeOfDay', nodeId: NodeIds.insuredContactTimeOfDay },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const heightAndWeight: AnswerPathTreeNode = {
  path: 'heightAndWeight',
  children: [
    { path: 'hadUnexplainedWeightLoss', nodeId: NodeIds.healthInsuredHadUnexplainedWeightLoss },
    { path: 'weightLossIsIntentional', nodeId: NodeIds.healthInsuredWeightLossIsIntentional },
    {
      path: 'voluntaryWeightLoss',
      children: [
        { path: 'amount', nodeId: NodeIds.healthInsuredVoluntaryWeightLossAmount },
        { path: 'details', nodeId: NodeIds.healthInsuredUnexplainedWeightLossDetails },
      ],
    },
  ],
};

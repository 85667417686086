import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';
import { abnormalBloodSugar } from './abnormalBloodSugar';
import { adrenalGlandDisorder } from './adrenalGlandDisorder';
import { anyOtherGlandularOrHormonalDisorder } from './anyOtherGlandularOrHormonalDisorder';
import { diabetes } from './diabetes';
import { pituitaryGlandDisorder } from './pituitaryGlandDisorder';
import { thyroid } from './thyroid';

export const endocrineSystem: AnswerPathTreeNode = {
  path: 'endocrineSystem',
  children: [
    { path: 'hasCondition', nodeId: NodeIds.healthInsuredEndocrineSystemHasCondition },
    { path: 'choices', nodeId: NodeIds.healthInsuredEndocrineChoices },
    abnormalBloodSugar,
    adrenalGlandDisorder,
    pituitaryGlandDisorder,
    thyroid,
    anyOtherGlandularOrHormonalDisorder,
    diabetes,
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const mentalHealthOther: AnswerPathTreeNode = {
  path: 'mentalHealthOther',
  children: [
    { path: 'disorderType', nodeId: NodeIds.healthInsuredMentalHealthOtherDisorderType },
    { path: 'date', nodeId: NodeIds.healthInsuredMentalHealthOtherDate },
    {
      path: 'hasOrWillConsultOtherDoctorsOrTherapists',
      nodeId: NodeIds.healthInsuredMentalHealthOtherHasOrWillConsultOtherDoctorsOrTherapists,
    },
    { path: 'hasDiagnostic', nodeId: NodeIds.healthInsuredMentalHealthOtherHasDiagnostic },
    { path: 'wentToEmergencyDepartment', nodeId: NodeIds.healthInsuredMentalHealthOtherWentToEmergencyDepartment },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredMentalHealthOtherMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredMentalHealthOtherMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredMentalHealthOtherMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredMentalHealthOtherMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredMentalHealthOtherMedicationEndDate },
        { path: 'stillUses', nodeId: NodeIds.healthInsuredMentalHealthOtherMedicationStillUses },
      ],
    },
    {
      path: 'hasOrWillConsultDoctorsDetails',
      nodeId: NodeIds.healthInsuredMentalHealthOtherHasOrWillConsultDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredMentalHealthOtherHasOrWillConsultDoctorsProfession },
        {
          path: 'professionOther',
          nodeId: NodeIds.healthInsuredMentalHealthOtherHasOrWillConsultDoctorsProfessionOther,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredMentalHealthOtherHasOrWillConsultDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredMentalHealthOtherHasOrWillConsultDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredMentalHealthOtherHasOrWillConsultDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredMentalHealthOtherHasOrWillConsultDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredMentalHealthOtherHasOrWillConsultDoctorsResult },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredMentalHealthOtherHasOrWillConsultDoctorsFrequencyNumber,
        },
        { path: 'frequency', nodeId: NodeIds.healthInsuredMentalHealthOtherHasOrWillConsultDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredMentalHealthOtherHasOrWillConsultDoctorsFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredMentalHealthOtherHasOrWillConsultDoctorsNextConsultationDate,
        },
      ],
    },

    {
      path: 'wentToEmergencyDepartmentDetails',
      nodeId: NodeIds.healthInsuredMentalHealthOtherWentToEmergencyDepartmentDetails,
      isCollection: true,
      children: [
        { path: 'date', nodeId: NodeIds.healthInsuredMentalHealthOtherWentToEmergencyDepartmentDate },
        { path: 'location', nodeId: NodeIds.healthInsuredMentalHealthOtherWentToEmergencyDepartmentLocation },
      ],
    },
    {
      path: 'wasHospitalizedDetails',
      nodeId: NodeIds.healthInsuredMentalHealthOtherWasHospitalizedDetails,
      isCollection: true,
      children: [
        { path: 'date', nodeId: NodeIds.healthInsuredMentalHealthOtherWasHospitalizedDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredMentalHealthOtherWasHospitalizedFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredMentalHealthOtherWasHospitalizedFrequency },
        { path: 'location', nodeId: NodeIds.healthInsuredMentalHealthOtherWasHospitalizedLocation },
      ],
    },
    {
      path: 'hadAbsenceDetails',
      nodeId: NodeIds.healthInsuredMentalHealthOtherhadAbsenceDetails,
      isCollection: true,
      children: [
        { path: 'startDate', nodeId: NodeIds.healthInsuredMentalHealthOtherAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredMentalHealthOtherAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredMentalHealthOtherAbsenceFrequency },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredMentalHealthOtherHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'details', nodeId: NodeIds.healthInsuredMentalHealthOtherTestsOrExamsDetails },
        {
          path: 'detailsDate',
          nodeId: NodeIds.healthInsuredMentalHealthOtherOtherTestsOrExamsDetailsDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredMentalHealthOtherOtherTestsOrExamsDetailsResult,
        },
      ],
    },
    { path: 'diagnosticResult', nodeId: NodeIds.healthInsuredMentalHealthOtherDiagnosticResult },
    { path: 'diagnosticDate', nodeId: NodeIds.healthInsuredMentalHealthOtherDiagnosticDate },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredMentalHealthOtherHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredMentalHealthOtherMedicationWasPrescribed },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredMentalHealthOtherHasConsultedDoctors },
    { path: 'wasHospitalized', nodeId: NodeIds.healthInsuredMentalHealthOtherWasHospitalized },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredMentalHealthOtherHadAbsence },
        { path: 'hasRecovered', nodeId: NodeIds.healthInsuredMentalHealthOtherAbsenceHasRecovered },
        { path: 'recoveryDate', nodeId: NodeIds.healthInsuredMentalHealthOtherAbsenceRecoveryDate },
        { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredMentalHealthOtherAbsenceNotRecoveredDetails },
      ],
    },
  ],
};

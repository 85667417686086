import _ from 'lodash';

// Credit to Christian C. Salvadó: https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
function camelize(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function makePartNameFromString(value: string): string {
  return camelize(_.deburr(value));
}

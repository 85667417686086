import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const lifestyleOwner: AnswerPathTreeNode = {
  path: 'lifestyleOwner',
  children: [
    {
      path: 'alcohol',
      children: [
        {
          path: 'use',
          children: [
            { path: 'use', nodeId: NodeIds.lifestyleOwnerAlcoholUse },
            { path: 'frequency', nodeId: NodeIds.lifestyleOwnerAlcoholUseFrequency },
            { path: 'quantity', nodeId: NodeIds.lifestyleOwnerAlcoholUseQuantity },
            { path: 'history', nodeId: NodeIds.lifestyleOwnerAlcoholUseHistory },
            { path: 'historyFrequency', nodeId: NodeIds.lifestyleOwnerAlcoholUseHistoryFrequency },
            { path: 'historyQuantity', nodeId: NodeIds.lifestyleOwnerAlcoholUseHistoryQuantity },
          ],
        },
        {
          path: 'advisedOrCeaseConsumption',
          children: [
            { path: 'advisedOrCeaseConsumption', nodeId: NodeIds.lifestyleOwnerAlcoholAdvisedOrCeaseConsumption },
            { path: 'date', nodeId: NodeIds.lifestyleOwnerAlcoholAdvisedOrCeaseConsumptionDate },
            {
              path: 'professionalContactInformation',
              nodeId: NodeIds.lifestyleOwnerAlcoholAdvisedOrCeaseConsumptionProfessionalContactInformation,
            },
          ],
        },
        {
          path: 'therapyOrTreatment',
          children: [
            { path: 'hasTherapy', nodeId: NodeIds.lifestyleOwnerAlcoholHasTherapy },
            { path: 'startDate', nodeId: NodeIds.lifestyleOwnerAlcoholTherapyOrTreatmentStartDate },
            { path: 'endDate', nodeId: NodeIds.lifestyleOwnerAlcoholTherapyOrTreatmentEndDate },
            { path: 'type', nodeId: NodeIds.lifestyleOwnerAlcoholTherapyOrTreatmentType },
            {
              path: 'professionalContactInformation',
              nodeId: NodeIds.lifestyleOwnerAlcoholTherapyOrTreatmentProfessionalContactInformation,
            },
            { path: 'additionalPeriod', nodeId: NodeIds.lifestyleOwnerAlcoholTherapyOrTreatmentAdditionalPeriod },
            { path: 'lastDateOfTheLastTreatment', nodeId: NodeIds.lifestyleOwnerAlcoholLastDateOfTheLastTreatment },
          ],
        },
        {
          path: 'supportGroup',
          children: [
            { path: 'meetings', nodeId: NodeIds.lifestyleOwnerAlcoholSupportGroupMeetings },
            { path: 'stillAttending', nodeId: NodeIds.lifestyleOwnerAlcoholSupportGroupStillAttending },
            { path: 'startDate', nodeId: NodeIds.lifestyleOwnerAlcoholSupportGroupStartDate },
            { path: 'endDate', nodeId: NodeIds.lifestyleOwnerAlcoholSupportGroupEndDate },
          ],
        },
        {
          path: 'changeHabitConsumption',
          children: [
            { path: 'date', nodeId: NodeIds.lifestyleOwnerAlcoholChangeHabitConsumptionDate },
            { path: 'reason', nodeId: NodeIds.lifestyleOwnerAlcoholChangeHabitConsumptionReason },
          ],
        },
      ],
    },
    {
      path: 'drugsOrNarcotics',
      children: [
        {
          path: 'marijuana',
          children: [
            { path: 'use', nodeId: NodeIds.lifestyleOwnerMarijuanaUse },
            { path: 'useForm', nodeId: NodeIds.lifestyleOwnerMarijuanaUseForm },
            { path: 'useFormDetails', nodeId: NodeIds.lifestyleOwnerMarijuanaUseFormDetails },
            {
              path: 'joint',
              children: [
                {
                  path: 'consumptionHabits',
                  children: [
                    { path: 'quantity', nodeId: NodeIds.lifestyleOwnerMarijuanaJointConsumptionHabitsQuantity },
                    { path: 'frequency', nodeId: NodeIds.lifestyleOwnerMarijuanaJointConsumptionHabitsFrequency },
                    { path: 'usageDate', nodeId: NodeIds.lifestyleOwnerMarijuanaJointConsumptionHabitsUsageDate },
                    { path: 'usageType', nodeId: NodeIds.lifestyleOwnerMarijuanaJointConsumptionHabitsUsageType },
                  ],
                },
                {
                  path: 'consumptionMedical',
                  children: [
                    { path: 'condition', nodeId: NodeIds.lifestyleOwnerMarijuanaJointConsumptionMedicalCondition },
                    { path: 'prescribed', nodeId: NodeIds.lifestyleOwnerMarijuanaJointConsumptionMedicalPrescribed },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleOwnerMarijuanaJointConsumptionMedicalPrescribingPhysician,
                    },
                    { path: 'pastUse', nodeId: NodeIds.lifestyleOwnerMarijuanaJointPastUse },
                  ],
                },
                {
                  path: 'pastConsumptionHabits',
                  children: [
                    { path: 'quantity', nodeId: NodeIds.lifestyleOwnerMarijuanaJointPastConsumptionHabitsQuantity },
                    { path: 'frequency', nodeId: NodeIds.lifestyleOwnerMarijuanaJointPastConsumptionHabitsFrequency },
                    { path: 'usageType', nodeId: NodeIds.lifestyleOwnerMarijuanaJointPastConsumptionHabitsUsageType },
                  ],
                },
                {
                  path: 'pastConsumptionMedical',
                  children: [
                    { path: 'condition', nodeId: NodeIds.lifestyleOwnerMarijuanaJointPastConsumptionMedicalCondition },
                    {
                      path: 'prescribed',
                      nodeId: NodeIds.lifestyleOwnerMarijuanaJointPastConsumptionMedicalPrescribed,
                    },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleOwnerMarijuanaJointPastConsumptionMedicalPrescribingPhysician,
                    },
                  ],
                },
                {
                  path: 'changeHabitConsumption',
                  children: [
                    { path: 'date', nodeId: NodeIds.lifestyleOwnerMarijuanaJointChangeHabitConsumptionDate },
                    { path: 'reason', nodeId: NodeIds.lifestyleOwnerMarijuanaJointChangeHabitConsumptionReason },
                  ],
                },
              ],
            },
            {
              path: 'edible',
              children: [
                {
                  path: 'consumptionHabits',
                  children: [
                    { path: 'quantity', nodeId: NodeIds.lifestyleOwnerMarijuanaEdibleConsumptionHabitsQuantity },
                    { path: 'frequency', nodeId: NodeIds.lifestyleOwnerMarijuanaEdibleConsumptionHabitsFrequency },
                    { path: 'usageDate', nodeId: NodeIds.lifestyleOwnerMarijuanaEdibleConsumptionHabitsUsageDate },
                    { path: 'usageType', nodeId: NodeIds.lifestyleOwnerMarijuanaEdibleConsumptionHabitsUsageType },
                  ],
                },
                {
                  path: 'consumptionMedical',
                  children: [
                    { path: 'condition', nodeId: NodeIds.lifestyleOwnerMarijuanaEdibleConsumptionMedicalCondition },
                    { path: 'prescribed', nodeId: NodeIds.lifestyleOwnerMarijuanaEdibleConsumptionMedicalPrescribed },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleOwnerMarijuanaEdibleConsumptionMedicalPrescribingPhysician,
                    },
                    { path: 'pastUse', nodeId: NodeIds.lifestyleOwnerMarijuanaEdiblePastUse },
                  ],
                },
                {
                  path: 'pastConsumptionHabits',
                  children: [
                    { path: 'quantity', nodeId: NodeIds.lifestyleOwnerMarijuanaEdiblePastConsumptionHabitsQuantity },
                    { path: 'frequency', nodeId: NodeIds.lifestyleOwnerMarijuanaEdiblePastConsumptionHabitsFrequency },
                    { path: 'usageType', nodeId: NodeIds.lifestyleOwnerMarijuanaEdiblePastConsumptionHabitsUsageType },
                  ],
                },
                {
                  path: 'pastConsumptionMedical',
                  children: [
                    { path: 'condition', nodeId: NodeIds.lifestyleOwnerMarijuanaEdiblePastConsumptionMedicalCondition },
                    {
                      path: 'prescribed',
                      nodeId: NodeIds.lifestyleOwnerMarijuanaEdiblePastConsumptionMedicalPrescribed,
                    },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleOwnerMarijuanaEdiblePastConsumptionMedicalPrescribingPhysician,
                    },
                  ],
                },
                {
                  path: 'changeHabitConsumption',
                  children: [
                    { path: 'date', nodeId: NodeIds.lifestyleOwnerMarijuanaEdibleChangeHabitConsumptionDate },
                    { path: 'reason', nodeId: NodeIds.lifestyleOwnerMarijuanaEdibleChangeHabitConsumptionReason },
                  ],
                },
              ],
            },
            {
              path: 'oil',
              children: [
                {
                  path: 'consumptionHabits',
                  children: [
                    { path: 'quantity', nodeId: NodeIds.lifestyleOwnerMarijuanaOilConsumptionHabitsQuantity },
                    { path: 'frequency', nodeId: NodeIds.lifestyleOwnerMarijuanaOilConsumptionHabitsFrequency },
                    { path: 'usageDate', nodeId: NodeIds.lifestyleOwnerMarijuanaOilConsumptionHabitsUsageDate },
                    { path: 'usageType', nodeId: NodeIds.lifestyleOwnerMarijuanaOilConsumptionHabitsUsageType },
                  ],
                },
                {
                  path: 'consumptionMedical',
                  children: [
                    { path: 'condition', nodeId: NodeIds.lifestyleOwnerMarijuanaOilConsumptionMedicalCondition },
                    { path: 'prescribed', nodeId: NodeIds.lifestyleOwnerMarijuanaOilConsumptionMedicalPrescribed },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleOwnerMarijuanaOilConsumptionMedicalPrescribingPhysician,
                    },
                    { path: 'pastUse', nodeId: NodeIds.lifestyleOwnerMarijuanaOilPastUse },
                  ],
                },
                {
                  path: 'pastConsumptionHabits',
                  children: [
                    { path: 'quantity', nodeId: NodeIds.lifestyleOwnerMarijuanaOilPastConsumptionHabitsQuantity },
                    { path: 'frequency', nodeId: NodeIds.lifestyleOwnerMarijuanaOilPastConsumptionHabitsFrequency },
                    { path: 'usageType', nodeId: NodeIds.lifestyleOwnerMarijuanaOilPastConsumptionHabitsUsageType },
                  ],
                },
                {
                  path: 'pastConsumptionMedical',
                  children: [
                    { path: 'condition', nodeId: NodeIds.lifestyleOwnerMarijuanaOilPastConsumptionMedicalCondition },
                    { path: 'prescribed', nodeId: NodeIds.lifestyleOwnerMarijuanaOilPastConsumptionMedicalPrescribed },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleOwnerMarijuanaOilPastConsumptionMedicalPrescribingPhysician,
                    },
                  ],
                },
                {
                  path: 'changeHabitConsumption',
                  children: [
                    { path: 'date', nodeId: NodeIds.lifestyleOwnerMarijuanaOilChangeHabitConsumptionDate },
                    { path: 'reason', nodeId: NodeIds.lifestyleOwnerMarijuanaOilChangeHabitConsumptionReason },
                  ],
                },
              ],
            },
            {
              path: 'other',
              children: [
                {
                  path: 'consumptionHabits',
                  children: [
                    { path: 'quantity', nodeId: NodeIds.lifestyleOwnerMarijuanaOtherConsumptionHabitsQuantity },
                    { path: 'frequency', nodeId: NodeIds.lifestyleOwnerMarijuanaOtherConsumptionHabitsFrequency },
                    { path: 'usageDate', nodeId: NodeIds.lifestyleOwnerMarijuanaOtherConsumptionHabitsUsageDate },
                    { path: 'usageType', nodeId: NodeIds.lifestyleOwnerMarijuanaOtherConsumptionHabitsUsageType },
                  ],
                },
                {
                  path: 'consumptionMedical',
                  children: [
                    { path: 'condition', nodeId: NodeIds.lifestyleOwnerMarijuanaOtherConsumptionMedicalCondition },
                    { path: 'prescribed', nodeId: NodeIds.lifestyleOwnerMarijuanaOtherConsumptionMedicalPrescribed },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleOwnerMarijuanaOtherConsumptionMedicalPrescribingPhysician,
                    },
                    { path: 'pastUse', nodeId: NodeIds.lifestyleOwnerMarijuanaOtherPastUse },
                  ],
                },
                {
                  path: 'pastConsumptionHabits',
                  children: [
                    { path: 'quantity', nodeId: NodeIds.lifestyleOwnerMarijuanaOtherPastConsumptionHabitsQuantity },
                    { path: 'frequency', nodeId: NodeIds.lifestyleOwnerMarijuanaOtherPastConsumptionHabitsFrequency },
                    { path: 'usageType', nodeId: NodeIds.lifestyleOwnerMarijuanaOtherPastConsumptionHabitsUsageType },
                  ],
                },
                {
                  path: 'pastConsumptionMedical',
                  children: [
                    { path: 'condition', nodeId: NodeIds.lifestyleOwnerMarijuanaOtherPastConsumptionMedicalCondition },
                    {
                      path: 'prescribed',
                      nodeId: NodeIds.lifestyleOwnerMarijuanaOtherPastConsumptionMedicalPrescribed,
                    },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleOwnerMarijuanaOtherPastConsumptionMedicalPrescribingPhysician,
                    },
                  ],
                },
                {
                  path: 'changeHabitConsumption',
                  children: [
                    { path: 'date', nodeId: NodeIds.lifestyleOwnerMarijuanaOtherChangeHabitConsumptionDate },
                    { path: 'reason', nodeId: NodeIds.lifestyleOwnerMarijuanaOtherChangeHabitConsumptionReason },
                  ],
                },
              ],
            },
            {
              path: 'advisedOrCeaseConsumption',
              children: [
                { path: 'advisedOrCeaseConsumption', nodeId: NodeIds.lifestyleOwnerMarijuanaAdvisedOrCeaseConsumption },
                { path: 'date', nodeId: NodeIds.lifestyleOwnerMarijuanaAdvisedOrCeaseConsumptionDate },
                {
                  path: 'professionalContactInformation',
                  nodeId: NodeIds.lifestyleOwnerMarijuanaAdvisedOrCeaseConsumptionProfessionalContactInformation,
                },
              ],
            },
            {
              path: 'therapyOrTreatment',
              children: [
                { path: 'hasTherapy', nodeId: NodeIds.lifestyleOwnerMarijuanaHasTherapy },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerMarijuanaTherapyOrTreatmentStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerMarijuanaTherapyOrTreatmentEndDate },
                { path: 'type', nodeId: NodeIds.lifestyleOwnerMarijuanaTherapyOrTreatmentType },
                {
                  path: 'professionalContactInformation',
                  nodeId: NodeIds.lifestyleOwnerMarijuanaTherapyOrTreatmentProfessionalContactInformation,
                },
                {
                  path: 'additionalPeriod',
                  children: [
                    {
                      path: 'isOnlyPeriod',
                      nodeId: NodeIds.lifestyleOwnerMarijuanaTherapyOrTreatmentIsOnlyPeriod,
                    },
                    {
                      path: 'details',
                      nodeId: NodeIds.lifestyleOwnerMarijuanaTherapyOrTreatmentAdditionalPeriodDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'supportGroup',
              children: [
                { path: 'meetings', nodeId: NodeIds.lifestyleOwnerMarijuanaSupportGroupMeetings },
                { path: 'stillAttending', nodeId: NodeIds.lifestyleOwnerMarijuanaSupportGroupStillAttending },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerMarijuanaSupportGroupStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerMarijuanaSupportGroupEndDate },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'type', nodeId: NodeIds.lifestyleOwnerMarijuanaPastUseType },
                { path: 'typeOther', nodeId: NodeIds.lifestyleOwnerMarijuanaPastUseTypeOther },
              ],
            },
          ],
        },
        {
          path: 'use',
          children: [
            { path: 'use', nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsUse },
            { path: 'type', nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsUseType },
            { path: 'formOther', nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsUseFormOther },
          ],
        },
        {
          path: 'consumptionAndTreatment',
          children: [
            {
              path: 'advisedOrCeaseConsumption',
              nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsAdvisedOrCeaseConsumption,
            },
            { path: 'hasTherapy', nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsHasTherapy },
            { path: 'supportGroupMeetings', nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsSupportGroupMeetings },
          ],
        },
        {
          path: 'advisedOrCeaseConsumption',
          children: [
            {
              path: 'date',
              nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsAdvisedOrCeaseConsumptionDate,
            },
            {
              path: 'professionalContactInformation',
              nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsAdvisedOrCeaseConsumptionProfessionalContactInformation,
            },
          ],
        },
        {
          path: 'therapyOrTreatment',
          children: [
            {
              path: 'startDate',
              nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsTherapyOrTreatmentStartDate,
            },
            { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsTherapyOrTreatmentEndDate },
            { path: 'type', nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsTherapyOrTreatmentType },
            {
              path: 'professionalContactInformation',
              nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsTherapyOrTreatmentProfessionalContactInformation,
            },
            {
              path: 'additionnalPeriod',
              nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsTherapyOrTreatmentAdditionnalPeriod,
            },
            {
              path: 'lastDateOfTheLastTreatment',
              nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsLastDateOfTheLastTreatment,
            },
          ],
        },
        {
          path: 'supportGroup',
          children: [
            { path: 'stillAttending', nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsSupportGroupStillAttending },
            { path: 'startDate', nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsSupportGroupStartDate },
            { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsOrNarcoticsSupportGroupEndDate },
          ],
        },
        {
          path: 'cocaine',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleOwnerDrugsCocaineUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerDrugsCocaineUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsCocaineUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                { path: 'quantity', nodeId: NodeIds.lifestyleOwnerDrugsCocaineConsumptionHabitsQuantity },
                { path: 'frequency', nodeId: NodeIds.lifestyleOwnerDrugsCocaineConsumptionHabitsFrequency },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'use', nodeId: NodeIds.lifestyleOwnerDrugsCocainePastUse },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerDrugsCocainePastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsCocainePastUseEndDate },
              ],
            },
            {
              path: 'pastConsumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleOwnerDrugsCocainePastConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleOwnerDrugsCocainePastConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleOwnerDrugsCocainePastChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleOwnerDrugsCocainePastChangeHabitConsumptionReason,
                },
              ],
            },
          ],
        },
        {
          path: 'ecstasy',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleOwnerDrugsEcstasyUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerDrugsEcstasyUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsEcstasyUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                { path: 'quantity', nodeId: NodeIds.lifestyleOwnerDrugsEcstasyConsumptionHabitsQuantity },
                { path: 'frequency', nodeId: NodeIds.lifestyleOwnerDrugsEcstasyConsumptionHabitsFrequency },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'use', nodeId: NodeIds.lifestyleOwnerDrugsEcstasyPastUse },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerDrugsEcstasyPastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsEcstasyPastUseEndDate },
              ],
            },
            {
              path: 'pastConsumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleOwnerDrugsEcstasyPastConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleOwnerDrugsEcstasyPastConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleOwnerDrugsEcstasyPastChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleOwnerDrugsEcstasyPastChangeHabitConsumptionReason,
                },
              ],
            },
          ],
        },
        {
          path: 'lsd',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleOwnerDrugsLsdUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerDrugsLsdUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsLsdUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                { path: 'quantity', nodeId: NodeIds.lifestyleOwnerDrugsLsdConsumptionHabitsQuantity },
                { path: 'frequency', nodeId: NodeIds.lifestyleOwnerDrugsLsdConsumptionHabitsFrequency },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'use', nodeId: NodeIds.lifestyleOwnerDrugsLsdPastUse },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerDrugsLsdPastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsLsdPastUseEndDate },
              ],
            },
            {
              path: 'pastConsumptionHabits',
              children: [
                { path: 'quantity', nodeId: NodeIds.lifestyleOwnerDrugsLsdPastConsumptionHabitsQuantity },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleOwnerDrugsLsdPastConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleOwnerDrugsLsdPastChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleOwnerDrugsLsdPastChangeHabitConsumptionReason,
                },
              ],
            },
          ],
        },
        {
          path: 'magicMushrooms',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleOwnerDrugsMagicMushroomsUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerDrugsMagicMushroomsUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsMagicMushroomsUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleOwnerDrugsMagicMushroomsConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleOwnerDrugsMagicMushroomsConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'use', nodeId: NodeIds.lifestyleOwnerDrugsMagicMushroomsPastUse },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerDrugsMagicMushroomsPastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsMagicMushroomsPastUseEndDate },
              ],
            },
            {
              path: 'pastConsumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleOwnerDrugsMagicMushroomsPastConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleOwnerDrugsMagicMushroomsPastConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleOwnerDrugsMagicMushroomsPastChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleOwnerDrugsMagicMushroomsPastChangeHabitConsumptionReason,
                },
              ],
            },
          ],
        },
        {
          path: 'heroin',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleOwnerDrugsHeroinUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerDrugsHeroinUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsHeroinUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                { path: 'quantity', nodeId: NodeIds.lifestyleOwnerDrugsHeroinConsumptionHabitsQuantity },
                { path: 'frequency', nodeId: NodeIds.lifestyleOwnerDrugsHeroinConsumptionHabitsFrequency },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'use', nodeId: NodeIds.lifestyleOwnerDrugsHeroinPastUse },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerDrugsHeroinPastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsHeroinPastUseEndDate },
              ],
            },
            {
              path: 'pastConsumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleOwnerDrugsHeroinPastConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleOwnerDrugsHeroinPastConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleOwnerDrugsHeroinPastChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleOwnerDrugsHeroinPastChangeHabitConsumptionReason,
                },
              ],
            },
          ],
        },
        {
          path: 'fentanyl',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleOwnerDrugsFentanylUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerDrugsFentanylUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsFentanylUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                { path: 'quantity', nodeId: NodeIds.lifestyleOwnerDrugsFentanylConsumptionHabitsQuantity },
                { path: 'frequency', nodeId: NodeIds.lifestyleOwnerDrugsFentanylConsumptionHabitsFrequency },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'use', nodeId: NodeIds.lifestyleOwnerDrugsFentanylPastUse },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerDrugsFentanylPastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsFentanylPastUseEndDate },
              ],
            },
            {
              path: 'pastConsumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleOwnerDrugsFentanylPastConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleOwnerDrugsFentanylPastConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleOwnerDrugsFentanylPastChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleOwnerDrugsFentanylPastChangeHabitConsumptionReason,
                },
              ],
            },
          ],
        },
        {
          path: 'anabolicSteroids',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleOwnerDrugsAnabolicSteroidsUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerDrugsAnabolicSteroidsUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsAnabolicSteroidsUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleOwnerDrugsAnabolicSteroidsConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleOwnerDrugsAnabolicSteroidsConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'use', nodeId: NodeIds.lifestyleOwnerDrugsAnabolicSteroidsPastUse },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerDrugsAnabolicSteroidsPastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerDrugsAnabolicSteroidsPastUseEndDate },
              ],
            },
            {
              path: 'pastConsumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleOwnerDrugsAnabolicSteroidsPastConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleOwnerDrugsAnabolicSteroidsPastConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleOwnerDrugsAnabolicSteroidsPastChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleOwnerDrugsAnabolicSteroidsPastChangeHabitConsumptionReason,
                },
              ],
            },
          ],
        },
        {
          path: 'other',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleOwnerOtherDrugsOrNarcoticsUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerOtherDrugsOrNarcoticsUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerOtherDrugsOrNarcoticsUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleOwnerOtherDrugsOrNarcoticsConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleOwnerOtherDrugsOrNarcoticsConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'changeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleOwnerOtherDrugsOrNarcoticsChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleOwnerOtherDrugsOrNarcoticsChangeHabitConsumptionReason,
                },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'startDate', nodeId: NodeIds.lifestyleOwnerOtherDrugsOrNarcoticsPastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleOwnerOtherDrugsOrNarcoticsPastUseEndDate },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleOwnerOtherDrugsOrNarcoticsPastConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleOwnerOtherDrugsOrNarcoticsPastConsumptionHabitsFrequency,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'drivingRecord',
      children: [
        {
          path: 'violations',
          children: [
            { path: 'hasViolations', nodeId: NodeIds.lifestyleOwnerDrivingHasViolations },
            { path: 'hasViolationsInPast3Years', nodeId: NodeIds.lifestyleOwnerDrivingHasViolationsInPast3Years },
            { path: 'reasons', nodeId: NodeIds.lifestyleOwnerDrivingViolationsReasons },
            {
              path: 'firstViolation',
              children: [
                { path: 'date', nodeId: NodeIds.lifestyleOwner1stViolationDate },
                { path: 'movingViolationDetails', nodeId: NodeIds.lifestyleOwnerMovingViolationDetails },
                { path: '1stSpeedingLimitDetails', nodeId: NodeIds.lifestyleOwner1stSpeedingLimitDetails },
                { path: 'OthersDetails', nodeId: NodeIds.lifestyleOwner1stDrivingViolationsOthersDetails },
              ],
            },
            {
              path: 'secondViolation',
              children: [
                { path: 'date', nodeId: NodeIds.lifestyleOwner2ndViolationDate },
                { path: 'choices', nodeId: NodeIds.lifestyleOwner2ndDrivingViolationsChoices },
                { path: '2ndSpeedingLimitDetails', nodeId: NodeIds.lifestyleOwner2ndSpeedingLimitDetails },
                { path: 'OthersDetails', nodeId: NodeIds.lifestyleOwner2ndDrivingViolationsOthersDetails },
              ],
            },
            { path: 'additionalDrivingViolations', nodeId: NodeIds.lifestyleOwnerAdditionalDrivingViolations },
            {
              path: 'impairedDrivingLicense',
              nodeId: NodeIds.lifestyleOwnerImpairedDrivingOffence,
              isCollection: true,
              children: [
                { path: 'lastOffenceDate', nodeId: NodeIds.lifestyleOwnerImpairedDrivingLastOffenceDate },
                { path: 'suspensionDate', nodeId: NodeIds.lifestyleOwnerImpairedDrivingLicenseSuspensionDate },
                { path: 'recoveryDate', nodeId: NodeIds.lifestyleOwnerImpairedDrivingLicenseRecoveryDate },
                {
                  path: 'hasIgnitionInterlockDevice',
                  nodeId: NodeIds.lifestyleOwnerImpairedDrivingHasIgnitionInterlockDevice,
                },
                {
                  path: 'ignitionInterlockDeviceStartDate',
                  nodeId: NodeIds.lifestyleOwnerImpairedDrivingIgnitionInterlockDeviceStartDate,
                },
                {
                  path: 'ignitionInterlockDeviceEndDate',
                  nodeId: NodeIds.lifestyleOwnerImpairedDrivingIgnitionInterlockDeviceEndDate,
                },
                { path: 'withSuspendedLicense', nodeId: NodeIds.lifestyleOwnerImpairedDrivingWithSuspendedLicense },
                {
                  path: 'anyOtherImpairedDrivingCharges',
                  nodeId: NodeIds.lifestyleOwnerImpairedDrivingAnyOtherImpairedDrivingCharges,
                },
                {
                  path: 'anyOtherImpairedDrivingChargesDates',
                  nodeId: NodeIds.lifestyleOwnerImpairedDrivingAnyOtherImpairedDrivingChargesDates,
                },
              ],
            },
            {
              path: 'licenseSuspendedOtherReason',
              nodeId: NodeIds.lifestyleOwnerLicenseSuspendedOtherReason,
              isCollection: true,
              children: [
                { path: 'reasons', nodeId: NodeIds.lifestyleOwnerLicenseSuspendedOtherReasonReasons },
                {
                  path: 'medicalReasonDetails',
                  nodeId: NodeIds.lifestyleOwnerLicenseSuspendedOtherReasonMedicalReasonDetails,
                },
                { path: 'otherDetails', nodeId: NodeIds.lifestyleOwnerLicenseSuspendedOtherReasonOtherDetails },
                { path: 'suspensionDate', nodeId: NodeIds.lifestyleOwnerLicenseSuspendedOtherReasonSuspensionDate },
                { path: 'recoveryDate', nodeId: NodeIds.lifestyleOwnerLicenseSuspendedOtherReasonRecoveryDate },
                {
                  path: 'droveWithSuspendedLicense',
                  nodeId: NodeIds.lifestyleOwnerLicenseSuspendedOtherReasonDroveWithSuspendedLicense,
                },
              ],
            },
            {
              path: 'additionalDrivingViolationDetails',
              nodeId: NodeIds.lifestyleOwnerAdditionalDrivingViolationDetails,
              isCollection: true,
              children: [
                { path: 'date', nodeId: NodeIds.lifestyleOwnerAdditionalDrivingViolationsDate },
                { path: 'type', nodeId: NodeIds.lifestyleOwnerAdditionalDrivingViolationType },
                {
                  path: 'kilometersOverSpeedLimit',
                  nodeId: NodeIds.lifestyleOwnerAdditionalDrivingViolationKmOverSpeedLimit,
                },
                {
                  path: 'violationsOtherDetails',
                  nodeId: NodeIds.lifestleOwnerAdditionalDrivingViolationsOtherDetails,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'criminalRecord',
      children: [
        { path: 'hasCriminalHistory', nodeId: NodeIds.lifestyleOwnerHasCriminalHistory },
        { path: 'hasCriminalHistory1', nodeId: NodeIds.lifestyleOwnerHasCriminalHistory1 },
        { path: 'criminalHistoryChoices', nodeId: NodeIds.lifestyleOwnerCriminalHistoryChoices },
        {
          path: 'underInvestigations',
          children: [
            { path: 'circumstances', nodeId: NodeIds.lifestyleOwnerUnderInvestigationsCircumstances },
            { path: 'date', nodeId: NodeIds.lifestyleOwnerUnderInvestigationsDate },
            { path: 'charges', nodeId: NodeIds.lifestyleOwnerUnderInvestigationsCharges },
          ],
        },
        {
          path: 'awaitingTrial',
          children: [
            { path: 'circumstances', nodeId: NodeIds.lifestyleOwnerTrialCircumstances },
            { path: 'date', nodeId: NodeIds.lifestyleOwnerTrialDate },
            { path: 'charges', nodeId: NodeIds.lifestyleOwnerTrialCharges },
          ],
        },
        {
          path: 'onProbation',
          children: [
            { path: 'startDate', nodeId: NodeIds.lifestyleOwnerProbationStartDate },
            { path: 'endDate', nodeId: NodeIds.lifestyleOwnerProbationEndDate },
            { path: 'circumstances', nodeId: NodeIds.lifestyleOwnerProbationCircumstances },
            { path: 'date', nodeId: NodeIds.lifestyleOwnerProbationDate },
            { path: 'charges', nodeId: NodeIds.lifestyleOwnerProbationCharges },
            { path: 'sentenceDetails', nodeId: NodeIds.lifestyleOwnerProbationSentenceDetails },
          ],
        },
        {
          path: 'otherOffences',
          children: [
            { path: 'anyOtherOffences', nodeId: NodeIds.lifestyleOwnerAnyOtherOffences },
            { path: 'circumstances', nodeId: NodeIds.lifestyleOwnerOtherOffencesCircumstances },
            { path: 'date', nodeId: NodeIds.lifestyleOwnerOtherOffencesDate },
            { path: 'charges', nodeId: NodeIds.lifestyleOwnerOtherOffencesCharges },
          ],
        },
        {
          path: 'chargedOrConvicted',
          children: [
            { path: 'circumstances', nodeId: NodeIds.lifestyleOwnerChargedOrConvictedCircumstances },
            { path: 'date', nodeId: NodeIds.lifestyleOwnerChargedOrConvictedDate },
            { path: 'charges', nodeId: NodeIds.lifestyleOwnerChargedOrConvictedCharges },
            { path: 'sentenceDetails', nodeId: NodeIds.lifestyleOwnerChargedOrConvictedSentenceDetails },
            {
              path: 'additionalOffence',
              nodeId: NodeIds.lifestyleOwnerChargedOrConvictedAdditionalOffence,
            },
          ],
        },
        {
          path: 'additionalChargedOrConvictedAdditionalOffence',
          children: [
            {
              path: 'circumstances',
              nodeId: NodeIds.lifestyleOwnerChargedOrConvictedAdditionalOffenceCircumstances,
            },
            {
              path: 'date',
              nodeId: NodeIds.lifestyleOwnerChargedOrConvictedAdditionalOffenceOtherOffencesDate,
            },
            {
              path: 'charges',
              nodeId: NodeIds.lifestyleOwnerChargedOrConvictedAdditionalOffenceCharges,
            },
          ],
        },
      ],
    },
    {
      path: 'travel',
      children: [
        { path: 'hasTravel', nodeId: NodeIds.lifestyleOwnerHasTravel },
        { path: 'pastOrFutureTravelWithin12Months', nodeId: NodeIds.lifestyleOwnerTravel12MonthsPastOrFuture },
      ],
    },
    { path: 'personalMedicalQuestionnaire', nodeId: NodeIds.lifestyleOwnerPersonalMedicalQuestionnaire },
    { path: 'hasFlownAsOtherThanPassenger', nodeId: NodeIds.lifestyleOwnerHasFlownAsOtherThanPassenger },
    {
      path: 'participationInExtremeSport',
      children: [
        { path: 'participationInExtremeSport', nodeId: NodeIds.lifestyleOwnerParticipationInExtremeSport },
        {
          path: 'participationInExtremeSportChoices',
          nodeId: NodeIds.lifestyleOwnerParticipationInExtremeSportChoices,
        },
      ],
    },
    {
      path: 'tobacco',
      children: [
        { path: 'isTobaccoUser', nodeId: NodeIds.lifestyleOwnerIsTobaccoUser },
        { path: 'usedWithin12Months', nodeId: NodeIds.lifestyleOwnerTobaccoUsedWithin12Months },
        { path: 'choices', nodeId: NodeIds.lifestyleOwnerTobaccoChoices },
        { path: 'choicesOtherIs', nodeId: NodeIds.lifestyleOwnerTobaccoChoicesOtherIs },
        { path: 'cigarettesDailyQuantity', nodeId: NodeIds.lifestyleOwnerTobaccoCigarettesDailyQuantity },
        { path: 'mostRecentUsage', nodeId: NodeIds.lifestyleOwnerTobaccoMostRecentUsage },
        { path: 'lastDateTobaccoUse', nodeId: NodeIds.lifestyleOwnerTobaccoLastDateTobaccoUse },
        { path: 'bigCigarsQuantity', nodeId: NodeIds.lifestyleOwnerTobaccoBigCigarsQuantity },
        { path: 'lastDateBigCigars', nodeId: NodeIds.lifestyleOwnerTobaccoLastDateBigCigars },
      ],
    },
    {
      path: 'travelLast12MonthsDetails',
      nodeId: NodeIds.lifestyleOwnerTravelLast12MonthsDetails,
      isCollection: true,
      children: [
        { path: 'destination', nodeId: NodeIds.lifestyleOwnerTravelLast12MonthsDestination },
        { path: 'destinationOther', nodeId: NodeIds.lifestyleOwnerTravelLast12MonthsDestinationOther },
        { path: 'reason', nodeId: NodeIds.lifestyleOwnerTravelLast12MonthsReason },
        { path: 'reasonOther', nodeId: NodeIds.lifestyleOwnerTravelLast12MonthsReasonOther },
        { path: 'departureDate', nodeId: NodeIds.lifestyleOwnerTravelLast12MonthsDepartureDate },
        { path: 'returnDate', nodeId: NodeIds.lifestyleOwnerTravelLast12MonthsReturnDate },
      ],
    },
    {
      path: 'travelNext12MonthsDetails',
      nodeId: NodeIds.lifestyleOwnerTravelNext12MonthsDetails,
      isCollection: true,
      children: [
        { path: 'destination', nodeId: NodeIds.lifestyleOwnerTravelNext12MonthsDestination },
        { path: 'destinationOther', nodeId: NodeIds.lifestyleOwnerTravelNext12MonthsDestinationOther },
        { path: 'reason', nodeId: NodeIds.lifestyleOwnerTravelNext12MonthsReason },
        { path: 'reasonOther', nodeId: NodeIds.lifestyleOwnerTravelNext12MonthsReasonOther },
        { path: 'departureDate', nodeId: NodeIds.lifestyleOwnerTravelNext12MonthsDepartureDate },
        { path: 'returnDate', nodeId: NodeIds.lifestyleOwnerTravelNext12MonthsReturnDate },
        { path: 'unknownDate', nodeId: NodeIds.lifestyleOwnerTravelNext12MonthsUnknownDate },
      ],
    },
  ],
};

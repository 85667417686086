import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const replacementJoint: AnswerPathTreeNode = {
  path: 'replacementJoint',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredReplacementJointIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredReplacementJointPreviousEpisodesCount },
    { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredReplacementJointDateSymptoms },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredReplacementJointHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredReplacementJointHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredReplacementJointMedicationWasPrescribed },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredReplacementJointPainLocation },
        {
          path: 'leftRight',
          nodeId: NodeIds.healthInsuredReplacementJointPainLocationLeftRight,
        },
        { path: 'other', nodeId: NodeIds.healthInsuredReplacementJointPainLocationOther },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredReplacementJointPainCause },
        { path: 'details', nodeId: NodeIds.healthInsuredReplacementJointPainCauseDetails },
      ],
    },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredReplacementJointHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredReplacementJointOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredReplacementJointTypeOfOperationPerformed,
        },
        {
          path: 'performedDate',
          nodeId: NodeIds.healthInsuredReplacementJointOperationPerformedDate,
        },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredReplacementJointTypeOfOperationPlanned,
        },
        {
          path: 'plannedDate',
          nodeId: NodeIds.healthInsuredReplacementJointOperationPlannedDate,
        },
        {
          path: 'unknownDate',
          nodeId: NodeIds.healthInsuredReplacementJointOperationUnknownDate,
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredReplacementJointHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredReplacementJointAbsenceStartDate },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredReplacementJointAbsenceFrequencyNumber,
        },
        { path: 'frequency', nodeId: NodeIds.healthInsuredReplacementJointAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredReplacementJointAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredReplacementJointAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredReplacementJointAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredReplacementJointHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredReplacementJointHasConsultedDoctorsProfession,
        },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredReplacementJointHasConsultedDoctorsProfessionDetails,
        },
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredReplacementJointHasConsultedDoctorsName,
        },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredReplacementJointHasConsultedDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredReplacementJointHasConsultedDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredReplacementJointHasConsultedDoctorsLastDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredReplacementJointHasConsultedDoctorsResult,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredReplacementJointHasConsultedDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredReplacementJointHasConsultedDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredReplacementJointHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredReplacementJointHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredReplacementJointHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        {
          path: 'type',
          nodeId: NodeIds.healthInsuredReplacementJointHasTakenTestsOrExamsType,
        },
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredReplacementJointHasTakenTestsOrExamsDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredReplacementJointHasTakenTestsOrExamsResult,
        },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredReplacementJointHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredReplacementJointMedicationDetails,
      isCollection: true,
      children: [
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredReplacementJointMedicationName,
        },
        {
          path: 'dosage',
          nodeId: NodeIds.healthInsuredReplacementJointMedicationDosage,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredReplacementJointMedicationStartDate,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredReplacementJointMedicationEndDate,
        },
      ],
    },
  ],
};

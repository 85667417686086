import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const sportAmusementOrAvocation: AnswerPathTreeNode = {
  path: 'sportAmusementOrAvocation',
  children: [
    { path: 'avocation', children: [{ path: 'sportChoice', nodeId: NodeIds.avocationSportChoice }] },
    {
      path: 'autoRacing',
      children: [
        { path: 'associations', nodeId: NodeIds.autoRacingAssociations },
        { path: 'licensesAndTitles', nodeId: NodeIds.autoRacingLicensesAndTitles },
        { path: 'participationMonetary', nodeId: NodeIds.autoRacingParticipationMonetary },
        { path: 'gains', nodeId: NodeIds.autoRacingGains },
        { path: 'location', nodeId: NodeIds.autoRacingLocation },
        { path: 'experimentalForm', nodeId: NodeIds.autoRacingExperimentalForm },
        { path: 'experimentalFormDetails', nodeId: NodeIds.autoRacingExperimentalFormDetails },
        { path: 'sportDuration', nodeId: NodeIds.autoRacingSportDuration },
        { path: 'participationFrequency', nodeId: NodeIds.autoRacingParticipationFrequency },
        { path: 'greatestSpeed', nodeId: NodeIds.autoRacingGreatestSpeed },
        { path: 'greatestSpeedCount', nodeId: NodeIds.autoRacingGreatestSpeedCount },
        { path: 'greatestSpeedCountYear', nodeId: NodeIds.autoRacingGreatestSpeedCountYear },
        { path: 'averageSpeed', nodeId: NodeIds.autoRacingAverageSpeed },
        { path: 'participationAverageTime', nodeId: NodeIds.autoRacingParticipationAverageTime },
        { path: 'vehicleModified', nodeId: NodeIds.autoRacingVehicleModified },
        { path: 'vehicleModel', nodeId: NodeIds.autoRacingVehicleModel },
        { path: 'vehicleClass', nodeId: NodeIds.autoRacingVehicleClass },
        { path: 'typeOfFuel', nodeId: NodeIds.autoRacingTypeOfFuel },
        { path: 'vehicleHp', nodeId: NodeIds.autoRacingVehicleHp },
        { path: 'topSpeed', nodeId: NodeIds.autoRacingTopSpeed },
        { path: 'engineDisplacement', nodeId: NodeIds.autoRacingEngineDisplacement },
        { path: 'trackType', nodeId: NodeIds.autoRacingTrackType },
      ],
    },
    {
      path: 'boatRacing',
      children: [
        { path: 'associations', nodeId: NodeIds.boatRacingAssociations },
        { path: 'licensesAndTitles', nodeId: NodeIds.boatRacingLicensesAndTitles },
        { path: 'participationMonetary', nodeId: NodeIds.boatRacingParticipationMonetary },
        { path: 'gains', nodeId: NodeIds.boatRacingGains },
        { path: 'location', nodeId: NodeIds.boatRacingLocation },
        { path: 'experimentalForm', nodeId: NodeIds.boatRacingExperimentalForm },
        { path: 'experimentalFormDetails', nodeId: NodeIds.boatRacingExperimentalFormDetails },
        { path: 'sportDuration', nodeId: NodeIds.boatRacingSportDuration },
        { path: 'participationFrequency', nodeId: NodeIds.boatRacingParticipationFrequency },
        { path: 'greatestSpeed', nodeId: NodeIds.boatRacingGreatestSpeed },
        { path: 'greatestSpeedCount', nodeId: NodeIds.boatRacingGreatestSpeedCount },
        { path: 'greatestSpeedCountYear', nodeId: NodeIds.boatRacingGreatestSpeedCountYear },
        { path: 'averageSpeed', nodeId: NodeIds.boatRacingAverageSpeed },
        { path: 'participationAverageTime', nodeId: NodeIds.boatRacingParticipationAverageTime },
        { path: 'vehicleModified', nodeId: NodeIds.boatRacingVehicleModified },
        { path: 'vehicleModel', nodeId: NodeIds.boatRacingVehicleModel },
        { path: 'vehicleClass', nodeId: NodeIds.boatRacingVehicleClass },
        { path: 'typeOfFuel', nodeId: NodeIds.boatRacingTypeOfFuel },
        { path: 'vehicleHp', nodeId: NodeIds.boatRacingVehicleHp },
        { path: 'topSpeed', nodeId: NodeIds.boatRacingTopSpeed },
        { path: 'engineDisplacement', nodeId: NodeIds.boatRacingEngineDisplacement },
        { path: 'trackType', nodeId: NodeIds.boatRacingTrackType },
      ],
    },
    {
      path: 'boxing',
      children: [
        { path: 'associations', nodeId: NodeIds.boxingAssociations },
        { path: 'licensesAndTitles', nodeId: NodeIds.boxingLicensesAndTitles },
        { path: 'participationMonetary', nodeId: NodeIds.boxingParticipationMonetary },
        { path: 'gains', nodeId: NodeIds.boxingGains },
        { path: 'location', nodeId: NodeIds.boxingLocation },
        { path: 'experimentalForm', nodeId: NodeIds.boxingExperimentalForm },
        { path: 'experimentalFormDetails', nodeId: NodeIds.boxingExperimentalFormDetails },
        { path: 'sportDuration', nodeId: NodeIds.boxingSportDuration },
        { path: 'participationFrequency', nodeId: NodeIds.boxingParticipationFrequency },
        { path: 'participationAverageTime', nodeId: NodeIds.boxingParticipationAverageTime },
      ],
    },
    {
      path: 'ballooning',
      children: [
        { path: 'associations', nodeId: NodeIds.ballooningAssociations },
        { path: 'licensesAndTitles', nodeId: NodeIds.ballooningLicensesAndTitles },
        { path: 'participationMonetary', nodeId: NodeIds.ballooningParticipationMonetary },
        { path: 'gains', nodeId: NodeIds.ballooningGains },
        { path: 'location', nodeId: NodeIds.ballooningLocation },
        { path: 'experimentalForm', nodeId: NodeIds.ballooningExperimentalForm },
        { path: 'experimentalFormDetails', nodeId: NodeIds.ballooningExperimentalFormDetails },
        { path: 'sportDuration', nodeId: NodeIds.ballooningSportDuration },
        { path: 'participationFrequency', nodeId: NodeIds.ballooningParticipationFrequency },
        { path: 'greatestHeight', nodeId: NodeIds.ballooningGreatestHeight },
        { path: 'greatestHeightCount', nodeId: NodeIds.ballooningGreatestHeightCount },
        { path: 'greatestHeightCountYear', nodeId: NodeIds.ballooningGreatestHeightCountYear },
        { path: 'averageHeight', nodeId: NodeIds.ballooningAverageHeight },
        { path: 'participationAverageTime', nodeId: NodeIds.ballooningParticipationAverageTime },
      ],
    },
    {
      path: 'hangGlidingUltralights',
      children: [
        { path: 'associations', nodeId: NodeIds.hangGlidingUltralightsAssociations },
        { path: 'licensesAndTitles', nodeId: NodeIds.hangGlidingUltralightsLicensesAndTitles },
        { path: 'participationMonetary', nodeId: NodeIds.hangGlidingUltralightsParticipationMonetary },
        { path: 'gains', nodeId: NodeIds.hangGlidingUltralightsGains },
        { path: 'location', nodeId: NodeIds.hangGlidingUltralightsLocation },
        { path: 'experimentalForm', nodeId: NodeIds.hangGlidingUltralightsExperimentalForm },
        { path: 'experimentalFormDetails', nodeId: NodeIds.hangGlidingUltralightsExperimentalFormDetails },
        { path: 'sportDuration', nodeId: NodeIds.hangGlidingUltralightsSportDuration },
        { path: 'participationFrequency', nodeId: NodeIds.hangGlidingUltralightsParticipationFrequency },
        { path: 'greatestHeight', nodeId: NodeIds.hangGlidingUltralightsGreatestHeight },
        { path: 'greatestHeightCount', nodeId: NodeIds.hangGlidingUltralightsGreatestHeightCount },
        { path: 'greatestHeightCountYear', nodeId: NodeIds.hangGlidingUltralightsGreatestHeightCountYear },
        { path: 'averageHeight', nodeId: NodeIds.hangGlidingUltralightsAverageHeight },
        { path: 'participationAverageTime', nodeId: NodeIds.hangGlidingUltralightsParticipationAverageTime },
      ],
    },
    {
      path: 'motorcycleRacing',
      children: [
        { path: 'associations', nodeId: NodeIds.motorcycleRacingAssociations },
        { path: 'licensesAndTitles', nodeId: NodeIds.motorcycleRacingLicensesAndTitles },
        { path: 'participationMonetary', nodeId: NodeIds.motorcycleRacingParticipationMonetary },
        { path: 'gains', nodeId: NodeIds.motorcycleRacingGains },
        { path: 'location', nodeId: NodeIds.motorcycleRacingLocation },
        { path: 'experimentalForm', nodeId: NodeIds.motorcycleRacingExperimentalForm },
        { path: 'experimentalFormDetails', nodeId: NodeIds.motorcycleRacingExperimentalFormDetails },
        { path: 'sportDuration', nodeId: NodeIds.motorcycleRacingSportDuration },
        { path: 'participationFrequency', nodeId: NodeIds.motorcycleRacingParticipationFrequency },
        { path: 'greatestSpeed', nodeId: NodeIds.motorcycleRacingGreatestSpeed },
        { path: 'greatestSpeedCount', nodeId: NodeIds.motorcycleRacingGreatestSpeedCount },
        { path: 'greatestSpeedCountYear', nodeId: NodeIds.motorcycleRacingGreatestSpeedCountYear },
        { path: 'averageSpeed', nodeId: NodeIds.motorcycleRacingAverageSpeed },
        { path: 'participationAverageTime', nodeId: NodeIds.motorcycleRacingParticipationAverageTime },
        { path: 'vehicleModified', nodeId: NodeIds.motorcycleRacingVehicleModified },
        { path: 'vehicleModel', nodeId: NodeIds.motorcycleRacingVehicleModel },
        { path: 'vehicleClass', nodeId: NodeIds.motorcycleRacingVehicleClass },
        { path: 'typeOfFuel', nodeId: NodeIds.motorcycleRacingTypeOfFuel },
        { path: 'vehicleHp', nodeId: NodeIds.motorcycleRacingVehicleHp },
        { path: 'topSpeed', nodeId: NodeIds.motorcycleRacingTopSpeed },
        { path: 'engineDisplacement', nodeId: NodeIds.motorcycleRacingEngineDisplacement },
        { path: 'trackType', nodeId: NodeIds.motorcycleRacingTrackType },
      ],
    },
    {
      path: 'parachutingSkydiving',
      children: [
        { path: 'associations', nodeId: NodeIds.parachutingSkydivingAssociations },
        { path: 'licensesAndTitles', nodeId: NodeIds.parachutingSkydivingLicensesAndTitles },
        { path: 'participationMonetary', nodeId: NodeIds.parachutingSkydivingParticipationMonetary },
        { path: 'gains', nodeId: NodeIds.parachutingSkydivingGains },
        { path: 'location', nodeId: NodeIds.parachutingSkydivingLocation },
        { path: 'experimentalForm', nodeId: NodeIds.parachutingSkydivingExperimentalForm },
        { path: 'experimentalFormDetails', nodeId: NodeIds.parachutingSkydivingExperimentalFormDetails },
        { path: 'sportDuration', nodeId: NodeIds.parachutingSkydivingSportDuration },
        { path: 'participationFrequency', nodeId: NodeIds.parachutingSkydivingParticipationFrequency },
        { path: 'greatestHeight', nodeId: NodeIds.parachutingSkydivingGreatestHeight },
        { path: 'greatestHeightCount', nodeId: NodeIds.parachutingSkydivingGreatestHeightCount },
        { path: 'greatestHeightCountYear', nodeId: NodeIds.parachutingSkydivingGreatestHeightCountYear },
        { path: 'averageHeight', nodeId: NodeIds.parachutingSkydivingAverageHeight },
        { path: 'participationAverageTime', nodeId: NodeIds.parachutingSkydivingParticipationAverageTime },
      ],
    },
    {
      path: 'professionalAthletics',
      children: [
        { path: 'associations', nodeId: NodeIds.professionalAthleticsAssociations },
        { path: 'licensesAndTitles', nodeId: NodeIds.professionalAthleticsLicensesAndTitles },
        { path: 'participationMonetary', nodeId: NodeIds.professionalAthleticsParticipationMonetary },
        { path: 'gains', nodeId: NodeIds.professionalAthleticsGains },
        { path: 'location', nodeId: NodeIds.professionalAthleticsLocation },
        { path: 'experimentalForm', nodeId: NodeIds.professionalAthleticsExperimentalForm },
        { path: 'experimentalFormDetails', nodeId: NodeIds.professionalAthleticsExperimentalFormDetails },
        { path: 'sportDuration', nodeId: NodeIds.professionalAthleticsSportDuration },
        { path: 'participationFrequency', nodeId: NodeIds.professionalAthleticsParticipationFrequency },
        { path: 'participationAverageTime', nodeId: NodeIds.professionalAthleticsParticipationAverageTime },
      ],
    },
    {
      path: 'scubaSkinDiving',
      children: [
        { path: 'associations', nodeId: NodeIds.scubaSkinDivingAssociations },
        { path: 'licensesAndTitles', nodeId: NodeIds.scubaSkinDivingLicensesAndTitles },
        { path: 'participationMonetary', nodeId: NodeIds.scubaSkinDivingParticipationMonetary },
        { path: 'gains', nodeId: NodeIds.scubaSkinDivingGains },
        { path: 'location', nodeId: NodeIds.scubaSkinDivingLocation },
        { path: 'experimentalForm', nodeId: NodeIds.scubaSkinDivingExperimentalForm },
        { path: 'experimentalFormDetails', nodeId: NodeIds.scubaSkinDivingExperimentalFormDetails },
        { path: 'sportDuration', nodeId: NodeIds.scubaSkinDivingSportDuration },
        { path: 'participationFrequency', nodeId: NodeIds.scubaSkinDivingParticipationFrequency },
        { path: 'participationAverageTime', nodeId: NodeIds.scubaSkinDivingParticipationAverageTime },
        { path: 'equipmentUsed', nodeId: NodeIds.scubaSkinDivingEquipmentUsed },
        { path: 'ownEquipment', nodeId: NodeIds.scubaSkinDivingOwnEquipment },
        { path: 'diveAlone', nodeId: NodeIds.scubaSkinDivingDiveAlone },
        { path: 'averageDepth', nodeId: NodeIds.scubaSkinDivingAverageDepth },
        { path: 'maximumDepth', nodeId: NodeIds.scubaSkinDivingMaximumDepth },
        { path: 'numberOfTimesAttained', nodeId: NodeIds.scubaSkinDivingNumberOfTimesAttained },
      ],
    },
    {
      path: 'snowmobileRacing',
      children: [
        { path: 'associations', nodeId: NodeIds.snowmobileRacingAssociations },
        { path: 'licensesAndTitles', nodeId: NodeIds.snowmobileRacingLicensesAndTitles },
        { path: 'participationMonetary', nodeId: NodeIds.snowmobileRacingParticipationMonetary },
        { path: 'gains', nodeId: NodeIds.snowmobileRacingGains },
        { path: 'location', nodeId: NodeIds.snowmobileRacingLocation },
        { path: 'experimentalForm', nodeId: NodeIds.snowmobileRacingExperimentalForm },
        { path: 'experimentalFormDetails', nodeId: NodeIds.snowmobileRacingExperimentalFormDetails },
        { path: 'sportDuration', nodeId: NodeIds.snowmobileRacingSportDuration },
        { path: 'participationFrequency', nodeId: NodeIds.snowmobileRacingParticipationFrequency },
        { path: 'greatestSpeed', nodeId: NodeIds.snowmobileRacingGreatestSpeed },
        { path: 'greatestSpeedCount', nodeId: NodeIds.snowmobileRacingGreatestSpeedCount },
        { path: 'greatestSpeedCountYear', nodeId: NodeIds.snowmobileRacingGreatestSpeedCountYear },
        { path: 'averageSpeed', nodeId: NodeIds.snowmobileRacingAverageSpeed },
        { path: 'participationAverageTime', nodeId: NodeIds.snowmobileRacingParticipationAverageTime },
        { path: 'vehicleModified', nodeId: NodeIds.snowmobileRacingVehicleModified },
        { path: 'vehicleModel', nodeId: NodeIds.snowmobileRacingVehicleModel },
        { path: 'vehicleClass', nodeId: NodeIds.snowmobileRacingVehicleClass },
        { path: 'typeOfFuel', nodeId: NodeIds.snowmobileRacingTypeOfFuel },
        { path: 'vehicleHp', nodeId: NodeIds.snowmobileRacingVehicleHp },
        { path: 'topSpeed', nodeId: NodeIds.snowmobileRacingTopSpeed },
        { path: 'engineDisplacement', nodeId: NodeIds.snowmobileRacingEngineDisplacement },
        { path: 'trackType', nodeId: NodeIds.snowmobileRacingTrackType },
      ],
    },
  ],
};

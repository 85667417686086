import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';
import { breastCyst } from './breastCyst';
import { breastDisorder } from './breastDisorder';
import { leukemia } from './leukemia';
import { lymphNodeDisorder } from './lymphNodeDisorder';
import { nodule } from './nodule';
import { ovarianCyst } from './ovarianCyst';
import { polyps } from './polyps';
import { tumor } from './tumor';

export const cancer: AnswerPathTreeNode = {
  path: 'cancer',
  children: [
    { path: 'hasCondition', nodeId: NodeIds.healthInsuredCancerHasCondition },
    { path: 'choices', nodeId: NodeIds.healthInsuredCancerChoices },
    { path: 'details', nodeId: NodeIds.healthInsuredCancerDetails },
    { path: 'otherDetails', nodeId: NodeIds.healthInsuredCancerOtherDetails },
    nodule,
    leukemia,
    breastCyst,
    ovarianCyst,
    polyps,
    tumor,
    lymphNodeDisorder,
    breastDisorder,
  ],
};

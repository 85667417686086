import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const bursitis: AnswerPathTreeNode = {
  path: 'bursitis',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredBursitisIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredBursitisPreviousEpisodesCount },
    { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredBursitisDateSymptoms },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredBursitisPainLocation },
        { path: 'leftRight', nodeId: NodeIds.healthInsuredBursitisPainLocationLeftRight },
        { path: 'other', nodeId: NodeIds.healthInsuredBursitisPainLocationOther },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredBursitisPainCause },
        { path: 'details', nodeId: NodeIds.healthInsuredBursitisPainCauseDetails },
      ],
    },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredBursitisHasConsultedDoctors },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredBursitisHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredBursitisHasConsultedDoctorsProfession,
        },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredBursitisHasConsultedDoctorsProfessionDetails,
        },
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredBursitisHasConsultedDoctorsName,
        },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredBursitisHasConsultedDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredBursitisHasConsultedDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredBursitisHasConsultedDoctorsLastDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredBursitisHasConsultedDoctorsResult,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredBursitisHasConsultedDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredBursitisHasConsultedDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredBursitisHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredBursitisHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredBursitisHasTakenTestsOrExams },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredBursitisHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        {
          path: 'type',
          nodeId: NodeIds.healthInsuredBursitisHasTakenTestsOrExamsType,
        },
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredBursitisHasTakenTestsOrExamsDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredBursitisHasTakenTestsOrExamsResult,
        },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredBursitisHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredBursitisMedicationDetails,
      isCollection: true,
      children: [
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredBursitisMedicationName,
        },
        {
          path: 'dosage',
          nodeId: NodeIds.healthInsuredBursitisMedicationDosage,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredBursitisMedicationStartDate,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredBursitisMedicationEndDate,
        },
      ],
    },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredBursitisMedicationWasPrecribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredBursitisHadOperation },
        { path: 'performedOrPlanned', nodeId: NodeIds.healthInsuredBursitisOperationPerformedOrPlanned },
        { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthInsuredBursitisTypeOfOperationPerformed },
        { path: 'performedDate', nodeId: NodeIds.healthInsuredBursitisOperationPerformedDate },
        { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthInsuredBursitisTypeOfOperationPlanned },
        { path: 'plannedDate', nodeId: NodeIds.healthInsuredBursitisOperationPlannedDate },
        { path: 'unknownDate', nodeId: NodeIds.healthInsuredBursitisOperationUnknownDate },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredBursitisHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredBursitisAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredBursitisAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredBursitisAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredBursitisAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredBursitisAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredBursitisAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
  ],
};

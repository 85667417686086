import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const asthma: AnswerPathTreeNode = {
  path: 'asthma',
  children: [
    { path: 'healthOtherAsthmaFirstAttackDate', nodeId: NodeIds.healthOtherAsthmaFirstAttackDate },
    { path: 'healthOtherAsthmaAttacksFrequency', nodeId: NodeIds.healthOtherAsthmaAttacksFrequency },
    { path: 'healthOtherAsthmaLastAttackDate', nodeId: NodeIds.healthOtherAsthmaLastAttackDate },
    { path: 'healthOtherAsthmaSeverity', nodeId: NodeIds.healthOtherAsthmaSeverity },
    { path: 'healthOtherAsthmaPhysicianCount', nodeId: NodeIds.healthOtherAsthmaPhysicianCount },
    { path: 'healthOtherAsthmaPhysicianName1', nodeId: 'health-other-asthma-physician-name1' },
    { path: 'healthOtherAsthmaPhysicianAddress1', nodeId: 'health-other-asthma-physician-address1' },
    { path: 'healthOtherAsthmaPhysicianName2', nodeId: 'health-other-asthma-physician-name2' },
    { path: 'healthOtherAsthmaPhysicianAddress2', nodeId: 'health-other-asthma-physician-address2' },
    { path: 'healthOtherAsthmaPhysicianName3', nodeId: 'health-other-asthma-physician-name3' },
    { path: 'healthOtherAsthmaPhysicianAddress3', nodeId: 'health-other-asthma-physician-address3' },
    { path: 'healthOtherAsthmaPhysicianName4', nodeId: 'health-other-asthma-physician-name4' },
    { path: 'healthOtherAsthmaPhysicianAddress4', nodeId: 'health-other-asthma-physician-address4' },
    { path: 'healthOtherAsthmaPhysicianName5', nodeId: 'health-other-asthma-physician-name5' },
    { path: 'healthOtherAsthmaPhysicianAddress5', nodeId: 'health-other-asthma-physician-address5' },
    { path: 'healthOtherAsthmaPhysicianName6', nodeId: 'health-other-asthma-physician-name6' },
    { path: 'healthOtherAsthmaPhysicianAddress6', nodeId: 'health-other-asthma-physician-address6' },
    { path: 'healthOtherAsthmaPhysicianName7', nodeId: 'health-other-asthma-physician-name7' },
    { path: 'healthOtherAsthmaPhysicianAddress7', nodeId: 'health-other-asthma-physician-address7' },
    { path: 'healthOtherAsthmaPhysicianName8', nodeId: 'health-other-asthma-physician-name8' },
    { path: 'healthOtherAsthmaPhysicianAddress8', nodeId: 'health-other-asthma-physician-address8' },
    { path: 'healthOtherAsthmaPhysicianName9', nodeId: 'health-other-asthma-physician-name9' },
    { path: 'healthOtherAsthmaPhysicianAddress9', nodeId: 'health-other-asthma-physician-address9' },
    { path: 'healthOtherAsthmaPhysicianName10', nodeId: 'health-other-asthma-physician-name10' },
    { path: 'healthOtherAsthmaPhysicianAddress10', nodeId: 'health-other-asthma-physician-address10' },
    { path: 'hasHealthOtherAsthmaInsuredHospitalized', nodeId: NodeIds.hasHealthOtherAsthmaInsuredHospitalized },
    { path: 'healthOtherAsthmaInsuredHospitalizedDate', nodeId: NodeIds.healthOtherAsthmaInsuredHospitalizedDate },
    { path: 'healthOtherAsthmaInsuredHospitalName', nodeId: NodeIds.healthOtherAsthmaInsuredHospitalName },
    { path: 'healthOtherAsthmaInsuredHospitalAddress', nodeId: NodeIds.healthOtherAsthmaInsuredHospitalAddress },
    { path: 'hasHealthOtherAsthmaInsuredEmergencyVisit', nodeId: NodeIds.hasHealthOtherAsthmaInsuredEmergencyVisit },
    { path: 'healthOtherAsthmaInsuredEmergencyVisitDate', nodeId: NodeIds.healthOtherAsthmaInsuredEmergencyVisitDate },
    { path: 'healthOtherAsthmaInsuredEmergencyName', nodeId: NodeIds.healthOtherAsthmaInsuredEmergencyName },
    { path: 'healthOtherAsthmaInsuredEmergencyAddress', nodeId: NodeIds.healthOtherAsthmaInsuredEmergencyAddress },
    {
      path: 'healthOtherAsthmaInsuredNumberOfMedications',
      nodeId: NodeIds.healthOtherAsthmaInsuredNumberOfMedications,
    },
    {
      path: 'healthOtherAsthmaInsuredNumberOfMedicationsDetails',
      nodeId: NodeIds.healthOtherAsthmaInsuredNumberOfMedicationsDetails,
    },
    { path: 'isHealthOtherAsthmaInsuredShortOfBreath', nodeId: NodeIds.isHealthOtherAsthmaInsuredShortOfBreath },
    { path: 'healthOtherAsthmaLimitations', nodeId: NodeIds.healthOtherAsthmaLimitations },
    { path: 'healthOtherAsthmaLimitationsDetails', nodeId: NodeIds.healthOtherAsthmaLimitationsDetails },
    { path: 'isHealthOtherAsthmaInsuredTobaccoUser', nodeId: NodeIds.isHealthOtherAsthmaInsuredTobaccoUser },
  ],
};

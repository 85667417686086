import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const familyIncome: AnswerPathTreeNode = {
  path: 'familyIncome',
  children: [
    { path: 'grossAnnualIncome', nodeId: NodeIds.insuredAnnualIncome },
    { path: 'otherIncome', nodeId: NodeIds.insuredOtherIncome },
    { path: 'otherSourceOfIncome', nodeId: NodeIds.insuredOtherSourceOfIncome },
    { path: 'annualIncomeFromAllSources', nodeId: NodeIds.insuredAnnualIncomeFromAllSources },
    { path: 'annualIncomeMultiplier10', nodeId: 'insured-annual-income-multiplier-10' },
    { path: 'annualIncomeMultiplier20', nodeId: 'insured-annual-income-multiplier-20' },
    { path: 'annualIncomeMultiplier30', nodeId: 'insured-annual-income-multiplier-30' },
  ],
};

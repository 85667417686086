import { Result, failure, success } from '@breathelife/result';
import {
  BlueprintReorder,
  isFieldBlueprintReorder,
  isQuestionBlueprintReorder,
  isSectionBlueprintReorder,
  isSelectOptionBlueprintReorder,
  isSubsectionBlueprintReorder,
} from '@breathelife/types';

export class CantUseReorderToMoveToAnotherParent extends Error {
  readonly tag = 'CantUseReorderToMoveToAnotherParent';
}

export function checkForSameParentInReorder(
  blueprintReorder: BlueprintReorder,
): Result<CantUseReorderToMoveToAnotherParent, void> {
  const { partIdentifier } = blueprintReorder;

  if (
    isSectionBlueprintReorder(blueprintReorder) ||
    isSubsectionBlueprintReorder(blueprintReorder) ||
    isQuestionBlueprintReorder(blueprintReorder) ||
    isFieldBlueprintReorder(blueprintReorder) ||
    isSelectOptionBlueprintReorder(blueprintReorder)
  ) {
    const { newPreviousSiblingIdentifier, parentIdentifier } = blueprintReorder.reorder;
    const identifier = parentIdentifier || newPreviousSiblingIdentifier;

    if (identifier && identifier.sectionGroupPartName !== partIdentifier.sectionGroupPartName) {
      return failure(new CantUseReorderToMoveToAnotherParent());
    }
  }

  if (
    isSubsectionBlueprintReorder(blueprintReorder) ||
    isQuestionBlueprintReorder(blueprintReorder) ||
    isFieldBlueprintReorder(blueprintReorder) ||
    isSelectOptionBlueprintReorder(blueprintReorder)
  ) {
    const { newPreviousSiblingIdentifier, parentIdentifier } = blueprintReorder.reorder;
    const identifier = parentIdentifier || newPreviousSiblingIdentifier;

    if (identifier && identifier.sectionPartName !== partIdentifier.sectionPartName) {
      return failure(new CantUseReorderToMoveToAnotherParent());
    }
  }

  if (
    isQuestionBlueprintReorder(blueprintReorder) ||
    isFieldBlueprintReorder(blueprintReorder) ||
    isSelectOptionBlueprintReorder(blueprintReorder)
  ) {
    const { newPreviousSiblingIdentifier, parentIdentifier } = blueprintReorder.reorder;
    const identifier = parentIdentifier || newPreviousSiblingIdentifier;

    if (identifier && identifier.subsectionPartName !== blueprintReorder.partIdentifier.subsectionPartName) {
      return failure(new CantUseReorderToMoveToAnotherParent());
    }
  }

  if (isFieldBlueprintReorder(blueprintReorder) || isSelectOptionBlueprintReorder(blueprintReorder)) {
    const { newPreviousSiblingIdentifier, parentIdentifier } = blueprintReorder.reorder;
    const identifier = parentIdentifier || newPreviousSiblingIdentifier;

    if (identifier && identifier.questionPartName !== blueprintReorder.partIdentifier.questionPartName) {
      return failure(new CantUseReorderToMoveToAnotherParent());
    }
  }

  if (isSelectOptionBlueprintReorder(blueprintReorder)) {
    const { newPreviousSiblingIdentifier, parentIdentifier } = blueprintReorder.reorder;
    const identifier = parentIdentifier || newPreviousSiblingIdentifier;
    if (identifier && identifier.fieldPartName !== blueprintReorder.partIdentifier.fieldPartName) {
      return failure(new CantUseReorderToMoveToAnotherParent());
    }
  }
  return success(undefined);
}

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const marijuanaUse: AnswerPathTreeNode = {
  path: 'marijuanaUse',
  children: [
    { path: 'marijuanaCurrentUse', nodeId: NodeIds.marijuanaCurrentUse },
    { path: 'marijuanaSmokingQuantity', nodeId: NodeIds.marijuanaSmokingQuantity },
    { path: 'marijuanaSmokingFrequency', nodeId: NodeIds.marijuanaSmokingFrequency },
    { path: 'marijuanaVaporizingQuantity', nodeId: NodeIds.marijuanaVaporizingQuantity },
    { path: 'marijuanaVaporizingFrequency', nodeId: NodeIds.marijuanaVaporizingFrequency },
    { path: 'marijuanaCapsuleQuantity', nodeId: NodeIds.marijuanaCapsuleQuantity },
    { path: 'marijuanaCapsuleFrequency', nodeId: NodeIds.marijuanaCapsuleFrequency },
    { path: 'marijuanaEatenQuantity', nodeId: NodeIds.marijuanaEatenQuantity },
    { path: 'marijuanaEatenFrequency', nodeId: NodeIds.marijuanaEatenFrequency },
    { path: 'marijuanaOtherQuantity', nodeId: NodeIds.marijuanaOtherQuantity },
    { path: 'marijuanaOtherFrequency', nodeId: NodeIds.marijuanaOtherFrequency },
    { path: 'marijuanaDisease', nodeId: NodeIds.marijuanaDisease },
    { path: 'marijuanaMedicinalMedProvDate', nodeId: NodeIds.marijuanaMedicinalMedProvDate },
    { path: 'marijuanaDiseaseOther', nodeId: NodeIds.marijuanaDiseaseOther },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const tendonitis: AnswerPathTreeNode = {
  path: 'tendonitis',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredTendonitisIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredTendonitisPreviousEpisodesCount },
    { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredTendonitisDateSymptoms },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredTendonitisPainLocation },
        { path: 'leftRight', nodeId: NodeIds.healthInsuredTendonitisPainLocationLeftRight },
        { path: 'other', nodeId: NodeIds.healthInsuredTendonitisPainLocationOther },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredTendonitisPainCause },
        { path: 'details', nodeId: NodeIds.healthInsuredTendonitisPainCauseDetails },
      ],
    },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredTendonitisHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredTendonitisHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredTendonitisMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredTendonitisHadOperation },
        { path: 'performedOrPlanned', nodeId: NodeIds.healthInsuredTendonitisOperationPerformedOrPlanned },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredTendonitisTypeOfOperationPerformed,
        },
        { path: 'performedDate', nodeId: NodeIds.healthInsuredTendonitisOperationPerformedDate },
        { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthInsuredTendonitisTypeOfOperationPlanned },
        { path: 'plannedDate', nodeId: NodeIds.healthInsuredTendonitisOperationPlannedDate },
        { path: 'unknownDate', nodeId: NodeIds.healthInsuredTendonitisOperationUnknownDate },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredTendonitisHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredTendonitisAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredTendonitisAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredTendonitisAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredTendonitisHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredTendonitisRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredTendonitisAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredTendonitisHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredTendonitisHasConsultedDoctorsProfession },
        { path: 'professionDetails', nodeId: NodeIds.healthInsuredTendonitisHasConsultedDoctorsProfessionDetails },
        { path: 'name', nodeId: NodeIds.healthInsuredTendonitisHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredTendonitisHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredTendonitisHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredTendonitisHasConsultedDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredTendonitisHasConsultedDoctorsResult },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredTendonitisHasConsultedDoctorsFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredTendonitisHasConsultedDoctorsFrequency },
        { path: 'furtherConsultation', nodeId: NodeIds.healthInsuredTendonitisHasConsultedDoctorFurtherConsultation },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredTendonitisHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredTendonitisHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredTendonitisHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredTendonitisHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredTendonitisHasTakenTestsOrExamsResult },
        { path: 'abnormalResult', nodeId: NodeIds.healthInsuredTendonitisHasTakenTestsOrExamsDetailsAbnormalResult },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredTendonitisMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredTendonitisMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredTendonitisMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredTendonitisMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredTendonitisMedicationEndDate },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const carpalTunnel: AnswerPathTreeNode = {
  path: 'carpalTunnel',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredCarpalTunnelIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredCarpalTunnelPreviousEpisodesCount },
    { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredCarpalTunnelDateSymptoms },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredCarpalTunnelPainLocation },
        {
          path: 'leftRight',
          nodeId: NodeIds.healthInsuredCarpalTunnelPainLocationLeftRight,
        },
        {
          path: 'other',
          nodeId: NodeIds.healthInsuredCarpalTunnelPainLocationOther,
        },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredCarpalTunnelPainCause },
        {
          path: 'details',
          nodeId: NodeIds.healthInsuredCarpalTunnelPainCauseDetails,
        },
      ],
    },
    {
      path: 'hasConsultedDoctors',
      nodeId: NodeIds.healthInsuredCarpalTunnelHasConsultedDoctors,
    },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredCarpalTunnelHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredCarpalTunnelMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredCarpalTunnelHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredCarpalTunnelOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredCarpalTunnelTypeOfOperationPerformed,
        },
        {
          path: 'performedDate',
          nodeId: NodeIds.healthInsuredCarpalTunnelOperationPerformedDate,
        },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredCarpalTunnelTypeOfOperationPlanned,
        },
        {
          path: 'plannedDate',
          nodeId: NodeIds.healthInsuredCarpalTunnelOperationPlannedDate,
        },
        {
          path: 'unknownDate',
          nodeId: NodeIds.healthInsuredCarpalTunnelOperationUnknownDate,
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredCarpalTunnelHadAbsence },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredCarpalTunnelAbsenceStartDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredCarpalTunnelAbsenceFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredCarpalTunnelAbsenceFrequency,
        },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredCarpalTunnelAbsenceHasRecovered },
            {
              path: 'recoveryDate',
              nodeId: NodeIds.healthInsuredCarpalTunnelAbsenceRecoveryDate,
            },
            {
              path: 'notRecoveredDetails',
              nodeId: NodeIds.healthInsuredCarpalTunnelAbsenceNotRecoveredDetails,
            },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredCarpalTunnelHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredCarpalTunnelHasConsultedDoctorsProfession },
        { path: 'professionDetails', nodeId: NodeIds.healthInsuredCarpalTunnelHasConsultedDoctorsProfessionDetails },
        { path: 'name', nodeId: NodeIds.healthInsuredCarpalTunnelHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredCarpalTunnelHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredCarpalTunnelHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredCarpalTunnelHasConsultedDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredCarpalTunnelHasConsultedDoctorsResult },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredCarpalTunnelHasConsultedDoctorsFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredCarpalTunnelHasConsultedDoctorsFrequency },
        { path: 'furtherConsultation', nodeId: NodeIds.healthInsuredCarpalTunnelHasConsultedDoctorFurtherConsultation },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredCarpalTunnelHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredCarpalTunnelHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredCarpalTunnelHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredCarpalTunnelHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredCarpalTunnelHasTakenTestsOrExamsResult },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredCarpalTunnelHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredCarpalTunnelMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredCarpalTunnelMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredCarpalTunnelMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredCarpalTunnelMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredCarpalTunnelMedicationEndDate },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const contingentBeneficiaries: AnswerPathTreeNode = {
  path: 'contingentBeneficiaries',
  children: [
    { path: 'exist', nodeId: NodeIds.insuredContingentBeneficiariesExist },
    { path: 'recipients', nodeId: NodeIds.insuredContingentBeneficiariesRecipients },
    { path: 'add', nodeId: NodeIds.insuredAddContingentBeneficiary },
    {
      path: 'beneficiaries',
      nodeId: NodeIds.insuredContingentBeneficiaries,
      isCollection: true,
      children: [
        { path: 'surrogateId', nodeId: NodeIds.insuredContingentBeneficiariesSurrogateId },
        { path: 'share', nodeId: NodeIds.insuredContingentBeneficiariesShare },
        { path: 'firstName', nodeId: NodeIds.insuredContingentBeneficiariesFirstName },
        { path: 'lastName', nodeId: NodeIds.insuredContingentBeneficiariesLastName },
        { path: 'birthDate', nodeId: NodeIds.insuredContingentBeneficiariesBirthDate },
        { path: 'relation', nodeId: NodeIds.insuredContingentBeneficiariesRelation },
        { path: 'relationOther', nodeId: NodeIds.insuredContingentBeneficiariesRelationOther },
        { path: 'revocable', nodeId: NodeIds.insuredContingentBeneficiariesRevocable },
        { path: 'addressAutocomplete', nodeId: NodeIds.insuredContingentBeneficiariesAddressAutocomplete },
        { path: 'address', nodeId: NodeIds.insuredContingentBeneficiariesAddress },
        { path: 'address2', nodeId: 'insured-contingent-beneficiaries-address-2' },
        { path: 'city', nodeId: NodeIds.insuredContingentBeneficiariesCity },
        { path: 'provinceOrState', nodeId: NodeIds.insuredContingentBeneficiariesProvinceOrState },
        { path: 'postalCode', nodeId: NodeIds.insuredContingentBeneficiariesPostalCode },
        { path: 'email', nodeId: NodeIds.insuredContingentBeneficiariesEmail },
        { path: 'phone', nodeId: NodeIds.insuredContingentBeneficiariesPhone },
        { path: 'type', nodeId: NodeIds.insuredContingentBeneficiariesType },
        { path: 'middleName', nodeId: NodeIds.insuredContingentBeneficiariesMiddleName },
        { path: 'gender', nodeId: NodeIds.insuredContingentBeneficiariesGender },
        { path: 'phoneType', nodeId: NodeIds.insuredContingentBeneficiariesPhoneType },
        { path: 'alternatePhone', nodeId: NodeIds.insuredContingentBeneficiariesAlternatePhone },
        { path: 'alternatePhoneType', nodeId: NodeIds.insuredContingentBeneficiariesAlternatePhoneType },
        { path: 'ssn', nodeId: NodeIds.insuredContingentBeneficiariesSSN },
        { path: 'tin', nodeId: NodeIds.insuredContingentBeneficiariesTIN },
        { path: 'trustName', nodeId: NodeIds.insuredContingentBeneficiariesTrustName },
        { path: 'trustDate', nodeId: NodeIds.insuredContingentBeneficiariesTrustDate },
        { path: 'entityName', nodeId: NodeIds.insuredContingentBeneficiariesEntityName },
        { path: 'trustee', nodeId: NodeIds.insuredContingentBeneficiariesTrustee },
        { path: 'officer', nodeId: NodeIds.insuredContingentBeneficiariesOfficer },
      ],
    },
    { path: 'revocableWarning', nodeId: NodeIds.insuredContingentBeneficiariesRevocableWarning },
  ],
};

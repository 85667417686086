import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const cancellingAndReplacement: AnswerPathTreeNode = {
  path: 'cancellingAndReplacement',
  children: [
    { path: 'external', nodeId: NodeIds.externalCancellationReplacement },
    { path: 'internalComplete', nodeId: NodeIds.internalCancellationReplacementComplete },
    { path: 'internalPartial', nodeId: NodeIds.internalCancellationReplacementPartial },
    { path: 'cancelledPolicyNumber', nodeId: NodeIds.cancelledPolicyNumber },
    { path: 'cancelledCoverage', nodeId: NodeIds.cancelledCoverage },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

import { ownerEmployment } from './ownerEmployment';
import { ownerIdentityVerification } from './ownerIdentityVerification';

export const owner: AnswerPathTreeNode = {
  path: 'owner',
  children: [
    { path: 'ownerIs', nodeId: NodeIds.ownerIs },
    { path: 'ownerIsTheInsured', nodeId: NodeIds.ownerIsTheInsured },
    { path: 'ownerIsTheLegalGuardian', nodeId: NodeIds.ownerIsTheLegalGuardian },
    { path: 'ownerType', nodeId: NodeIds.ownerType },
    { path: 'ownerFirstName', nodeId: NodeIds.ownerFirstName },
    { path: 'ownerMiddleName', nodeId: NodeIds.ownerMiddleName },
    { path: 'ownerLastName', nodeId: NodeIds.ownerLastName },
    { path: 'ownerGender', nodeId: NodeIds.ownerGender },
    { path: 'ownerAddressStreet', nodeId: NodeIds.ownerAddressStreet },
    { path: 'ownerAddressApartmentNumber', nodeId: NodeIds.ownerAddressApartmentNumber },
    { path: 'ownerAddressCity', nodeId: NodeIds.ownerAddressCity },
    { path: 'ownerAddressCountry', nodeId: NodeIds.ownerAddressCountry },
    { path: 'ownerAddressProvinceOrState', nodeId: NodeIds.ownerAddressProvinceOrState },
    { path: 'ownerAddressPostalCode', nodeId: NodeIds.ownerAddressPostalCode },
    { path: 'ownerPrimaryPhoneNumber', nodeId: NodeIds.ownerPrimaryPhoneNumber },
    { path: 'ownerPrimaryPhoneNumberType', nodeId: NodeIds.ownerPrimaryPhoneNumberType },
    { path: 'ownerPrimaryPhoneNumberExtension', nodeId: NodeIds.ownerPrimaryPhoneNumberExtension },
    { path: 'ownerAlternatePhoneNumber', nodeId: NodeIds.ownerAlternatePhoneNumber },
    { path: 'ownerAlternatePhoneNumberType', nodeId: NodeIds.ownerAlternatePhoneNumberType },
    { path: 'ownerAlternatePhoneNumberExtension', nodeId: NodeIds.ownerAlternatePhoneNumberExtension },
    { path: 'ownerAlternatePhoneNumberTwo', nodeId: NodeIds.ownerAlternatePhoneNumberTwo },
    { path: 'ownerAlternatePhoneNumberTypeTwo', nodeId: NodeIds.ownerAlternatePhoneNumberTypeTwo },
    { path: 'ownerAlternatePhoneNumberExtensionTwo', nodeId: NodeIds.ownerAlternatePhoneNumberExtensionTwo },
    { path: 'ownerEmail', nodeId: NodeIds.ownerEmail },
    { path: 'ownerSSN', nodeId: NodeIds.ownerSSN },
    { path: 'ownerDateOfBirth', nodeId: NodeIds.ownerDateOfBirth },
    { path: 'ownerMaritalStatus', nodeId: NodeIds.ownerMaritalStatus },
    { path: 'ownerOccupation', nodeId: NodeIds.ownerOccupation },
    { path: 'ownerOccupationOtherIs', nodeId: NodeIds.ownerOccupationOtherIs },
    { path: 'ownerOccupationInCategory', nodeId: NodeIds.ownerOccupationInCategory },
    { path: 'birthCountry', nodeId: NodeIds.ownerBirthCountry },
    { path: 'birthCountryOther', nodeId: NodeIds.ownerBirthCountryOther },
    { path: 'birthProvinceOrState', nodeId: NodeIds.ownerBirthProvinceOrState },
    { path: 'birthCity', nodeId: NodeIds.ownerBirthCity },
    { path: 'signatureCity', nodeId: NodeIds.ownerSignatureCity },
    { path: 'signatureState', nodeId: NodeIds.ownerSignatureState },
    { path: 'signatureDate', nodeId: NodeIds.ownerSignatureDate },
    { path: 'middleNameInitial', nodeId: NodeIds.ownerMiddleNameInitial },
    { path: 'ownerRelationToProposedInsured', nodeId: NodeIds.ownerRelationToProposedInsured },
    { path: 'ownerRelationToProposedInsuredOtherIs', nodeId: NodeIds.ownerRelationToProposedInsuredOtherIs },
    { path: 'ownerOtherRelationToProposedInsured', nodeId: NodeIds.ownerOtherRelationToProposedInsured },
    { path: 'ownerIsUnitedStatesCitizen', nodeId: NodeIds.ownerIsUnitedStatesCitizen },
    { path: 'ownerNumberOfYearsInUnitedStates', nodeId: NodeIds.ownerNumberOfYearsInUnitedStates },
    { path: 'ownerIdVerificationStatus', nodeId: NodeIds.ownerIdVerificationStatus },
    { path: 'ownerHasDriversLicense', nodeId: NodeIds.ownerHasDriversLicense },
    { path: 'ownerOtherIdentificationDocument', nodeId: NodeIds.ownerOtherIdentificationDocument },
    { path: 'ownerIdentificationNumber', nodeId: NodeIds.ownerIdentificationNumber },
    { path: 'ownerIdentificationStateIssued', nodeId: NodeIds.ownerIdentificationStateIssued },
    { path: 'ownerIdentificationIssueDate', nodeId: NodeIds.ownerIdentificationIssueDate },
    { path: 'ownerIdentificationExpiryDate', nodeId: NodeIds.ownerIdentificationExpiryDate },
    { path: 'ownerThirdPartyPhoneType', nodeId: NodeIds.ownerThirdPartyPhoneType },
    { path: 'ownerThirdPartyPhoneNumber', nodeId: NodeIds.ownerThirdPartyPhoneNumber },
    { path: 'ownerThirdPartyPhoneExtension', nodeId: NodeIds.ownerThirdPartyPhoneExtension },
    {
      path: 'ownerIdentificationDocumentIssuingAuthority',
      nodeId: NodeIds.ownerIdentificationDocumentIssuingAuthority,
    },
    { path: 'ownerTrustName', nodeId: NodeIds.ownerTrustName },
    { path: 'ownerTrustDate', nodeId: NodeIds.ownerTrustDate },
    { path: 'ownerEntityName', nodeId: NodeIds.ownerEntityName },
    { path: 'ownerTrustOrEntityDateOfBirth', nodeId: NodeIds.ownerTrustOrEntityDateOfBirth },
    { path: 'ownerTrustOrEntityOccupation', nodeId: NodeIds.ownerTrustOrEntityOccupation },
    { path: 'ownerSin', nodeId: NodeIds.ownerSin },
    { path: 'ownerTIN', nodeId: NodeIds.ownerTIN },
    { path: 'ownerResidentOtherCountry', nodeId: NodeIds.ownerResidentOtherCountry },
    { path: 'ownerOtherCountry', nodeId: NodeIds.ownerOtherCountry },
    { path: 'ownerOtherCountryTin', nodeId: NodeIds.ownerOtherCountryTin },
    { path: 'ownerTrusteeName', nodeId: NodeIds.ownerTrusteeName },
    { path: 'ownerOfficerName', nodeId: NodeIds.ownerOfficerName },
    { path: 'ownerTrustOrEntityAddressStreet', nodeId: NodeIds.ownerTrustOrEntityAddressStreet },
    { path: 'ownerTrustOrEntityAddressApartmentNumber', nodeId: NodeIds.ownerTrustOrEntityAddressApartmentNumber },
    { path: 'ownerTrustOrEntityAddressCity', nodeId: NodeIds.ownerTrustOrEntityAddressCity },
    { path: 'ownerTrustOrEntityAddressCountry', nodeId: NodeIds.ownerTrustOrEntityAddressCountry },
    { path: 'ownerTrustOrEntityAddressProvinceOrState', nodeId: NodeIds.ownerTrustOrEntityAddressProvinceOrState },
    { path: 'ownerTrustOrEntityAddressPostalCode', nodeId: NodeIds.ownerTrustOrEntityAddressPostalCode },
    { path: 'ownerTrustOrEntityPrimaryPhoneNumber', nodeId: NodeIds.ownerTrustOrEntityPrimaryPhoneNumber },
    { path: 'ownerTrustOrEntityPrimaryPhoneType', nodeId: NodeIds.ownerTrustOrEntityPrimaryPhoneType },
    { path: 'ownerTrustOrEntityAlternatePhoneNumber', nodeId: NodeIds.ownerTrustOrEntityAlternatePhoneNumber },
    { path: 'ownerTrustOrEntityAlternatePhoneType', nodeId: NodeIds.ownerTrustOrEntityAlternatePhoneType },
    { path: 'ownerTrustOrEntityEmail', nodeId: NodeIds.ownerTrustOrEntityEmail },
    { path: 'ownerTrustOrEntityRelationToInsured', nodeId: NodeIds.ownerTrustOrEntityRelationToInsured },
    { path: 'ownerOtherTrustOrEntityRelationToInsured', nodeId: NodeIds.ownerOtherTrustOrEntityRelationToInsured },
    { path: 'addSubrogatedOwner', nodeId: NodeIds.addSubrogatedOwner },
    { path: 'subrogatedOwnerType', nodeId: NodeIds.subrogatedOwnerType },
    { path: 'subrogatedOwnerFirstName', nodeId: NodeIds.subrogatedOwnerFirstName },
    { path: 'subrogatedOwnerLastName', nodeId: NodeIds.subrogatedOwnerLastName },
    { path: 'subrogatedOwnerSex', nodeId: NodeIds.subrogatedOwnerSex },
    { path: 'subrogatedOwnerDateOfBirth', nodeId: NodeIds.subrogatedOwnerDateOfBirth },
    { path: 'subrogatedOwnerRelationship', nodeId: NodeIds.subrogatedOwnerRelationship },
    { path: 'subrogatedOwnerOther', nodeId: NodeIds.subrogatedOwnerOther },
    { path: 'ownerWaiverOfPremium', nodeId: NodeIds.ownerWaiverOfPremium },
    { path: 'ownerWaiverOfPremiumTerm', nodeId: NodeIds.ownerWaiverOfPremiumTerm },
    { path: 'ownerWaiverOfPremiumType', nodeId: NodeIds.ownerWaiverOfPremiumType },
    { path: 'ownerThirdPartyLastName', nodeId: NodeIds.ownerThirdPartyLastName },
    { path: 'ownerThirdPartyFirstName', nodeId: NodeIds.ownerThirdPartyFirstName },
    { path: 'ownerThirdPartyDateOfBirth', nodeId: NodeIds.ownerThirdPartyDateOfBirth },
    { path: 'ownerThirdPartyOccupation', nodeId: NodeIds.ownerThirdPartyOccupation },
    { path: 'ownerThirdPartyOccupationOtherIs', nodeId: NodeIds.ownerThirdPartyOccupationOtherIs },
    { path: 'ownerThirdPartyRelationship', nodeId: NodeIds.ownerThirdPartyRelationship },
    { path: 'ownerThirdPartyRelationshipOther', nodeId: NodeIds.ownerThirdPartyRelationshipOther },
    { path: 'ownerThirdPartyAddress', nodeId: NodeIds.ownerThirdPartyAddress },
    { path: 'ownerThirdPartyApartment', nodeId: NodeIds.ownerThirdPartyApartment },
    { path: 'ownerThirdPartyCity', nodeId: NodeIds.ownerThirdPartyCity },
    { path: 'ownerThirdPartyCountry', nodeId: NodeIds.ownerThirdPartyCountry },
    { path: 'ownerThirdPartyProvince', nodeId: NodeIds.ownerThirdPartyProvince },
    { path: 'ownerThirdPartyPostalCode', nodeId: NodeIds.ownerThirdPartyPostalCode },
    { path: 'ownerActsForThirdParty', nodeId: NodeIds.ownerActsForThirdParty },
    { path: 'ownerThirdPartyDetermination', nodeId: NodeIds.ownerThirdPartyDetermination },
    { path: 'ownerPersonalizedOffersAndAdviceConsent', nodeId: NodeIds.ownerPersonalizedOffersAndAdviceConsent },
    ownerEmployment,
    ownerIdentityVerification,
  ],
};

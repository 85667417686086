import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const downSyndrome: AnswerPathTreeNode = {
  path: 'downSyndrome',
  children: [
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredDownSyndromeMedicationWasPrescribed },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredDownSyndromeHasConsultedDoctors },
    {
      path: 'hasOrWillConsultOtherDoctorsOrTherapists',
      nodeId: NodeIds.healthInsuredDownSyndromeHasOrWillConsultOtherDoctorsOrTherapists,
    },
    {
      path: 'performsDailyActivitiesWithoutHelp',
      nodeId: NodeIds.healthInsuredDownSyndromePerformsDailyActivitiesWithoutHelp,
    },
    {
      path: 'performsDailyActivitiesWithoutHelpDetails',
      nodeId: NodeIds.healthInsuredDownSyndromePerformsDailyActivitiesWithoutHelpDetails,
    },
    { path: 'accommodation', nodeId: NodeIds.healthInsuredDownSyndromeNeedsAccommodation },
    { path: 'accommodationDetails', nodeId: NodeIds.healthInsuredDownSyndromeAccommodationDetails },
    {
      path: 'hasOrWillConsultDoctorsDetails',
      nodeId: NodeIds.healthInsuredDownSyndromeHasOrWillConsultDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredDownSyndromeHasOrWillConsultDoctorsProfession },
        {
          path: 'professionOther',
          nodeId: NodeIds.healthInsuredDownSyndromeHasOrWillConsultDoctorsProfessionOther,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredDownSyndromeHasOrWillConsultDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredDownSyndromeHasOrWillConsultDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredDownSyndromeHasOrWillConsultDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredDownSyndromeHasOrWillConsultDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredDownSyndromeHasOrWillConsultDoctorsResult },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredDownSyndromeHasOrWillConsultDoctorsFrequencyNumber,
        },
        { path: 'frequency', nodeId: NodeIds.healthInsuredDownSyndromeHasOrWillConsultDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredDownSyndromeHasOrWillConsultDoctorsFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredDownSyndromeHasOrWillConsultDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredDownSyndromeMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredDownSyndromeMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredDownSyndromeMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredDownSyndromeMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredDownSyndromeMedicationEndDate },
        { path: 'stillUses', nodeId: NodeIds.healthInsuredDownSyndromeMedicationStillUses },
        { path: 'hasChanged', nodeId: NodeIds.healthInsuredDownSyndromeMedicationHasChanged },
        { path: 'hasChangedDetails', nodeId: NodeIds.healthInsuredDownSyndromeMedicationHasChangedDetails },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const secondOwnerEmployment: AnswerPathTreeNode = {
  path: 'employment',
  children: [
    { path: 'status', nodeId: NodeIds.secondOwnerEmploymentStatus },
    { path: 'companyName', nodeId: NodeIds.secondOwnerEmployerCompanyName },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const drug: AnswerPathTreeNode = {
  path: 'drug',
  children: [
    { path: 'counsellingPrescribed', nodeId: NodeIds.drugCounsellingPrescribed },
    { path: 'counsellingBeganDate', nodeId: NodeIds.drugCounsellingBeganDate },
    { path: 'counsellingHealthProfessionName', nodeId: NodeIds.drugCounsellingHealthProfessionName },
    { path: 'counsellingHealthProfessionAddress', nodeId: NodeIds.drugCounsellingHealthProfessionAddress },
    { path: 'counsellingNow', nodeId: NodeIds.drugCounsellingNow },
    { path: 'counsellingEndDate', nodeId: NodeIds.drugCounsellingEndDate },
    { path: 'treatmentPrescribed', nodeId: NodeIds.drugTreatmentPrescribed },
    { path: 'treatmentBeganDate', nodeId: NodeIds.drugTreatmentBeganDate },
    { path: 'treatmentHealthProfessionName', nodeId: NodeIds.drugTreatmentHealthProfessionName },
    { path: 'treatmentHealthProfessionAddress', nodeId: NodeIds.drugTreatmentHealthProfessionAddress },
    { path: 'treatmentNow', nodeId: NodeIds.drugTreatmentNow },
    { path: 'treatmentEndDate', nodeId: NodeIds.drugTreatmentEndDate },
  ],
};

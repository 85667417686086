import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const drugUse: AnswerPathTreeNode = {
  path: 'drugUse',
  children: [
    { path: 'drugUseChoice', nodeId: NodeIds.drugUseChoice },
    { path: 'drugUseOther', nodeId: NodeIds.drugUseOther },
    {
      path: 'drugOpiumDerivatives',
      children: [
        { path: 'drugOpiumDerivativesQuantity', nodeId: NodeIds.drugOpiumDerivativesQuantity },
        { path: 'drugOpiumDerivativesFrequency', nodeId: NodeIds.drugOpiumDerivativesFrequency },
        { path: 'drugOpiumDerivativesFirstUseDate', nodeId: NodeIds.drugOpiumDerivativesFirstUseDate },
        { path: 'drugOpiumDerivativesLastUseDate', nodeId: NodeIds.drugOpiumDerivativesLastUseDate },
      ],
    },
    {
      path: 'drugMarijuana',
      children: [
        { path: 'drugMarijuanaQuantity', nodeId: NodeIds.drugMarijuanaQuantity },
        { path: 'drugMarijuanaFrequency', nodeId: NodeIds.drugMarijuanaFrequency },
        { path: 'drugMarijuanaFirstUseDate', nodeId: NodeIds.drugMarijuanaFirstUseDate },
        { path: 'drugMarijuanaLastUseDate', nodeId: NodeIds.drugMarijuanaLastUseDate },
      ],
    },
    {
      path: 'drugPhenobarbital',
      children: [
        { path: 'drugPhenobarbitalQuantity', nodeId: NodeIds.drugPhenobarbitalQuantity },
        { path: 'drugPhenobarbitalFrequency', nodeId: NodeIds.drugPhenobarbitalFrequency },
        { path: 'drugPhenobarbitalFirstUseDate', nodeId: NodeIds.drugPhenobarbitalFirstUseDate },
        { path: 'drugPhenobarbitalLastUseDate', nodeId: NodeIds.drugPhenobarbitalLastUseDate },
      ],
    },
    {
      path: 'drugHallucinogens',
      children: [
        { path: 'drugHallucinogensQuantity', nodeId: NodeIds.drugHallucinogensQuantity },
        { path: 'drugHallucinogensFrequency', nodeId: NodeIds.drugHallucinogensFrequency },
        { path: 'drugHallucinogensFirstUseDate', nodeId: NodeIds.drugHallucinogensFirstUseDate },
        { path: 'drugHallucinogensLastUseDate', nodeId: NodeIds.drugHallucinogensLastUseDate },
      ],
    },
    {
      path: 'drugHeroine',
      children: [
        { path: 'drugHeroineQuantity', nodeId: NodeIds.drugHeroineQuantity },
        { path: 'drugHeroineFrequency', nodeId: NodeIds.drugHeroineFrequency },
        { path: 'drugHeroineFirstUseDate', nodeId: NodeIds.drugHeroineFirstUseDate },
        { path: 'drugHeroineLastUseDate', nodeId: NodeIds.drugHeroineLastUseDate },
      ],
    },
    {
      path: 'drugHashish',
      children: [
        { path: 'drugHashishQuantity', nodeId: NodeIds.drugHashishQuantity },
        { path: 'drugHashishFrequency', nodeId: NodeIds.drugHashishFrequency },
        { path: 'drugHashishFirstUseDate', nodeId: NodeIds.drugHashishFirstUseDate },
        { path: 'drugHashishLastUseDate', nodeId: NodeIds.drugHashishLastUseDate },
      ],
    },
    {
      path: 'drugLsd',
      children: [
        { path: 'drugLsdQuantity', nodeId: NodeIds.drugLsdQuantity },
        { path: 'drugLsdFrequency', nodeId: NodeIds.drugLsdFrequency },
        { path: 'drugLsdFirstUseDate', nodeId: NodeIds.drugLsdFirstUseDate },
        { path: 'drugLsdLastUseDate', nodeId: NodeIds.drugLsdLastUseDate },
      ],
    },
    {
      path: 'drugPcp',
      children: [
        { path: 'drugPcpQuantity', nodeId: NodeIds.drugPcpQuantity },
        { path: 'drugPcpFrequency', nodeId: NodeIds.drugPcpFrequency },
        { path: 'drugPcpFirstUseDate', nodeId: NodeIds.drugPcpFirstUseDate },
        { path: 'drugPcpLastUseDate', nodeId: NodeIds.drugPcpLastUseDate },
      ],
    },
    {
      path: 'drugMorphine',
      children: [
        { path: 'drugMorphineQuantity', nodeId: NodeIds.drugMorphineQuantity },
        { path: 'drugMorphineFrequency', nodeId: NodeIds.drugMorphineFrequency },
        { path: 'drugMorphineFirstUseDate', nodeId: NodeIds.drugMorphineFirstUseDate },
        { path: 'drugMorphineLastUseDate', nodeId: NodeIds.drugMorphineLastUseDate },
      ],
    },
    {
      path: 'drugAmphetamines',
      children: [
        { path: 'drugAmphetaminesQuantity', nodeId: NodeIds.drugAmphetaminesQuantity },
        { path: 'drugAmphetaminesFrequency', nodeId: NodeIds.drugAmphetaminesFrequency },
        { path: 'drugAmphetaminesFirstUseDate', nodeId: NodeIds.drugAmphetaminesFirstUseDate },
        { path: 'drugAmphetaminesLastUseDate', nodeId: NodeIds.drugAmphetaminesLastUseDate },
      ],
    },
    {
      path: 'drugHydrocodone',
      children: [
        { path: 'drugHydrocodoneQuantity', nodeId: NodeIds.drugHydrocodoneQuantity },
        { path: 'drugHydrocodoneFrequency', nodeId: NodeIds.drugHydrocodoneFrequency },
        { path: 'drugHydrocodoneFirstUseDate', nodeId: NodeIds.drugHydrocodoneFirstUseDate },
        { path: 'drugHydrocodoneLastUseDate', nodeId: NodeIds.drugHydrocodoneLastUseDate },
      ],
    },
    {
      path: 'drugCrystalMeth',
      children: [
        { path: 'drugCrystalMethQuantity', nodeId: NodeIds.drugCrystalMethQuantity },
        { path: 'drugCrystalMethFrequency', nodeId: NodeIds.drugCrystalMethFrequency },
        { path: 'drugCrystalMethFirstUseDate', nodeId: NodeIds.drugCrystalMethFirstUseDate },
        { path: 'drugCrystalMethLastUseDate', nodeId: NodeIds.drugCrystalMethLastUseDate },
      ],
    },
    {
      path: 'drugPercodan',
      children: [
        { path: 'drugPercodanQuantity', nodeId: NodeIds.drugPercodanQuantity },
        { path: 'drugPercodanFrequency', nodeId: NodeIds.drugPercodanFrequency },
        { path: 'drugPercodanFirstUseDate', nodeId: NodeIds.drugPercodanFirstUseDate },
        { path: 'drugPercodanLastUseDate', nodeId: NodeIds.drugPercodanLastUseDate },
      ],
    },
    {
      path: 'drugCocaine',
      children: [
        { path: 'drugCocaineQuantity', nodeId: NodeIds.drugCocaineQuantity },
        { path: 'drugCocaineFrequency', nodeId: NodeIds.drugCocaineFrequency },
        { path: 'drugCocaineFirstUseDate', nodeId: NodeIds.drugCocaineFirstUseDate },
        { path: 'drugCocaineLastUseDate', nodeId: NodeIds.drugCocaineLastUseDate },
      ],
    },
    {
      path: 'drugCodeine',
      children: [
        { path: 'drugCodeineQuantity', nodeId: NodeIds.drugCodeineQuantity },
        { path: 'drugCodeineFrequency', nodeId: NodeIds.drugCodeineFrequency },
        { path: 'drugCodeineFirstUseDate', nodeId: NodeIds.drugCodeineFirstUseDate },
        { path: 'drugCodeineLastUseDate', nodeId: NodeIds.drugCodeineLastUseDate },
      ],
    },
    {
      path: 'drugSpeed',
      children: [
        { path: 'drugSpeedQuantity', nodeId: NodeIds.drugSpeedQuantity },
        { path: 'drugSpeedFrequency', nodeId: NodeIds.drugSpeedFrequency },
        { path: 'drugSpeedFirstUseDate', nodeId: NodeIds.drugSpeedFirstUseDate },
        { path: 'drugSpeedLastUseDate', nodeId: NodeIds.drugSpeedLastUseDate },
      ],
    },
    {
      path: 'drugDemerol',
      children: [
        { path: 'drugDemerolQuantity', nodeId: NodeIds.drugDemerolQuantity },
        { path: 'drugDemerolFrequency', nodeId: NodeIds.drugDemerolFrequency },
        { path: 'drugDemerolFirstUseDate', nodeId: NodeIds.drugDemerolFirstUseDate },
        { path: 'drugDemerolLastUseDate', nodeId: NodeIds.drugDemerolLastUseDate },
      ],
    },
    {
      path: 'drugCrack',
      children: [
        { path: 'drugCrackQuantity', nodeId: NodeIds.drugCrackQuantity },
        { path: 'drugCrackFrequency', nodeId: NodeIds.drugCrackFrequency },
        { path: 'drugCrackFirstUseDate', nodeId: NodeIds.drugCrackFirstUseDate },
        { path: 'drugCrackLastUseDate', nodeId: NodeIds.drugCrackLastUseDate },
      ],
    },
    {
      path: 'drugOxycodone',
      children: [
        { path: 'drugOxycodoneQuantity', nodeId: NodeIds.drugOxycodoneQuantity },
        { path: 'drugOxycodoneFrequency', nodeId: NodeIds.drugOxycodoneFrequency },
        { path: 'drugOxycodoneFirstUseDate', nodeId: NodeIds.drugOxycodoneFirstUseDate },
        { path: 'drugOxycodoneLastUseDate', nodeId: NodeIds.drugOxycodoneLastUseDate },
      ],
    },
    {
      path: 'drugLibrium',
      children: [
        { path: 'drugLibriumQuantity', nodeId: NodeIds.drugLibriumQuantity },
        { path: 'drugLibriumFrequency', nodeId: NodeIds.drugLibriumFrequency },
        { path: 'drugLibriumFirstUseDate', nodeId: NodeIds.drugLibriumFirstUseDate },
        { path: 'drugLibriumLastUseDate', nodeId: NodeIds.drugLibriumLastUseDate },
      ],
    },
    {
      path: 'drugMethadone',
      children: [
        { path: 'drugMethadoneQuantity', nodeId: NodeIds.drugMethadoneQuantity },
        { path: 'drugMethadoneFrequency', nodeId: NodeIds.drugMethadoneFrequency },
        { path: 'drugMethadoneFirstUseDate', nodeId: NodeIds.drugMethadoneFirstUseDate },
        { path: 'drugMethadoneLastUseDate', nodeId: NodeIds.drugMethadoneLastUseDate },
      ],
    },
    {
      path: 'drugBarbiturates',
      children: [
        { path: 'drugBarbituratesQuantity', nodeId: NodeIds.drugBarbituratesQuantity },
        { path: 'drugBarbituratesFrequency', nodeId: NodeIds.drugBarbituratesFrequency },
        { path: 'drugBarbituratesFirstUseDate', nodeId: NodeIds.drugBarbituratesFirstUseDate },
        { path: 'drugBarbituratesLastUseDate', nodeId: NodeIds.drugBarbituratesLastUseDate },
      ],
    },
    {
      path: 'drugVicodin',
      children: [
        { path: 'drugVicodinQuantity', nodeId: NodeIds.drugVicodinQuantity },
        { path: 'drugVicodinFrequency', nodeId: NodeIds.drugVicodinFrequency },
        { path: 'drugVicodinFirstUseDate', nodeId: NodeIds.drugVicodinFirstUseDate },
        { path: 'drugVicodinLastUseDate', nodeId: NodeIds.drugVicodinLastUseDate },
      ],
    },
    {
      path: 'drugAlcohol',
      children: [
        { path: 'drugAlcoholQuantity', nodeId: NodeIds.drugAlcoholQuantity },
        { path: 'drugAlcoholFrequency', nodeId: NodeIds.drugAlcoholFrequency },
        { path: 'drugAlcoholFirstUseDate', nodeId: NodeIds.drugAlcoholFirstUseDate },
        { path: 'drugAlcoholLastUseDate', nodeId: NodeIds.drugAlcoholLastUseDate },
      ],
    },
    {
      path: 'drugOtherDrugs',
      children: [
        { path: 'drugOtherDrugsQuantity', nodeId: NodeIds.drugOtherDrugsQuantity },
        { path: 'drugOtherDrugsFrequency', nodeId: NodeIds.drugOtherDrugsFrequency },
        { path: 'drugOtherDrugsFirstUseDate', nodeId: NodeIds.drugOtherDrugsFirstUseDate },
        { path: 'drugOtherDrugsLastUseDate', nodeId: NodeIds.drugOtherDrugsLastUseDate },
        {
          path: 'changeHabitConsumptionDate',
          nodeId: NodeIds.lifestyleInsuredDrugsOtherPastChangeHabitConsumptionDate,
        },
        {
          path: 'changeHabitConsumptionReason',
          nodeId: NodeIds.lifestyleInsuredDrugsOtherPastChangeHabitConsumptionReason,
        },
        {
          path: 'hasBeenAdvisedReduceOrCeaseConsumption',
          nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsHasBeenAdvisedReduceOrCeaseConsumption,
        },
        { path: 'hadTherapy', nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsHadTherapy },
        { path: 'supportGroupMeetings', nodeId: NodeIds.lifestyleInsuredDrugsOrNarcoticsSupportGroupMeetings },
      ],
    },
    {
      path: 'drugMed',
      children: [
        { path: 'drugMedProv', nodeId: NodeIds.drugMedProv },
        { path: 'drugMedProvDetails', nodeId: NodeIds.drugMedProvDetails },
      ],
    },
    { path: 'drugDui', nodeId: NodeIds.drugDui },
    { path: 'drugMissedWork', nodeId: NodeIds.drugMissedWork },
    { path: 'drugGuilty', nodeId: NodeIds.drugGuilty },
    { path: 'drugFamily', nodeId: NodeIds.drugFamily },
    { path: 'drugTreatment', nodeId: NodeIds.drugTreatment },
    {
      path: 'drugSupportGroupDetails',
      children: [
        { path: 'drugSupportGroup', nodeId: NodeIds.drugSupportGroup },
        { path: 'drugSupportGroupName', nodeId: NodeIds.drugSupportGroupName },
        { path: 'drugSupportGroupDateFirstAttended', nodeId: NodeIds.drugSupportGroupDateFirstAttended },
        { path: 'drugSupportGroupDateLastAttended', nodeId: NodeIds.drugSupportGroupDateLastAttended },
        { path: 'drugSupportGroupFrequency', nodeId: NodeIds.drugSupportGroupFrequency },
      ],
    },
    { path: 'drugUseInsuredPastWeight', nodeId: NodeIds.drugUseInsuredPastWeight },
  ],
};

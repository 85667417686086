import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const identification: AnswerPathTreeNode = {
  path: 'identification',
  children: [
    { path: 'hasDriversLicence', nodeId: NodeIds.insuredHasDriversLicence },
    { path: 'type', nodeId: NodeIds.insuredIdentificationType },
    { path: 'driverLicenseNumber', nodeId: NodeIds.insuredIdentificationDriverLicenseNumber },
    { path: 'driverLicenseIssuingAuthority', nodeId: NodeIds.insuredIdentificationDriverLicenseIssuingAuthority },
    { path: 'driverLicenseIssuedOnDate', nodeId: NodeIds.insuredIdentificationDriverLicenseIssuedOnDate },
    { path: 'driverLicenseExpiredOnDate', nodeId: NodeIds.insuredIdentificationDriverLicenseExpiredOnDate },
    { path: 'governmentIssuedPictureIdNumber', nodeId: NodeIds.insuredIdentificationGovernmentIssuedPictureIdNumber },
    {
      path: 'governmentIssuedPictureIdIssuingAuthority',
      nodeId: NodeIds.insuredIdentificationGovernmentIssuedPictureIdIssuingAuthority,
    },
    {
      path: 'governmentIssuedPictureIdIssuedOnDate',
      nodeId: NodeIds.insuredIdentificationGovernmentIssuedPictureIdIssuedOnDate,
    },
    {
      path: 'governmentIssuedPictureIdExpiredOnDate',
      nodeId: NodeIds.insuredIdentificationGovernmentIssuedPictureIdExpiredOnDate,
    },
  ],
};

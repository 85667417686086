import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const osteoarthritis: AnswerPathTreeNode = {
  path: 'osteoarthritis',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredOsteoarthritisIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredOsteoarthritisPreviousEpisodesCount },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredOsteoarthritisPainLocation },
        { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredOsteoarthritisDateSymptoms },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredOsteoarthritisPainCause },
        { path: 'details', nodeId: NodeIds.healthInsuredOsteoarthritisPainCauseDetails },
      ],
    },
    {
      path: 'hasConsultedDoctors',
      nodeId: NodeIds.healthInsuredOsteoarthritisHasConsultedDoctors,
    },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredOsteoarthritisHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredOsteoarthritisMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredOsteoarthritisHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredOsteoarthritisOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredOsteoarthritisTypeOfOperationPerformed,
        },
        {
          path: 'performedDate',
          nodeId: NodeIds.healthInsuredOsteoarthritisOperationPerformedDate,
        },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredOsteoarthritisTypeOfOperationPlanned,
        },
        { path: 'plannedDate', nodeId: NodeIds.healthInsuredOsteoarthritisOperationPlannedDate },
        { path: 'unknownDate', nodeId: NodeIds.healthInsuredOsteoarthritisOperationUnknownDate },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredOsteoarthritisHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredOsteoarthritisAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredOsteoarthritisAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredOsteoarthritisAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredOsteoarthritisAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredOsteoarthritisAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredOsteoarthritisAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredOsteoarthritisHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredOsteoarthritisHasConsultedDoctorsProfession,
        },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredOsteoarthritisHasConsultedDoctorsProfessionDetails,
        },
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredOsteoarthritisHasConsultedDoctorsName,
        },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredOsteoarthritisHasConsultedDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredOsteoarthritisHasConsultedDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredOsteoarthritisHasConsultedDoctorsLastDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredOsteoarthritisHasConsultedDoctorsResult,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredOsteoarthritisHasConsultedDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredOsteoarthritisHasConsultedDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredOsteoarthritisHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredOsteoarthritisHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredOsteoarthritisHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        {
          path: 'type',
          nodeId: NodeIds.healthInsuredOsteoarthritisHasTakenTestsOrExamsType,
        },
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredOsteoarthritisHasTakenTestsOrExamsDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredOsteoarthritisHasTakenTestsOrExamsResult,
        },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredOsteoarthritisHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredOsteoarthritisMedicationDetails,
      isCollection: true,
      children: [
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredOsteoarthritisMedicationName,
        },
        {
          path: 'dosage',
          nodeId: NodeIds.healthInsuredOsteoarthritisMedicationDosage,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredOsteoarthritisMedicationStartDate,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredOsteoarthritisMedicationEndDate,
        },
      ],
    },
  ],
};

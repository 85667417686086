import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const sprain: AnswerPathTreeNode = {
  path: 'sprain',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredSprainIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredSprainPreviousEpisodesCount },

    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredSprainHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredSprainHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredSprainMedicationWasPrescribed },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredSprainPainLocation },
        { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredSprainDateSymptoms },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredSprainPainCause },
        { path: 'details', nodeId: NodeIds.healthInsuredSprainPainCauseDetails },
      ],
    },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredSprainHadOperation },
        { path: 'performedOrPlanned', nodeId: NodeIds.healthInsuredSprainOperationPerformedOrPlanned },
        { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthInsuredSprainTypeOfOperationPerformed },
        { path: 'performedDate', nodeId: NodeIds.healthInsuredSprainOperationPerformedDate },
        { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthInsuredSprainTypeOfOperationPlanned },
        { path: 'plannedDate', nodeId: NodeIds.healthInsuredSprainOperationPlannedDate },
        { path: 'unknownDate', nodeId: NodeIds.healthInsuredSprainOperationUnknownDate },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredSprainHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredSprainAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredSprainAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredSprainAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredSprainAbsenceRecoveryDate },
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredSprainAbsenceHasRecovered },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredSprainAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredSprainHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredSprainHasConsultedDoctorsProfession },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredSprainHasConsultedDoctorsProfessionDetails,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredSprainHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredSprainHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredSprainHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredSprainHasConsultedDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredSprainHasConsultedDoctorsResult },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredSprainHasConsultedDoctorsFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredSprainHasConsultedDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredSprainHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredSprainHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredSprainHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredSprainHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredSprainHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredSprainHasTakenTestsOrExamsResult },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredSprainHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredSprainMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredSprainMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredSprainMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredSprainMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredSprainMedicationEndDate },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';
import { abnormalBloodPressure } from './abnormalBloodPressure';
import { aneurysm } from './aneurysm';
import { angina } from './angina';
import { anyOtherHeartDisorder } from './anyOtherHeartDisorder';
import { heartAttack } from './heartAttack';
import { highBloodPressure } from './highBloodPressure';
import { highCholesterol } from './highCholesterol';
import { palpitation } from './palpitation';

export const cardiovascular: AnswerPathTreeNode = {
  path: 'cardiovascular',
  children: [
    { path: 'hasCondition', nodeId: NodeIds.healthInsuredCardiovascularSystemHasCondition },
    { path: 'choices', nodeId: NodeIds.healthInsuredCardiovascularSystemChoices },
    aneurysm,
    angina,
    palpitation,
    abnormalBloodPressure,
    highCholesterol,
    anyOtherHeartDisorder,
    heartAttack,
    highBloodPressure,
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const medicalConsultations: AnswerPathTreeNode = {
  path: 'medicalConsultations',
  children: [
    {
      path: 'medicalTestsLast5Years',
      children: [
        {
          path: 'hadOtherMedicalTestsLast5YearsTests',
          nodeId: NodeIds.healthInsuredHadOtherMedicalTestsLast5YearsTests,
        },
        {
          path: 'hadOtherMedicalTestsLast5YearsOtherTestsDetails',
          nodeId: NodeIds.healthInsuredHadOtherMedicalTestsLast5YearsOtherTestsDetails,
        },
      ],
    },
    { path: 'hasConsultedOtherProfessionals', nodeId: NodeIds.healthInsuredHasConsultedOtherProfessionals },
    {
      path: 'hasConsultedOtherProfessionalsDetails',
      nodeId: NodeIds.healthInsuredLast5YearsHasConsultedOtherProfessionalsDetails,
    },
    { path: 'moreMedicalActionPending', nodeId: NodeIds.healthInsuredMoreMedicalActionPending },
    { path: 'moreMedicalActionChoices', nodeId: NodeIds.healthInsuredMoreMedicalActionChoices },
    { path: 'moreMedicalActionSurgeryDetails', nodeId: NodeIds.healthInsuredMoreMedicalActionSurgeryDetails },
    { path: 'moreMedicalActionTreatmentDetails', nodeId: NodeIds.healthInsuredMoreMedicalActionTreatmentDetails },
    {
      path: 'moreMedicalActionAwaitingResultsDetails',
      nodeId: NodeIds.healthInsuredMoreMedicalActionAwaitingResultsDetails,
    },
    { path: 'diagnosticExamsOrTestsDetails', nodeId: NodeIds.healthInsuredDiagnosticExamsOrTestsDetails },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const product: AnswerPathTreeNode = {
  path: 'product',
  children: [
    { path: 'id', nodeId: NodeIds.productId },
    { path: 'coverageAmount', nodeId: NodeIds.coverageAmount },
    { path: 'addons', nodeId: NodeIds.productAddons },
    { path: 'addonDetails', nodeId: NodeIds.productAddonDetails },
    { path: 'term', nodeId: NodeIds.productTerm },
    { path: 'totalSumInsured', nodeId: NodeIds.totalSumInsured },
    { path: 'productPremium', nodeId: NodeIds.productPremium },
    { path: 'productType', nodeId: NodeIds.productType },
    { path: 'riskClass', nodeId: NodeIds.productRiskClass },
    { path: 'dividends', nodeId: NodeIds.productDividends },
    { path: 'tableRating', nodeId: NodeIds.productTableRating },
    { path: 'flatRatingAmount', nodeId: NodeIds.productFlatRatingAmount },
    { path: 'flatRatingEnd', nodeId: NodeIds.productFlatRatingEnd },
    {
      path: 'riders',
      children: [
        { path: 'ids', nodeId: NodeIds.riderIds },
        {
          path: 'accidentalDeathBenefit',
          children: [{ path: 'annualPremium', nodeId: NodeIds.accidentalDeathBenefitAnnualPremium }],
        },
        {
          path: 'guaranteedInsurability',
          children: [
            { path: 'faceAmount', nodeId: NodeIds.guaranteedInsurabilityFaceAmount },
            { path: 'annualPremium', nodeId: NodeIds.guaranteedInsurabilityAnnualPremium },
          ],
        },
        { path: 'waiverPremium', children: [{ path: 'annualPremium', nodeId: NodeIds.waiverOfPremiumAnnualPremium }] },
        {
          path: '10yearLevel',
          children: [
            { path: 'faceAmount', nodeId: 'rider-10-year-level-face-amount' },
            { path: 'annualPremium', nodeId: 'rider-10-year-level-annual-premium' },
            { path: 'riskClass', nodeId: 'rider-10-year-level-risk-class' },
            { path: 'tableRating', nodeId: 'rider-10-year-level-table-rating' },
            { path: 'flatRatingAmount', nodeId: 'rider-10-year-level-flat-rating-amount' },
            { path: 'flatRatingEnd', nodeId: 'rider-10-year-level-flat-rating-end' },
          ],
        },
        {
          path: '20yearLevel',
          children: [
            { path: 'faceAmount', nodeId: 'rider-20-year-level-face-amount' },
            { path: 'annualPremium', nodeId: 'rider-20-year-level-annual-premium' },
            { path: 'riskClass', nodeId: 'rider-20-year-level-risk-class' },
            { path: 'tableRating', nodeId: 'rider-20-year-level-table-rating' },
            { path: 'flatRatingAmount', nodeId: 'rider-20-year-level-flat-rating-amount' },
            { path: 'flatRatingEnd', nodeId: 'rider-20-year-level-flat-rating-end' },
          ],
        },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const aviationSection: AnswerPathTreeNode = {
  path: 'aviationSection',
  children: [
    { path: 'typeOfFlyingDone', nodeId: NodeIds.typeOfFlyingDone },
    { path: 'civilianAviation', nodeId: NodeIds.civilianAviation },
    { path: 'civilianAviationOther', nodeId: NodeIds.civilianAviationOther },
    { path: 'militaryAviation', nodeId: NodeIds.militaryAviation },
    { path: 'militaryAviationOther', nodeId: NodeIds.militaryAviationOther },
    { path: 'aviationDisqualification', nodeId: NodeIds.aviationDisqualification },
    { path: 'aviationDisqualificationDetails', nodeId: NodeIds.aviationDisqualificationDetails },
    { path: 'typeOfAircraft', nodeId: NodeIds.typeOfAircraft },
    { path: 'hoursFlownLast12Months', nodeId: 'hours-flown-last-12-months' },
    { path: 'hoursWillFlyNext12Months', nodeId: 'hours-will-fly-next-12-months' },
    { path: 'internationalAviation', nodeId: NodeIds.internationalAviation },
    { path: 'internationalAviationDetails', nodeId: NodeIds.internationalAviationDetails },
  ],
};

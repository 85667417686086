import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const thyroid: AnswerPathTreeNode = {
  path: 'thyroid',
  children: [
    { path: 'synthroid', nodeId: NodeIds.healthInsuredThyroidDisorderSynthroid },
    { path: 'recoveredDetails', nodeId: NodeIds.healthInsuredThyroidDisorderRecoveredDetails },
    { path: 'isHypothyroidism', nodeId: NodeIds.healthInsuredThyroidDisorderIsHypothyroidism },
    { path: 'diagnosisDate', nodeId: NodeIds.healthInsuredThyroidDisorderDiagnosisDate },
    { path: 'hadSurgery', nodeId: NodeIds.healthInsuredThyroidDisorderHadSurgery },
    { path: 'medication', nodeId: NodeIds.healthInsuredThyroidDisorderMedication },
    { path: 'isControlled', nodeId: NodeIds.healthInsuredThyroidDisorderIsControlled },
  ],
};

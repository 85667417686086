import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const trust: AnswerPathTreeNode = {
  path: 'trust',
  children: [
    { path: 'exist', nodeId: NodeIds.trusteeExists },
    {
      path: 'trustees',
      nodeId: NodeIds.trustees,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.trusteeType },
        { path: 'firstName', nodeId: NodeIds.trusteeFirstName },
        { path: 'lastName', nodeId: NodeIds.trusteeLastName },
        { path: 'address', nodeId: NodeIds.trusteeAddress },
        { path: 'city', nodeId: NodeIds.trusteeCity },
        { path: 'province', nodeId: NodeIds.trusteeProvince },
        { path: 'postalCode', nodeId: NodeIds.trusteePostalCode },
        { path: 'email', nodeId: NodeIds.trusteeEmail },
        { path: 'phoneNumber', nodeId: NodeIds.trusteePhoneNumber },
        { path: 'relationToInsured', nodeId: NodeIds.trusteeRelationToInsured },
        { path: 'relationToInsuredOther', nodeId: NodeIds.trusteeRelationToInsuredOther },
        { path: 'dateOfBirth', nodeId: NodeIds.trusteeDateOfBirth },
        { path: 'middleName', nodeId: NodeIds.trusteeMiddleName },
        { path: 'gender', nodeId: NodeIds.trusteeGender },
        { path: 'phoneType', nodeId: NodeIds.trusteePhoneType },
        { path: 'alternatePhone', nodeId: NodeIds.trusteeAlternatePhone },
        { path: 'alternatePhoneType', nodeId: NodeIds.trusteeAlternatePhoneType },
        { path: 'ssn', nodeId: NodeIds.trusteeSSN },
        { path: 'tin', nodeId: NodeIds.trusteeTIN },
        { path: 'benefitsPayableOption', nodeId: NodeIds.trusteeBenefitsPayableOption },
        { path: 'benefitsPayableAtAge', nodeId: NodeIds.trusteeBenefitsPayableAtAge },
      ],
    },
  ],
};

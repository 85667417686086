import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const identityVerification: AnswerPathTreeNode = {
  path: 'identificationVerification',
  children: [
    {
      path: 'insuredIdentityVerification',
      nodeId: NodeIds.insuredIdentityVerification,
    },
    {
      path: 'insuredIdentityVerificationDocumentType',
      nodeId: NodeIds.insuredIdentityVerificationDocumentType,
    },
    {
      path: 'insuredIdentityVerificationDocumentTypeOther',
      nodeId: NodeIds.insuredIdentityVerificationDocumentTypeOther,
    },
    {
      path: 'insuredIdentityVerificationDocumentLastName',
      nodeId: NodeIds.insuredIdentityVerificationDocumentLastName,
    },
    {
      path: 'insuredIdentityVerificationDocumentFirstName',
      nodeId: NodeIds.insuredIdentityVerificationDocumentFirstName,
    },
    {
      path: 'insuredIdentityVerificationDocumentNumber',
      nodeId: NodeIds.insuredIdentityVerificationDocumentNumber,
    },
    {
      path: 'insuredIdentityVerificationDocumentJurisdiction',
      nodeId: NodeIds.insuredIdentityVerificationDocumentJurisdiction,
    },
    {
      path: 'insuredIdentityVerificationDocumentExpiryDate',
      nodeId: NodeIds.insuredIdentityVerificationDocumentExpiryDate,
    },
    {
      path: 'insuredIdentityVerificationLastName',
      nodeId: NodeIds.insuredIdentityVerificationLastName,
    },
    {
      path: 'insuredIdentityVerificationFirstName',
      nodeId: NodeIds.insuredIdentityVerificationFirstName,
    },
    {
      path: 'insuredIdentityVerificationDateOfBirth',
      nodeId: NodeIds.insuredIdentityVerificationDateOfBirth,
    },
    {
      path: 'insuredIdentityVerificationAddress',
      nodeId: NodeIds.insuredIdentityVerificationAddress,
    },
    {
      path: 'insuredIdentityVerificationApartment',
      nodeId: NodeIds.insuredIdentityVerificationApartment,
    },
    {
      path: 'insuredIdentityVerificationCity',
      nodeId: NodeIds.insuredIdentityVerificationCity,
    },
    {
      path: 'insuredIdentityVerificationProvince',
      nodeId: NodeIds.insuredIdentityVerificationProvince,
    },
    {
      path: 'insuredIdentityVerificationPostalCode',
      nodeId: NodeIds.insuredIdentityVerificationPostalCode,
    },
    {
      path: 'insuredIdentityVerificationDualProcessReason',
      nodeId: NodeIds.insuredIdentityVerificationDualProcessReason,
    },
    {
      path: 'insuredIdentityVerificationDualProcessReasonOther',
      nodeId: NodeIds.insuredIdentityVerificationDualProcessReasonOther,
    },
    {
      path: 'insuredIdentityVerificationDualProcessNameAddressType',
      nodeId: NodeIds.insuredIdentityVerificationDualProcessNameAddressType,
    },
    {
      path: 'insuredIdentityVerificationDualProcessNameAddressIssuingAuthority',
      nodeId: NodeIds.insuredIdentityVerificationDualProcessNameAddressIssuingAuthority,
    },
    {
      path: 'insuredIdentityVerificationDualProcessNameAddressIssuingJurisdiction',
      nodeId: NodeIds.insuredIdentityVerificationDualProcessNameAddressIssuingJurisdiction,
    },
    {
      path: 'insuredIdentityVerificationDualProcessNameAddressReferenceNumber',
      nodeId: NodeIds.insuredIdentityVerificationDualProcessNameAddressReferenceNumber,
    },
    {
      path: 'insuredIdentityVerificationDualProcessNameAddressDate',
      nodeId: NodeIds.insuredIdentityVerificationDualProcessNameAddressDate,
    },
    {
      path: 'insuredIdentityVerificationDualProcessNameBirthDateType',
      nodeId: NodeIds.insuredIdentityVerificationDualProcessNameBirthDateType,
    },
    {
      path: 'insuredIdentityVerificationDualProcessNameBirthDateIssuingAuthority',
      nodeId: NodeIds.insuredIdentityVerificationDualProcessNameBirthDateIssuingAuthority,
    },
    {
      path: 'insuredIdentityVerificationDualProcessNameBirthDateIssuingJurisdiction',
      nodeId: NodeIds.insuredIdentityVerificationDualProcessNameBirthDateIssuingJurisdiction,
    },
    {
      path: 'insuredIdentityVerificationDualProcessNameBirthDateReferenceNumber',
      nodeId: NodeIds.insuredIdentityVerificationDualProcessNameBirthDateReferenceNumber,
    },
    {
      path: 'insuredIdentityVerificationDualProcessNameBirthDateDate',
      nodeId: NodeIds.insuredIdentityVerificationDualProcessNameBirthDateDate,
    },
  ],
};

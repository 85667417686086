import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';
import { anyOtherDisorderOfTheDigestiveSystem } from './anyOtherDisorderOfTheDigestiveSystem';
import { bleeding } from './bleeding';
import { celiac } from './celiac';
import { cirrhosis } from './cirrhosis';
import { crohns } from './crohns';
import { gallstones } from './gallstones';
import { gerd } from './gerd';
import { hepatitis } from './hepatitis';
import { pancreatitis } from './pancreatitis';
import { stomachPolyps } from './stomachPolyps';
import { ulcerativeColitis } from './ulcerativeColitis';
import { ulcers } from './ulcers';

export const digestiveSystem: AnswerPathTreeNode = {
  path: 'digestiveSystem',
  children: [
    { path: 'hasCondition', nodeId: NodeIds.healthInsuredDigestiveSystemHasCondition },
    { path: 'choices', nodeId: NodeIds.healthInsuredDigestiveSystemChoices },
    bleeding,
    cirrhosis,
    anyOtherDisorderOfTheDigestiveSystem,
    gallstones,
    gerd,
    celiac,
    crohns,
    hepatitis,
    pancreatitis,
    stomachPolyps,
    ulcerativeColitis,
    ulcers,
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const alcoholUse: AnswerPathTreeNode = {
  path: 'alcoholUse',
  children: [
    { path: 'alcoholHabit', nodeId: NodeIds.alcoholHabit },
    { path: 'receivedMedicalTreatmentForAlcohol', nodeId: NodeIds.receivedMedicalTreatmentForAlcohol },
    { path: 'receivedMedicalTreatmentForAlcoholDetails', nodeId: NodeIds.receivedMedicalTreatmentForAlcoholDetails },
    { path: 'lostJobDueToAlcohol', nodeId: NodeIds.lostJobDueToAlcohol },
    { path: 'lostJobDueToAlcoholDetails', nodeId: NodeIds.lostJobDueToAlcoholDetails },
    { path: 'drivingUnderInfluenceDetails', nodeId: NodeIds.drivingUnderInfluenceDetails },
    { path: 'alcoholUseAdditionalInformation', nodeId: NodeIds.alcoholUseAdditionalInformation },
    {
      path: 'beerConsumption',
      children: [
        { path: 'drinkBeer', nodeId: NodeIds.drinkBeer },
        { path: 'quantity', nodeId: NodeIds.beerQuantity },
        { path: 'date', nodeId: NodeIds.beerDate },
        { path: 'drinkPast', nodeId: NodeIds.beerDrinkPast },
        { path: 'pastQuantity', nodeId: NodeIds.beerPastQuantity },
        { path: 'pastDate', nodeId: NodeIds.beerPastDate },
        { path: 'reducedReason', nodeId: NodeIds.beerReducedReason },
      ],
    },
    {
      path: 'wineConsumption',
      children: [
        { path: 'drinkWine', nodeId: NodeIds.drinkWine },
        { path: 'quantity', nodeId: NodeIds.wineQuantity },
        { path: 'date', nodeId: NodeIds.wineDate },
        { path: 'drinkPast', nodeId: NodeIds.wineDrinkPast },
        { path: 'pastQuantity', nodeId: NodeIds.winePastQuantity },
        { path: 'pastDate', nodeId: NodeIds.winePastDate },
        { path: 'reducedReason', nodeId: NodeIds.wineReducedReason },
      ],
    },
    {
      path: 'liquorConsumption',
      children: [
        { path: 'drinkLiquor', nodeId: NodeIds.drinkLiquor },
        { path: 'quantity', nodeId: NodeIds.liquorQuantity },
        { path: 'date', nodeId: NodeIds.liquorDate },
        { path: 'drinkPast', nodeId: NodeIds.liquorDrinkPast },
        { path: 'pastQuantity', nodeId: NodeIds.liquorPastQuantity },
        { path: 'pastDate', nodeId: NodeIds.liquorPastDate },
        { path: 'reducedReason', nodeId: NodeIds.liquorReducedReason },
      ],
    },
    {
      path: 'alcoholicsAnonymous',
      children: [
        { path: 'member', nodeId: NodeIds.memberOfAlcoholicsAnonymous },
        { path: 'meetingDateFirstAttended', nodeId: NodeIds.alcoholicsAnonymousMeetingDateFirstAttended },
        { path: 'meetingDateLastAttended', nodeId: NodeIds.alcoholicsAnonymousMeetingDateLastAttended },
        { path: 'meetingFrequency', nodeId: NodeIds.alcoholicsAnonymousMeetingFrequency },
      ],
    },
    {
      path: 'alcoholAdvisedOrCeaseConsumptionDetails',
      children: [
        {
          path: 'hasBeenAdvisedReduceOrCeaseConsumption',
          nodeId: NodeIds.lifestyleInsuredAlcoholHasBeenAdvisedReduceOrCeaseConsumption,
        },
        { path: 'consumptionDate', nodeId: NodeIds.lifestyleInsuredAlcoholHasBeenAdvisedReduceOrCeaseConsumptionDate },
        {
          path: 'consumptionProfessionalContactInformation',
          nodeId: NodeIds.lifestyleInsuredAlcoholHasBeenAdvisedReduceOrCeaseConsumptionProfessionalContactInformation,
        },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const secondary: AnswerPathTreeNode = {
  path: 'secondary',
  children: [
    {
      path: 'child',
      children: [
        {
          path: 'rider',
          nodeId: NodeIds.secondaryChildRiderInfo,
          isCollection: true,
          children: [
            { path: 'lastName', nodeId: NodeIds.secondaryChildRiderLastName },
            { path: 'firstName', nodeId: NodeIds.secondaryChildRiderFirstName },
            { path: 'middleName', nodeId: NodeIds.secondaryChildRiderMiddleName },
            { path: 'sex', nodeId: NodeIds.secondaryChildRiderSex },
            { path: 'dateOfBirth', nodeId: NodeIds.secondaryChildRiderDateOfBirth },
            { path: 'height', nodeId: NodeIds.secondaryChildRiderHeight },
            { path: 'weight', nodeId: NodeIds.secondaryChildRiderWeight },
            { path: 'relationship', nodeId: NodeIds.secondaryChildRiderRelationship },
            { path: 'dateAndReasonLastConsultation', nodeId: NodeIds.secondaryChildRiderDateAndReasonLastConsultation },
            { path: 'results', nodeId: NodeIds.secondaryChildRiderResults },
            { path: 'treatmentAndFollowUp', nodeId: NodeIds.secondaryChildRiderTreatmentAndFollowUp },
            { path: 'physicianInformation', nodeId: NodeIds.secondaryChildRiderPhysicianInformation },
            { path: 'prematureBirth', nodeId: NodeIds.secondaryChildRiderPrematureBirth },
            { path: 'medicalInformation', nodeId: NodeIds.secondaryChildRiderMedicalInformation },
            { path: 'medicalInformationDetails', nodeId: NodeIds.secondaryChildRiderMedicalInformationDetails },
            { path: 'otherMedicalInformation', nodeId: NodeIds.secondaryChildRiderOtherMedicalInformation },
            {
              path: 'otherMedicalInformationDetails',
              nodeId: NodeIds.secondaryChildRiderOtherMedicalInformationDetails,
            },
            { path: 'hasNotYetConsulted', nodeId: NodeIds.secondaryChildRiderNotYetConsulted },
            {
              path: 'notYetConsultedDetails',
              nodeId: NodeIds.secondaryChildRiderNotYetConsultedDetails,
            },
            { path: 'familyHistory', nodeId: NodeIds.secondaryChildRiderFamilyHistory },
            { path: 'historyDetails', nodeId: NodeIds.secondaryChildRiderHistoryDetails },
            { path: 'address', nodeId: NodeIds.secondaryChildRiderAddress },
            { path: 'apartment', nodeId: NodeIds.secondaryChildRiderApartment },
            { path: 'city', nodeId: NodeIds.secondaryChildRiderCity },
            { path: 'postalCode', nodeId: NodeIds.secondaryChildRiderPostalCode },
            { path: 'occupation', nodeId: NodeIds.secondaryChildRiderOccupation },
            { path: 'street', nodeId: NodeIds.secondaryChildRiderStreet },
          ],
        },
        {
          path: 'childLimiter',
          children: [
            { path: 'riderLimiter', nodeId: NodeIds.secondaryChildRiderLimiter },
            { path: 'endorsementLimiter', nodeId: NodeIds.secondaryChildrenEndorsementLimiter },
          ],
        },
        {
          path: 'criticalIllnessRider',
          nodeId: NodeIds.secondaryChildCriticalIllnessRiderInfo,
          isCollection: true,
          children: [
            { path: 'lastName', nodeId: NodeIds.secondaryChildCriticalIllnessRiderLastName },
            { path: 'firstName', nodeId: NodeIds.secondaryChildCriticalIllnessRiderFirstName },
            { path: 'middleName', nodeId: NodeIds.secondaryChildCriticalIllnessRiderMiddleName },
            { path: 'sex', nodeId: NodeIds.secondaryChildCriticalIllnessRiderSex },
            { path: 'dateOfBirth', nodeId: NodeIds.secondaryChildCriticalIllnessRiderDateOfBirth },
            { path: 'height', nodeId: NodeIds.secondaryChildCriticalIllnessRiderHeight },
            { path: 'weight', nodeId: NodeIds.secondaryChildCriticalIllnessRiderWeight },
            { path: 'relationship', nodeId: NodeIds.secondaryChildCriticalIllnessRiderRelationship },
            {
              path: 'dateAndReasonLastConsultation',
              nodeId: NodeIds.secondaryChildCriticalIllnessRiderDateAndReasonLastConsultation,
            },
            { path: 'results', nodeId: NodeIds.secondaryChildCriticalIllnessRiderResults },
            { path: 'treatmentAndFollowUp', nodeId: NodeIds.secondaryChildCriticalIllnessRiderTreatmentAndFollowUp },
            { path: 'physicianInformation', nodeId: NodeIds.secondaryChildCriticalIllnessRiderPhysicianInformation },
            { path: 'prematureBirth', nodeId: NodeIds.secondaryChildCriticalIllnessRiderPrematureBirth },
            { path: 'medicalInformation', nodeId: NodeIds.secondaryChildCriticalIllnessRiderMedicalInformation },
            {
              path: 'medicalInformationDetails',
              nodeId: NodeIds.secondaryChildCriticalIllnessRiderMedicalInformationDetails,
            },
            {
              path: 'otherMedicalInformation',
              nodeId: NodeIds.secondaryChildCriticalIllnessRiderOtherMedicalInformation,
            },
            {
              path: 'otherMedicalInformationDetails',
              nodeId: NodeIds.secondaryChildCriticalIllnessRiderOtherMedicalInformationDetails,
            },
            {
              path: 'hasNotYetConsulted',
              nodeId: NodeIds.secondaryChildCriticalIllnessRiderNotYetConsulted,
            },
            {
              path: 'notYetConsultedDetails',
              nodeId: NodeIds.secondaryChildCriticalIllnessRiderNotYetConsultedDetails,
            },
            { path: 'familyHistory', nodeId: NodeIds.secondaryChildCriticalIllnessRiderFamilyHistory },
            { path: 'historyDetails', nodeId: NodeIds.secondaryChildCriticalIllnessRiderHistoryDetails },
            { path: 'address', nodeId: NodeIds.secondaryChildCriticalIllnessRiderAddress },
            { path: 'apartment', nodeId: NodeIds.secondaryChildCriticalIllnessRiderApartment },
            { path: 'city', nodeId: NodeIds.secondaryChildCriticalIllnessRiderCity },
            { path: 'postalCode', nodeId: NodeIds.secondaryChildCriticalIllnessRiderPostalCode },
            { path: 'occupation', nodeId: NodeIds.secondaryChildCriticalIllnessRiderOccupation },
            { path: 'street', nodeId: NodeIds.secondaryChildCriticalIllnessRiderStreet },
          ],
        },
      ],
    },
    {
      path: 'pastChild',
      children: [
        {
          path: 'riderApplicationInfo',
          children: [
            { path: 'riderApplication', nodeId: NodeIds.secondaryPastChildRiderApplication },
            {
              path: 'criticalIllnessRiderApplication',
              nodeId: NodeIds.secondaryPastChildCriticalIllnessRiderApplication,
            },
          ],
        },
        {
          path: 'riderApplication',
          nodeId: NodeIds.secondaryPastChildRiderApplicationInfo,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.secondaryPastChildRiderApplicationName },
            { path: 'firstName', nodeId: NodeIds.secondaryPastChildRiderApplicationFirstName },
            { path: 'lastName', nodeId: NodeIds.secondaryPastChildRiderApplicationLastName },
            { path: 'choices', nodeId: NodeIds.secondaryPastChildRiderApplicationChoices },
            { path: 'date', nodeId: NodeIds.secondaryPastChildRiderApplicationDate },
            { path: 'reason', nodeId: NodeIds.secondaryPastChildRiderApplicationReason },
          ],
        },
        {
          path: 'criticalIllnessRiderApplication',
          nodeId: NodeIds.secondaryPastChildCriticalIllnessRiderApplicationInfo,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.secondaryPastChildCriticalIllnessRiderApplicationName },
            { path: 'firstName', nodeId: NodeIds.secondaryPastChildCriticalIllnessRiderApplicationFirstName },
            { path: 'lastName', nodeId: NodeIds.secondaryPastChildCriticalIllnessRiderApplicationLastName },
            { path: 'choices', nodeId: NodeIds.secondaryPastChildCriticalIllnessRiderApplicationChoices },
            { path: 'date', nodeId: NodeIds.secondaryPastChildCriticalIllnessRiderApplicationDate },
            { path: 'reason', nodeId: NodeIds.secondaryPastChildCriticalIllnessRiderApplicationReason },
          ],
        },
      ],
    },
    {
      path: 'replacementInsurance',
      children: [
        { path: 'childRiderHistory', nodeId: NodeIds.secondaryReplacementInsuranceChildRiderHistory },
        { path: 'childEndorsementHistory', nodeId: NodeIds.secondaryInsuranceChildEndorsementHistory },
        {
          path: 'childRider',
          nodeId: NodeIds.secondaryReplacementInsuranceChildRiderInfo,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.secondaryReplacementInsuranceChildRiderName },
            { path: 'lastName', nodeId: NodeIds.secondaryReplacementInsuranceChildRiderLastName },
            { path: 'firstName', nodeId: NodeIds.secondaryReplacementInsuranceChildRiderFirstName },
            { path: 'choices', nodeId: NodeIds.secondaryReplacementInsuranceChildRiderChoices },
            { path: 'companyName', nodeId: NodeIds.secondaryReplacementInsuranceChildRiderCompanyName },
            { path: 'amount', nodeId: NodeIds.secondaryReplacementInsuranceChildRiderAmount },
            { path: 'date', nodeId: NodeIds.secondaryReplacementInsuranceChildRiderDate },
            { path: 'pending', nodeId: NodeIds.secondaryReplacementInsuranceChildRiderPending },
          ],
        },
        {
          path: 'childCriticalIllnessRider',
          nodeId: NodeIds.secondaryReplacementInsuranceChildCriticalIllnessRiderInfo,
          isCollection: true,
          children: [
            { path: 'name', nodeId: NodeIds.secondaryReplacementInsuranceChildCriticalIllnessRiderName },
            { path: 'lastName', nodeId: NodeIds.secondaryReplacementInsuranceChildCriticalIllnessRiderLastName },
            { path: 'firstName', nodeId: NodeIds.secondaryReplacementInsuranceChildCriticalIllnessRiderFirstName },
            { path: 'choices', nodeId: NodeIds.secondaryReplacementInsuranceChildCriticalIllnessRiderChoices },
            { path: 'companyName', nodeId: NodeIds.secondaryReplacementInsuranceChildCriticalIllnessRiderCompanyName },
            { path: 'amount', nodeId: NodeIds.secondaryReplacementInsuranceChildCriticalIllnessRiderAmount },
            { path: 'date', nodeId: NodeIds.secondaryReplacementInsuranceChildCriticalIllnessRiderDate },
            { path: 'pending', nodeId: NodeIds.secondaryReplacementInsuranceChildCriticalIllnessRiderPending },
          ],
        },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const epicondylitis: AnswerPathTreeNode = {
  path: 'epicondylitis',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredEpicondylitisIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredEpicondylitisPreviousEpisodesCount },
    { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredEpicondylitisDateSymptoms },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredEpicondylitisPainLocation },
        { path: 'leftRight', nodeId: NodeIds.healthInsuredEpicondylitisPainLocationLeftRight },
        { path: 'other', nodeId: NodeIds.healthInsuredEpicondylitisPainLocationOther },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredEpicondylitisPainCause },
        { path: 'details', nodeId: NodeIds.healthInsuredEpicondylitisPainCauseDetails },
      ],
    },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredEpicondylitisHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredEpicondylitisHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredEpicondylitisMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredEpicondylitisHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredEpicondylitisOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredEpicondylitisTypeOfOperationPerformed,
        },
        { path: 'performedDate', nodeId: NodeIds.healthInsuredEpicondylitisOperationPerformedDate },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredEpicondylitisTypeOfOperationPlanned,
        },
        { path: 'plannedDate', nodeId: NodeIds.healthInsuredEpicondylitisOperationPlannedDate },
        { path: 'unknownDate', nodeId: NodeIds.healthInsuredEpicondylitisOperationUnknownDate },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredEpicondylitisHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredEpicondylitisAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredEpicondylitisAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredEpicondylitisAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredEpicondylitisHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredEpicondylitisRecoveryDate },
            {
              path: 'notRecoveredDetails',
              nodeId: NodeIds.healthInsuredEpicondylitisAbsenceNotRecoveredDetails,
            },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredEpicondylitisHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredEpicondylitisHasConsultedDoctorsProfession },
        { path: 'professionDetails', nodeId: NodeIds.healthInsuredEpicondylitisHasConsultedDoctorsProfessionDetails },
        { path: 'name', nodeId: NodeIds.healthInsuredEpicondylitisHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredEpicondylitisHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredEpicondylitisHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredEpicondylitisHasConsultedDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredEpicondylitisHasConsultedDoctorsResult },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredEpicondylitisHasConsultedDoctorsFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredEpicondylitisHasConsultedDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredEpicondylitisHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredEpicondylitisHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredEpicondylitisHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredEpicondylitisHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredEpicondylitisHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredEpicondylitisHasTakenTestsOrExamsResult },
        { path: 'abnormalResult', nodeId: NodeIds.healthInsuredEpicondylitisHasTakenTestsOrExamsDetailsAbnormalResult },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredEpicondylitisMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredEpicondylitisMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredEpicondylitisMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredEpicondylitisMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredEpicondylitisMedicationEndDate },
      ],
    },
  ],
};

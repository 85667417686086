import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const child: AnswerPathTreeNode = {
  path: 'child',
  children: [
    {
      path: 'rider',
      nodeId: NodeIds.childRiderInfo,
      isCollection: true,
      children: [
        { path: 'lastName', nodeId: NodeIds.childRiderLastName },
        { path: 'firstName', nodeId: NodeIds.childRiderFirstName },
        { path: 'middleName', nodeId: NodeIds.childRiderMiddleName },
        { path: 'sex', nodeId: NodeIds.childRiderSex },
        { path: 'dateOfBirth', nodeId: NodeIds.childRiderDateOfBirth },
        { path: 'height', nodeId: NodeIds.childRiderHeight },
        { path: 'weight', nodeId: NodeIds.childRiderWeight },
        { path: 'relationship', nodeId: NodeIds.childRiderRelationship },
        { path: 'dateAndReasonLastConsultation', nodeId: NodeIds.childRiderDateAndReasonLastConsultation },
        { path: 'results', nodeId: NodeIds.childRiderResults },
        { path: 'treatmentAndFollowUp', nodeId: NodeIds.childRiderTreatmentAndFollowUp },
        { path: 'physicianInformation', nodeId: NodeIds.childRiderPhysicianInformation },
        { path: 'prematureBirth', nodeId: NodeIds.childRiderPrematureBirth },
        { path: 'medicalInformation', nodeId: NodeIds.childRiderMedicalInformation },
        { path: 'medicalInformationDetails', nodeId: NodeIds.childRiderMedicalInformationDetails },
        { path: 'otherMedicalInformation', nodeId: NodeIds.childRiderOtherMedicalInformation },
        { path: 'otherMedicalInformationDetails', nodeId: NodeIds.childRiderOtherMedicalInformationDetails },
        { path: 'hasNotYetConsulted', nodeId: NodeIds.childRiderNotYetConsulted },
        { path: 'notYetConsultedDetails', nodeId: NodeIds.childRiderNotYetConsultedDetails },
        { path: 'familyHistory', nodeId: NodeIds.childRiderFamilyHistory },
        { path: 'historyDetails', nodeId: NodeIds.childRiderHistoryDetails },
        { path: 'address', nodeId: NodeIds.childRiderAddress },
        { path: 'apartment', nodeId: NodeIds.childRiderApartment },
        { path: 'city', nodeId: NodeIds.childRiderCity },
        { path: 'postalCode', nodeId: NodeIds.childRiderPostalCode },
        { path: 'occupation', nodeId: NodeIds.childRiderOccupation },
        { path: 'street', nodeId: NodeIds.childRiderStreet },
      ],
    },
    {
      path: 'riderLimiter',
      children: [
        { path: 'limiter', nodeId: NodeIds.childRiderLimiter },
        { path: 'endorsementLimiter', nodeId: NodeIds.childrenEndorsementLimiter },
      ],
    },
    {
      path: 'criticalIllnessRider',
      nodeId: NodeIds.childCriticalIllnessRiderInfo,
      isCollection: true,
      children: [
        { path: 'lastName', nodeId: NodeIds.childCriticalIllnessRiderLastName },
        { path: 'firstName', nodeId: NodeIds.childCriticalIllnessRiderFirstName },
        { path: 'middleName', nodeId: NodeIds.childCriticalIllnessRiderMiddleName },
        { path: 'sex', nodeId: NodeIds.childCriticalIllnessRiderSex },
        { path: 'dateOfBirth', nodeId: NodeIds.childCriticalIllnessRiderDateOfBirth },
        { path: 'height', nodeId: NodeIds.childCriticalIllnessRiderHeight },
        { path: 'weight', nodeId: NodeIds.childCriticalIllnessRiderWeight },
        { path: 'relationship', nodeId: NodeIds.childCriticalIllnessRiderRelationship },
        {
          path: 'dateAndReasonLastConsultation',
          nodeId: NodeIds.childCriticalIllnessRiderDateAndReasonLastConsultation,
        },
        { path: 'results', nodeId: NodeIds.childCriticalIllnessRiderResults },
        { path: 'treatmentAndFollowUp', nodeId: NodeIds.childCriticalIllnessRiderTreatmentAndFollowUp },
        { path: 'physicianInformation', nodeId: NodeIds.childCriticalIllnessRiderPhysicianInformation },
        { path: 'prematureBirth', nodeId: NodeIds.childCriticalIllnessRiderPrematureBirth },
        { path: 'medicalInformation', nodeId: NodeIds.childCriticalIllnessRiderMedicalInformation },
        { path: 'medicalInformationDetails', nodeId: NodeIds.childCriticalIllnessRiderMedicalInformationDetails },
        { path: 'otherMedicalInformation', nodeId: NodeIds.childCriticalIllnessRiderOtherMedicalInformation },
        {
          path: 'otherMedicalInformationDetails',
          nodeId: NodeIds.childCriticalIllnessRiderOtherMedicalInformationDetails,
        },
        {
          path: 'hasNotYetConsulted',
          nodeId: NodeIds.childCriticalIllnessRiderNotYetConsulted,
        },
        {
          path: 'notYetConsultedDetails',
          nodeId: NodeIds.childCriticalIllnessRiderNotYetConsultedDetails,
        },
        { path: 'familyHistory', nodeId: NodeIds.childCriticalIllnessRiderFamilyHistory },
        { path: 'historyDetails', nodeId: NodeIds.childCriticalIllnessRiderHistoryDetails },
        { path: 'address', nodeId: NodeIds.childCriticalIllnessRiderAddress },
        { path: 'apartment', nodeId: NodeIds.childCriticalIllnessRiderApartment },
        { path: 'city', nodeId: NodeIds.childCriticalIllnessRiderCity },
        { path: 'postalCode', nodeId: NodeIds.childCriticalIllnessRiderPostalCode },
        { path: 'occupation', nodeId: NodeIds.childCriticalIllnessRiderOccupation },
        { path: 'street', nodeId: NodeIds.childCriticalIllnessRiderStreet },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const insomnia: AnswerPathTreeNode = {
  path: 'insomnia',
  children: [
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredInsomniaMedicationWasPrescribed },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredInsomniaHasConsultedDoctors },
    {
      path: 'hasOrWillConsultOtherDoctorsOrTherapists',
      nodeId: NodeIds.healthInsuredInsomniaHasOrWillConsultOtherDoctorsOrTherapists,
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredInsomniaHadAbsence },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredInsomniaAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredInsomniaAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredInsomniaAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredInsomniaMedicationDetails,
      isCollection: true,
      children: [
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredInsomniaMedicationName,
        },
        {
          path: 'dosage',
          nodeId: NodeIds.healthInsuredInsomniaMedicationDosage,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredInsomniaMedicationStartDate,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredInsomniaMedicationEndDate,
        },
        {
          path: 'stillUses',
          nodeId: NodeIds.healthInsuredInsomniaMedicationStillUses,
        },
        {
          path: 'hasChanged',
          nodeId: NodeIds.healthInsuredInsomniaMedicationHasChanged,
        },
        {
          path: 'hasChangedDetails',
          nodeId: NodeIds.healthInsuredInsomniaMedicationHasChangedDetails,
        },
      ],
    },
    {
      path: 'hasOrWillConsultDoctorsDetails',
      nodeId: NodeIds.healthInsuredInsomniaHasOrWillConsultDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredInsomniaHasOrWillConsultDoctorsProfession,
        },
        {
          path: 'professionOther',
          nodeId: NodeIds.healthInsuredInsomniaHasOrWillConsultDoctorsProfessionOther,
        },
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredInsomniaHasOrWillConsultDoctorsName,
        },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredInsomniaHasOrWillConsultDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredInsomniaHasOrWillConsultDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredInsomniaHasOrWillConsultDoctorsLastDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredInsomniaHasOrWillConsultDoctorsResult,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredInsomniaHasOrWillConsultDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredInsomniaHasOrWillConsultDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredInsomniaHasOrWillConsultDoctorsFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredInsomniaHasOrWillConsultDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hadAbsenceDetails',
      nodeId: NodeIds.healthInsuredInsomniaHadAbsenceDetails,
      isCollection: true,
      children: [
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredInsomniaAbsenceStartDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredInsomniaAbsenceFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredInsomniaAbsenceFrequency,
        },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const lifestyleInsuredAlcohol: AnswerPathTreeNode = {
  path: 'lifestyleInsuredAlcohol',
  children: [
    {
      path: 'alcoholUse',
      children: [
        { path: 'use', nodeId: NodeIds.lifestyleInsuredAlcoholUse },
        { path: 'frequency', nodeId: NodeIds.lifestyleInsuredAlcoholUseFrequency },
        { path: 'quantity', nodeId: NodeIds.lifestyleInsuredAlcoholUseQuantity },
        { path: 'history', nodeId: NodeIds.lifestyleInsuredAlcoholUseHistory },
        { path: 'historyFrequency', nodeId: NodeIds.lifestyleInsuredAlcoholUseHistoryFrequency },
        { path: 'historyQuantity', nodeId: NodeIds.lifestyleInsuredAlcoholUseHistoryQuantity },
      ],
    },
    {
      path: 'therapyOrTreatment',
      children: [
        { path: 'hasTherapy', nodeId: NodeIds.lifestyleInsuredAlcoholHasTherapy },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredAlcoholTherapyOrTreatmentStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredAlcoholTherapyOrTreatmentEndDate },
        { path: 'type', nodeId: NodeIds.lifestyleInsuredAlcoholTherapyOrTreatmentType },
        {
          path: 'contactInformation',
          nodeId: NodeIds.lifestyleInsuredAlcoholTherapyOrTreatmentProfessionalContactInformation,
        },
        { path: 'additionnalPeriod', nodeId: NodeIds.lifestyleInsuredAlcoholTherapyOrTreatmentAdditionnalPeriod },
        {
          path: 'additionnalPeriodDetails',
          nodeId: NodeIds.lifestyleInsuredAlcoholTherapyOrTreatmentAdditionnalPeriodDetails,
        },
      ],
    },
    {
      path: 'supportGroup',
      children: [
        { path: 'meetings', nodeId: NodeIds.lifestyleInsuredAlcoholSupportGroupMeetings },
        { path: 'stillAttending', nodeId: NodeIds.lifestyleInsuredAlcoholSupportGroupStillAttending },
        { path: 'startDate', nodeId: NodeIds.lifestyleInsuredAlcoholSupportGroupStartDate },
        { path: 'endDate', nodeId: NodeIds.lifestyleInsuredAlcoholSupportGroupEndDate },
      ],
    },
    {
      path: 'changeHabitConsumption',
      children: [
        { path: 'date', nodeId: NodeIds.lifestyleInsuredAlcoholChangeHabitConsumptionDate },
        { path: 'reason', nodeId: NodeIds.lifestyleInsuredAlcoholChangeHabitConsumptionReason },
      ],
    },
  ],
};

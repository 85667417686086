import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const financialNeeds: AnswerPathTreeNode = {
  path: 'financialNeeds',
  children: [
    { path: 'insuredFinancing', nodeId: NodeIds.insuredFinancing },
    { path: 'termsOfTheAgreementFinancing', nodeId: NodeIds.termsOfTheAgreementFinancing },
    {
      path: 'educationalCoverage',
      children: [
        {
          path: 'lessExpensiveEducationFundRecommendedAmount',
          nodeId: NodeIds.lessExpensiveEducationFundRecommendedAmount,
        },
        {
          path: 'moreExpensiveEducationFundRecommendedAmount',
          nodeId: NodeIds.moreExpensiveEducationFundRecommendedAmount,
        },
        { path: 'useCalculator', nodeId: NodeIds.useCalculator },
        { path: 'numberOfChildrenCovered', nodeId: NodeIds.numberOfChildrenCovered },
        { path: 'numberOfYearsPerChild', nodeId: NodeIds.numberOfYearsPerChild },
        { path: 'amountPerSchoolYear', nodeId: NodeIds.amountPerSchoolYear },
        { path: 'customEducationFundRecommendedAmount', nodeId: NodeIds.customEducationFundRecommendedAmount },
      ],
    },
  ],
};

import {
  FieldTypes,
  BooleanFieldValidation,
  DateFieldValidation,
  InputFieldValidation,
  MoneyFieldValidation,
  NumberFieldValidation,
  PhoneFieldValidation,
  StringFieldValidation,
  AlwaysValid,
} from '@breathelife/types';

export function isOptionFieldType(fieldType: unknown): boolean {
  return (
    fieldType === FieldTypes.dropdown ||
    fieldType === FieldTypes.checkboxGroup ||
    fieldType === FieldTypes.radio ||
    fieldType === FieldTypes.checkbox
  );
}

export function isAgreeFieldType(fieldType: unknown): boolean {
  return fieldType === FieldTypes.agree;
}

export function isAddressAutocompleteFieldType(fieldType: unknown): boolean {
  return fieldType === FieldTypes.autocomplete;
}

// Select options in the frontend and changes applied in the backend should both use the same mapping between field type and validation.
export const fieldTypeToValidation = {
  [FieldTypes.agree]: BooleanFieldValidation,
  [FieldTypes.checkbox]: BooleanFieldValidation,
  [FieldTypes.button]: BooleanFieldValidation,
  [FieldTypes.input]: InputFieldValidation,
  [FieldTypes.number]: NumberFieldValidation,
  [FieldTypes.money]: MoneyFieldValidation,
  [FieldTypes.date]: DateFieldValidation,
  [FieldTypes.phone]: PhoneFieldValidation,
  [FieldTypes.currencyCard]: NumberFieldValidation,
  [FieldTypes.information]: StringFieldValidation,
  [FieldTypes.autocomplete]: StringFieldValidation,
  [FieldTypes.textarea]: StringFieldValidation,
  [FieldTypes.radio]: StringFieldValidation,
  [FieldTypes.dropdown]: StringFieldValidation,
  [FieldTypes.checkboxGroup]: StringFieldValidation,
  [FieldTypes.signature]: AlwaysValid,
  [FieldTypes.yearMonth]: DateFieldValidation,
};

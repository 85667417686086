import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const musculoskeletalFracture: AnswerPathTreeNode = {
  path: 'musculoskeletalFracture',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredMusculoskeletalFractureIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredMusculoskeletalFracturePreviousEpisodesCount },
    { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredMusculoskeletalFractureDateSymptoms },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredMusculoskeletalFracturePainLocation },
        { path: 'leftRight', nodeId: NodeIds.healthInsuredMusculoskeletalFracturePainLocationLeftRight },
        { path: 'other', nodeId: NodeIds.healthInsuredMusculoskeletalFracturePainLocationOther },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredMusculoskeletalFracturePainCause },
        { path: 'causeDetails', nodeId: NodeIds.healthInsuredMusculoskeletalFracturePainCauseDetails },
      ],
    },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredMusculoskeletalFractureMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredMusculoskeletalFractureHadOperation },
        {
          path: 'operationPerformedOrPlanned',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureTypeOfOperationPerformed,
        },
        { path: 'operationPerformedDate', nodeId: NodeIds.healthInsuredMusculoskeletalFractureOperationPerformedDate },
        { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthInsuredMusculoskeletalFractureTypeOfOperationPlanned },
        { path: 'operationPlannedDate', nodeId: NodeIds.healthInsuredMusculoskeletalFractureOperationPlannedDate },
        { path: 'operationUnknownDate', nodeId: NodeIds.healthInsuredMusculoskeletalFractureOperationUnknownDate },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredMusculoskeletalFractureHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredMusculoskeletalFractureAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredMusculoskeletalFractureAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredMusculoskeletalFractureAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredMusculoskeletalFractureAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredMusculoskeletalFractureAbsenceRecoveryDate },
            {
              path: 'notRecoveredDetails',
              nodeId: NodeIds.healthInsuredMusculoskeletalFractureAbsenceNotRecoveredDetails,
            },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasConsultedDoctorsProfession,
        },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasConsultedDoctorsProfessionDetails,
        },
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasConsultedDoctorsName,
        },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasConsultedDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasConsultedDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasConsultedDoctorsLastDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasConsultedDoctorsResult,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasConsultedDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasConsultedDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        {
          path: 'type',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasTakenTestsOrExamsType,
        },
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasTakenTestsOrExamsDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasTakenTestsOrExamsResult,
        },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredMusculoskeletalFractureMedicationDetails,
      isCollection: true,
      children: [
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureMedicationName,
        },
        {
          path: 'dosage',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureMedicationDosage,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureMedicationStartDate,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredMusculoskeletalFractureMedicationEndDate,
        },
      ],
    },
  ],
};

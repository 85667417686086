import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const gallstones: AnswerPathTreeNode = {
  path: 'gallstones',
  children: [
    { path: 'diagnosisDate', nodeId: NodeIds.healthInsuredGallstonesDiagnosisDate },
    { path: 'hadOperation', nodeId: NodeIds.healthInsuredGallstonesHadOperation },
    { path: 'operationDate', nodeId: NodeIds.healthInsuredGallstonesOperationDate },
    { path: 'pastSurgeryHasRecovered', nodeId: NodeIds.healthInsuredGallstonesPastSurgeryHasRecovered },
    { path: 'hasPendingSurgery', nodeId: NodeIds.healthInsuredGallstonesHasPendingSurgery },
    { path: 'pendingSurgeryDate', nodeId: NodeIds.healthInsuredGallstonesPendingSurgeryDate },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const respiratory: AnswerPathTreeNode = {
  path: 'respiratory',
  children: [
    { path: 'healthOtherRespiratoryDetails', nodeId: NodeIds.healthOtherRespiratoryDetails },
    { path: 'healthOtherRespiratoryChoices', nodeId: NodeIds.healthOtherRespiratoryChoices },
  ],
};

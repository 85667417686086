import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const policy: AnswerPathTreeNode = {
  path: 'policy',
  children: [
    { path: 'isMec', nodeId: NodeIds.policyIsMec },
    { path: 'holdingCourt', nodeId: NodeIds.policyHoldingCourt },
    {
      path: 'signature',
      children: [
        { path: 'city', nodeId: NodeIds.policySignatureCity },
        { path: 'stateOrProvince', nodeId: NodeIds.policySignatureStateOrProvince },
      ],
    },
    {
      path: 'insuranceProducer',
      children: [
        { path: 'npnNumber', nodeId: NodeIds.policyInsuranceProducerNPNNumber },
        { path: 'agentCode', nodeId: NodeIds.policyInsuranceProducerAgentCode },
        { path: 'agentEmail', nodeId: NodeIds.policyInsuranceProducerAgentEmail },
        { path: 'generalAgentName', nodeId: NodeIds.policyInsuranceGeneralAgentName },
        { path: 'generalAgentEmail', nodeId: NodeIds.policyInsuranceGeneralAgentEmail },
        { path: 'generalAgentCode', nodeId: NodeIds.policyInsuranceGeneralAgentCode },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const citizenship: AnswerPathTreeNode = {
  path: 'citizenship',
  children: [
    { path: 'isUnitedStatesCitizen', nodeId: NodeIds.insuredIsUnitedStatesCitizen },
    { path: 'numberOfYearsUSCitizen', nodeId: NodeIds.insuredNumberOfYearsUnitedStatesCitizen },
    { path: 'isCanadianCitizen', nodeId: NodeIds.insuredIsCanadianCitizen },
    { path: 'numberOfYearsCanadianCitizen', nodeId: NodeIds.insuredNumberOfYearsCanadianCitizen },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const fracture: AnswerPathTreeNode = {
  path: 'fracture',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredFractureIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredFracturePreviousEpisodesCount },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredFracturePainLocation },
        { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredFractureDateSymptoms },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredFracturePainCause },
        { path: 'causeDetails', nodeId: NodeIds.healthInsuredFracturePainCauseDetails },
      ],
    },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredFractureHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredFractureHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredFractureMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredFractureHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredFractureOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredFractureTypeOfOperationPerformed,
        },
        {
          path: 'performedDate',
          nodeId: NodeIds.healthInsuredFractureOperationPerformedDate,
        },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredFractureTypeOfOperationPlanned,
        },
        {
          path: 'plannedDate',
          nodeId: NodeIds.healthInsuredFractureOperationPlannedDate,
        },
        {
          path: 'unknownDate',
          nodeId: NodeIds.healthInsuredFractureOperationUnknownDate,
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredFractureHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredFractureAbsenceStartDate },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredFractureAbsenceFrequencyNumber,
        },
        { path: 'frequency', nodeId: NodeIds.healthInsuredFractureAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredFractureAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredFractureAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredFractureAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredFractureHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredFractureHasConsultedDoctorsProfession },
        { path: 'professionDetails', nodeId: NodeIds.healthInsuredFractureHasConsultedDoctorsProfessionDetails },
        { path: 'name', nodeId: NodeIds.healthInsuredFractureHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredFractureHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredFractureHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredFractureHasConsultedDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredFractureHasConsultedDoctorsResult },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredFractureHasConsultedDoctorsFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredFractureHasConsultedDoctorsFrequency },
        { path: 'furtherConsultation', nodeId: NodeIds.healthInsuredFractureHasConsultedDoctorFurtherConsultation },
        { path: 'nextConsultationDate', nodeId: NodeIds.healthInsuredFractureHasConsultedDoctorsNextConsultationDate },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredFractureHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredFractureHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredFractureHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredFractureHasTakenTestsOrExamsResult },
        { path: 'abnormalResult', nodeId: NodeIds.healthInsuredFractureHasTakenTestsOrExamsDetailsAbnormalResult },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredFractureMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredFractureMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredFractureMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredFractureMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredFractureMedicationEndDate },
      ],
    },
  ],
};

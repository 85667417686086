import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const arnoldsNeuralgia: AnswerPathTreeNode = {
  path: 'arnoldsNeuralgia',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaPreviousEpisodesCount },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaMedicationWasPrescribed },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaPainLocation },
        { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaDateSymptoms },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaPainCause },
        { path: 'details', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaPainCauseDetails },
      ],
    },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredArnoldsNeuralgiaOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredArnoldsNeuralgiaTypeOfOperationPerformed,
        },
        {
          path: 'performedDate',
          nodeId: NodeIds.healthInsuredArnoldsNeuralgiaOperationPerformedDate,
        },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredArnoldsNeuralgiaTypeOfOperationPlanned,
        },
        {
          path: 'plannedDate',
          nodeId: NodeIds.healthInsuredArnoldsNeuralgiaOperationPlannedDate,
        },
        {
          path: 'unknownDate',
          nodeId: NodeIds.healthInsuredArnoldsNeuralgiaOperationUnknownDate,
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasConsultedDoctorsProfession },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasConsultedDoctorsProfessionDetails,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasConsultedDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasConsultedDoctorsResult },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasConsultedDoctorsFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasConsultedDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasTakenTestsOrExamsResult },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredArnoldsNeuralgiaHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredArnoldsNeuralgiaMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredArnoldsNeuralgiaMedicationEndDate },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { bladder } from './bladder';
import { disordersOfTheKidney } from './disordersOfTheKidney';
import { genitalOrgans } from './genitalOrgans';
import { kidneyStone } from './kidneyStone';
import { prostate } from './prostate';
import { urinaryTractDisorder } from './urinaryTractDisorder';
import { urineAbnormalities } from './urineAbnormalities';

export const genitourinary: AnswerPathTreeNode = {
  path: 'genitourinary',
  children: [
    urinaryTractDisorder,
    urineAbnormalities,
    bladder,
    disordersOfTheKidney,
    genitalOrgans,
    kidneyStone,
    prostate,
  ],
};

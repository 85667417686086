import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const scoliosisLordosis: AnswerPathTreeNode = {
  path: 'scoliosisLordosis',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredScoliosisLordosisIsSingleEpisode },
    {
      path: 'previousEpisodesCount',
      nodeId: NodeIds.healthInsuredScoliosisLordosisPreviousEpisodesCount,
    },
    {
      path: 'painLocation',
      children: [
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredScoliosisLordosisPainLocation,
        },
        {
          path: 'dateSymptoms',
          nodeId: NodeIds.healthInsuredScoliosisLordosisDateSymptoms,
        },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredScoliosisLordosisPainCause },
        {
          path: 'details',
          nodeId: NodeIds.healthInsuredScoliosisLordosisPainCauseDetails,
        },
      ],
    },
    {
      path: 'hasConsultedDoctors',
      nodeId: NodeIds.healthInsuredScoliosisLordosisHasConsultedDoctors,
    },
    {
      path: 'hasTakenTestsOrExams',
      nodeId: NodeIds.healthInsuredScoliosisLordosisHasTakenTestsOrExams,
    },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredScoliosisLordosisMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredScoliosisLordosisHadOperation },
        {
          path: 'performedOrPlanned',
          nodeId: NodeIds.healthInsuredScoliosisLordosisOperationPerformedOrPlanned,
        },
        {
          path: 'typeOfOperationPerformed',
          nodeId: NodeIds.healthInsuredScoliosisLordosisTypeOfOperationPerformed,
        },
        {
          path: 'performedDate',
          nodeId: NodeIds.healthInsuredScoliosisLordosisOperationPerformedDate,
        },
        {
          path: 'typeOfOperationPlanned',
          nodeId: NodeIds.healthInsuredScoliosisLordosisTypeOfOperationPlanned,
        },
        {
          path: 'plannedDate',
          nodeId: NodeIds.healthInsuredScoliosisLordosisOperationPlannedDate,
        },
        {
          path: 'unknownDate',
          nodeId: NodeIds.healthInsuredScoliosisLordosisOperationUnknownDate,
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredScoliosisLordosisHadAbsence },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredScoliosisLordosisAbsenceStartDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredScoliosisLordosisAbsenceFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredScoliosisLordosisAbsenceFrequency,
        },
        {
          path: 'recovery',
          children: [
            {
              path: 'hasRecovered',
              nodeId: NodeIds.healthInsuredScoliosisLordosisAbsenceHasRecovered,
            },
            {
              path: 'recoveryDate',
              nodeId: NodeIds.healthInsuredScoliosisLordosisAbsenceRecoveryDate,
            },
            {
              path: 'notRecoveredDetails',
              nodeId: NodeIds.healthInsuredScoliosisLordosisAbsenceNotRecoveredDetails,
            },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredScoliosisLordosisHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredScoliosisLordosisHasConsultedDoctorsProfession },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredScoliosisLordosisHasConsultedDoctorsProfessionDetails,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredScoliosisLordosisHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredScoliosisLordosisHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredScoliosisLordosisHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredScoliosisLordosisHasConsultedDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredScoliosisLordosisHasConsultedDoctorsResult },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredScoliosisLordosisHasConsultedDoctorsFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredScoliosisLordosisHasConsultedDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredScoliosisLordosisHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredScoliosisLordosisHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredScoliosisLordosisHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredScoliosisLordosisHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredScoliosisLordosisHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredScoliosisLordosisHasTakenTestsOrExamsResult },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredScoliosisLordosisHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredScoliosisLordosisMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredScoliosisLordosisMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredScoliosisLordosisMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredScoliosisLordosisMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredScoliosisLordosisMedicationEndDate },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const highBloodPressure: AnswerPathTreeNode = {
  path: 'highBloodPressure',
  children: [
    { path: 'details', nodeId: NodeIds.healthInsuredHighBloodPressureDetails },
    { path: 'medicationDetails', nodeId: NodeIds.healthInsuredHighBloodPressureMedicationDetails },
    { path: 'noMedicationReason', nodeId: NodeIds.healthInsuredHighBloodPressureNoMedicationReason },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';
import { anyOtherRespiratoryDisorder } from './anyOtherRespiratoryDisorder';
import { asthma } from './asthma';
import { chronicBronchitis } from './chronicBronchitis';
import { copd } from './copd';
import { coughingUpBlood } from './coughingUpBlood';
import { cysticFibrosis } from './cysticFibrosis';
import { emphysema } from './emphysema';
import { respiratoryCondition } from './respiratoryCondition';
import { sarcoidosis } from './sarcoidosis';
import { shortnessOfBreath } from './shortnessOfBreath';
import { sleepApnea } from './sleepApnea';

export const respiratorySystem: AnswerPathTreeNode = {
  path: 'respiratorySystem',
  children: [
    { path: 'choices', nodeId: NodeIds.healthInsuredRespiratoryChoices },
    coughingUpBlood,
    cysticFibrosis,
    sarcoidosis,
    respiratoryCondition,
    sleepApnea,
    shortnessOfBreath,
    anyOtherRespiratoryDisorder,
    asthma,
    chronicBronchitis,
    copd,
    emphysema,
  ],
};

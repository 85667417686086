import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const muscularDystrophy: AnswerPathTreeNode = {
  path: 'muscularDystrophy',
  children: [
    { path: 'type', nodeId: NodeIds.healthInsuredMuscularDystrophyType },
    { path: 'diagnosisDate', nodeId: NodeIds.healthInsuredMuscularDystrophyDiagnosisDate },
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredMuscularDystrophyIsSingleEpisode },
    { path: 'previousEpisodesCount', nodeId: NodeIds.healthInsuredMuscularDystrophyPreviousEpisodesCount },
    {
      path: 'painLocation',
      children: [
        { path: 'location', nodeId: NodeIds.healthInsuredMuscularDystrophyPainLocation },
        { path: 'dateSymptoms', nodeId: NodeIds.healthInsuredMuscularDystrophyDateSymptoms },
        { path: 'locationLeftRight', nodeId: NodeIds.healthInsuredMuscularDystrophyPainLocationLeftRight },
        { path: 'locationOther', nodeId: NodeIds.healthInsuredMuscularDystrophyPainLocationOther },
      ],
    },
    {
      path: 'painCause',
      children: [
        { path: 'cause', nodeId: NodeIds.healthInsuredMuscularDystrophyPainCause },
        { path: 'causeDetails', nodeId: NodeIds.healthInsuredMuscularDystrophyPainCauseDetails },
      ],
    },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredMuscularDystrophyHasConsultedDoctors },
    { path: 'hasTakenTestsOrExams', nodeId: NodeIds.healthInsuredMuscularDystrophyHasTakenTestsOrExams },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredMuscularDystrophyMedicationWasPrescribed },
    {
      path: 'operation',
      children: [
        { path: 'hadOperation', nodeId: NodeIds.healthInsuredMuscularDystrophyHadOperation },
        { path: 'performedOrPlanned', nodeId: NodeIds.healthInsuredMuscularDystrophyOperationPerformedOrPlanned },
        { path: 'typeOfOperationPerformed', nodeId: NodeIds.healthInsuredMuscularDystrophyTypeOfOperationPerformed },
        { path: 'performedDate', nodeId: NodeIds.healthInsuredMuscularDystrophyOperationPerformedDate },
        { path: 'typeOfOperationPlanned', nodeId: NodeIds.healthInsuredMuscularDystrophyTypeOfOperationPlanned },
        { path: 'plannedDate', nodeId: NodeIds.healthInsuredMuscularDystrophyOperationPlannedDate },
        { path: 'unknownDate', nodeId: NodeIds.healthInsuredMuscularDystrophyOperationUnknownDate },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredMuscularDystrophyHadAbsence },
        { path: 'startDate', nodeId: NodeIds.healthInsuredMuscularDystrophyAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredMuscularDystrophyAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredMuscularDystrophyAbsenceFrequency },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredMuscularDystrophyAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredMuscularDystrophyAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredMuscularDystrophyAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'hasConsultedDoctorsDetails',
      nodeId: NodeIds.healthInsuredMuscularDystrophyHasConsultedDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredMuscularDystrophyHasConsultedDoctorsProfession },
        {
          path: 'professionDetails',
          nodeId: NodeIds.healthInsuredMuscularDystrophyHasConsultedDoctorsProfessionDetails,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredMuscularDystrophyHasConsultedDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredMuscularDystrophyHasConsultedDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredMuscularDystrophyHasConsultedDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredMuscularDystrophyHasConsultedDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredMuscularDystrophyHasConsultedDoctorsResult },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredMuscularDystrophyHasConsultedDoctorsFrequencyNumber,
        },
        { path: 'frequency', nodeId: NodeIds.healthInsuredMuscularDystrophyHasConsultedDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredMuscularDystrophyHasConsultedDoctorFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredMuscularDystrophyHasConsultedDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'hasTakenTestsOrExamsDetails',
      nodeId: NodeIds.healthInsuredMuscularDystrophyHasTakenTestsOrExamsDetails,
      isCollection: true,
      children: [
        { path: 'type', nodeId: NodeIds.healthInsuredMuscularDystrophyHasTakenTestsOrExamsType },
        { path: 'date', nodeId: NodeIds.healthInsuredMuscularDystrophyHasTakenTestsOrExamsDate },
        { path: 'result', nodeId: NodeIds.healthInsuredMuscularDystrophyHasTakenTestsOrExamsResult },
        {
          path: 'abnormalResult',
          nodeId: NodeIds.healthInsuredMuscularDystrophyHasTakenTestsOrExamsDetailsAbnormalResult,
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredMuscularDystrophyMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredMuscularDystrophyMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredMuscularDystrophyMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredMuscularDystrophyMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredMuscularDystrophyMedicationEndDate },
      ],
    },
  ],
};

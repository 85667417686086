import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const ownerIdentityVerification: AnswerPathTreeNode = {
  path: 'ownerIdentityVerification',
  children: [
    { path: 'identityVerification', nodeId: NodeIds.ownerIdentityVerification },
    { path: 'documentType', nodeId: NodeIds.ownerIdentityVerificationDocumentType },
    { path: 'documentTypeOther', nodeId: NodeIds.ownerIdentityVerificationDocumentTypeOther },
    { path: 'documentLastName', nodeId: NodeIds.ownerIdentityVerificationDocumentLastName },
    { path: 'documentFirstName', nodeId: NodeIds.ownerIdentityVerificationDocumentFirstName },
    { path: 'documentNumber', nodeId: NodeIds.ownerIdentityVerificationDocumentNumber },
    { path: 'documentJurisdiction', nodeId: NodeIds.ownerIdentityVerificationDocumentJurisdiction },
    { path: 'documentExpiryDate', nodeId: NodeIds.ownerIdentityVerificationDocumentExpiryDate },
    { path: 'dualProcessReason', nodeId: NodeIds.ownerIdentityVerificationDualProcessReason },
    { path: 'dualProcessReasonOther', nodeId: NodeIds.ownerIdentityVerificationDualProcessReasonOther },
    {
      path: 'dualProcessNameAddressDocumentType',
      nodeId: NodeIds.ownerIdentityVerificationDualProcessNameAddressDocumentType,
    },
    {
      path: 'dualProcessNameAddressDocumentIssuingAuthority',
      nodeId: NodeIds.ownerIdentityVerificationDualProcessNameAddressDocumentIssuingAuthority,
    },
    {
      path: 'dualProcessNameAddressDocumentIssuingJurisdiction',
      nodeId: NodeIds.ownerIdentityVerificationDualProcessNameAddressDocumentIssuingJurisdiction,
    },
    {
      path: 'dualProcessNameAddressDocumentReferenceNumber',
      nodeId: NodeIds.ownerIdentityVerificationDualProcessNameAddressDocumentReferenceNumber,
    },
    { path: 'dualProcessNameAddressDate', nodeId: NodeIds.ownerIdentityVerificationDualProcessNameAddressDate },
    {
      path: 'dualProcessNameBirthDateDocumentType',
      nodeId: NodeIds.ownerIdentityVerificationDualProcessNameBirthDateDocumentType,
    },
    {
      path: 'dualProcessNameBirthDateIssuingAuthority',
      nodeId: NodeIds.ownerIdentityVerificationDualProcessNameBirthDateIssuingAuthority,
    },
    {
      path: 'dualProcessNameBirthDateIssuingJurisdiction',
      nodeId: NodeIds.ownerIdentityVerificationDualProcessNameBirthDateIssuingJurisdiction,
    },
    {
      path: 'dualProcessNameBirthDateReferenceNumber',
      nodeId: NodeIds.ownerIdentityVerificationDualProcessNameBirthDateReferenceNumber,
    },
    {
      path: 'dualProcessNameBirthDateDate',
      nodeId: NodeIds.ownerIdentityVerificationDualProcessNameBirthDateDate,
    },
  ],
};

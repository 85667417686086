import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const disabilityOrAbsenceFromWork: AnswerPathTreeNode = {
  path: 'disabilityOrAbsenceFromWork',
  children: [
    { path: 'hadDisabilityOrAbsenceFromWork', nodeId: NodeIds.healthInsuredHadDisabilityOrAbsenceFromWork },
    {
      path: 'hadDisabilityOrAbsenceFromWorkDetails',
      nodeId: NodeIds.healthInsuredHadDisabilityOrAbsenceFromWorkDetails,
    },
  ],
};

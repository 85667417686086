import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';
import { aids } from './aids';
import { hiv } from './hiv';
import { lupus } from './lupus';
import { otherAutoImmuneSystemDisorder } from './otherAutoImmuneSystemDisorder';
import { scleroderma } from './scleroderma';

export const immuneSystem: AnswerPathTreeNode = {
  path: 'immuneSystem',
  children: [
    { path: 'hasCondition', nodeId: NodeIds.healthInsuredAutoImmuneSystemHasCondition },
    { path: 'choices', nodeId: NodeIds.healthInsuredAutoImmuneSystemChoices },
    scleroderma,
    aids,
    hiv,
    lupus,
    otherAutoImmuneSystemDisorder,
  ],
};
